// Customizable Area Start
import React from 'react'
import ContactusController from './ContactusController';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';


const sos1 = require('.././assets/sos1.png');
const sos2 = require('.././assets/sos2.png')


const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 870,
  bgcolor: 'background.paper',

  boxShadow: 24,
  p: 4,
};

export default class SoS extends ContactusController {
  constructor(props: any) {
    super(props);
    
  }
  
  render() {
    return (
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.props.openSoS}
          onClose={this.props.closeSoS}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          >
          <Fade in={this.props.openSoS}>
            <Box sx={style}>
              <div style={{ display: 'flex' }}>
                <div id='register' style={{ width: '382px', height: '333px', backgroundColor: '#ffd2d3', marginRight: '20px', borderRadius: '10px', padding: '20px' }}
                  onClick={() => { this.props.history.push("/RegisterServiceCall"); this.props.closeSoS();this.props.clearSearch() }}
                  >
                  <span style={{ fontSize: '30px' }}> Register a service call</span>
                  <img src={sos1} width={400} height={250} alt='sos1' style={{ padding: '65px 2px 0px' }} />
                </div>
                <div id='request' style={{ width: '382px', height: '333px', backgroundColor: '#a5f6df', marginLeft: '7px', borderRadius: '10px', padding: '20px' }}
                  onClick={() => { this.props.history.push("/RequestCallBack"); this.props.closeSoS();this.props.clearSearch() }}
                >
                  <span style={{ fontSize: '30px' }}> Request a call back from EZ batteries</span>
                  <img src={sos2} width={400} height={200} alt='sos2' style={{ paddingTop: '80px' }} />
                </div>
              </div>

            </Box>
          </Fade>
        </Modal>
      </div>
    )
  }
}
// Customizable Area End
