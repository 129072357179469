import React from 'react'
// Customizable Area Start


import { Grid, RadioGroup, FormControlLabel, Radio,withStyles } from "@material-ui/core"
import FilteroptionsController, {
    Props,
    configJSON
} from "./FilteroptionsController";

const CssRadio = withStyles({
    colorSecondary: {
        color: '#757575',
        '&$checked': {
            color: '#43CBA0',
          },
    },
    checked: {}
  })(Radio)
export default class FilterVehicleManufacturer extends FilteroptionsController {

    render() {
        return (
            <div>



                <div style={{ backgroundColor: "#fff" }}>



                    <div style={{ marginRight: "118px", marginLeft: "118px" }}>
                        <Grid item style={{ marginTop: "30px", overflowY: "scroll", maxHeight: "227px" }}>

                            {console.log("manufacture mil rahe.....", this.state.getmanufacturerData)
                            }
                            {this.state.getmanufacturerData.map((item: any, index: any) => {

                                return (
                                    <div key={index}
                                        style={{
                                            padding: "7px",
                                            border: "1px solid lightgrey",
                                           
                                            marginBottom: "10px"
                                        }}
                                    >
                                        <Grid container style={{ display: "flex" }}>
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label1"
                                                name="radio-buttons-group1">
                                                <FormControlLabel
                                                    checked={this.state.selectedValue == item?.attributes?.manufacturer}
                                                    value={item?.attributes?.manufacturer}
                                                    onChange={(e: any) => {
                                                        this.setState({ selectedValue: e.target.value });
                                                        localStorage.setItem("mainId", item?.id);
                                                        localStorage.setItem('manufactureName', item?.attributes?.manufacturer)

                                                    }
                                                    }
                                                    control={<CssRadio />} label={item?.attributes?.manufacturer} />

                                            </RadioGroup>
                                            
                                        </Grid>
                                    </div>
                                )
                            })}

                        </Grid>
                    </div>

                    
                </div>
               

                
            </div>
        )
    }
}

// Customizable Area End                           





