// Customizable Area Start
import React from 'react'
import ContactusController from './ContactusController';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Radio from '@material-ui/core/Radio';
import Container from '@material-ui/core/Container';

import RightIcon from '@material-ui/icons/ChevronRight';
import LeftIcon from '@material-ui/icons/ChevronLeft';
import '../../../components/src/assets/styles.css'

const battery = require('.././assets/red_battery.png')
export default class SelectBattery extends ContactusController {
  scrollRef: any;
  constructor(props: any) {
    super(props);
    
    this.scrollRef = React.createRef()
    
  }
  arr = [0, 1, 2, 3, 4, 5, 6];
  handleChange = (e: any) => {
    this.setState({ selectedBattery: e.target.value })
  }
  prev = (scrollRef: any) => {
    requestAnimationFrame(() => {
      const scrollLeft = scrollRef.current.scrollLeft;
      const itemWidth = parseInt(
        getComputedStyle(scrollRef.current.children[0]).width
        );
        scrollRef.current.scrollLeft = scrollLeft - itemWidth * 1;
      });
    };
    
    next = (scrollRef: any) => {
      requestAnimationFrame(() => {
        const scrollLeft = scrollRef.current.scrollLeft;
        const itemWidth = parseInt(
          getComputedStyle(scrollRef.current.children[0]).width
          );
          scrollRef.current.scrollLeft = scrollLeft + itemWidth * 1;
        });
      };
      render() {
        return (
          <Container component="main" maxWidth='lg'>
        <div style={{ margin: '20px 0px 20px 0px', fontSize: '25px', fontWeight: 'bold' }}>Please Select Battery</div>
        <Box
          sx={{
            bgcolor: 'white',
            padding: '0px 20px'
          }}
          >
          <div>
            <div style={{ display: 'flex',justifyContent:'space-between',alignItems:'center' }}>

              <div style={{ fontWeight: 'bold', margin: '15px 0px' }}>Please select the purchased battery for which you want to register a call</div>
              <div style={{ display: 'flex',margin: '15px 0px' }}>
                <div onClick={()=>this.prev(this.scrollRef)} style={{ cursor: 'pointer', border: '1px solid ##E6E8EA', borderRadius: '50%', padding: '3px', backgroundColor: '#ECF0F3' }}><LeftIcon /></div>
                <div onClick={()=>this.next(this.scrollRef)}  style={{ cursor: 'pointer', border: '1px solid ##E6E8EA', borderRadius: '50%', padding: '3px', backgroundColor: '#ECF0F3',marginLeft:'10px' }}><RightIcon /></div>
              </div>

            </div>
            {/* <div style={{ display: 'flex', overflowX: 'scroll', width: '100%', scrollbarWidth: 'none' }}> */}
            <div className='carouselContainer' ref={this.scrollRef}>
              {this.arr.map((item) => {
                return <div style={{ marginRight: '20px' }} className='itemScroll'>
                  <div style={{ padding: '20px', border: '1px solid #e8e9ed', display: 'flex' }}>
                    <Radio
                      checked={this.state.selectedBattery == item}
                      onChange={this.handleChange}
                      value={item}
                      name="radio-buttons"
                      inputProps={{ 'aria-label': 'A' }}
                      />
                    <img src={battery} alt='battery' width={100} />
                    <span style={{ display: 'flex', alignItems: 'center', marginLeft: '15px' }}>EZ34RR</span>
                  </div>
                </div>
              })}
            </div>
            <div style={{ marginTop: '20px' }}>

              <Divider />
            </div>
            <div style={{ display: 'flex',padding:'10px 0px' }}>
              <Radio
                onChange={this.handleChange}
                name="radio-buttons"
                inputProps={{ 'aria-label': 'A' }}
                />
              <span style={{ display: 'flex', alignItems: 'center',fontWeight:'bold' }}>Other Battery</span>
            </div>
          </div>


        </Box>
      </Container>
    )
  }
}
// Customizable Area End
