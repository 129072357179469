// Customizable Area Start
import React, { Component } from "react";
import MerchantOrderController from "./MerchantOrderController";

import {
  Box,
  Container,
  Modal,
  Button,
  ListItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Collapse,
  List,
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
  Switch,
  Radio,
  Paper,
  Avatar,
  Badge,
  Backdrop,
  Fade,
  Stepper,
  Step,
  StepLabel,
  TextField,
} from "@material-ui/core";

export default class FinalObservations extends MerchantOrderController {
  render() {
    return (
      //   <Box
      //     sx={{
      //       bgcolor: "background.paper",
      //       padding: "30px",
      //     }}
      //   >
      <Grid container spacing={2}>
        <Grid item xs={8} sm={12}>
          <div style={{ margin: "10px 0px" ,fontWeight:'bold'}}>Specific Gravity</div>
          <TextField
            multiline
            minRows={4}
            name="firstName"
            fullWidth
            id="firstName"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={8} sm={6}>
        <div style={{ margin: "10px 0px",fontWeight:'bold' }}>Open Voltage</div>
          <TextField
            autoComplete="given-name"
            name="firstName"
            fullWidth
            id="firstName"
           
            variant="outlined"
          />
        </Grid>
        <Grid item xs={8} sm={6}>
        <div style={{ margin: "10px 0px",fontWeight:'bold' }}>Load Voltage</div>
          <TextField
            autoComplete="given-name"
            name="firstName"
            fullWidth
            id="firstName"
            label="First Name"
            variant="outlined"
          />
        </Grid>

        <Grid item xs={8} sm={6}>
        <div style={{ margin: "10px 0px",fontWeight:'bold' }}>Charging Voltage</div>
          <TextField
            autoComplete="given-name"
            name="firstName"
            fullWidth
            id="firstName"
            label="First Name"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={8} sm={6}>
        <div style={{ margin: "10px 0px",fontWeight:'bold'  }}>Backup Time</div>
          <TextField
            fullWidth
            id="lastName"
            label="Mobile Number"
            name="Mobile Number"
            autoComplete="family-name"
            variant="outlined"
            
          />
        </Grid>

        <Grid item xs={8} sm={6}>
        <div style={{ margin: "10px 0px" ,fontWeight:'bold'}}>Battery Status</div>
          <Grid container spacing={1}>
            <Grid item xs={6} sm={4}>
              <div
                style={{
                  border: "1px solid black",
                  borderRadius: "20px",
                  height: "100px",
                }}
              >
                <div>
                  <Radio />
                </div>
                <div style={{ marginLeft: "10px" }}>Ok</div>
              </div>
            </Grid>
            <Grid item xs={6} sm={4}>
              <div
                style={{
                  border: "1px solid black",
                  borderRadius: "20px",
                  height: "100px",
                }}
              >
                <div>
                  <Radio />
                </div>
                <div style={{ marginLeft: "10px" }}>Needs Charging</div>
              </div>
            </Grid>
            <Grid item xs={6} sm={4}>
              <div
                style={{
                  border: "1px solid black",
                  borderRadius: "20px",
                  height: "100px",
                }}
              >
                <div>
                  <Radio />
                </div>
                <div style={{ marginLeft: "10px" }}>Needs Replacement</div>
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={8} sm={6}>
        <div style={{ margin: "10px 0px",fontWeight:'bold'  }}>Charging System</div>
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6}>
              <div
                style={{
                  border: "1px solid black",
                  borderRadius: "15px",

                  display: "flex",
                  alignItems: "center",
                  padding: "5px",
                }}
              >
                <div>
                  <Radio />
                </div>
                <div>Ok</div>
              </div>
            </Grid>
            <Grid item xs={6} sm={6}>
              <div
                style={{
                  border: "1px solid black",
                  borderRadius: "15px",

                  display: "flex",
                  alignItems: "center",
                  padding: "5px",
                }}
              >
                <div>
                  <Radio />
                </div>
                <div>Bad</div>
              </div>
            </Grid>
          </Grid>
        </Grid>

        {/* ---------------------------------------------------------------------------------------------- */}
        <Grid item xs={8} sm={6}>
          <div style={{ marginBottom: "10px",fontWeight:'bold' }}>Current Leakage</div>
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6}>
              <div
                style={{
                  border: "1px solid black",
                  borderRadius: "15px",

                  display: "flex",
                  alignItems: "center",
                  padding: "5px",
                }}
              >
                <div>
                  <Radio />
                </div>
                <div>Yes</div>
              </div>
            </Grid>
            <Grid item xs={6} sm={6}>
              <div
                style={{
                  border: "1px solid black",
                  borderRadius: "15px",

                  display: "flex",
                  alignItems: "center",
                  padding: "5px",
                }}
              >
                <div>
                  <Radio />
                </div>
                <div>No</div>
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={8} sm={6}>
          <div style={{ marginBottom: "10px",fontWeight:'bold' }}>Warranty</div>
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6}>
              <div
                style={{
                  border: "1px solid black",
                  borderRadius: "15px",

                  display: "flex",
                  alignItems: "center",
                  padding: "5px",
                }}
              >
                <div>
                  <Radio />
                </div>
                <div>In</div>
              </div>
            </Grid>
            <Grid item xs={6} sm={6}>
              <div
                style={{
                  border: "1px solid black",
                  borderRadius: "15px",

                  display: "flex",
                  alignItems: "center",
                  padding: "5px",
                }}
              >
                <div>
                  <Radio />
                </div>
                <div>Out</div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      //   </Box>
    );
  }
}
// Customizable Area End