// Customizable Area Start
import React, { Component } from 'react'
import MerchantDashboard2Controller from './MerchantDashboard2Controller';
import {
    Box,
    Container,
    Modal,
    Button,
    ListItem,
    IconButton,
    ListItemIcon,
    ListItemText,
    Divider,
    Collapse,
    List,
    Grid,
    Card,
    CardContent,
    CardActions,
    Typography,
    Switch, Radio, TextField, InputAdornment
    
} from "@material-ui/core";
import FooterImage from './FooterImage.web';
import SearchIcon from '@material-ui/icons/Search';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';

const battery = require(".././assets/red_battery.png");

export default class InventoryManagement extends MerchantDashboard2Controller {
    arr = [0, 1]
    render() {
        return (
            <>
                <div style={{ display: 'flex', justifyContent: 'flex-start', padding: '20px', backgroundColor: '#efefef' }}>
                    <div style={{ color: '#a1a0a4', marginLeft: '40px' }}>
                        <a href='/' style={{ textDecoration: 'none', color: '#a1a0a4' }}>Home</a> <ArrowForwardIosRoundedIcon className="arrow-left" /> Profile <ArrowForwardIosRoundedIcon className="arrow-left" /> Inventory Management
                    </div>

                </div>

                <Container style={{ marginTop: '20px' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={8}>
                            <div style={{display:'flex',alignItems:'center',marginTop:'15px'}}>

                            <TextField fullWidth variant='outlined' placeholder='Search Batteries'
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <span>
                                                <SearchIcon />
                                            </span>
                                        </InputAdornment>
                                    ),
                                }}
                                />

                                </div>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <div
                                style={{
                                    display: 'flex', justifyContent: 'space-between',
                                    backgroundColor:'white', padding: '20px',
                                    
                                }}>
                                <div style={{ fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                                    <img src={battery} width='50px' />
                                    <span style={{ marginLeft: '10px' }}>Total Batteries in Stock</span>
                                </div>
                                <div style={{ fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>145</div>
                            </div>

                        </Grid>
                    </Grid>

                    <Grid container style={{ marginTop: '20px' }}>
                        <Grid item xs={12} md={4}>

                            <Box sx={{
                                bgcolor: 'background.paper',
                                marginRight: '25px'
                            }}>
                                <nav aria-label="main mailbox folders">
                                    <List>

                                        <ListItem  >
                                            <ListItemText primary={'Inverter Batteries'} />

                                        </ListItem>
                                        <Divider />
                                        <ListItem  >
                                            <ListItemText primary={'Vehicular Batteries'} />

                                        </ListItem>
                                    </List>
                                </nav>

                            </Box>





                        </Grid>



                        <Grid item xs={12} md={8}>
                            <Box sx={{
                                bgcolor: 'background.paper',
                                padding: '30px'
                            }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={8} sm={6}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', border: '3px solid #f5f5f5', padding: '10px', borderRadius: '10px' }}>
                                            <div style={{ fontWeight: 'bold' }}>Total Batteries in stock</div>
                                            <div style={{ fontWeight: 'bold' }}>46</div>
                                        </div>

                                    </Grid>
                                    <Grid item xs={8} sm={6}>

                                    </Grid>

                                    {this.arr.map(() => {
                                        return <Grid item xs={8} sm={6}>
                                            <div
                                                style={{
                                                    display: 'flex', justifyContent: 'space-between',
                                                    border: '3px solid #f5f5f5', padding: '10px',
                                                    borderRadius: '10px'
                                                }}>
                                                <div style={{ fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                                                    <img src={battery} width='50px' />
                                                    <span style={{ marginLeft: '10px' }}>12EZ9B</span>
                                                </div>
                                                <div style={{ fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>23</div>
                                            </div>
                                        </Grid>
                                    })}



                                </Grid>

                            </Box>



                        </Grid>


                        {/* paid */}

                    </Grid>

                    <FooterImage navigation={this.props.navigation} id={this.props.id} />

                </Container>



            </>
        )
    }
}
// Customizable Area End
