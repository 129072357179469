// Customizable Area Start
import React, { Component } from 'react'
import { Box, Grid,  InputAdornment, TextField, Container, Button,Backdrop,CircularProgress } from "@material-ui/core";
import MenuItem from '@material-ui/core/MenuItem';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';

import UserProfileBasicController, {
    Props
} from "./UserProfileBasicController";


export default class UserProfileEditAddress extends UserProfileBasicController {
    render() {
        console.log(this.state.singleAddress)
        if (!localStorage.getItem("loginSuccessToken")) {
            this.props.navigation.navigate('signUp');
            return null

        }
        return (
            <div >
                <Backdrop
          style={{ color: '#fff', zIndex: 1 }}
          open={this.state.isLoading}

        > <CircularProgress color="inherit" /></Backdrop>


                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px', backgroundColor: '#efefef' }}>
                    <Container>

                    <div style={{ color: '#a1a0a4' }}>
                        <a href='/' style={{ textDecoration: 'none', color: '#a1a0a4' }}>Home</a> <ArrowForwardIosRoundedIcon className="arrow-left" /> Profile
                    </div>
                    </Container>

                </div>

                <Container style={{ marginTop: '20px' }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={3}>
                            <div style={{
                                height: "55px", border: "1px solid #F4F5F6", borderBottom: "none", borderTopRightRadius: "3px",
                                borderTopLeftRadius: "3px", cursor:'pointer', backgroundColor: "#fff"
                            }}onClick={()=>this.props.navigation.navigate('UserProfileBasicBlock')}>
                                <p style={{ margin: "18px", fontSize: "16px", fontWeight: "bold" }}>Profile Details</p>
                            </div>
                            <div style={{
                                height: "55px", border: "1px solid #F4F5F6", borderBottomRightRadius: "3px",
                                borderBottomLeftRadius: "3px", cursor:'pointer', backgroundColor: "#fff"
                            }}onClick={()=>this.props.navigation.navigate('UserAddressEditDelete')}>
                                <p style={{ margin: "18px", fontSize: "16px", fontWeight: "bold" }}>Saved Addresses</p>
                            </div>




                        </Grid>


                        <Grid item xs={12} md={8}>
                            <Box sx={{
                                bgcolor: 'background.paper',
                                padding: '30px'
                            }}>

                                <Grid container spacing={2}>


                                    <Grid item xs={8} sm={12}>
                                        <div style={{ margin: '10px 0px' }}>Edit Address</div>
                                        <TextField

                                            name="fullName"
                                            fullWidth
                                            id="firstName"

                                            variant='outlined'
                                            value={this.state.singleAddress?.name}
                                            placeholder="Full Name"
                                            onChange={(e: any) => {
                                                this.setState({ singleAddress:{...this.state.singleAddress, name:e.target.value} })


                                            }}
                                            InputProps={{

                                                startAdornment: (
                                                    <InputAdornment
                                                        position="start"

                                                    >
                                                        <span>
                                                            <PersonOutlineOutlinedIcon />
                                                        </span>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={8} sm={12}>
                                        <div style={{ margin: '10px 0px 5px 0px' }}>Address</div>
                                        <TextField

                                            fullWidth
                                            id="address"

                                            name="address"
                                            autoComplete="family-name"
                                            variant='outlined'
                                            value={this.state.singleAddress?.address}
                                            placeholder="Your Address"
                                            onChange={(e: any) => {
                                                this.setState({ singleAddress:{...this.state.singleAddress, address:e.target.value} })
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={8} sm={12}>
                                        <TextField
                                            autoComplete="given-name"
                                            name="country"
                                            fullWidth
                                            id="country"
                                            variant='outlined'
                                            value={this.state.singleAddress?.country}
                                            placeholder='Country'

                                            onChange={(e: any) => {
                                                this.setState({ singleAddress:{...this.state.singleAddress, country:e.target.value} })
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={8} sm={6}>
                                        <TextField
                                            fullWidth
                                            variant='outlined'
                                            id="outlined-select-currency"
                                            select
                                            label='State'

                                            value={this.state.singleAddress?.state ?? ''}
                                            onChange={(e: any) => {
                                                this.setState({ singleAddress:{...this.state.singleAddress, state:e.target.value} })
                                            }}

                                        >
                                            {this.state.stateDropdownData.map((d: any, i: any) => (
                                                <MenuItem key={i} value={d?.id}>
                                                    {d?.state}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={8} sm={6}>

                                        <TextField
                                            fullWidth
                                            variant='outlined'
                                            id="outlined-select-currency"
                                            select
                                            label='City'


                                            value={this.state.singleAddress?.city ?? ''}
                                            onChange={(e: any) => {
                                                this.setState({ singleAddress:{...this.state.singleAddress, city:e.target.value} })
                                            }}

                                        >

                                            {this.state.cityDropdownData.map((d: any, i: any) => (
                                                <MenuItem key={i} value={d?.id}>
                                                    {d?.city}
                                                </MenuItem>
                                            ))}
                                        </TextField>


                                    </Grid>
                                    <Grid item xs={8} sm={6}>
                                        <TextField
                                            fullWidth
                                            variant='outlined'
                                            id="outlined-select-currency"
                                            select
                                            label='District'

                                            value={this.state.singleAddress?.district ?? ''}
                                            onChange={(e: any) => {
                                                this.setState({ singleAddress:{...this.state.singleAddress, district:e.target.value} })
                                            }}
                                        >

                                            {this.state.distDropdownData.map((d: any, i: any) => (
                                                <MenuItem key={i} value={d?.id}>
                                                    {d?.district}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={8} sm={6}>
                                        <TextField
                                            autoComplete="given-name"
                                            name="firstName"
                                            fullWidth
                                            id="firstName"
                                            placeholder="Pin"
                                            value={this.state.singleAddress?.zip_code ?? ''}
                                            onChange={(e: any) => {
                                                this.setState({ singleAddress:{...this.state.singleAddress, zip_code:e.target.value} })
                                            }}
                                            variant='outlined'
                                        />
                                    </Grid>
                                    {/* ---------------------------------------------------------------------------------------------- */}
















                                </Grid>
                                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>

                                    <Button onClick={()=>{
                                        console.log(this.state.singleAddress)
                                        this.editAddress(this.state.singleAddress)
                                    }} style={{ backgroundColor: '#32cba1', padding: '10px 90px' }}>Save</Button>
                                </div>
                            </Box>



                        </Grid>
                    </Grid>



                </Container>

                

            </div>
        )
    }
}
// Customizable Area End