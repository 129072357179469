// Customizable Area Start
import React, { Component } from 'react'
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import {
    Box,
    Container,
    Modal,
    Button,
    ListItem,
    IconButton,
    ListItemIcon,
    ListItemText,
    Divider,
    Collapse,
    List,
    Grid,
    Card,
    CardContent,
    CardActions,
    Typography,
    Switch, Radio, Checkbox, Avatar, Paper

} from "@material-ui/core";

import MerchantDashboard2Controller from './MerchantDashboard2Controller';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import VisionStatementWeb from '../../../components/src/VisionStatement.web';



export default class ManageTimeSlots extends MerchantDashboard2Controller {
    arr = ['Product isnt functioning properly', 'Got a faulty product', 'Other Reason'];
    arr1 = [{ label: 'Exchange product with any other model', info: '*Extra charges may apply' }, { label: 'Exchange product with the same model', info: '*No Extra charges' }]

    category = [0, 1, 2, 3, 4, 5]
    manufacturer = [0, 1, 2, 3, 4, 5];
    products = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]
    handleCloseModal = () => {
        this.props.navigation.navigate('Orders')

    }
    steps = ['Return Reason', 'Return Method', 'Confirm Return'];



    render() {
        return (
            <>
                <div style={{ padding: '20px', backgroundColor: '#efefef' }}>
                    <Container>

                        <div  style={{ display: 'flex', justifyContent: 'space-between' }}>

                            <div  style={{ color: '#a1a0a4' }}>
                                <a   href='/' style={{ textDecoration: 'none', color: '#a1a0a4' }}>Home</a> <ArrowForwardIosRoundedIcon className="arrow-left" /> <span id="home" style={{ cursor: 'pointer' }} onClick={() => this.props.navigation.goBack()}>Profile</span>
                                <ArrowForwardIosRoundedIcon className="arrow-left" />Technician Management
                            </div>
                            {!localStorage.getItem("loginSuccessToken") && <div style={{ color: '#a1a0a4' }}>
                                <span>
                                    <i>For Better Experience</i>
                                    <span id="fbe" onClick={() => {
                                        this.props.navigation.navigate('EmailAccountLoginBlock')
                                    }} style={{ color: "red", fontWeight: 'bold', cursor: 'pointer' }}> Login/
                                    </span>
                                    <span id="signup" onClick={() => {
                                        this.props.navigation.navigate('signUp')
                                    }} style={{ color: "red", fontWeight: 'bold', cursor: 'pointer' }}>Register
                                    </span>
                                </span>
                            </div>
                            }
                        </div>
                    </Container>
                </div>
                <Container style={{ marginTop: '20px' }}>
                   
                    <Grid container>
                        <Grid item xs={12} md={3}>

                            <Box sx={{
                                marginRight: '25px',
                                bgcolor: 'background.paper'
                            }}>
                                <nav aria-label="main mailbox folders">
                                    <List>

                                        <ListItem  >
                                            <ListItemText primary={'Manage Time Slot'} />

                                        </ListItem>
                                       
                                    </List>
                                </nav>



                            </Box>


                            




                        </Grid>

                        <Grid item xs={12} md={8}>
                            <Box sx={{
                                bgcolor: 'background.paper',
                                padding: '30px'
                            }}>
                                <div style={{display:'flex',justifyContent:'center'}}>

                                <Calendar/>
                                </div>
                                

                               
                            </Box>







                        </Grid>



                    </Grid>
                    <VisionStatementWeb classes={undefined} id={''} navigation={undefined} openSearch={undefined}/>

                </Container>

                

            </>
        )
    }
}
// Customizable Area End