// Customizable Area Start
import React, { Component } from "react";
import MerchantOrderController from "./MerchantOrderController";


import {
    Box,
    Container,
    Modal,
    Button,
    ListItem,
    IconButton,
    ListItemIcon,
    ListItemText,
    Divider,
    Collapse,
    List,
    Grid,
    Card,
    CardContent,
    CardActions,
    Typography,
    Switch,
    Radio,
    Paper,
    Avatar,
    Badge,
    Backdrop,
    Fade,
  } from "@material-ui/core";

  import Person from "@material-ui/icons/Person";
import Right from "@material-ui/icons/ArrowForward";
import Close from "@material-ui/icons/Close";

import Place from "@material-ui/icons/Place";
import Mail from "@material-ui/icons/Mail";
import Copy from "@material-ui/icons/FileCopy";
import DateRange from "@material-ui/icons/DateRange";

import Bell from "@material-ui/icons/NotificationsNone";

export default class Notification extends MerchantOrderController {
  render() {
    return (
        <>
      <Paper elevation={2}>
        <div
          style={{ float: "right", cursor: "pointer" }}
          onClick={() =>
            this.setState({
              toggleNotification: !this.state.toggleNotification,
            })
          }
        >
          <Badge
            style={{ backgroundColor: "white" }}
            color="secondary"
            overlap="circle"
            badgeContent=" "
            variant="dot"
            >
            <Bell />
          </Badge>
        </div>
      </Paper>

      {this.state.toggleNotification && (
                <Paper elevation={3}>
                  <Box
                    sx={{
                      bgcolor: "background.paper",
                      width: "400px",
                      position: "absolute",
                      top: "5%",
                      right: "15%",
                    }}
                  >
                    <div style={{ padding: "10px" }}>
                      

                      <div>
                        <div style={{ fontSize: "small", padding: "10px 0px" }}>
                          PERSONALIZATION
                        </div>
                        <div style={{ display: "flex" }}>
                          <div style={{ padding: "0px 10px 0px 0px" }}>
                            <Person />
                          </div>
                          <div
                            onClick={() =>
                              this.props.navigation.navigate("ProfileDetails")
                            }
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              cursor: "pointer",
                            }}
                          >
                            <small style={{ fontWeight: "bold" }}>
                              Profile Details
                            </small>
                            <small>Manage Your Information</small>
                          </div>
                        </div>

                        <div style={{ display: "flex", padding: "10px 0px" }}>
                          <div style={{ padding: "0px 10px 0px 0px" }}>
                            <Person />
                          </div>
                          <div
                            
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              cursor: "pointer",
                            }}
                          >
                            <small style={{ fontWeight: "bold" }}>
                              Language
                            </small>
                            <small>Toggle Between English and Hindi</small>
                          </div>
                        </div>
                      </div>
                      <Divider />

                      <div>
                        <div style={{ fontSize: "small", padding: "10px 0px" }}>
                          ORDERS AND MORE
                        </div>
                        <div style={{ display: "flex" }}>
                          <div style={{ padding: "0px 10px 0px 0px" }}>
                            <Person />
                          </div>
                          <div
                            onClick={() =>
                              this.props.navigation.navigate(
                                "InvoicesBillingHistory"
                              )
                            }
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              cursor: "pointer",
                            }}
                          >
                            <small style={{ fontWeight: "bold" }}>
                              Invoices and Billing History
                            </small>
                            <small>
                              Find Invoices for upcoming and past payments
                            </small>
                          </div>
                        </div>

                        <div style={{ display: "flex", padding: "10px 0px" }}>
                          <div style={{ padding: "0px 10px 0px 0px" }}>
                            <Person />
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <small style={{ fontWeight: "bold" }}>Orders</small>
                            <small>Find Order Updates</small>
                          </div>
                        </div>
                        <div style={{ display: "flex", padding: "10px 0px" }}>
                          <div style={{ padding: "0px 10px 0px 0px" }}>
                            <Person />
                          </div>
                          <div
                            onClick={() =>
                              this.props.navigation.navigate(
                                "TechnicianManagement"
                              )
                            }
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              cursor: "pointer",
                            }}
                          >
                            <small style={{ fontWeight: "bold" }}>
                              Technician Management
                            </small>
                            <small>Manage Your Technicians Here</small>
                          </div>
                        </div>
                      </div>
                      <Divider />
                      <div>
                        <div style={{ fontSize: "small", padding: "10px 0px" }}>
                          FOR CUSTOMERS
                        </div>
                        <div style={{ display: "flex" }}>
                          <div style={{ padding: "0px 10px 0px 0px" }}>
                            <Person />
                          </div>
                          <div
                            onClick={() =>
                              this.props.navigation.navigate(
                                "ServiceCallRequest"
                              )
                            }
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              cursor: "pointer",
                            }}
                          >
                            <small style={{ fontWeight: "bold" }}>
                              Service Call Request
                            </small>
                            <small>Check Request Placed by Customers</small>
                          </div>
                        </div>

                        <div style={{ display: "flex", padding: "10px 0px" }}>
                          <div style={{ padding: "0px 10px 0px 0px" }}>
                            <Person />
                          </div>
                          <div
                            onClick={() =>
                              this.props.navigation.navigate(
                                "WarrantyRegistration"
                              )
                            }
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              cursor: "pointer",
                            }}
                          >
                            <small style={{ fontWeight: "bold" }}>
                              Register Warranty
                            </small>
                            <small>
                              Register warranty of Batteries placed offline
                            </small>
                          </div>
                        </div>
                        <div style={{ display: "flex", padding: "10px 0px" }}>
                          <div style={{ padding: "0px 10px 0px 0px" }}>
                            <Person />
                          </div>
                          <div
                            onClick={() =>
                              this.props.navigation.navigate(
                                "ManageBatteryCollection"
                              )
                            }
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              cursor: "pointer",
                            }}
                          >
                            <small style={{ fontWeight: "bold" }}>
                              Manage Battery Collection
                            </small>
                            <small>Manage collections of customers</small>
                          </div>
                        </div>
                        <div style={{ display: "flex", padding: "10px 0px" }}>
                          <div style={{ padding: "0px 10px 0px 0px" }}>
                            <Person />
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <small style={{ fontWeight: "bold" }}>
                              Service Call
                            </small>
                            <small>
                              Register s service call for a customer
                            </small>
                          </div>
                        </div>
                      </div>
                      <div style={{ padding: "10px" }}>Logout</div>
                    </div>
                  </Box>
                </Paper>
              )}
              </>
    );
  }
}
// Customizable Area End