
const baseurl=require('./../../../framework/src/config.js')

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");
const {baseURL:apiBaseUrl}=require('./../../../framework/src/config')
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  openSearch: boolean;
  searchValue: any;
  serachData: any;
  showSearchData: boolean;
  openProfile: boolean;
  open: boolean;
  recentSearchData: any;
  recentSearchCross: boolean;
  openVideo: any;
  getMediaPhotoData: any;
  mediaPhoto: boolean;
  openSortByPhoto: boolean;
  openSortByVideo: boolean;
  openSortbyPhotoBox: boolean;
  openSortbyVideoBox: boolean;
  recentPhotoSort: any;
  recentVideoSort: any;
  getMediaVideoData:any;
  MediaVideo: boolean;
  openSortbyBox: boolean;
  viewAdvertisementsData: any;
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  isExpand:boolean;
  sort:any;
  showLess:boolean;
  isLoading:boolean;
  galleryData:any;
  isPhoto:boolean;
  allPhotos:any;
  allVideos:any;
  imgModal:boolean;
  singleImg:any;
  videoModal:boolean;
  singleVideo:any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class VideoManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      openSearch: false,
      searchValue: "",
      serachData: [],
      showSearchData: false,
      openProfile: false,
      open: false,
      recentSearchData: [],
      recentSearchCross: false,
      openVideo: false,
      getMediaPhotoData: [],
      mediaPhoto: false,
      openSortByPhoto: false,
      openSortByVideo: false,
      openSortbyPhotoBox: false,
      openSortbyVideoBox: false,
      recentPhotoSort: "",
      recentVideoSort: "",
      getMediaVideoData: [],
      MediaVideo: false,
      viewAdvertisementsData: [],
      openSortbyBox: false,
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      isExpand:false,
      sort:"recently_added",
      showLess:true,
      isLoading:false,
      galleryData:[],
      isPhoto:true,
      allPhotos:[],
      allVideos:[],
      imgModal:false,
      singleImg:'',
      videoModal:false,
      singleVideo:''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }


  async componentDidMount() {
    super.componentDidMount();
    this.viewAdvertisementsCall();
    this.viewGallery();

  }


   
  handleSearch = () => {
    this.setState({ openSearch: true })
    if (this.state.searchValue !== "") {
      this.getSearchCall();
    } else {
      return
    }
  }

  getSearchCall = () => {
    let TOKEN: any = localStorage.getItem("loginSuccessToken")

    let myHeaders = new Headers();
    {localStorage.getItem("loginSuccessToken") &&  
    myHeaders.append("token", TOKEN);
    }
    myHeaders.append("Content-Type", "application/json");



    fetch(`${apiBaseUrl}/bx_block_fedex_integration/search_data?search=${this.state.searchValue}`, {
      method: 'GET',
      headers: myHeaders,
    })
      .then(response => response.json())
      .then(result => {
        console.log("search result milrahe", result)
        this.setState({ showSearchData: true, serachData: result?.data })
      })
      .catch(error => console.log('error', error));
  }

  
  recentSearchCall = () => {
    let TOKEN: any = localStorage.getItem("loginSuccessToken")

    let myHeaders = new Headers();
    myHeaders.append("token", TOKEN);
    myHeaders.append("Content-Type", "application/json");



    fetch(`${apiBaseUrl}/bx_block_fedex_integration/user_search`, {
      method: 'GET',
      headers: myHeaders,
    })
      .then(response => response.json())
      .then(result => {
        console.log(result)
        this.setState({ recentSearchData: result?.data })
  
      })
      .catch(error => console.log('error', error));
  }

  deleteRecentSearchCall = (ItemId:any) => {
    let TOKEN: any = localStorage.getItem("loginSuccessToken")
   

    let myHeaders = new Headers();
    myHeaders.append("token",TOKEN);
    myHeaders.append("Content-Type", "application/json");


    fetch(`${apiBaseUrl}/bx_block_fedex_integration/user_search/${ItemId}`, {
      method: 'DELETE',
      headers: myHeaders,
    })
      .then(response => response.json())
      .then(result => {console.log(result)
         this.setState({recentSearchCross:true})
       })
      .catch(error => console.log('error', error));
  } 
onlyPhotos=(type:any,category:any)=>{
  this.setState({isLoading:true})
  fetch(`${apiBaseUrl}/bx_block_settings/gallery_filter/?type=photo&category=${category}`, {
    method: 'GET',
  }).then(response => response.json())
  .then(result => {
      console.log(result)
     this.setState({allPhotos:result?.data,isLoading:false});
    })
    .catch(error =>{
      console.log('error', error);
      this.setState({isLoading:false})
    });
  
}
onlyVideos=()=>{
  this.setState({isLoading:true})
  fetch(`${apiBaseUrl}/bx_block_settings/gallery_filter/?type=photo&category=Behind the scene`, {
    method: 'GET',
  }).then(response => response.json())
  .then(result => {
      console.log(result)
     this.setState({allPhotos:result?.data,isLoading:false});
    })
    .catch(error =>{
      console.log('error', error);
      this.setState({isLoading:false})
    });
  
}
 



   viewGallery=(type:any='photo')=>{
    this.setState({isLoading:true})
    fetch(`${apiBaseUrl}/bx_block_settings/gallery?type=${type}`, {
      method: 'GET',
    }).then(response => response.json())
    .then(result => {
        console.log(result)
       this.setState({galleryData:result?.data,isLoading:false});
      })
      .catch(error =>{
        console.log('error', error);
        this.setState({isLoading:false})
      });
      return true
    }
    
sortAdvertisements=(sort:any)=>{
  this.setState({isLoading:true})
  fetch(`${apiBaseUrl}/bx_block_settings/advertisement?sort_by=${sort}`, {
      method: 'GET',
    })
      .then(response => response.json())
      .then(result => {console.log(result);
        

        this.setState({viewAdvertisementsData:result?.data,isLoading:false});
       
       })
      .catch(error => {console.log('error', error);this.setState({isLoading:false})});

}
  viewAdvertisementsCall=()=>{
    this.setState({isLoading:true})
    
    fetch(`${apiBaseUrl}/bx_block_settings/advertisement?sort_by=${this.state.sort}`, {
      method: 'GET',
    })
      .then(response => response.json())
      .then(result => {console.log(result);
        let arr=result?.data?.map((item:any)=>{
          return {...item,showMore:false}
        });

        this.setState({viewAdvertisementsData:arr,isLoading:false})
       })
      .catch(error => {console.log('error', error);this.setState({isLoading:false})});
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  // Customizable Area End
}
