// Customizable Area Start

import React from 'react'
import ContactusController from './ContactusController';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import ServiceCallDetails from './ServiceCallDetails.web';
import SlotServiceCall from './SlotServiceCall.web';
import SelectBattery from './SelectBattery.web';
import AddBatteryCollection from './AddBatteryCollection.web';
import {Container,Typography,Stepper,Step,StepLabel,Button,Box,withStyles,Paper} from '@material-ui/core';

import VisionStatementWeb from '../../../components/src/VisionStatement.web';

const CustomStepper = withStyles({
  root: {
      "& .MuiStepIcon-active": { color: "#42454E" },
      "& .MuiStepIcon-completed": { color: "#43CBA0" },
      "& .MuiStepLabel-root": { border: "1px solid #E8EAEB", padding: '10px', backgroundColor: '#EBEEF1' },
      "& .MuiStepLabel-completed-root": { border: "1px solid #43CBA0", padding: '10px' },
      
      
  }

})(Stepper);

const CustomPaper=withStyles({
  root: {
    "& .MuiPaper-root":{backgroundColor:'#F3F5F7'}
  }
  })(Paper)
    
     
      
export default class RegisterServiceCall extends ContactusController {
  constructor(props: any) {
    super(props);

  }
  steps = ['Select Battery', 'Service Calls Details', 'Slot for service call', 'Payment', 'Add to battery collection'];

  getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return <SelectBattery navigation={this.props.navigation} history={this.props.history} id={this.props.id} openSoS={this.props.openSoS} closeSoS={this.props.closeSoS} clearSearch={this.props.clearSearch} />;
      case 1:
        return <ServiceCallDetails navigation={this.props.navigation} history={this.props.history} id={this.props.id} openSoS={this.props.openSoS} closeSoS={this.props.closeSoS} clearSearch={this.props.clearSearch} />;
      case 2:
        return <SlotServiceCall navigation={this.props.navigation} history={this.props.history} id={this.props.id} openSoS={this.props.openSoS} closeSoS={this.props.closeSoS} clearSearch={this.props.clearSearch} />;
      case 3:
        return <div></div>;
      case 4:
        return <AddBatteryCollection navigation={this.props.navigation} history={this.props.history} id={this.props.id} openSoS={this.props.openSoS} closeSoS={this.props.closeSoS} clearSearch={this.props.clearSearch} />;
      default:
        throw new Error('Unknown step');
    }
  }

  handleNext = () => {
    this.setState({ activeStep: this.state.activeStep + 1 })

  };

  handleBack = () => {
    this.setState({ activeStep: this.state.activeStep - 1 })
  };


  render() {
    return (
      <div>

        <div style={{ padding: '20px', backgroundColor: '#efefef' }}>
            <Container>

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                <div style={{ color: '#a1a0a4' }}>
                <a href='/' style={{ textDecoration: 'none', color: '#a1a0a4' }}>Home</a> <ArrowForwardIosRoundedIcon className="arrow-left" /> Warranty Management <ArrowForwardIosRoundedIcon className="arrow-left" /> Register a Service call
                </div>
                {!localStorage.getItem("loginSuccessToken") && <div style={{ color: '#a1a0a4' }}>
                  <span>
                    <i>For Better Experience</i>
                    <span onClick={() => {
                      this.props.navigation.navigate('EmailAccountLoginBlock')
                    }} style={{ color: "red", fontWeight: 'bold', cursor: 'pointer' }}> Login/</span><span onClick={() => {
                      this.props.navigation.navigate('signUp')
                    }} style={{ color: "red", fontWeight: 'bold', cursor: 'pointer' }}>Register</span>
                  </span>
                </div>
                }
              </div>
            </Container>
          </div>


        <div>


          {this.state.activeStep === this.steps.length ? (
            <React.Fragment>
              <Typography variant="h5" gutterBottom>
                Thank you for your order.
              </Typography>
              <Typography variant="subtitle1">
                Your order number is #2001539. We have emailed your order
                confirmation, and will send you an update when your order has
                shipped.
              </Typography>
            </React.Fragment>
          ) : (

            <Container component="main" maxWidth='lg'>
              <CustomPaper elevation={0}>

              <CustomStepper activeStep={this.state.activeStep}

>
                {this.steps.map((label: any) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </CustomStepper>
                </CustomPaper>

              {this.getStepContent(this.state.activeStep)}
              <Container>

              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                {this.state.activeStep !== 0 && (
                  <Button onClick={this.handleBack}
                  variant='outlined'
                  style={{ padding: '10px 90px', marginTop: '30px' }}
                  >
                    Back
                  </Button>
                )}

                {this.state.activeStep === this.steps.length - 1 ? <div style={{ display: 'flex' }}>
                  <Button variant='outlined' style={{ padding: '10px 90px', margin: '30px 20px 0px 0px' }}>Finish</Button>
                  <Button variant='contained' style={{ padding: '10px 90px', marginTop: '30px', backgroundColor: '#32cba0' }}>Add</Button>
                </div> : <Button
                  variant="contained"
                  onClick={this.handleNext}
                  style={{ padding: '10px 90px', marginTop: '30px', backgroundColor: '#32cba0' }}
                  
                  >
                  Next
                </Button>}
              </Box>
                  </Container>
              <VisionStatementWeb classes={undefined} id={''} navigation={undefined} openSearch={undefined}/>
            </Container>

          )}

        </div>
      </div>
    )
  }
}
// Customizable Area End