// Customizable Area Start
import React, { Component } from 'react'
import OrdermanagementController from './OrdermanagementController';
import {
    Box,
    Container,
    Modal,
    Button,
    ListItem,
    IconButton,
    ListItemIcon,
    ListItemText,
    Divider,
    Collapse,
    List,
    Grid,
    Card,
    CardContent,
    CardActions,
    Typography,
    Switch, Radio,Checkbox

} from "@material-ui/core";
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import VisionStatementWeb from '../../../components/src/VisionStatement.web';

const ezBattery = require(".././assets/red_battery.png");
const backIcon = require(".././assets/backIcon.png");

export default class ConfirmExchange extends OrdermanagementController {
    arr = [{ label: 'Pickup', info: 'A representative will come and pickup the package from your address' },

    { label: 'Speed post', info: 'You need to send the package via speed post' },
    { label: 'Walk in', info: 'You need to drop the package to the nearest EZ battery office' }]
    render() {
        return (
            <>
                <div style={{ display: 'flex', justifyContent: 'flex-start', padding: '20px', backgroundColor: '#efefef' }}>
                    <Container>

                    <div style={{ color: '#a1a0a4' }}>
                        <a href='/' style={{ textDecoration: 'none', color: '#a1a0a4' }}>Home</a> <ArrowForwardIosRoundedIcon className="arrow-left" /> Orders <ArrowForwardIosRoundedIcon className="arrow-left" /> EZTZ4 <ArrowForwardIosRoundedIcon className="arrow-left" /> Exchange
                    </div>
                    </Container>

                </div>

                <Container style={{ marginTop: '20px' }}>
                    <Grid container>
                        <Grid item xs={12} md={4}>

                            <Box sx={{
                                bgcolor: 'background.paper',
                                marginRight: '25px'
                            }}>
                                <div style={{ display: 'flex', flexDirection: 'column', padding: '20px', alignItems: 'center' }}>

                                    <img src={ezBattery} width='250' height='250' />
                                    <div style={{ padding: '20px', fontWeight: 'bold', fontSize: '25px' }}>EZTZ4</div>
                                    <div style={{ padding: '20px', fontWeight: 'bold' }}>

                                        Order Number: 6789070
                                    </div>


                                </div>

                            </Box>



                        </Grid>

                        <Grid item xs={12} md={8}>
                            <Box sx={{
                                bgcolor: 'background.paper',
                                padding: '30px'
                            }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div onClick={() => this.props.navigation.navigate('SelectProductToExchange')} style={{ marginBottom: '10px', marginRight: '10px' }} >
                                        <img src={backIcon} style={{ backgroundColor: '#ebeff2', borderRadius: '50%', padding: '10px', width: '20px' }} />
                                    </div>
                                    <div style={{ fontWeight: 'bold', marginBottom: '10px' }}>Confirm Exchange</div>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div>

                                        <div style={{ fontWeight: 'bold' }}>Confirm Return</div>
                                        <div style={{ padding: '20px 0px 0px 0px', color: '#acb2bf' }}>John Doe,9384994</div>
                                        <div style={{ padding: '10px 0px 15px 0px', color: '#acb2bf' }}>A refund of Rs.8999 will reach within 5-7 business days after we receive the package</div>
                                    </div>

                                    <Divider />
                                    <div >

                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <div style={{ padding: '20px 0px 20px 0px' }}>Back to source</div>
                                            <div style={{ padding: '20px 0px 20px 0px' }}>Rs.8999</div>
                                        </div>
                                        <div style={{ padding: '10px 0px 15px 0px', color: '#acb2bf' }}>Money will be refunded in the payment method used for placing the order</div>

                                    </div>
                                    <Divider />
                                    <div >
                                        <div style={{ padding: '20px 0px 20px 0px', fontWeight: 'bold' }}>Pickup Address</div>
                                        <div style={{ padding: '10px 0px 0px 0px', color: '#acb2bf' }}>John Doe,9384994</div>
                                        <div style={{ padding: '10px 0px 15px 0px', color: '#acb2bf' }}>Bezel Pharma, Govt Indl Estate,Opp plot 99,Hindustan Naka, Kandivali west</div>
                                        <div>
                                            <Button style={{ backgroundColor: '#d6f5ec', padding: '10px 90px' }}>Change Address</Button>
                                        </div>
                                        <div style={{ display: 'flex', marginTop: '25px' }}>
                                            <div><Checkbox color='default' /></div>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <small style={{ color: '#acb2bf' }}>I agree to return the items in the condition they were received along with the price tags and other accessories</small>
                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </Box>

                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                                <Button variant='outlined' style={{ padding: '10px 90px 10px 90px' }}>Cancel</Button>
                                <Button onClick={()=>this.props.navigation.navigate('ExchangeSummary')} style={{ padding: '10px 90px 10px 90px', backgroundColor: '#32cba0' }}>Next</Button>
                            </div>
                        </Grid>
                    </Grid>
                    <VisionStatementWeb classes={undefined} id={''} navigation={this.props.navigation} openSearch={undefined}/>

                </Container>

                {/* <div>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={this.state.toggleCancelOrder}
                        onClose={() => this.handleCloseModal()}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={this.state.toggleCancelOrder}>
                            <Box sx={style}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ fontWeight: 'bold', fontSize: '20px' }}>Cancel Order?</div>
                                    <span><CloseIcon /></span>
                                </div>

                                <div style={{ marginTop: '20px', color: '#9da4b3' }}>
                                    Do you wish to cancel your order?Note: Refunds may take 5-7 business days to reflect on your account
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                                    <Button variant='outlined' onClick={() => this.handleCloseModal()} style={{ padding: '15px 45px' }}>No</Button>
                                    <Button variant='contained' style={{ padding: '15px 45px', backgroundColor: '#dd403a', marginLeft: '10px', color: 'white' }} >Yes</Button>
                                </div>








                            </Box>
                        </Fade>
                    </Modal>
                </div> */}

            </>
        )
    }
}
// Customizable Area End