import React from "react";

// Customizable Area Start
import {
  Container,
  Grid,
  Button,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  Checkbox, Backdrop, CircularProgress
} from "@material-ui/core";
import Box from '@material-ui/core/Box';
import Input from "@material-ui/core/Input";
import Error from "@material-ui/icons/Error";
import CallIcon from '@material-ui/icons/Call';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { BackGroundImg, BatteryHindi } from "./assets";

import "./signUp.css"

// Customizable Area End


import SignUpController, { Props } from "./signUpController";

const call = require("../assets/call.png");

const Styles = {

  mainbox: {
    height: "100px",
    width: "350px",
  },
  callset: {
    // color: "white"
  }
}
// Customizable Area Start

export default class signUp extends SignUpController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      // Required for all blocks
      <>
        <Backdrop
          style={{ color: '#fff', zIndex: 1 }}
          open={this.state.isLoading}

        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <div style={{
         backgroundColor: "#fff",
         backgroundImage: `url(${BackGroundImg})`,
         backgroundSize: "100% 100%",
         height: "100vh",
        }}>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "30px",
              paddingTop:'100px'
            }}
          >
            <img src={BatteryHindi} />
          </div>
          <Grid item style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ width: "600px", height: "500px", borderRadius: "4px", backgroundColor: "#fff" }}>
              <div style={{ display: "flex", alignItems: "center", marginTop: "50px", marginBottom: "70px" }}>
                <a href="EmailAccountLoginBlock" style={{ textDecoration: "none" }}>
                  <Typography style={{ color: '#C6C6CE', marginLeft: "180px", marginRight: "40px", fontSize: "20px" }} >Sign in</Typography>
                </a>
                <Typography style={{ marginLeft: "50px", fontSize: "20px", fontWeight: 'bold' }}>Register</Typography>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <TextField
                  id="outlined-full-width"
                  type="text"
                  name='name'
                  value={this.state.first_name}
                  placeholder="Full Name"
                  variant="outlined"
                  style={{
                    width: "70%",

                    borderRadius: "4px",


                    marginLeft: "90px",
                    marginBottom: "20px",
                    border: this.state.nameError||(!this.state.first_name && this.state.blankDataF) ? '1px solid red' : '0px solid',
                    backgroundColor:this.state.nameError||(!this.state.first_name && this.state.blankDataF) ? "#FDEFEF" : '',
                  }}
                  InputProps={{

                    startAdornment: (
                      <InputAdornment
                        style={Styles.callset}
                        position="start"

                      >

                        <PersonOutlineOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}

                  onChange={(e: any) => {
                    this.setState({blankDataF:false})
                    let re = /^([a-zA-Z]+\s)*[a-zA-Z]+$/
                    if(re.test(e.target.value)){
                      this.setState({nameError:''})
                    }
                    else{
                      this.setState({nameError:'Invalid Full Name'})
                    }
                    
                    
                    this.setState({ first_name: e.target.value })
                    localStorage.setItem("FirstName", e.target.value)
                  }}
                />

                {
                  !this.state.first_name && this.state.blankDataF &&
                  <div
                    style={{
                      marginLeft: "95px",
                      marginTop: "-15px",
                      marginBottom: "15px",
                      color: "red",

                      fontSize: "smaller",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ marginRight: "5px" }}>
                      <Error color="error" fontSize="small" />
                    </div>
                    <div>Full Name is Required</div>
                  </div>
                }
                 {!this.state.blankDataF&&this.state.nameError &&
                  <div
                    style={{
                      marginLeft: "95px",
                      marginTop: "-15px",
                      marginBottom: "15px",
                      color: "red",

                      fontSize: "smaller",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ marginRight: "5px" }}>
                      <Error color="error" fontSize="small" />
                    </div>
                    <div>  {this.state.nameError}</div>
                  </div>


                }
                <TextField
                  id="outlined-full-width"
                  type="text"
                  value={this.state.phone_number}
                  variant="outlined"
                  placeholder="Mobile number"
                  style={{
                    width: "70%",


                    borderRadius: "4px",


                    marginLeft: "90px",
                    marginBottom: "20px",
                    border: this.state.phoneError||(!this.state.phone_number && this.state.blankDataM) ? '1px solid red' : '0px solid',
                    backgroundColor: this.state.phoneError||(!this.state.phone_number && this.state.blankDataM)? "#FDEFEF" : '',
                  }}
                  InputProps={{

                    startAdornment: (
                      <InputAdornment
                        style={Styles.callset}
                        position="start"

                      >

                        <img src={call} width="25px" />

                      </InputAdornment>
                    ),
                  }}

                  onChange={(e: any) => {
                    this.setState({blankDataM:false,showErrors:false})
                    let regex=/^[0-9]{10}$/
                    if(regex.test(e.target.value)){
                      this.setState({phoneError:''})
                    }
                    else{
                      this.setState({phoneError:'Invalid Phone Number'})
                    }
                    this.setState({ phone_number: e.target.value })
                    localStorage.setItem("PhoneNumber", e.target.value)
                    
                    if (!this.state.phone_number) {
                      this.setState({ showErrors: false })
                    }
                  }}
                />

                {
                  !this.state.phone_number && this.state.blankDataM &&
                  <div
                    style={{
                      marginLeft: "95px",
                      marginTop: "-15px",
                      marginBottom: "15px",
                      color: "red",

                      fontSize: "smaller",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ marginRight: "5px" }}>
                      <Error color="error" fontSize="small" />
                    </div>
                    <div>Mobile Number is Required</div>
                  </div>
                }

                {this.state.phone_number && this.state.showErrors &&
                  <div
                    style={{
                      marginLeft: "95px",
                      marginTop: "-15px",
                      marginBottom: "15px",
                      color: "red",

                      fontSize: "smaller",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ marginRight: "5px" }}>
                      <Error color="error" fontSize="small" />
                    </div>
                    <div>  {this.state.errorsData}</div>
                  </div>


                }
                 {!this.state.blankDataM&&this.state.phoneError &&
                  <div
                    style={{
                      marginLeft: "95px",
                      marginTop: "-15px",
                      marginBottom: "15px",
                      color: "red",

                      fontSize: "smaller",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ marginRight: "5px" }}>
                      <Error color="error" fontSize="small" />
                    </div>
                    <div>  {this.state.phoneError}</div>
                  </div>


                }


                <div style={{
                  width: "70%",
                  height: "50px",
                  marginLeft: "90px",
                  backgroundColor: "#32CBA1",
                  borderRadius: "4px",
                  cursor: "pointer",
                  fontWeight: "bold",
                  marginTop: '50px'
                }}
                  onClick={() => {
                    this.validate();
                    if(this.state.first_name&&this.state.phone_number&&!this.state.phoneError&&!this.state.nameError){

                      this.sendOTP();
                    }
                   if(!this.state.first_name){
                    this.setState({ blankDataF: true })
                   }
                   if(!this.state.phone_number){
                    this.setState({ blankDataM: true })
                   }
                  }}

                >

                  <p style={{ textAlign: "center", color: "black" }} > Verify mobile number </p>
                </div>

              </div>
            </div>

          </Grid>
          <Grid container style={{ display: "flex", justifyContent: "center" }}>
            <a
              href="/"
              style={{ textDecoration: "none",color:'black' }}
            >
              <div style={{ width: "135px", height: "50px", marginTop: "40px", border: "1px solid gray",backgroundColor:'#F3F3F3', cursor: "pointer" }} >
                <p style={{ textAlign: "center",fontWeight:'bold' }}>Skip</p>
              </div>
            </a>
          </Grid>
        </div>

      </>
    );
  }
}
// Customizable Area End





