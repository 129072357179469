// Customizable Area Start
import React, { Component } from 'react'
import OrdermanagementController from './OrdermanagementController';
import {
    Box,
    Container,
    Modal,
    Button,
    ListItem,
    IconButton,
    ListItemIcon,
    ListItemText,
    Divider,
    Collapse,
    List,
    Grid,
    Card,
    CardContent,
    CardActions,
    Typography,
    Switch, Radio

} from "@material-ui/core";
import Info from '@material-ui/icons/Info';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import TextField from '@material-ui/core/TextField';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import VisionStatementWeb from '../../../components/src/VisionStatement.web';
const ezBattery = require(".././assets/red_battery.png");

export default class MyBatteryCollection extends OrdermanagementController {
    constructor(props: any) {
        super(props);
    }
    arr = [0, 1, 2, 3]
    render() {
        if (!localStorage.getItem("loginSuccessToken")) {
            this.props.navigation.navigate('signUp');
            return null

        }
        return (
            <>
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px', backgroundColor: '#efefef' }}>
                    <Container>

                    <div style={{ color: '#a1a0a4' }}>
                        <a href='/' style={{ textDecoration: 'none', color: '#a1a0a4' }}>Home</a> <ArrowForwardIosRoundedIcon className="arrow-left" /> My Battery Collection
                    </div>
                    {!localStorage.getItem("loginSuccessToken") && <div style={{ color: '#a1a0a4' }}>
                        <span><i>For Better Experience</i> <span style={{ color: "red" }}>Login/Register</span></span>
                    </div>}
                    </Container>
                </div>

                <Container style={{ marginTop: '20px' }}>

                    <Grid container>

                        <Grid item xs={12} md={3}>

                            <Box sx={{
                                bgcolor: 'background.paper',
                                marginRight: '25px'
                            }}>
                                <nav aria-label="main mailbox folders">
                                    <List>
                                        <ListItem style={{ borderLeft: '5px solid #44c39f' }} >

                                            <ListItemText primary='Non EZ Batteries' />

                                        </ListItem>
                                        <Divider />
                                        <ListItem >

                                            <ListItemText primary='EZ Batteries' />

                                        </ListItem>



                                    </List>
                                </nav>


                            </Box>

                            <Box sx={{
                                marginRight: '25px',
                                bgcolor: 'background.paper'
                            }}>
                                <div style={{ marginTop: '20px' }}>


                                    <div style={{ padding: '15px 0px 15px 15px', fontWeight: 'bold' }}>Sort By</div>
                                    <div style={{ display: 'flex' }}>

                                        <Radio

                                            name="radio-buttons"
                                            inputProps={{ 'aria-label': 'A' }}
                                        />
                                        <span style={{ display: 'flex', alignItems: 'center' }}>Recently Added</span>
                                    </div>
                                    <div style={{ display: 'flex', marginTop: '20px' }}>
                                        <Radio

                                            name="radio-buttons"
                                            inputProps={{ 'aria-label': 'A' }}
                                        />
                                        <span style={{ display: 'flex', alignItems: 'center' }}>Price low to high</span>
                                    </div>
                                    <div style={{ display: 'flex', marginTop: '20px' }}>
                                        <Radio

                                            name="radio-buttons"
                                            inputProps={{ 'aria-label': 'A' }}
                                        />
                                        <span style={{ display: 'flex', alignItems: 'center' }}>Price high to low</span>
                                    </div>
                                    <div style={{ display: 'flex', marginTop: '20px' }}>
                                        <Radio

                                            name="radio-buttons"
                                            inputProps={{ 'aria-label': 'A' }}
                                        />
                                        <span style={{ display: 'flex', alignItems: 'center' }}>From A to Z</span>
                                    </div>
                                </div>


                            </Box>

                        </Grid>





                        {/* empty  */}
                        {!true && <Grid item xs={12} md={9}>

                            <Box
                                display={'flex'}
                                flexDirection='column'
                                alignItems='center'
                                justifyContent={'center'}
                                sx={{
                                    bgcolor: 'background.paper',
                                    height: '600px',

                                }}>
                                <div style={{ position: 'relative' }}>

                                    <div style={{ display: 'flex', justifyContent: 'center', fontSize: '25px', fontWeight: 'bold' }}>
                                        No Batteries
                                    </div>
                                    <div>
                                        <small>Please add non EZ Batteries if you have</small>
                                    </div>
                                </div>

                                <div style={{ position: "absolute", display: 'flex', top: '75%' }}>
                                    <div style={{ padding: '5px', backgroundColor: '#e5f3fa', width: '525px', display: 'flex', alignItems: 'center', marginRight: '200px' }}>
                                        <span><Info color='primary' /></span>
                                        <span style={{ color: '#67bbe5', marginLeft: '5px', display: 'flex', alignItems: 'center' }}>We ask this information to help improve your web experience</span>
                                    </div>

                                    <Button variant='contained' style={{ backgroundColor: '#32cba1', padding: '10px 30px 10px 30px' }}>Add</Button>

                                </div>

                            </Box>

                        </Grid>}

                        {/* <Container component="main" style={{ maxWidth: '800px' }}> */}

                        {/* add new batteries */}
                        {/* </Container> */}
                        {!true && <Container component="main" style={{ maxWidth: '800px' }}>

                            <Box
                                sx={{

                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    bgcolor: 'white',
                                    padding: '30px'
                                }}
                            >


                                <Box component="form" sx={{ mt: 3 }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                autoComplete="given-name"
                                                name="firstName"

                                                fullWidth
                                                id="firstName"
                                                label="First Name"
                                                autoFocus
                                                variant='outlined'
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField

                                                fullWidth
                                                id="lastName"
                                                label="Mobile Number"
                                                name="Mobile Number"
                                                autoComplete="family-name"
                                                variant='outlined'
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                autoComplete="given-name"
                                                name="firstName"

                                                fullWidth
                                                id="firstName"
                                                label="First Name"
                                                autoFocus
                                                variant='outlined'
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                autoComplete="given-name"
                                                name="firstName"

                                                fullWidth
                                                id="firstName"
                                                label="First Name"
                                                autoFocus
                                                variant='outlined'
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField

                                                fullWidth

                                                id="email"
                                                label="Type your message here"
                                                name="email"
                                                autoComplete="email"
                                                variant='outlined'
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField

                                                fullWidth

                                                id="email"
                                                label="Type your message here"
                                                name="email"
                                                autoComplete="email"
                                                variant='outlined'
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                autoComplete="given-name"
                                                name="firstName"

                                                fullWidth
                                                id="firstName"
                                                label="First Name"
                                                autoFocus
                                                variant='outlined'
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                autoComplete="given-name"
                                                name="firstName"

                                                fullWidth
                                                id="firstName"
                                                label="First Name"
                                                autoFocus
                                                variant='outlined'
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                autoComplete="given-name"
                                                name="firstName"

                                                fullWidth
                                                id="firstName"
                                                label="First Name"
                                                autoFocus
                                                variant='outlined'
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                autoComplete="given-name"
                                                name="firstName"

                                                fullWidth
                                                id="firstName"
                                                label="First Name"
                                                autoFocus
                                                variant='outlined'
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                                                <small style={{ display: 'flex', alignItems: 'center' }}>Is this battery in warranty period?</small>
                                                <Switch />
                                            </div>
                                        </Grid>
                                        <br />

                                    </Grid>

                                    <Button variant='contained' style={{ float: 'right', backgroundColor: '#32cba1', padding: '10px 40px 10px 40px' }}>Add</Button>

                                </Box>
                            </Box>

                        </Container>}

                        {/* battery listing */}
                        {true && <Grid container spacing={3} md={9}>

                            {this.arr.map(() => {
                                return <Grid item xs={12} sm={6}>
                                    <Card  >
                                        <CardContent>
                                            <Typography gutterBottom>
                                                Ordered on 21 Apr 22
                                            </Typography>
                                            <Divider />
                                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <MoreHorizIcon />
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                <div style={{ padding: '15px' }}><img src={ezBattery} width='100' height='100' /></div>
                                                <div style={{ padding: '20px' }}>
                                                    <span style={{ fontWeight: 'bold' }}>EZ2345</span>
                                                    <div style={{ margin: '10px 0px 10px 0px', padding: '5px', backgroundColor: '#32cba1' }}>

                                                        <small>Brand:Exide Batteries</small>
                                                    </div>



                                                </div>

                                            </div>
                                        </CardContent>

                                    </Card>
                                </Grid>
                            })}


                        </Grid>}



                    </Grid>
                    <VisionStatementWeb classes={undefined} id={''} navigation={undefined} openSearch={undefined}/>
                </Container>




            </>

        )
    }
}
// Customizable Area End