import React, { Component } from 'react'
// Customizable Area Start



import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';

import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';

import { Box, Container, Grid, Card, CardContent, withStyles, Backdrop, CircularProgress } from "@material-ui/core"
import Typography from '@material-ui/core/Typography';





const greenTick = require("../assets/greentick.png")
export const yellowStar = require("../assets/yellow_star.png")
export const heart = require("../assets/heart.png")

const Styles = {
  inputSearchBox: {
    width: "25px",
    height: "35px",
    alignItems: "center",
    border: "1px solid #b30000",
    borderRadiusRight: "5px",
    backgroundColor: "#b30000",
    paddingRight: "10px",
    color: "white",
    cursor: "pointer"
  },
  cardhead: {
    font: "10px",
    color: "grayText",
    textDecoration: "underline",
    textDecorationColor: "gray",
    textDecorationThickness: "1px",
  }
}


import DashboardController, { Props } from "./DashboardController";
import VisionStatement from '../../../components/src/VisionStatement.web';

const securedGreenTick = require("../assets/securedGreenTick.png")
const love = require("../assets/love copy.png");

const StyledFormControlLabel = withStyles({
  root: {
    marginLeft: '0px',
    marginRight: '0px'
  },
  label: {

  },
})(FormControlLabel);


export default class VehiclesBatteries extends DashboardController {
  checkManufacture = (item: any) => {
    let a = this.state.selectManufacturer.find((element: any) => {
      return element?.attributes?.manufacturer == item?.attributes?.manufacturer
    })
    if (a?.id) {
      return true
    }
    else {
      return false
    }

  }
  checkModel = (item: any) => {
    let a = this.state.selectModel.find((element: any) => {
      return element?.id == item?.id
      // return element?.attributes?.fuel == item?.attributes?.fuel
    })
    if (a?.id) {
      return true
    }
    else {
      return false
    }

  }
  checkFuel = (item: any) => {
    let a = this.state.selectFuel.find((element: any) => {
      return element?.attributes?.fuel == item?.attributes?.fuel
    })
    if (a?.id) {
      return true
    }
    else {
      return false
    }

  }
  // checkIfFuelDisabled = (item: any) => {
  //   let a = this.state.selectManufacturer.find((element: any) => {
  //     // return element?.id==item?.id
  //     return element?.attributes?.fuel == item?.attributes?.fuel
  //   })
  //   if (a?.id) {
  //     return true
  //   }
  //   else {
  //     return false
  //   }


  // }
  checkIfFuelDisabled = (item: any) => {
    let a = this.state.selectModel.find((element: any) => {
      // return element?.id==item?.id
      return element?.attributes?.fuel == item?.attributes?.fuel
    })
    if (a?.id) {
      return true
    }
    else {
      return false
    }


  }
  checkSubCategory = (item: any) => {
    let a = this.state.selectCategory.find((element: any) => {
      return element?.attributes?.sub_category_id == item?.attributes?.sub_category_id
    })
    if (a?.attributes?.sub_category_id) {
      return true
    }
    else {
      return false
    }

  }
  render() {
    return (

      <>
        <Backdrop
          style={{ color: '#fff', zIndex: 1 }}
          open={this.state.isLoading}

        > <CircularProgress color="inherit" /></Backdrop>

        <div>
          <div>



          <div style={{ padding: '20px', backgroundColor: '#efefef' }}>
            <Container>

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                <div style={{ color: '#a1a0a4' }}>
                  <a href='/' style={{ textDecoration: 'none', color: '#a1a0a4' }}>Home</a> <ArrowForwardIosRoundedIcon className="arrow-left" /> Vehicle Batteries
                </div>
                {!localStorage.getItem("loginSuccessToken") && <div style={{ color: '#a1a0a4' }}>
                  <span>
                    <i>For Better Experience</i>
                    <span onClick={() => {
                      this.props.navigation.navigate('EmailAccountLoginBlock')
                    }} style={{ color: "red", fontWeight: 'bold', cursor: 'pointer' }}> Login/</span><span onClick={() => {
                      this.props.navigation.navigate('signUp')
                    }} style={{ color: "red", fontWeight: 'bold', cursor: 'pointer' }}>Register</span>
                  </span>
                </div>
                }
              </div>
            </Container>
          </div>

            {this.state.showSearchData && this.state.openSearch &&
              <div style={{ marginTop: "165px", marginLeft: "155px", marginRight: "155px" }}>
                <Grid container style={{ display: "flex", flexDirection: "row", position: "relative", }} >

                  {this.state.serachData?.map((item: any, index: any) => {
                    return (
                      <Grid item xs={3} key={index} >


                        <Box m={1}>
                          <Card style={{ width: "250px", height: "400px" }} >
                            <div style={{ display: "flex", flexDirection: "row" }}>
                              <img style={{ width: "10%", height: "10%", marginLeft: "10px", marginTop: "10px" }} src={greenTick} />
                              <p style={Styles.cardhead}>{item?.attributes?.warranty} warranty</p>

                              <IconButton aria-label="settings" style={{ marginTop: "5px" }}>
                                <FavoriteBorderIcon />
                              </IconButton>

                            </div>

                            <CardContent>
                              <img style={{ display: "block", marginLeft: "60px", marginBottom: "15px", width: "50%", height: "30%" }} src={item?.attributes?.thumbnail_image} />
                              <div style={{ marginLeft: "30px" }}>

                              </div>
                              <p style={{ textAlign: "center" }}>{item?.attributes?.model_no}</p>
                              <p style={{ textAlign: "center" }}>{item?.attributes?.capaciy}</p>
                              <p style={{ textAlign: "center" }}>${item?.attributes?.amount}</p>
                              <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                <div style={{ width: "35px", height: "20px", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", border: "1px solid gray", borderRadius: "10px" }}>
                                  <img style={{ width: "10px", height: "10px" }} src={yellowStar} />
                                  <p>{item?.attributes?.range}</p>
                                </div>
                                <div style={{ marginLeft: "10px" }}>
                                  <div style={{ width: "70px", border: "1px solid gray", borderRadius: "10px", backgroundColor: "green" }}>
                                    {item?.attributes?.applied_discount}% off
                                  </div>
                                </div>
                              </div>
                            </CardContent>
                          </Card>
                        </Box>
                      </Grid>


                    )

                  })

                  }
                </Grid>
              </div>
            }

            <Container style={{ marginTop: '30px' }}>

              <Grid container>
                <Grid item xs={12} md={3} >

                  <Box sx={{
                    bgcolor: 'background.paper',
                    marginRight: '25px',

                  }}>
                    <div style={{ fontWeight: 'bold', padding: '10px' }}>Filter</div>
                    <div style={{ backgroundColor: '#f1f1f1', margin: '10px' }}>
                      <div style={{ padding: '10px' }}>Battery Type</div>

                      <RadioGroup aria-label="gender" name="gender1" value={this.state.batteryType}
                        onChange={(e: any) => this.setState({ batteryType: e.target.value })}>
                        <StyledFormControlLabel value="female" control={<Radio />} label="Vehicular Batteries" checked
                          onClick={this.getVehicleBatteriesCall}

                        />
                        <StyledFormControlLabel value="male" control={<Radio />} label="Inverter Batteries"
                          onClick={() => this.props.navigation.navigate("InverterBatteries")}
                        />
                      </RadioGroup>
                    </div>

                    {/* category */}
                    <div style={{ backgroundColor: '#f1f1f1', margin: '10px' }}>
                      <div style={{ padding: '10px' }}>Category</div>
                      {this.state.uniqueSubcategoriesData.map((item: any, index: any) => {
                        return (
                          <FormGroup>
                            <StyledFormControlLabel
                              control={<Checkbox
                                checked={this.checkSubCategory(item)}
                              //  checked={this.state.selectCategory.includes(+item?.attributes?.sub_category_id)}
                              />}
                              label={item.attributes?.sub_category_name}
                              onChange={(e: any) => {
                                if (e.target.checked) {

                                  this.setState({
                                    selectCategory: [...this.state.selectCategory, item],
                                    allFilterSelect: true
                                  }, () => {
                                    this.ApplyVehicleFilterSubCatCall(item);
                                  })
                                } else {
                                  // code for unchecking fuel when category is unchecked
                                  let copyFuel = [...this.state.selectFuel]
                                  let arr2 = copyFuel.filter((element: any) => {
                                    return element?.attributes?.sub_category_id != item?.attributes?.sub_category_id
                                  })
                                  this.setState({ selectFuel: [...arr2] })


                                  // code for unchecking manufacture when category is unchecked
                                  let copyManufacturer = [...this.state.selectManufacturer]
                                  let arr = copyManufacturer.filter((element: any) => {
                                    return element?.attributes?.sub_category_id != item?.attributes?.sub_category_id
                                  })
                                  this.setState({ selectManufacturer: [...arr] })

                                  // code for unchecking model when category is unchecked
                                  let copyModel = [...this.state.selectModel]
                                  let arr1 = copyModel.filter((element: any) => {
                                    return element?.attributes?.sub_category_id != item?.attributes?.sub_category_id
                                  })
                                  this.setState({ selectModel: [...arr1] })



                                  // code for unchecking category and creating value for it to pass in api body
                                  let copyCategory = [...this.state.selectCategory];
                                  let index = copyCategory.findIndex((number: any) => {
                                    return +number?.attributes?.sub_category_id === +item?.attributes?.sub_category_id
                                  })

                                  copyCategory.splice(index, 1)
                                  this.setState({
                                    selectCategory: [...copyCategory], allFilterReject: true, showManufacurerList: false,
                                    showModelList: false, showFuelList: false
                                  },
                                    () => {
                                      this.ApplyVehicleFilterSubCatCall(item);
                                    })
                                }
                              }}

                            />
                          </FormGroup>
                        )
                      }
                      )}
                    </div>

                    {/* manufacturer */}
                    <div style={{ backgroundColor: '#f1f1f1', margin: '10px' }}>
                      <div style={{ padding: '10px' }}>Manufacturer</div>
                      {this.state.uniqueManufacturerData.map((item: any, index: any) => {
                        return (
                          <FormGroup>
                            <StyledFormControlLabel
                              control={<Checkbox
                                checked={!this.checkSubCategory(item) ? false : this.checkManufacture(item)}
                                // checked={!this.state.selectCategory.includes(+item?.attributes?.sub_category_id) ? false : this.checkManufacture(item)}

                                disabled={!this.checkSubCategory(item)}
                              />}
                              label={item?.attributes?.manufacturer}
                              onChange={(e: any) => {
                                if (e.target.checked) {
                                  this.setState({ selectManufacturer: [...this.state.selectManufacturer, item], showModelList: true },
                                    () => {
                                      this.ApplyVehicleFilterManufacturerCall(item);
                                    })
                                } else {

                                  // code for uncheck models when manufacture are unchecked
                                  let copyModel = [...this.state.selectModel]
                                  let arr = copyModel.filter((element: any) => {
                                    return element?.attributes?.item_id != item?.attributes?.item_id && item?.attributes?.manufacturer != element?.attributes?.manufacturer

                                  })
                                  this.setState({ selectModel: [...arr] });

                                  // code for uncheck fuels when manufacture are checked
                                  // let copyFuel = [...this.state.selectFuel]
                                  // let arr1 = copyFuel.filter((element: any) => {
                                  //   return element?.attributes?.fuel != item?.attributes?.fuel

                                  // })
                                  // this.setState({ selectFuel: [...arr1] })


                                  // code for unchecking manufacture and creating value for it to pass in api body
                                  let copyManufacture = [...this.state.selectManufacturer]
                                  let index = copyManufacture.findIndex((number: any) => {
                                    return +number?.id === +item?.id
                                  })
                                  copyManufacture.splice(index, 1)
                                  this.setState({ selectManufacturer: [...copyManufacture] }, () => {
                                    this.ApplyVehicleFilterManufacturerCall(item);
                                  })
                                }
                              }}


                            />
                          </FormGroup>
                        )
                      }
                      )}
                    </div>

                    {/* model */}
                    <div style={{ backgroundColor: '#f1f1f1', margin: '10px' }}>
                      <div style={{ padding: '10px' }}>Model</div>
                      {this.state.uniqueModelData.map((item: any, index: any) => {
                        return (
                          <FormGroup>
                            <StyledFormControlLabel
                              control={<Checkbox
                                checked={!this.checkManufacture(item) ? false : this.checkModel(item)}
                                // checked={this.state.selectModel.includes(+item?.id) && this.state.selectManufacturer.includes(+item?.id)}
                                disabled={!this.checkManufacture(item)}
                              // disabled={!this.state.selectManufacturer.includes(+item?.id)}
                              />}
                              label={item?.attributes?.vehicle_model}
                              onChange={(e: any) => {
                                if (e.target.checked) {
                                  // this.setState({ selectModel: [...this.state.selectModel, +item?.id], showFuelList: true },
                                  this.setState({ selectModel: [...this.state.selectModel, item], showFuelList: true },
                                    () => {
                                      this.ApplyVehicleFilterModelCall(item);
                                    })
                                } else {

                                  // code for uncheck fuels when model are checked
                                  let copyFuel = [...this.state.selectFuel]
                                  let arr1 = copyFuel.filter((element: any) => {
                                    return element?.attributes?.fuel != item?.attributes?.fuel

                                  })
                                  this.setState({ selectFuel: [...arr1] })

                                  let copyModel = [...this.state.selectModel]
                                  let index = copyModel.findIndex((number: any) => {
                                    return +number?.id === +item?.id
                                  })
                                  // let index = copyModel.findIndex(number => number === (+item?.id))
                                  copyModel.splice(index, 1)
                                  this.setState({ selectModel: [...copyModel] }, () => {
                                    this.ApplyVehicleFilterModelCall(item);
                                  })
                                }
                              }}

                            />
                          </FormGroup>
                        )
                      }
                      )}
                    </div>

                    {/* Fuel type */}
                    <div style={{ backgroundColor: '#f1f1f1', margin: '10px' }}>
                      <div style={{ padding: '10px' }}>Fuel Type</div>
                      {this.state.uniqueFuelData.map((item: any, index: any) => {
                        return (
                          <FormGroup>
                            <StyledFormControlLabel
                              control={<Checkbox
                                checked={!this.checkIfFuelDisabled(item) ? false : this.checkFuel(item)}
                                // checked={this.state.selectFuel.includes(+item?.attributes?.item_id) && this.state.selectModel.includes(+item?.id)}
                                // disabled={!this.state.selectModel.includes(+item?.id)}
                                disabled={!this.checkIfFuelDisabled(item)}
                              />}
                              label={item?.attributes?.fuel}
                              onChange={(e: any) => {
                                if (e.target.checked) {

                                  this.setState({ selectFuel: [...this.state.selectFuel, item] }, () => {
                                    this.ApplyVehicleFilterFuelCall(item);
                                  })
                                } else {
                                  let copyFuel = [...this.state.selectFuel]
                                  let index = copyFuel.findIndex((element: any) => {
                                    return +element?.attributes?.item_id === +item?.attributes?.item_id
                                  })
                                  copyFuel.splice(index, 1)
                                  this.setState({ selectFuel: [...copyFuel] }, () => {
                                    this.ApplyVehicleFilterFuelCall(item);
                                  })
                                }
                              }}

                            />
                          </FormGroup>
                        )
                      }
                      )}
                    </div>

                  </Box>

                  <Box sx={{
                    marginRight: '25px',
                    bgcolor: 'background.paper'
                  }}>
                    <div style={{ marginTop: '20px' }}>


                      <div style={{ padding: '15px 0px 15px 15px', fontWeight: 'bold' }}>Sort By</div>
                      <RadioGroup aria-label="sort" name="sort1" value={this.state.sortType}
                        onChange={(e: any) => this.setState({ sortType: e.target.value })}>
                        <StyledFormControlLabel value="radd" control={<Radio value={'recently_added'} />} label="Recently added"
                          onClick={this.sortbyVehicleRecentlyAddedCall}
                        />
                        <StyledFormControlLabel value="priceltoh" control={<Radio value={'price_l_to_h'} />} label="Price low to high"
                          onClick={this.sortbyPriceLowtoHighCall}
                        />
                        <StyledFormControlLabel value="pricehtol" control={<Radio value={'price_h_to_l'} />} label="Price high to low"
                          onClick={this.sortbyPriceHighttoLowCall}
                        />
                        <StyledFormControlLabel value="atoz" control={<Radio value={'atoz'} />} label="From A to Z"
                          onClick={this.sortbyFromAtoZCall}
                        />
                      </RadioGroup>



                    </div>


                  </Box>



                </Grid>

                <Grid item xs={12} md={8}>
                  <Grid spacing={3} container>
                    {!this.state.allFilterSelect && this.state.allFilterReject &&
                      this.state.vehicleBatteriesData?.map((item: any, index: any) => {
                        return <Grid item xs={12} md={4} key={index} >





                          <Card>
                            <div style={{ display: "flex", justifyContent: 'space-between', padding: '15px' }}>
                              <div style={{ display: 'flex', alignItems: 'center' }}>

                                <img src={securedGreenTick} width='25px' height='25px' />
                                <small style={{ textDecoration: 'underline', marginLeft: '10px', color: "#9CA0A9" }}>{item?.attributes?.warranty}</small>
                              </div>

                              <div
                                onClick={() => {
                                  if (localStorage.getItem('loginSuccessToken')) {

                                    if (item?.attributes?.is_wishlisted) {
                                      localStorage.setItem('item_id', item?.id);
                                      this.deleteWishlist()

                                    }
                                    else {

                                      localStorage.setItem('item_id', item?.id);
                                      this.createWishList()
                                    }
                                  }
                                  else {
                                    localStorage.setItem('wishList',JSON.stringify(true))
                                    this.props.navigation.navigate('LoginRequired')
                                  }


                                }}
                                style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>

                                <img src={item?.attributes?.is_wishlisted ? heart : love} width='25px' height='20px' />
                              </div>


                            </div>
                            <CardContent>
                              <div
                               onClick={() => {
                              
                                localStorage.setItem("applyShowBatteriesId", item.id)
                              this.props.navigation.navigate('ProductDescription1',item?.id)
                              }}
                              style={{ display: 'flex', flexDirection: 'column', alignItems: 'center',cursor:'pointer' }}>
                                <div style={{ padding: '15px' }}><img src={item?.attributes?.thumbnail_image} width='100' height='100' /></div>



                                <div style={{ fontWeight: 'bold', marginTop: '15px' }}>{item?.attributes?.model_no}</div>
                                <div style={{ fontWeight: 'bold', marginTop: '15px', marginBottom: '20px' }}><span style={{ color: '#B1B5C3' }}>Capacity</span> {item?.attributes?.capaciy}</div>

                                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                  <span style={{ fontWeight: 'bold', fontSize: '20px' }}>&#x20b9;{this.finalPrice(item)}</span>
                                  <s style={{ color: '#9498A9', marginLeft: '10px', fontSize: '20px', fontWeight: 'bold' }}>&#x20b9;{item?.attributes?.amount}</s>
                                </div>
                                <div style={{ display: 'flex', marginTop: '10px' }}>
                                  <div style={{ border: '1px solid gray', display: 'flex', marginRight: '10px', borderRadius: '25px', paddingLeft: '5px', paddingRight: '5px' }}>
                                    <span>

                                      <img src={yellowStar} width='15px' height='15px' />
                                    </span>


                                    <span style={{ display: 'flex', alignItems: 'center' }}>{item?.attributes?.range}</span>

                                  </div>
                                  <span
                                    style={{
                                      borderRadius: '25px', paddingLeft: '10px', color: 'white',
                                      display: 'flex', alignItems: 'center',
                                      paddingRight: '10px', backgroundColor: '#66bc54'
                                    }}> {Math.round(item?.attributes?.applied_discount)}% off</span>
                                </div>
                              </div>
                            </CardContent>


                          </Card>

                        </Grid>

                      })}




                  </Grid>
                </Grid>
              </Grid>
              {/* vision statement */}
              <VisionStatement navigation={this.props.navigation} id={''} classes={undefined} openSearch={undefined} />
            </Container>

          </div>
        </div>

      </>
    )
  }
}
// Customizable Area End





























