// Customizable Area Start
import React, { Component } from "react";
import MerchantOrderController from "./MerchantOrderController";
import Sidebar from "./Sidebar.web";
import {
  Box,
  Container,
  Modal,
  Button,
  ListItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Collapse,
  List,
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
  Switch,
  Radio,
  Paper,
  Avatar,
  Badge,
  Backdrop,
  Fade,
  Stepper,
  Step,
  StepLabel,
} from "@material-ui/core";
import Person from "@material-ui/icons/Person";
import Right from "@material-ui/icons/ArrowForward";
import Close from "@material-ui/icons/Close";
import Bell from "@material-ui/icons/NotificationsNone";
import Place from "@material-ui/icons/Place";
import Mail from "@material-ui/icons/Mail";
import Copy from "@material-ui/icons/FileCopy";
import DateRange from "@material-ui/icons/DateRange";
import InitialObservations from "./InitialObservations.web";
import ActionsInitiated from "./ActionsInitiated.web";
import FinalObservations from "./FinalObservations.web";
import ExtraCharges from "./ExtraCharges.web";
import Notification from "./Notification.web";

const ezBattery = require(".././assets/red_battery.png");
const report = require(".././assets/report.png");

const buttonColor = {
  backgroundColor: "#32cba1",
  padding: "10px 50px",
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  mt: 5,
  mb: 5,

  boxShadow: 24,
  p: 2,
  borderRadius: "20px",
};

export default class UpcomingServiceCall extends MerchantOrderController {
  arr = [0, 1, 2];
  arr1 = [0, 1, 2];
  steps = [
    "Initial Observations",
    "Actions Initiated",
    "Final Observations",
    "Extra Charges",
  ];
  getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <InitialObservations
            navigation={this.props.navigation}
            id={this.props.id}
          />
        );
      case 1:
        return (
          <ActionsInitiated
            navigation={this.props.navigation}
            id={this.props.id}
          />
        );
      case 2:
        return (
          <FinalObservations
            navigation={this.props.navigation}
            id={this.props.id}
          />
        );
      case 3:
        return (
          <ExtraCharges navigation={this.props.navigation} id={this.props.id} />
        );
      //   case 4:
      //     return <AddBatteryCollection navigation={this.props.navigation} id={this.props.id} openSoS={this.props.openSoS} closeSoS={this.props.closeSoS} />;
      default:
        throw new Error("Unknown step");
    }
  };

  handleNext = () => {
    this.setState({ activeStep: this.state.activeStep + 1 });
  };

  handleBack = () => {
    this.setState({ activeStep: this.state.activeStep - 1 });
  };

  render() {
    return (
      <>
        <div style={{ display: "flex" }}>
          {/* sidebar */}

          <Sidebar navigation={this.props.navigation} id={this.props.id} />

          <Container style={{ marginTop: "20px" }}>
          <Notification navigation={this.props.navigation} id={this.props.id} />

            <Grid container spacing={3}>
              <Grid item xs={12} md={8} style={{ marginRight: "70px" }}>
                <Grid spacing={2} container>
                  <Grid item xs={12} md={12}>
                    <div style={{ padding: "20px 0px" }}>
                      Hi John Good morning
                    </div>
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <div style={{ fontWeight: "bold", fontSize: "25px" }}>
                        Initial Observations
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <div>
                      <Stepper
                        activeStep={this.state.activeStep}
                        alternativeLabel
                      >
                        {this.steps.map((label: any) => (
                          <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                      {this.state.activeStep === this.steps.length ? (
                        <React.Fragment>
                          <Typography variant="h5" gutterBottom>
                            Thank you for your order.
                          </Typography>
                          <Typography variant="subtitle1">
                            Your order number is #2001539. We have emailed your
                            order confirmation, and will send you an update when
                            your order has shipped.
                          </Typography>
                        </React.Fragment>
                      ) : (
                        // <Container component="main" maxWidth="lg">
                        <>
                          {this.getStepContent(this.state.activeStep)}
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            {this.state.activeStep !== 0 && (
                              <Button
                                onClick={this.handleBack}
                                variant="outlined"
                                style={{
                                  padding: "10px 90px 10px 90px",
                                  margin: "30px 0px 0px 20px",
                                }}
                              >
                                Back
                              </Button>
                            )}

                            {this.state.activeStep === this.steps.length - 1 ? (
                              <div style={{ display: "flex" }}>
                                <Button
                                  variant="outlined"
                                  style={{
                                    padding: "10px 90px 10px 90px",
                                    margin: "30px 20px 0px 0px",
                                  }}
                                  onClick={() =>
                                    this.setState({ toggleOTPModal: true })
                                  }
                                >
                                  Finish
                                </Button>
                                <Button
                                  variant="contained"
                                  style={{
                                    padding: "10px 90px 10px 90px",
                                    margin: "30px 20px 0px 0px",
                                    backgroundColor: "#32cba0",
                                  }}
                                >
                                  Add
                                </Button>
                              </div>
                            ) : (
                              <Button
                                variant="contained"
                                onClick={this.handleNext}
                                style={{
                                  padding: "10px 90px 10px 90px",
                                  margin: "30px 20px 0px 0px",
                                  backgroundColor: "#32cba0",
                                }}
                              >
                                Next
                              </Button>
                            )}
                          </Box>
                        </>
                        // </Container>
                      )}
                      {/* </Paper> */}
                    </div>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={3} style={{ marginTop: "20px" }}>
                <div style={{marginBottom:'40px',fontSize:'20px'}}>You are viewing John Doe Car Battery Problem</div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    margin: "10px 0px",
                  }}
                >
                  <span>
                    <DateRange />
                  </span>
                  <div>10 Apr 22 - 4:30PM</div>
                </div>
                <Paper elevation={2}>
                  <Box
                    sx={{
                      bgcolor: "background.paper",
                      borderRadius: "20px",
                      padding: "5px",
                    }}
                  >
                    <div
                      style={{
                        marginTop: "10px",
                        padding: "5px",
                      }}
                    >
                      <small>Type of service call</small>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <small style={{ fontWeight: "bold" }}>
                          Car Battery Problem
                        </small>

                        <div
                          style={{
                            backgroundColor: "#32cba1",
                            borderRadius: "25px",
                            padding: "0px 5px",
                            width: "max-content",
                            margin: "5px 0px",
                          }}
                        >
                          <span style={{ fontSize: "small" }}>EZ Battery</span>
                        </div>
                      </div>
                    </div>

                    <Divider />

                    <div>
                      <div style={{ fontSize: "small", padding: "5px" }}>
                        Customer Details
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <Person fontSize="small" />
                        </div>
                        <div
                          style={{
                            fontSize: "small",
                            paddingLeft: "5px",
                            fontWeight: "bold",
                          }}
                        >
                          John Doe, +91788448848
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <div>
                          <Place fontSize="small" />
                        </div>
                        <div
                          style={{
                            fontSize: "small",
                            paddingLeft: "5px",
                            fontWeight: "bold",
                          }}
                        >
                          Bezel Pharma, Opp Plot 99,Govt Indl Estate,Mumbai,7233
                        </div>
                      </div>
                    </div>
                  </Box>
                </Paper>

                <div style={{ margin: "20px 0px", fontWeight: "bold" }}>
                  Request Reports
                </div>
                <div style={{ display: "flex" }}>
                  {this.arr1.map(() => {
                    return (
                      <Box
                        sx={{
                          bgcolor: "background.paper",
                          padding: "10px",
                          margin: "20px 10px 20px 0px",
                          borderRadius: "10px",
                        }}
                      >
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <img src={report} width="60px" height="60px" />
                        </div>
                        <div
                          style={{
                            fontSize: "small",
                            padding: "10px 0px 0px 0px",
                            fontWeight: "bold",
                          }}
                        >
                          Technician's{" "}
                        </div>
                        <div
                          style={{
                            display: "small",
                            justifyContent: "center",
                            fontSize: "15px",
                            padding: "0px 0px 10px 0px",
                            fontWeight: "bold",
                          }}
                        >
                          Report
                        </div>
                      </Box>
                    );
                  })}
                </div>

                <div style={{ fontWeight: "bold", marginTop: "55px" }}>
                  Contact Us
                </div>

                <Paper elevation={3}>
                  <Box
                    sx={{
                      marginRight: "25px",
                      marginTop: "30px",
                      bgcolor: "background.paper",
                      width: "300px",
                      padding: "10px",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "small",
                        padding: "10px 10px 0px 10px",
                      }}
                    >
                      Dealer contact details
                    </div>
                    <div
                      style={{
                        display: "flex",
                        fontSize: "small",
                        alignItems: "center",
                        padding: "5px",
                      }}
                    >
                      <div>
                        <Person fontSize="small" />
                      </div>
                      <div style={{ fontWeight: "bold" }}>
                        Aaron Almaraz, +91 73535672
                      </div>
                      <div style={{ color: "#32cba1" }}>
                        <Copy fontSize="small" />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        fontSize: "small",
                        alignItems: "center",
                        padding: "5px",
                      }}
                    >
                      <div>
                        <Mail fontSize="small" />
                      </div>
                      <div style={{ fontWeight: "bold" }}>
                        aaron@hotmail.com
                      </div>
                      <div style={{ color: "#32cba1" }}>
                        <Copy fontSize="small" />
                      </div>
                    </div>
                    <Divider />

                    <div
                      style={{
                        fontSize: "small",
                        padding: "10px 10px 0px 10px",
                      }}
                    >
                      EZ Battery contact details
                    </div>
                    <div
                      style={{
                        display: "flex",
                        fontSize: "small",
                        alignItems: "center",
                        padding: "5px",
                      }}
                    >
                      <div>
                        <Person fontSize="small" />
                      </div>
                      <div style={{ fontWeight: "bold" }}>
                        Carol D, +91 73535672
                      </div>
                      <div style={{ color: "#32cba1" }}>
                        <Copy fontSize="small" />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        fontSize: "small",
                        alignItems: "center",
                        padding: "5px",
                      }}
                    >
                      <div>
                        <Mail fontSize="small" />
                      </div>
                      <div style={{ fontWeight: "bold" }}>
                        carol@ezbattery.com
                      </div>
                      <div style={{ color: "#32cba1" }}>
                        <Copy fontSize="small" />
                      </div>
                    </div>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </div>

        <div>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={this.state.toggleOTPModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={this.state.toggleOTPModal}>
              <Box sx={style}>
                <div onClick={()=>this.setState({toggleOTPModal:false})} style={{ display:"flex",justifyContent:'flex-end' ,cursor:'pointer'}}>
                  <Close />
                </div>
                <div style={{display:'flex',justifyContent:'center',fontWeight:'bold'}}>
                  Please ask the customer for the OTP to finish the service
                </div>

                <div
                  style={{ display: "flex", justifyContent: "space-evenly",marginTop:'25px' }}
                >
                  <div
                    style={{
                      border: "1px solid black",
                      width: "60px",
                      height: "60px",
                      borderRadius:'10px'
                    }}
                  />
                  <div
                    style={{
                      border: "1px solid black",
                      width: "60px",
                      height: "60px",
                      borderRadius:'10px'
                    }}
                  />
                  <div
                    style={{
                      border: "1px solid black",
                      width: "60px",
                      height: "60px",
                      borderRadius:'10px'
                    }}
                  />
                  <div
                    style={{
                      border: "1px solid black",
                      width: "60px",
                      height: "60px",
                      borderRadius:'10px'
                    }}
                  />
                </div>

                <div
                  style={{ backgroundColor: "#32cba1", borderRadius: "10px",marginTop:'60px' }}
                >
                  <div
                    style={{
                      padding: "15px 50px",
                      display: "flex",
                      justifyContent: "center",
                      fontWeight: "bold",
                    }}
                  >
                   Finish service
                  </div>
                </div>


              </Box>
            </Fade>
          </Modal>
        </div>
      </>
    );
  }
}
// Customizable Area End