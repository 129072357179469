export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const upArrow = require("../assets/up.png");
export const downArrow = require("../assets/down.png");
export const checked = require("../assets/check_box.png");
export const unchecked = require("../assets/blank_check_box.png");
export const Delete = require("../assets/delete.png");
export const bike = require("../assets/bike.png");
export const shock = require("../assets/shock.png");
export const dust = require("../assets/dust.png");
export const heat = require("../assets/heat.png");
export const love = require("../assets/love.png");
export const ambasdar = require("../assets/ambasdar.png");
export const appleStore = require("../assets/appleStore.png");
export const googlePlay = require("../assets/googlePlay.png");
export const batterySelection = require("../assets/batterySelection.png");
export const mobile = require("../assets/mobile.png");
export const down = require("../assets/down1.png");
export const ezLogo = require("../assets/ezLogo.png");
export const NonSpillable = require("../assets/Non-spillable.png")
export const LowMaintanance = require("../assets/Low-maintance.png")
export const FactoryCharged = require("../assets/factory-charged.png")
export const rickshaw = require("../assets/rickshaw.png");
export const car = require("../assets/car.png");


// navbar images
const sos = require("../assets/sos.png");
const bell = require("../assets/bell.png");
const cart = require("../assets/cart.png");
const logo = require("../assets/logo.png");



