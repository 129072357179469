// Customizable Area Start
import React, { Component } from "react";
import MerchantOrderController from "./MerchantOrderController";
import Sidebar from "./Sidebar.web";
import {
  Box,
  Container,
  Modal,
  Button,
  ListItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Collapse,
  List,
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
  Switch,
  Radio,
  Paper,
  Avatar,
  Badge,
  Backdrop,
  Fade,
} from "@material-ui/core";
import Person from "@material-ui/icons/Person";
import Right from "@material-ui/icons/ArrowForward";
import Close from "@material-ui/icons/Close";
import Bell from "@material-ui/icons/NotificationsNone";
import Place from "@material-ui/icons/Place";
import Mail from "@material-ui/icons/Mail";
import Copy from "@material-ui/icons/FileCopy";
import DateRange from "@material-ui/icons/DateRange";
import Notification from "./Notification.web";

const ezBattery = require(".././assets/red_battery.png");
const report = require(".././assets/report.png");

const buttonColor = {
  backgroundColor: "#32cba1",
  padding: "10px 50px",
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  mt: 5,
  mb: 5,

  boxShadow: 24,
  p: 2,
  borderRadius: "20px",
};
const textColor = {
  color: "#C4C6D1",
  margin: "15px 0px",
};

export default class TechnicianProfile extends MerchantOrderController {
  arr = [0, 1, 2];
  arr1 = [0, 1, 2];

  render() {
    return (
      <>
        <div style={{ display: "flex" }}>
          {/* sidebar */}

          <Sidebar navigation={this.props.navigation} id={this.props.id} />

          <Container style={{ marginTop: "20px" }}>
          <Notification navigation={this.props.navigation} id={this.props.id} />

            <Grid container spacing={3}>
              <Grid item xs={12} md={8} style={{ marginRight: "70px" }}>
                <Grid spacing={2} container>
                  <Grid item xs={12} md={12}>
                    <div style={{ padding: "20px 0px" }}>
                      Hi John Good morning
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ fontWeight: "bold", fontSize: "25px" }}>
                        My Profile
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          width: "125px",
                          height: "130px",
                          borderRadius: "15px",
                          border: "1px solid gray",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginRight:'20px'
                        }}
                      >
                        <Person />
                      </div>
                      <div>
                        <div style={textColor}>Full Name</div>
                        <div style={{ fontWeight: "bold" }}>Aaron Almaraz</div>
                        <div style={textColor}>Mobile Number</div>
                        <div style={{ fontWeight: "bold" }}>+91 6333828373</div>
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <div style={{ display: "flex" }}>
                      <div>
                        <div style={textColor}>Address</div>
                        <div style={{ fontWeight: "bold" }}>
                          Bezel Pharma,Govt Indl Estate, Opp Plot 99,Hindustan
                          Naka,Kandivali (west),Pune,367209
                        </div>
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={12} md={6} />

                  <Grid item xs={12} md={6}>
                    <div style={textColor}>Aadhar Number</div>
                    <div style={{ fontWeight: "bold" }}>7458 8547 4578</div>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <div style={textColor}>Driving License Number</div>
                    <div style={{ fontWeight: "bold" }}>KA-7842 ALQOV</div>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <div style={textColor}>PAN Number</div>
                    <div style={{ fontWeight: "bold" }}>FSAS6468BS33</div>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <div style={textColor}>Toolkit assigned Number</div>
                    <div style={{ fontWeight: "bold" }}>EZ084YTIWSER22</div>
                  </Grid>

                  <Grid item xs={6} md={4}>
                    <div
                    onClick={()=>this.props.navigation.navigate('EditProfile')}
                      style={{
                        padding: "15px 10px",
                        margin: "35px 0px",
                        backgroundColor: "#32cba1",
                        borderRadius: "10px",
                        fontWeight: "bold",
                        display: "flex",
                        justifyContent: "center",
                        fontSize: "small",
                        cursor:'pointer'
                      }}
                    >
                      Edit
                    </div>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={3} style={{ marginTop: "65px" }}>
                <Paper elevation={3}>
                  <Box
                    sx={{
                      bgcolor: "background.paper",
                      marginRight: "25px",
                      padding: "20px 25px",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: "20px",
                        padding: "20px 20px 0px 20px",
                      }}
                    >
                      Vision Statement
                    </div>
                    <div
                      style={{
                        width: "70x",
                        fontSize: "smaller",
                        padding: "15px 20px 20px 20px",
                      }}
                    >
                      Laxmi Motors through EZ Battery {"&"} Auto Spares
                      envisions to be the first
                    </div>
                  </Box>
                </Paper>

                <div
                  style={{
                    padding: "15px",
                    margin: "35px 0px",
                    backgroundColor: "#32cba1",
                    borderRadius: "10px",
                    fontWeight: "bold",
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "small",
                  }}
                >
                  Manage Customer Battery Collection
                </div>

                <div style={{ margin: "20px 0px", fontWeight: "bold" }}>
                  Request Reports
                </div>
                <div style={{ display: "flex" }}>
                  {this.arr1.map(() => {
                    return (
                      <Box
                        sx={{
                          bgcolor: "background.paper",
                          padding: "10px",
                          margin: "20px 10px 20px 0px",
                          borderRadius: "10px",
                        }}
                      >
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <img src={report} width="60px" height="60px" />
                        </div>
                        <div
                          style={{
                            fontSize: "small",
                            padding: "10px 0px 0px 0px",
                            fontWeight: "bold",
                          }}
                        >
                          Technician's{" "}
                        </div>
                        <div
                          style={{
                            display: "small",
                            justifyContent: "center",
                            fontSize: "15px",
                            padding: "0px 0px 10px 0px",
                            fontWeight: "bold",
                          }}
                        >
                          Report
                        </div>
                      </Box>
                    );
                  })}
                </div>

                <div style={{ fontWeight: "bold", marginTop: "55px" }}>
                  Contact Us
                </div>

                <Paper elevation={3}>
                  <Box
                    sx={{
                      marginRight: "25px",
                      marginTop: "30px",
                      bgcolor: "background.paper",
                      width: "300px",
                      padding: "10px",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "small",
                        padding: "10px 10px 0px 10px",
                      }}
                    >
                      Dealer contact details
                    </div>
                    <div
                      style={{
                        display: "flex",
                        fontSize: "small",
                        alignItems: "center",
                        padding: "5px",
                      }}
                    >
                      <div>
                        <Person fontSize="small" />
                      </div>
                      <div style={{ fontWeight: "bold" }}>
                        Aaron Almaraz, +91 73535672
                      </div>
                      <div style={{ color: "#32cba1" }}>
                        <Copy fontSize="small" />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        fontSize: "small",
                        alignItems: "center",
                        padding: "5px",
                      }}
                    >
                      <div>
                        <Mail fontSize="small" />
                      </div>
                      <div style={{ fontWeight: "bold" }}>
                        aaron@hotmail.com
                      </div>
                      <div style={{ color: "#32cba1" }}>
                        <Copy fontSize="small" />
                      </div>
                    </div>
                    <Divider />

                    <div
                      style={{
                        fontSize: "small",
                        padding: "10px 10px 0px 10px",
                      }}
                    >
                      EZ Battery contact details
                    </div>
                    <div
                      style={{
                        display: "flex",
                        fontSize: "small",
                        alignItems: "center",
                        padding: "5px",
                      }}
                    >
                      <div>
                        <Person fontSize="small" />
                      </div>
                      <div style={{ fontWeight: "bold" }}>
                        Carol D, +91 73535672
                      </div>
                      <div style={{ color: "#32cba1" }}>
                        <Copy fontSize="small" />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        fontSize: "small",
                        alignItems: "center",
                        padding: "5px",
                      }}
                    >
                      <div>
                        <Mail fontSize="small" />
                      </div>
                      <div style={{ fontWeight: "bold" }}>
                        carol@ezbattery.com
                      </div>
                      <div style={{ color: "#32cba1" }}>
                        <Copy fontSize="small" />
                      </div>
                    </div>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </div>

       
        
      </>
    );
  }
}
// Customizable Area End