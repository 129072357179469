// Customizable Area Start
import React, { Component } from 'react'
import MerchantDashboard2Controller from './MerchantDashboard2Controller';

import {
    Box,
    Container,
    Button,
    Grid, Avatar, Menu, MenuItem

} from "@material-ui/core";
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import VisionStatementWeb from '../../../components/src/VisionStatement.web';
const threedots = require('.././assets/threedots.png')
const edit = require('.././assets/edit.png')
const deleteIcon = require('.././assets/delete.png')

export default class TechnicianManagement extends MerchantDashboard2Controller {
   

    handleClick = (event: any) => {
        this.setState({ anchorEl: event.currentTarget });
    };
    handleClose = () => {
        this.setState({ anchorEl: null })
    };
    render() {
        return (
            <>
               <div style={{ padding: '20px', backgroundColor: '#efefef' }}>
                    <Container>

                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                            <div style={{ color: '#a1a0a4' }}>
                                <a href='/' style={{ textDecoration: 'none', color: '#a1a0a4' }}>Home</a> <ArrowForwardIosRoundedIcon className="arrow-left" /> <span style={{ cursor: 'pointer' }} onClick={() => this.props.navigation.goBack()}>Profile</span>
                                <ArrowForwardIosRoundedIcon className="arrow-left" />Technician Management
                            </div>
                            {!localStorage.getItem("loginSuccessToken") && <div style={{ color: '#a1a0a4' }}>
                                <span>
                                    <i>For Better Experience</i>
                                    <span id='fbe' onClick={() => {
                                        this.props.navigation.navigate('EmailAccountLoginBlock')
                                    }} style={{ color: "red", fontWeight: 'bold', cursor: 'pointer' }}> Login/</span><span onClick={() => {
                                        this.props.navigation.navigate('signUp')
                                    }} style={{ color: "red", fontWeight: 'bold', cursor: 'pointer' }}>Register</span>
                                </span>
                            </div>
                            }
                        </div>
                    </Container>
                </div>

                <Container style={{ marginTop: '20px' }}>
                    <Grid  container spacing={3}>
                        {this.state?.allTechnicians?.map((item:any) => {
                            return <Grid id='grid'  item xs={12} md={4}>
                                <div key={item?.id} style={{ padding: '15px', borderRadius: '15px', backgroundColor: 'white', display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ display: 'flex' }}>
                                        <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" style={{ width: '30px', height: '30px', marginRight: '10px' }} />
                                        <div style={{ display: 'flex', flexDirection: 'column' }} >
                                            <div style={{ fontWeight: 'bold' }}>{item?.attributes?.details?.full_name}</div>
                                            <div>{item?.attributes?.details?.mobile_no}</div>

                                        </div>


                                    </div>

                                    <div id='dots' style={{ cursor: 'pointer' }} onClick={(e) => {
                                        this.handleClick(e);
                                        localStorage.setItem('singleTech',JSON.stringify(item))
                                        localStorage.setItem('techId',item?.attributes?.details?.id)
                                    }}>
                                        <img src={threedots} width='30px' />
                                    </div>



                                </div>

                            </Grid>


                        })}


                    </Grid>

                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
                        <Button id='addButton' onClick={()=>this.props.navigation.navigate('AddTechnician')} style={{ backgroundColor: "32cba1", padding: '10px 90px' }}>Add Technician</Button>
                    </div>

                    <VisionStatementWeb classes={undefined} id={''} navigation={undefined} openSearch={undefined}/>

                </Container>

                <Menu
                    id="basic-menu"
                    anchorEl={this.state.anchorEl}
                    open={Boolean(this.state.anchorEl)}
                    onClose={this.handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem onClick={()=>{
                       
                        this.props.navigation.navigate('EditTechnician')
                    }}  ><img src={edit} width='25px'/><Box component={'span'} sx={{marginLeft:'10px'}}>Edit</Box></MenuItem>
                    <MenuItem onClick={()=>{
                        this.deleteTechnician()
                    }} ><img src={deleteIcon} width='25px'/><Box component={'span'} sx={{marginLeft:'10px'}}>Delete</Box></MenuItem>
                  

                </Menu>

            </>
        )
    }
}
// Customizable Area End