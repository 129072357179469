// Customizable Area Start
import React, { Component } from 'react'
import OrdermanagementController from './OrdermanagementController';
import {
    Box,
    Container,
    Modal,
    Button,
    ListItem,
    IconButton,
    ListItemIcon,
    ListItemText,
    Divider,
    Collapse,
    List,
    Grid,
    Card,
    CardContent,
    CardActions,
    Typography,
    Switch, Radio

} from "@material-ui/core";

export default class ReturnMethod extends OrdermanagementController {
    arr = [{ label: 'Pickup', info: 'A representative will come and pickup the package from your address' },

    { label: 'Speed post', info: 'You need to send the package via speed post' },
    { label: 'Walk in', info: 'You need to drop the package to the nearest EZ battery office' }]
    render() {
        return (
            <>
                <div style={{ fontWeight: 'bold', marginBottom: '10px' }}>Please select return method</div>
                <div>
                    <Grid spacing={3} container>
                        {this.arr.map((item) => {
                            return <Grid item xs={12} md={4}>


                                <div style={{ padding: '10px', border: '1px solid #f3f3f6',height:'100%' }}>

                                    <div style={{ display: 'flex' }}>
                                        <Radio

                                            name="radio-buttons"
                                            inputProps={{ 'aria-label': 'A' }}
                                        />
                                        <span style={{ display: 'flex', alignItems: 'center' }}>{item.label}</span>
                                    </div>
                                    <div>
                                        {item.info}
                                    </div>

                                </div>
                            </Grid>
                        })}
                    </Grid>

                </div>
            </>
        )
    }
}
// Customizable Area End