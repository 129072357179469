// Customizable Area Start
import React, { Component } from 'react'
import ContactusController from './ContactusController';
import {
    Box,
    Container,
    Modal,
    Button,
    ListItem,
    IconButton,
    ListItemIcon,
    ListItemText,
    Divider,
    Collapse,
    List,
    Grid,
    Card,
    CardContent,
    CardActions,
    Typography
    
} from "@material-ui/core";
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';


import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Radio from '@material-ui/core/Radio';

import ReportsRequest from './ReportsRequest.web';
import VisionStatementWeb from '../../../components/src/VisionStatement.web';




const ezBattery = require(".././assets/red_battery.png");
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    mt: 5,
    mb: 5,
    
    boxShadow: 24,
    p: 4,
};


export default class MyRequest extends ContactusController {
    constructor(props: any) {
        super(props);
    }
    arr = [0, 1, 2];
    timeline = ['Warranty', 'Installation', 'Out for delievery', 'Dispatched',
        'Terms Returned', 'Needs replacement or out of warranty', 'Battery Ok/Not Ok', 'Battery Testing',
        'Claim warranty request confirmed', 'Claim warranty request placed']
        listItems = [{ label: 'My service requests', isSelected: false }, { label: 'My warranty claims', isSelected: false }, { label: 'My reports request', isSelected: false }]
        
        handleCloseModal = () => {
            this.setState({ toggleClaimWarranty: false })
        }
        
        handleLists = (item: any) => {
            const arr = this.listItems;
            
            console.log(arr?.map((element: any) => {
                if (item?.label == element?.label) {
                    return { ...element, label: item.label, isSelected: true }
                }
                else {
                    return element
                }
            }))
            
            this.setState({
                listItems: arr?.map((element: any) => {
                    if (item?.label == element?.label) {
                        return { ...element, isSelected: true }
                    }
                    else {
                        return element
                    }
                })
            })
            
        }
        render() {
            if (!localStorage.getItem("loginSuccessToken")) {
                this.props.navigation.navigate('signUp');
                return null
                
            }
            return (
                <div>
                {/* header */}
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px', backgroundColor: '#efefef' }}>
                    <Container>

                    <div style={{ color: '#a1a0a4' }}>
                        <a href='/' style={{ textDecoration: 'none', color: '#a1a0a4' }}>Home</a> <ArrowForwardIosRoundedIcon className="arrow-left" /> My Request
                    </div>
                    {!localStorage.getItem("loginSuccessToken") && <div style={{ color: '#a1a0a4' }}>
                        <span><i>For Better Experience</i> <span style={{ color: "red" }} onClick={() => this.props.navigation.navigate("EmailAccountLoginBlock")}>Login/Register</span></span>
                    </div>}
                    </Container>
                </div>

                <Container component="main" maxWidth='lg' >
                    <div style={{ display: 'flex', marginTop: '30px' }}>
                        {/* aside */}

                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Grid container spacing={1}>
                                {/* filter */}
                                <Box sx={{
                                    marginRight: '20px',
                                    width: '300px',
                                    bgcolor: 'background.paper'
                                }}>
                                    <nav aria-label="main mailbox folders">
                                        <List>
                                            {this.state.listItems.map((item: any) => {
                                                return <>
                                                    <ListItem onClick={() => {
                                                        if (item.label == 'My reports request') {
                                                            this.setState({ toggleReportsRequest: true })
                                                        }
                                                        else {
                                                            this.setState({ toggleReportsRequest: false })
                                                        }
                                                        this.handleLists(item)
                                                    }} style={{ borderLeft: item.isSelected ? '5px solid #44c39f' : '0px solid' }} >

                                                        <ListItemText primary={item?.label} />

                                                    </ListItem>
                                                    <Divider />
                                                </>
                                            })}



                                        </List>
                                    </nav>


                                </Box>

                                {!this.state.toggleReportsRequest && <Box sx={{
                                    marginTop: '10px',
                                    // marginLeft: '300px',
                                    width: '300px',
                                    bgcolor: 'background.paper'
                                }}>
                                    <div style={{ display: 'flex', marginTop: '20px' }}>
                                        <Radio

                                            name="radio-buttons"
                                            inputProps={{ 'aria-label': 'A' }}
                                            />
                                        <span style={{ display: 'flex', alignItems: 'center' }}>Recently Added</span>
                                    </div>
                                    <div style={{ display: 'flex', marginTop: '20px' }}>
                                        <Radio

                                            name="radio-buttons"
                                            inputProps={{ 'aria-label': 'A' }}
                                            />
                                        <span style={{ display: 'flex', alignItems: 'center' }}>From A to Z</span>
                                    </div>


                                </Box>}




                            </Grid>
                        </div>

                        {/* maincontent */}




                        {!this.state.toggleReportsRequest && <Grid container spacing={3}>

                            {this.arr.map(() => {
                                return <Grid item xs={12} md={6}>
                                    <Card  >
                                        <CardContent>
                                            <Typography gutterBottom>
                                                Ordered on 21 Apr 22
                                            </Typography>
                                            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                <div style={{ padding: '15px' }}><img src={ezBattery} width='100' height='100' /></div>
                                                <div style={{ padding: '20px' }}>
                                                    <span style={{ fontWeight: 'bold' }}>EZ2345</span>
                                                    <div style={{ margin: '10px 0px 10px 0px' }}>

                                                        <small>Confirmation no - 98AXEJDJ</small>
                                                    </div>
                                                    <Divider />
                                                    <div onClick={() => this.setState({ toggleClaimWarranty: true })} style={{
                                                        marginTop: '20px',
                                                        color: 'red',
                                                        display: 'flex',
                                                        alignItems: 'flex-start',
                                                        fontSize: 'larger'
                                                        
                                                    }}>Track</div>


                                                </div>

                                            </div>
                                        </CardContent>

                                    </Card>
                                </Grid>
                            })}


                        </Grid>}



                        {this.state.toggleReportsRequest && <ReportsRequest history={this.props.history} navigation={this.props.navigation} id={this.props.id} openSoS={this.props.openSoS} closeSoS={this.props.closeSoS} clearSearch={undefined} />}
                    </div>
                    <VisionStatementWeb navigation={undefined} id={''} classes={undefined} openSearch={undefined}/>
                </Container>

                {this.state.toggleReportsRequest &&
                    <Container maxWidth='lg'>

                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '30px' }}>
                            <Button variant='outlined' style={{ padding: '10px 50px 10px 50px', marginRight: '10px' }}>Reports</Button>
                            <Button variant='contained' style={{ backgroundColor: '#32cba1', padding: '10px 50px 10px 50px' }}>Request Reports</Button>
                        </div>
                    </Container>
                }


                {/* </Container> */}
                <div>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={this.state.toggleClaimWarranty}
                        onClose={() => this.handleCloseModal()}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                        >
                        <Fade in={this.state.toggleClaimWarranty}>
                            <Box sx={style}>
                                <div>
                                    Claim Warranty Status
                                </div>

                                {/* <Stepper activeStep={this.state.activeStep} orientation='vertical'
                                
                                >
                                {this.timeline.map((label: any) => (
                                    <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                    </Step>
                                    ))}
                                </Stepper> */}
                                <div className="container">




                                    <div className="rightbox">
                                        <div className="rb-container">
                                            <ul className="rb">
                                                {this.timeline.map((item) => {
                                                    return <li className="rb-item" ng-repeat="itembx">
                                                        <div className="timestamp">
                                                            {item}
                                                        </div>


                                                    </li>
                                                })}



                                            </ul>

                                        </div>
                                    </div>
                                </div>


                            </Box>
                        </Fade>
                    </Modal>
                </div>
            </div>
        )
    }
}
// Customizable Area End
