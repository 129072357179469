// Customizable Area Start
import React, { Component } from 'react'
import OrdermanagementController from './OrdermanagementController';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';

import {
    Box,
    Container,
    Modal,
    Button,
    ListItem,
    IconButton,
    ListItemIcon,
    ListItemText,
    Divider,
    Collapse,
    List,
    Grid,
    Card,
    CardContent,
    CardActions,
    Typography,
    Switch, Radio, Checkbox

} from "@material-ui/core";
import Rating from '@material-ui/lab/Rating'
import StarIcon from '@material-ui/icons/Star';
import CloseIcon from '@material-ui/icons/Close';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TextField from '@material-ui/core/TextField';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import ReturnReason from './ReturnReason.web';
import ReturnMethod from './ReturnMethod.web';
import ConfirmReturn from './ConfirmReturn.web';
import VisionStatementWeb from '../../../components/src/VisionStatement.web';


const ezBattery = require(".././assets/red_battery.png");
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    mt: 5,
    mb: 5,

    boxShadow: 24,
    p: 4,
};

export default class SelectProductToExchange extends OrdermanagementController {
    arr = ['Product isnt functioning properly', 'Got a faulty product', 'Other Reason'];
    arr1 = [{ label: 'Exchange product with any other model', info: '*Extra charges may apply' }, { label: 'Exchange product with the same model', info: '*No Extra charges' }]

    category = [0, 1, 2, 3, 4, 5]
    manufacturer = [0, 1, 2, 3, 4, 5];
    products = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]
    handleCloseModal = () => {
        this.props.navigation.navigate('Orders')
        this.setState({ toggleReturn: false })
    }
    steps = ['Return Reason', 'Return Method', 'Confirm Return'];

    getStepContent = (step: number) => {
        switch (step) {
            case 0:
                return <ReturnReason navigation={this.props.navigation} id={this.props.id} />;
            case 1:
                return <ReturnMethod navigation={this.props.navigation} id={this.props.id} />;
            case 2:
                return <ConfirmReturn navigation={this.props.navigation} id={this.props.id} />

            default:
                throw new Error('Unknown step');
        }
    }

    handleNext = () => {
        this.setState({ activeStep: this.state.activeStep + 1 })

    };

    handleBack = () => {
        this.setState({ activeStep: this.state.activeStep - 1 })
    };
    render() {
        return (
            <>
                <div style={{ display: 'flex', justifyContent: 'flex-start', padding: '20px', backgroundColor: '#efefef' }}>
                    <Container>

                    <div style={{ color: '#a1a0a4' }}>
                        <a href='/' style={{ textDecoration: 'none', color: '#a1a0a4' }}>Home</a> <ArrowForwardIosRoundedIcon className="arrow-left" /> Orders <ArrowForwardIosRoundedIcon className="arrow-left" /> EZTZ4 <ArrowForwardIosRoundedIcon className="arrow-left" /> Exchange
                    </div>
                    </Container>

                </div>

                <Container style={{ marginTop: '20px' }}>
                    <div style={{display:'flex',justifyContent:'space-between',marginBottom:'10px'}}>
                        <div style={{display:"flex",alignItems:'center'}}>Select Product</div>
                        <div >

                        <Button onClick={()=>this.props.navigation.navigate('ExchangeMethod')}  style={{ padding: '10px 90px 10px 90px',marginRight:'100px', backgroundColor: '#32cba0' }} >Next</Button>
                        </div>
                    </div>
                    <Grid container>
                        <Grid item xs={12} md={3}>

                            <Box sx={{
                                bgcolor: 'background.paper',
                                marginRight: '25px'
                            }}>
                                <div style={{ fontWeight: 'bold', padding: '10px' }}>Filter</div>
                                <div style={{ backgroundColor: '#f1f1f1', margin: '10px' }}>
                                    <div style={{ padding: '10px' }}>Battery Type</div>

                                    <div style={{ display: 'flex' }}>
                                        <Radio
                                            color='default'
                                            name="radio-buttons"
                                            inputProps={{ 'aria-label': 'A' }}
                                        />
                                        <span style={{ display: 'flex', alignItems: 'center' }}>From A to Z</span>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <Radio
                                            color='default'
                                            name="radio-buttons"
                                            inputProps={{ 'aria-label': 'A' }}
                                        />
                                        <span style={{ display: 'flex', alignItems: 'center' }}>From A to Z</span>
                                    </div>
                                </div>

                                {/* category */}
                                <div style={{ backgroundColor: '#f1f1f1', margin: '10px' }}>
                                    <div style={{ padding: '10px' }}>Category</div>
                                    {this.category.map(() => {
                                        return <div style={{ display: 'flex' }}>
                                            <Checkbox
                                                color='default'
                                                name="radio-buttons"
                                                inputProps={{ 'aria-label': 'A' }}
                                            />
                                            <span style={{ display: 'flex', alignItems: 'center' }}>2 Wheeler</span>
                                        </div>
                                    })}
                                </div>

                                {/* manufacturer */}
                                <div style={{ backgroundColor: '#f1f1f1', margin: '10px' }}>
                                    <div style={{ padding: '10px' }}>Manufacturer</div>
                                    {this.manufacturer.map(() => {
                                        return <div style={{ display: 'flex' }}>
                                            <Checkbox
                                                color='default'
                                                name="radio-buttons"
                                                inputProps={{ 'aria-label': 'A' }}
                                            />
                                            <span style={{ display: 'flex', alignItems: 'center' }}>Honda</span>
                                        </div>
                                    })}
                                </div>

                                {/* model */}
                                <div style={{ backgroundColor: '#f1f1f1', margin: '10px' }}>
                                    <div style={{ padding: '10px' }}>Model</div>
                                    {this.manufacturer.map(() => {
                                        return <div style={{ display: 'flex' }}>
                                            <Checkbox
                                                color='default'
                                                name="radio-buttons"
                                                inputProps={{ 'aria-label': 'A' }}
                                            />
                                            <span style={{ display: 'flex', alignItems: 'center' }}>CB Twister</span>
                                        </div>
                                    })}
                                </div>

                                {/* Fuel type */}
                                <div style={{ backgroundColor: '#f1f1f1', margin: '10px' }}>
                                    <div style={{ padding: '10px' }}>Fuel Type</div>
                                    {this.manufacturer.map(() => {
                                        return <div style={{ display: 'flex' }}>
                                            <Checkbox
                                                color='default'
                                                name="radio-buttons"
                                                inputProps={{ 'aria-label': 'A' }}
                                            />
                                            <span style={{ display: 'flex', alignItems: 'center' }}>Petrol</span>
                                        </div>
                                    })}
                                </div>

                            </Box>

                            <Box sx={{
                                marginRight: '25px',
                                bgcolor: 'background.paper'
                            }}>
                                <div style={{ marginTop: '20px' }}>


                                    <div style={{ padding: '15px 0px 15px 15px', fontWeight: 'bold' }}>Sort By</div>
                                    <div style={{ display: 'flex' }}>

                                        <Radio

                                            name="radio-buttons"
                                            inputProps={{ 'aria-label': 'A' }}
                                        />
                                        <span style={{ display: 'flex', alignItems: 'center' }}>Recently Added</span>
                                    </div>
                                    <div style={{ display: 'flex', marginTop: '20px' }}>
                                        <Radio

                                            name="radio-buttons"
                                            inputProps={{ 'aria-label': 'A' }}
                                        />
                                        <span style={{ display: 'flex', alignItems: 'center' }}>Price low to high</span>
                                    </div>
                                    <div style={{ display: 'flex', marginTop: '20px' }}>
                                        <Radio

                                            name="radio-buttons"
                                            inputProps={{ 'aria-label': 'A' }}
                                        />
                                        <span style={{ display: 'flex', alignItems: 'center' }}>Price high to low</span>
                                    </div>
                                    <div style={{ display: 'flex', marginTop: '20px' }}>
                                        <Radio

                                            name="radio-buttons"
                                            inputProps={{ 'aria-label': 'A' }}
                                        />
                                        <span style={{ display: 'flex', alignItems: 'center' }}>From A to Z</span>
                                    </div>
                                </div>


                            </Box>



                        </Grid>

                        <Grid item xs={12} md={8}>
                            <Grid spacing={3} container>
                                {this.products.map((item) => {
                                    return <Grid item xs={12} md={4}>
                                        <Card  >
                                            <CardContent>


                                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                    <div style={{ padding: '15px' }}><img src={ezBattery} width='100' height='100' /></div>


                                                    <div style={{ fontWeight: 'bold', paddingTop: '35px', paddingLeft: '10px' }}>EZ2345</div>
                                                    <div style={{ margin:'10px 0px' }}>Capacity 2Ah</div>
                                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                        <span>Rs.8999</span>
                                                        <s>Rs.15999</s>
                                                    </div>
                                                    <div style={{ display: 'flex', marginTop: '10px' }}>
                                                        <div style={{ border: '1px solid black', display: 'flex', marginRight: '10px', borderRadius: '25px', paddingLeft: '5px', paddingRight: '5px' }}>
                                                            <span>

                                                                <StarIcon fontSize='small' />
                                                            </span>


                                                            <span style={{ display: 'flex', alignItems: 'center' }}>4.0</span>

                                                        </div>
                                                        <span
                                                            style={{
                                                                borderRadius: '25px', paddingLeft: '10px', color: 'white',
                                                                display: 'flex', alignItems: 'center',
                                                                paddingRight: '10px', backgroundColor: '#66bc54'
                                                            }}>25%</span>
                                                    </div>



                                                </div>


                                            </CardContent>

                                        </Card>
                                    </Grid>
                                })}
                            </Grid>







                        </Grid>



                    </Grid>
                    <VisionStatementWeb classes={undefined} id={''} navigation={this.props.navigation} openSearch={undefined}/>

                </Container>

                <div>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={this.state.toggleCancelOrder}
                        onClose={() => this.handleCloseModal()}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={this.state.toggleCancelOrder}>
                            <Box sx={style}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ fontWeight: 'bold', fontSize: '20px' }}>Cancel Order?</div>
                                    <span><CloseIcon /></span>
                                </div>

                                <div style={{ marginTop: '20px', color: '#9da4b3' }}>
                                    Do you wish to cancel your order?Note: Refunds may take 5-7 business days to reflect on your account
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                                    <Button variant='outlined' onClick={() => this.handleCloseModal()} style={{ padding: '15px 45px' }}>No</Button>
                                    <Button variant='contained' style={{ padding: '15px 45px', backgroundColor: '#dd403a', marginLeft: '10px', color: 'white' }} >Yes</Button>
                                </div>








                            </Box>
                        </Fade>
                    </Modal>
                </div>

            </>
        )
    }
}
// Customizable Area End