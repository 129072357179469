import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import React from "react";
// Customizable Area End

export const configJSON = require("./config");
const {baseURL:apiBaseUrl}=require('./../../../framework/src/config')

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  product: boolean;
  productPage1Data: any;
  productpageImgData: any;
  openLanguageMenu: any;
  isLoading:boolean;
  openShare:any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProductDescriptionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  scrollRef1: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.scrollRef1 = React.createRef();
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      product: false,
      productPage1Data: "",
      productpageImgData: [],
      openLanguageMenu: null,
      isLoading:false,
      openShare:null
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getProductionShowItemsCall();
  }
  // Customizable Area Start


  getProductionShowItemsCall = () => {
    let urlArr=this.props?.navigation?.getParam().split('/');
    console.log(urlArr)
    let id=urlArr[urlArr.length-1]
    this.setState({isLoading:true})
    let myHeaders = new Headers();
    if(localStorage.getItem("loginSuccessToken")){
      let TOKEN: any = localStorage.getItem("loginSuccessToken")??''

      myHeaders.append("token", TOKEN);
      fetch(`${apiBaseUrl}/bx_block_fedex_integration/show_for_user?item_id=${id}`, {
        method: 'GET',
        headers:myHeaders
      })
        .then(response => response.json())
        .then(result => {
          console.log(result)
          this.setState({ productPage1Data: result?.data, productpageImgData: result?.data?.attributes?.image,isLoading:false })
        })
        .catch(error => {console.log('error', error);this.setState({isLoading:false})});
    }
 else{
   fetch(`${apiBaseUrl}/bx_block_fedex_integration/items/${id}`, {
     method: 'GET',
     headers:myHeaders
   })
     .then(response => response.json())
     .then(result => {
       console.log(result)
       this.setState({ productPage1Data: result?.data, productpageImgData: result?.data?.attributes?.image,isLoading:false })
     })
     .catch(error => {console.log('error', error);this.setState({isLoading:false})});
   }
 }

 
    

  createWishList=()=>{
    this.setState({isLoading:true})
    let TOKEN: any = localStorage.getItem("loginSuccessToken");
    let item_id:any=localStorage.getItem('item_id')
  
    let myHeaders = new Headers();
   
    myHeaders.append("token", TOKEN);
  

    let formdata = new FormData();
    formdata.append("item_id", item_id);
  
  
  
    fetch(`${apiBaseUrl}/bx_block_fedex_integration/wishlist`, {
      method: 'POST',
      headers: myHeaders,
      body:formdata
    })
      .then(response => response.json())
      .then(result => {
        console.log( result);
        this.getProductionShowItemsCall();
       
       
       
       
      })
      .catch(error =>{ console.log('error', error); this.setState({isLoading:false})});
  
  }
  deleteWishlist=()=>{
    this.setState({isLoading:true})
    let TOKEN: any = localStorage.getItem("loginSuccessToken");
    let item_id:any=localStorage.getItem('item_id')
  
    let myHeaders = new Headers();
   
    myHeaders.append("token", TOKEN);
  


    let formdata = new FormData();
    formdata.append("item_id", item_id);
  
  
  
    fetch(`${apiBaseUrl}/bx_block_fedex_integration/wishlist/${item_id}`, {
      method: 'DELETE',
      headers: myHeaders,
      body:formdata
    })
      .then(response => response.json())
      .then(result => {
        console.log( result);
      this.getProductionShowItemsCall()
      
       
      })
      .catch(error =>{ console.log('error', error); this.setState({isLoading:false})});
    
  }
  AddToCartCall = () => {
    let myHeaders = new Headers();
    let TOKEN: any = localStorage.getItem("loginSuccessToken")
    myHeaders.append("token", TOKEN);
    let ProductionId = localStorage.getItem("applyShowBatteriesId")


    


    fetch(`${apiBaseUrl}/bx_block_fedex_integration/cart?item_id=${ProductionId}`, {
      method: 'POST',
      headers: myHeaders,
    })
      .then(response => response.json())
      .then(result => {
        console.log(result)
        alert(result?.message)
      })
      .catch(error => console.log('error', error));
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  // Customizable Area End
}
