import React, { Component } from "react";
// Customizable Area Start
import FilteritemsController from "./FilteritemsController";
import {
  Radio,
  Typography,
  Grid,
  Avatar,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Stepper,
  Step,
  StepLabel,
  Button,withStyles
} from "@material-ui/core";


const CssRadio = withStyles({
  colorSecondary: {
      color: '#757575',
      '&$checked': {
          color: '#43CBA0',
        },
  },
  checked: {}
})(Radio)
export default class VehicleBatteryType extends FilteritemsController {
  render() {
    return (
      <>
        {/* <Grid container style={{ marginLeft: "110px" }}>
          <Avatar
          onClick={(e)=>{
            this.setState({iMenu:e.currentTarget})
          }}
            style={{
              cursor:'pointer',
              width: "25px",
              height: "25px",
              fontSize: "15px",
              margin: "17px 10px",
              backgroundColor: "#E0E0E0",
              color:'#B0B2B6'
            }}
          >
            i
          </Avatar>
        </Grid>
        <Grid
          container
          style={{
            display: "flex",
            marginLeft: "118px",
            marginTop: "-15px",
            marginRight: "118px",
          }}
        >
          <h2>Select Battery type</h2>

          <div
            style={{
              display: "flex",
              marginLeft: "245px",
              marginTop: "14px",
            }}
          >
            <img
              src={downloadImg}
              style={{
                width: "20px",
                height: "20px",
                color: "red",
                marginTop: "14px",
              }}
            />
            <p style={{ color: "red", margin: "16px" }}>
              Download Application Chart
            </p>
          </div>
        </Grid> */}
        <Grid item style={{ marginTop: "30px" }}>
          <div
            style={{
              padding: "7px",
              border: "1px solid lightgrey",
              marginLeft: "118px",
              marginRight: "118px",
            }}
          >
            <Grid container style={{ display: "flex" }}>
              <Grid item style={{ marginLeft: "-16px" }}>
                <Typography variant="subtitle1" style={{ marginLeft: "32px" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <RadioGroup
                   
                      aria-labelledby="demo-radio-buttons-group-label1"
                      name="radio-buttons-group1"
                    >
                      <FormControlLabel
                        checked={
                          this.state.selectedValue == "Vehicular Batteries"
                        }
                        value="Vehicular Batteries"
                        onChange={(e: any) => {
                          // this.setState({ isVehicular: !this.state.isVehicular })
                          this.props.handleVeh();
                          this.props.handleCheckedOption()
                          
                          this.setState({ selectedValue: e.target.value });
                          this.setState({ checkNextVehicle: true });
                          this.setState({ checkNextInverter: false });
                          localStorage.setItem("batteryType", e.target.value);
                        }}
                        control={<CssRadio/>}
                        label="Vehicular Batteries"
                      />
                    </RadioGroup>
                  </div>
                </Typography>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item style={{ marginTop: "30px" }}>
          <div
            style={{
              padding: "7px",
              border: "1px solid lightgrey",
              marginLeft: "118px",
              marginRight: "118px",
            }}
          >
            <Grid container style={{ display: "flex" }}>
              <Grid item style={{ marginLeft: "-16px" }}>
                <Typography variant="subtitle1" style={{ marginLeft: "32px" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label2"
                      name="radio-buttons-group2"
                    >
                      <FormControlLabel
                        checked={
                          this.state.selectedValue == "Inverter Batteries"
                        }
                        onChange={(e: any) => {
                          this.props.handleInv();
                          this.props.handleCheckedOption()
                         
                          this.setState({ selectedValue: e.target.value });
                          this.setState({ checkNextInverter: true });
                          this.setState({ checkNextVehicle: false });
                          localStorage.setItem("batteryType", e.target.value);
                        }}
                        value="Inverter Batteries"
                        control={<CssRadio />}
                        label="Inverter Batteries"
                      />
                    </RadioGroup>
                  </div>
                </Typography>
              </Grid>
            </Grid>
          </div>
        </Grid>

       
      </>
    );
  }
}
// Customizable Area End
