import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
const {baseURL:apiBaseUrl}=require('./../../../framework/src/config');
import React from "react";
import { profile } from "console";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  toggleModal:boolean;
  profileModal:boolean;
  toggleLanguage:boolean;
  anchorEl:any;
  toggleAdd:boolean;
  toggleWarrantyRegistration:boolean
  cityDropDown:any;
  allState:any;
  allDistrict:any;
  technicianData:any;
  allTechnicians:any;
  isLoading:boolean;
  singleTechn:any;
  error:boolean;
  techDetailErrors:any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class MerchantDashboard2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  callCityApi:any
  callStateApi:any;
  callCountryApi:any;
  imgRef:any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.imgRef=React.createRef()
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      toggleModal:false,
      profileModal:false,
      toggleLanguage:false,
      anchorEl:null,toggleAdd:false,
      toggleWarrantyRegistration:false,
      cityDropDown:[],
      allState:[],
      allDistrict:[],
      technicianData:{
        profile:'',
        profilePic:'',fullName:'',
        mobileNumber:'',address:'',
        country:'',stateId:'',
        cityId:'',
        city:'',
        state:'',district:'',districtId:'',
        zip:"",adharNo:'',panNo:'',licenseNo:'',toolkitNo:''
      },
      techDetailErrors:{
        profileErr:false,
        profilePicErr:false,fullNameErr:false,
        mobileNumberErr:false,addressErr:false,
        countryErr:false,stateIdErr:false,
        cityIdErr:false,
        cityErr:false,
        stateErr:false,districtErr:false,districtIdErr:false,
        zipErr:false,adharNoErr:false,panNoErr:false,licenseNoErr:false,toolkitNoErr:false
      },
      allTechnicians:[],
      isLoading:false,
      singleTechn:{},
      error:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    console.log(message.id);
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      )

    
        if (apiRequestCallId === this.callCityApi) {
          console.log(responseJson);
          this.setState({cityDropDown:responseJson?.data})
        }
        // ------------------------ wishlist
        else if (apiRequestCallId === this.callStateApi) {
          
        }
        else if (apiRequestCallId === this.callCountryApi) {
         
        }
     
    }

    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    // this.getCity()
   this.getAllCity();
this.getALLState();
this.getAllDistrict();
this.getAllTechnicians();
this.setSingleTech()

    // Customizable Area End
  }
getCity=async()=>{
  this.callCityApi = await this.apiCall({
    contentType: 'application/json',
    method: 'GET',
    endPoint: `${apiBaseUrl}/bx_block_address/city?id=1`,
  })
}

  apiCall = async (data: any) => {
  

    const { contentType, method, endPoint, body, type } = data
    console.log('Api data >> ', JSON.stringify(data))
     const header = { 
      "Content-Type": contentType,
     // token   
     };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    )
    body && type != 'formData'
      ? requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      : requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      )
    runEngine.sendMessage(requestMessage.id, requestMessage)

    return requestMessage.messageId
  }
  getAllCity = () => {

    let Id = localStorage.getItem("stateId")


    fetch(`${apiBaseUrl}/bx_block_address/city?id=1`, {
      method: 'GET',
    })
      .then(response => response.json())
      .then(result => {
        this.setState({ cityDropDown: result?.data })
      })
      .catch(error => console.log('error', error));
  }
  getALLState = () => {

    fetch(apiBaseUrl+"/bx_block_address/states", {
      method: 'GET',
    })
      .then(response => response.json())
      .then(result => {
        console.log("###", result)
        this.setState({ allState: result?.data })
        localStorage.setItem("stateId", result?.data.id)
      })
      .catch(error => console.log('error', error));
  }
  getAllDistrict = () => {

    fetch(apiBaseUrl+"/bx_block_address/district?id=1", {
      method: 'GET',
    })
      .then(response => response.json())
      .then(result => {

        this.setState({ allDistrict: result?.data })
      })
      .catch(error => console.log('error', error));
  }
  getAllTechnicians=()=>{
    // bx_block_profile_bio/technicians
    let TOKEN: any = localStorage.getItem("loginSuccessToken");
    
  let myHeaders=new Headers()
    
    myHeaders.append("token", TOKEN);
    
    myHeaders.append("Content-Type", "application/json");
  
  
  
    fetch(`${apiBaseUrl}/bx_block_profile_bio/technicians`, {
      method: 'GET',
      headers: myHeaders,
    })
      .then(response => response.json())
      .then(result => {
        console.log( result)
        this.setState({ allTechnicians:result?.data,isLoading:false })
      })
      .catch(error => {console.log('error', error);});
    }

    createTechnician=(e:any)=>{
      e.preventDefault()
      // bx_block_profile_bio/technicians
      const {profile,profilePic,fullName,mobileNumber,address,country,city,cityId,state,stateId,district,districtId,zip,adharNo,panNo,licenseNo,toolkitNo}=this.state.technicianData
      let TOKEN: any = localStorage.getItem("loginSuccessToken");
      console.log(profile)
      console.log(profilePic)
    // let myHeaders=new Headers()
      
    //   myHeaders.append("token", TOKEN);
      
      // myHeaders.append("Content-Type", "application/json");
      let formdata = new FormData();
      formdata.append('data[image]',profile);
      formdata.append('data[full_name]',fullName);
      formdata.append('data[mobile_no]',mobileNumber);
      formdata.append('data[aadhar_no]',adharNo);
      formdata.append('data[pan_no]',panNo);
      formdata.append('data[licence_no]',licenseNo);
      formdata.append('data[toolkit_no]',toolkitNo);
      formdata.append('data[delivery_address_attributes][name]',address);
      formdata.append('data[delivery_address_attributes][country]',country);
      formdata.append('data[delivery_address_attributes][state_id]',stateId);
      formdata.append('data[delivery_address_attributes][city_id]',cityId);
      formdata.append('data[delivery_address_attributes][district_id]',districtId);
      formdata.append('data[delivery_address_attributes][zip_code]',zip);
      formdata.append('token',TOKEN)

    
    
      fetch(`${apiBaseUrl}/bx_block_profile_bio/technicians`, {
        method: 'POST',
        // headers: myHeaders,
        body:formdata
      })
        .then(response => response.ok?response.json():Promise.reject(response))
        .then(result => {
          console.log( result)
          this.getAllTechnicians()
        })
        .catch(error => {console.log('error', error);});
      }
     deleteTechnician=()=>{
       
       let techId=localStorage.getItem('techId')
        let TOKEN: any = localStorage.getItem("loginSuccessToken");
        let myHeaders=new Headers();


        myHeaders.append("token", TOKEN);
        fetch(`${apiBaseUrl}/bx_block_profile_bio/technicians/${techId}`, {
          method: 'DELETE',
          headers: myHeaders,
        
        })
          .then(response => response.json())
          .then(result => {
            console.log( result)
            this.getAllTechnicians()
          })
          .catch(error => {console.log('error', error);});
      }
      setSingleTech=()=>{
        if(localStorage.getItem('singleTech')){
         

          let singleTechnician=JSON.parse(localStorage.getItem('singleTech')||'');
          
          const {image}=singleTechnician?.attributes
          const {id:addr_id,name,zip_code,country,address_type,address_for,is_default,state_id,district_id,city_id}=singleTechnician?.attributes?.address
          const {id,full_name,mobile_no,aadhar_no,pan_no,licence_no,toolkit_no}=singleTechnician?.attributes?.details;
        

        
         let obj={
             id,
             addr_id:addr_id,
            profile:image,
            profilePic:image,fullName:full_name,
            mobileNumber:mobile_no,address:name,
            country:country,stateId:state_id,
            cityId:city_id,
            city:city_id,
            state:'',district:'',districtId:district_id,
            zip:zip_code,adharNo:aadhar_no,panNo:pan_no,licenseNo:licence_no,toolkitNo:toolkit_no
          }
          this.setState({singleTechn:obj});
          this.setState({technicianData:singleTechnician})
        }

      }
      editTechnician=(e:any)=>{
        e.preventDefault()
     
      const {id,addr_id,
        profile,profilePic,
        fullName,mobileNumber,
        address,country,city,cityId,state,stateId,district,districtId,zip,
        adharNo,panNo,licenseNo,toolkitNo}=this.state.singleTechn
      let TOKEN: any = localStorage.getItem("loginSuccessToken");
      
   
      let formdata = new FormData();
      formdata.append('id',id);
      if(typeof profile!='string' && profile!=null){

        formdata.append('data[image]',profile);
      }
      formdata.append('data[full_name]',fullName);
      formdata.append('data[mobile_no]',mobileNumber);
      formdata.append('data[aadhar_no]',adharNo);
      formdata.append('data[pan_no]',panNo);
      formdata.append('data[licence_no]',licenseNo);
      formdata.append('data[toolkit_no]',toolkitNo);
      formdata.append('data[delivery_address_attributes][name]',address);
      formdata.append('data[delivery_address_attributes][country]',country);
      formdata.append('data[delivery_address_attributes][state_id]',stateId);
      formdata.append('data[delivery_address_attributes][city_id]',cityId);
      formdata.append('data[delivery_address_attributes][district_id]',districtId);
      formdata.append('data[delivery_address_attributes][zip_code]',zip);
      formdata.append('data[delivery_address_attributes][id]',addr_id);
      formdata.append('token',TOKEN)

    
    
      fetch(`${apiBaseUrl}/bx_block_profile_bio/technician_update`, {
        method: 'PUT',
        // headers: myHeaders,
        body:formdata
      })
        .then(response => response.ok?response.json():Promise.reject(response))
        .then(result => {
          console.log( result)
          this.getAllTechnicians()
        })
        .catch(error => {console.log('error', error);});
      }
  // Customizable Area End
}
