import React from "react";
// Customizable Area Start

import { Container,
    Box,Radio,
    Button,
    Typography, Grid, Avatar, TextField, MenuItem, InputLabel, Select, CircularProgress,IconButton,Card, CardContent,InputAdornment,OutlinedInput,FormControl,Switch } from "@material-ui/core";

import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';

import { AccountBalance, ArrowBackSharp,  PersonOutlineOutlined } from "@material-ui/icons";

import { createTheme} from "@material-ui/core/styles";
import CreditCardIcon from '@material-ui/icons/CreditCard';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import "./Shoppingcart.css";


const greenTick = require("../assets/greentick.png")
const yellowStar = require("../assets/yellow_star.png")




const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});

const Styles = {
    inputSearchBox: {
        width: "25px",
        height: "35px",
        alignItems: "center",
        border: "1px solid #b30000",
        borderRadiusRight: "5px",
        backgroundColor: "#b30000",
        paddingRight: "10px",
        color: "white",
        cursor: "pointer"
    },
    cardhead: {
        font: "10px",
        color: "grayText",
        textDecoration: "underline",
        textDecorationColor: "gray",
        textDecorationThickness: "1px",
    }
}
// Customizable Area End

import ShoppingCartOrdersController, {
    Props,
} from "./ShoppingCartOrdersController";
import { threadId } from "worker_threads";

export default class AddShoppingCartOrderItem extends ShoppingCartOrdersController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    finalPrice=(item:any)=>{
        return item?.attributes?.amount-item?.attributes?.discount_amount
          }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { address } = this.state
        if (!localStorage.getItem("loginSuccessToken")) {
            this.props.navigation.navigate('signUp');
            return null

        }

        return (
            <>

                <div>

                    <Box style={{ background: '#F4F5F6', position: "unset" }}>
                        <div style={{ padding: '20px', backgroundColor: '#efefef' }}>
                            <Container>

                                <div  style={{ display: 'flex', justifyContent: 'space-between' }}>

                                    <div id="home" style={{ color: '#a1a0a4' }}>
                                        <a href='/' style={{ textDecoration: 'none', color: '#a1a0a4' }}>Home</a> <ArrowForwardIosRoundedIcon className="arrow-left" /> Cart
                                    </div>
                                    
                                </div>
                            </Container>
                        </div>

                       

                        <div style={{ marginTop: this.state.openSearch ? "130px" : "50px" }}>
                            <Box sx={style}>
                                <Box >
                                    <Box style={{ width: "550px", margin: "0 auto", borderBottom: "1px dashed #000" }}></Box>
                                    <Grid
                                        container
                                        spacing={2}
                                        justifyContent="space-around"
                                        alignItems="center"
                                        style={{ marginTop: "-30px" }}
                                    >
                                        {carts.map((name, i) => {
                                            return (
                                                <Grid key={i} item xs={2}>
                                                    <Box
                                                        className={
                                                            this.state.activecart > i ? "cart-box-active" : "cart-box"
                                                        }
                                                        style={{
                                                            background: "#EBEEF1",
                                                            borderRadius: 4,
                                                            width: "120px",
                                                            height: 30,
                                                            padding: 6,
                                                            border: this.state.activecart > i ? ("1px solid #32CBA0") : ("none"),
                                                            display: 'flex',
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        <Grid
                                                            container

                                                            style={{
                                                                flexWrap: "nowrap",
                                                                display: 'flex',
                                                                justifyContent: 'center'
                                                            }}
                                                        >
                                                            <Grid item style={{ marginRight: '5px' }} >
                                                                {this.state.activecart > i ? (
                                                                    <CheckCircleRoundedIcon style={{ color: "#32CBA0" }} />
                                                                ) : (
                                                                    <Avatar
                                                                        style={{
                                                                            width: "20px",
                                                                            height: "20px",
                                                                            fontSize: "10px",
                                                                            position: "unset"
                                                                        }}
                                                                    >
                                                                        {i + 1}
                                                                    </Avatar>
                                                                )}
                                                            </Grid>

                                                            <Grid item>
                                                                <Typography className="cart-text"> {name}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </Box>
                            </Box>

                            {this.state.activecart === carts.length - 1
                                ? (
                                    <Box textAlign="center" mt={5}>
                                        <CheckCircleOutlinedIcon style={{ color: "green", width: "50px", height: "50px" }} />
                                        <Typography variant="h5" style={{ fontSize: 20, fontWeight: "bold" }}> Order placed Successfully</Typography>
                                        <Typography variant="subtitle2">Order number : 8754623</Typography>
                                    </Box>
                                )
                                : null}
                            <Container maxWidth="lg">
                                <Grid container spacing={6} style={{ margin: "40px auto", }}  >
                                    <Grid item xs={8}>
                                        <Box >

                                            {this.state.activecart === 0 ? (
                                                <Box style={{ marginTop: "-25px" }}>
                                                    <Box display="flex" flexDirection="row" justifyContent="space-between">
                                                        <Typography variant="h5" className="cart">Cart</Typography>
                                                        <Typography variant="subtitle1" className="clear">clear</Typography>
                                                    </Box>
                                                    <Box style={{ background: "#fff" }} >
                                                        <Box display="flex" flexDirection="row" justifyContent="space-between" padding={3}>
                                                            <Typography variant="h6" style={{ fontSize: 14,fontWeight:'bold' }}>Items</Typography>
                                                            <Typography variant="subtitle1" style={{ fontSize: 14,fontWeight:'bold' }}>Price</Typography>
                                                        </Box >
                                                        <Box>
                                                            {
                                                                this.state.catItemsData?.map((items: any, index: any) => {

                                                                    return (
                                                                        <Box id="cartItems" key={index} display="flex" justifyContent="space-between" alignItems="center" style={{ borderBottom: "1px solid lightgrey", padding: "20px" }}>
                                                                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                                                                <img src={items?.attributes?.thumbnail_image} alt="bat-img" style={{ width: "100px", height: "100px", padding: 0 }} />
                                                                                <div style={{fontWeight:'bold'}}> {items?.attributes?.model_no}</div>
                                                                            </Box>
                                                                            <Box display="flex" key={index} alignItems="center" justifyContent="space-betweeen">
                                                                                <div id="reduce"
                                                                                    style={{ width: "25px", height: "24px", border: "1px solid #000" }}
                                                                                    onClick={() => {
                                                                                        let x = this.state.catItemsData
                                                                                       
                                                                                        this.handleReduce(items.id)

                                                                                    }}
                                                                                >
                                                                                    <p style={{ fontSize: "30px", margin: "-6px 8px", cursor: "pointer" }}>-</p>
                                                                                </div>

                                                                                <div> &nbsp;&nbsp;{items?.isCartLoading ? <div style={{ padding: '0px 8px' }}><CircularProgress size={15} /></div> : items?.attributes?.cart_item_count} &nbsp;&nbsp;</div>
                                                                                <div id="add"
                                                                                    style={{ width: "25px", height: "24px", border: "1px solid #000" }}
                                                                                    onClick={() => {
                                                                                       
                                                                                        this.handleAdd(items.id)
                                                                                    }}
                                                                                >
                                                                                    <p style={{ fontSize: "20px", margin: "2px 7px", cursor: "pointer" }}>+</p>
                                                                                </div>

                                                                            </Box>
                                                                          <div style={{fontWeight:'bold'}}>

                                                                            &#x20b9;{this.finalPrice(items).toLocaleString()}
                                                                          </div>


                                                                            



                                                                        </Box>

                                                                    )
                                                                })

                                                            }

                                                        </Box>

                                                    </Box>
                                                </Box>
                                            ) : (
                                                <Box>
                                                    {this.state.activecart === 1 ? (
                                                        <>
                                                            {
                                                                address === false ? (
                                                                    <Box>
                                                                        <Typography variant="h3" style={{ fontSize: 20, fontWeight: "bolder" }}>
                                                                            Choose Delivery Address
                                                                        </Typography>
                                                                        <Box style={{ background: "#fff", borderRadius: "5px", padding: "40px", marginTop: "10px" }}>

                                                                            <Box >
                                                                                <Grid container spacing={4} style={{ gridGap: "20px" }}>
                                                                                    {
                                                                                        DeliveryAddress.map((items, index) => {
                                                                                            return (
                                                                                                <Box key={index} display="flex" alignItems="center" p={2} style={{ border: this.state.change === index ? ("1px solid green") : ("1px solid lightgrey"), borderRadius: "5px", width: "100%" }}>

                                                                                                    <Radio
                                                                                                        checked={this.state.change === index}
                                                                                                        onChange={this.handleChange}
                                                                                                        value={index}
                                                                                                        color="default"
                                                                                                        name="radio-button-demo"

                                                                                                    />
                                                                                                    <Grid item style={{ paddingLeft: "20px" }}>

                                                                                                        <Typography variant="subtitle1">{items.name}</Typography>
                                                                                                        <Typography variant="body2">{items.address}</Typography>

                                                                                                    </Grid>
                                                                                                </Box>


                                                                                            )
                                                                                        })
                                                                                    }


                                                                                </Grid>
                                                                            </Box>

                                                                            <Box mt={4}>
                                                                                <Button
                                                                                    style={{ color: "red" }}
                                                                                    onClick={() => this.setState({ address: true })}
                                                                                >
                                                                                    + Add
                                                                                </Button>

                                                                            </Box>

                                                                        </Box>

                                                                    </Box>

                                                                ) : (
                                                                    <Box>
                                                                        <Typography>
                                                                            <Button
                                                                                onClick={() => this.setState({ address: false })}><ArrowBackSharp />
                                                                            </Button>
                                                                            &nbsp; &nbsp; &nbsp; Add Delivery Address
                                                                        </Typography>
                                                                        <Box bgcolor="#ffff" p={2}>
                                                                            <Box >
                                                                                <FormControl variant="outlined" style={{ width: "100%" }}>
                                                                                    <OutlinedInput

                                                                                        id="outlined-adornment-weight"


                                                                                        startAdornment={<InputAdornment position="start">
                                                                                            <PersonOutlineOutlined style={{ width: 30, height: 25 }} />
                                                                                        </InputAdornment>}
                                                                                        placeholder="Full Name"
                                                                                        aria-describedby="outlined-weight-helper-text"
                                                                                        inputProps={{
                                                                                            'aria-label': 'weight',
                                                                                        }}
                                                                                        labelWidth={0}
                                                                                    />

                                                                                </FormControl>
                                                                            </Box>
                                                                            <Box mt={3}>
                                                                                <Typography variant="h5" style={{ fontSize: 16 }}>Address</Typography>
                                                                                <Box mt={2} display="flex" flexDirection="column" className="addres-crd">

                                                                                    <FormControl variant="outlined">
                                                                                        <OutlinedInput
                                                                                            id="outlined-adornment-weight"

                                                                                            placeholder="Your Address"

                                                                                            aria-describedby="outlined-weight-helper-text"

                                                                                            labelWidth={0}
                                                                                        />

                                                                                    </FormControl>

                                                                                    <FormControl variant="outlined">
                                                                                        <InputLabel id="demo-simple-select-outlined-label">Country</InputLabel>
                                                                                        <Select
                                                                                            labelId="demo-simple-select-outlined-label"
                                                                                            id="demo-simple-select-outlined"
                                                                                            // value={age}
                                                                                            // onChange={handleChange}
                                                                                            label="Country"
                                                                                        >
                                                                                            <MenuItem value="">
                                                                                                <em>None</em>
                                                                                            </MenuItem>
                                                                                            <MenuItem value="India">India</MenuItem>
                                                                                            <MenuItem value="Canada">Canada</MenuItem>
                                                                                            <MenuItem value="Singapore">Singapore</MenuItem>
                                                                                        </Select>
                                                                                    </FormControl>

                                                                                    <Box className="addres-crd">
                                                                                        <Grid container spacing={3}>
                                                                                            <Grid item xs={6}>
                                                                                                <FormControl variant="outlined" style={{ width: "100%" }}>
                                                                                                    <InputLabel id="demo-simple-select-outlined-label">State</InputLabel>
                                                                                                    <Select
                                                                                                        labelId="demo-simple-select-outlined-label"
                                                                                                        id="demo-simple-select-outlined"
                                                                                                        // value={age}
                                                                                                        // onChange={handleChange}
                                                                                                        label="Country"
                                                                                                    >
                                                                                                        <MenuItem value="">
                                                                                                            <em>None</em>
                                                                                                        </MenuItem>
                                                                                                        <MenuItem value="TamilNadu">Tamil Nadu</MenuItem>
                                                                                                        <MenuItem value="Kerala">Kerala</MenuItem>
                                                                                                        <MenuItem value="Hydrabad">Hydrabad</MenuItem>
                                                                                                    </Select>
                                                                                                </FormControl>
                                                                                            </Grid>
                                                                                            <Grid item xs={6}>
                                                                                                <FormControl variant="outlined" style={{ width: "100%" }}>
                                                                                                    <InputLabel id="demo-simple-select-outlined-label">City</InputLabel>
                                                                                                    <Select
                                                                                                        labelId="demo-simple-select-outlined-label"
                                                                                                        id="demo-simple-select-outlined"
                                                                                                        // value={age}
                                                                                                        // onChange={handleChange}
                                                                                                        label="Country"
                                                                                                    >
                                                                                                        <MenuItem value="">
                                                                                                            <em>None</em>
                                                                                                        </MenuItem>
                                                                                                        <MenuItem value="Kanchipuram">Kanchipuram</MenuItem>
                                                                                                        <MenuItem value="Chennai">Chennai</MenuItem>
                                                                                                        <MenuItem value="Vellore">Vellore</MenuItem>
                                                                                                    </Select>
                                                                                                </FormControl>
                                                                                            </Grid>
                                                                                            <Grid item xs={6}>
                                                                                                <FormControl variant="outlined" style={{ width: "100%" }}>
                                                                                                    <InputLabel id="demo-simple-select-outlined-label">District</InputLabel>
                                                                                                    <Select
                                                                                                        labelId="demo-simple-select-outlined-label"
                                                                                                        id="demo-simple-select-outlined"
                                                                                                        // value={age}
                                                                                                        // onChange={handleChange}
                                                                                                        label="Country"
                                                                                                    >
                                                                                                        <MenuItem value="">
                                                                                                            <em>None</em>
                                                                                                        </MenuItem>
                                                                                                        <MenuItem value="Kanchipuram">Kanchipuram</MenuItem>
                                                                                                        <MenuItem value="Chennai">Chennai</MenuItem>
                                                                                                        <MenuItem value="Vellore">Vellore</MenuItem>
                                                                                                    </Select>
                                                                                                </FormControl>
                                                                                            </Grid>
                                                                                            <Grid item xs={6}>
                                                                                                <FormControl variant="outlined" style={{ width: "100%" }}>
                                                                                                    <OutlinedInput

                                                                                                        id="outlined-adornment-weight"
                                                                                                        // value={}
                                                                                                        // onChange={}

                                                                                                        placeholder="Pin"
                                                                                                        aria-describedby="outlined-weight-helper-text"
                                                                                                        inputProps={{
                                                                                                            'aria-label': 'pincode',
                                                                                                        }}
                                                                                                        labelWidth={0}
                                                                                                    />

                                                                                                </FormControl>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Box>


                                                                                </Box>
                                                                            </Box>
                                                                            <Box mt={2} textAlign="end">
                                                                                <Button

                                                                                    style={{
                                                                                        background: "#5BCBA0",
                                                                                        width: "200px",
                                                                                        height: "50px",
                                                                                        padding: "20px",
                                                                                        fontSize: "18px",
                                                                                        fontWeight: "bolder",
                                                                                        color: "#000",

                                                                                    }}
                                                                                >
                                                                                    Save
                                                                                </Button>
                                                                            </Box>
                                                                        </Box>
                                                                    </Box>
                                                                )
                                                            }
                                                        </>
                                                    ) : (
                                                        <Box>
                                                            {this.state.activecart === 2 ? (
                                                                <Box>
                                                                    <Typography variant="h4" style={{ fontSize: 20 }}>Pay </Typography>
                                                                    <Box bgcolor="#fff" mt={1}>
                                                                        <Box p={2}>
                                                                            <Typography variant="body2" style={{ fontWeight: "bolder" }}>Credit/Debit card</Typography>
                                                                            <div style={{ display: "grid", justifyItems: "center", alignItems: "center" }}>
                                                                                <img style={{ width: "200px", height: "200px" }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQd-m3SpH4kaUUCjKMlnxqGYCaEzl4KjiMfp5hEfbKmZEoERQiz4ucw4nZ154rOANu_9OE&usqp=CAU" alt="add_cards" />
                                                                                <Typography variant="h6" style={{ color: "#9FA5B4" }}> No cards added yet</Typography>
                                                                            </div>
                                                                        </Box>
                                                                        <Box mt={5}>
                                                                            <Box display="flex" justifyContent="space-between" p={2}>
                                                                                <Typography variant="body1" style={{ fontSize: 16, display: "flex" }}>
                                                                                    <CreditCardIcon style={{ width: "40px", height: "25px" }} /> &nbsp;&nbsp; Add a card
                                                                                </Typography>
                                                                                <NavigateNextIcon />
                                                                            </Box>
                                                                        </Box>
                                                                        <Box borderBottom="1px solid lightgray" borderTop="1px solid lightgray" p={4}>
                                                                            <Typography variant="body1" style={{ fontWeight: "bold" }}>UPI</Typography>

                                                                            {
                                                                                paymentOption.map((pay, index) => {
                                                                                    return (
                                                                                        <Box key={index} display="flex" justifyContent="space-between" pb={2} pt={2} borderBottom="1px solid lightgray">
                                                                                            <Typography variant="body1" style={{ fontSize: 16, display: "flex", alignItems: "center" }}>
                                                                                                <img className="pay_img" src={pay.img} /> &nbsp;&nbsp; {pay.name}
                                                                                            </Typography>
                                                                                            <NavigateNextIcon />
                                                                                        </Box>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Box>
                                                                        <Box p={4}>
                                                                            <Typography variant="body1" style={{ fontWeight: "bold" }}>Internet Banking</Typography>
                                                                            <Box display="flex" justifyContent="space-between" mt={2}>
                                                                                <Typography variant="body1" style={{ fontSize: 16, display: "flex" }}>
                                                                                    <AccountBalance style={{ width: "40px", height: "25px", color: "green" }} />
                                                                                    &nbsp;&nbsp; Add a card
                                                                                </Typography>
                                                                                <NavigateNextIcon />
                                                                            </Box>
                                                                        </Box>


                                                                    </Box>
                                                                </Box>
                                                            ) : (
                                                                <Box>
                                                                    <Typography variant="h6" style={{ fontSize: 16, fontWeight: "bold" }}>Order Details</Typography>
                                                                    <Box bgcolor="#fff" mt={1}>
                                                                        <Box>
                                                                            {
                                                                                PaymentItems.map((items, index) => {
                                                                                    return (
                                                                                        <Box display="flex" justifyContent="space-between" alignItems="center" style={{ borderBottom: "1px solid lightgrey", padding: "20px" }}>
                                                                                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                                                                                <img src={items.img} alt="bat-img" style={{ width: "100px", height: "100px", padding: 0 }} />
                                                                                                <Typography variant="h6" style={{ fontSize: 16 }}>&nbsp;&nbsp;&nbsp;&nbsp;{items.name}</Typography>
                                                                                                <Typography variant="body2" style={{ fontSize: 12, color: "#b0b5c2" }}>&nbsp;&nbsp;&nbsp;&nbsp; x{items.number}</Typography>
                                                                                            </Box>

                                                                                            <Box>
                                                                                                <Box bgcolor="#E5F3FA" p={1} borderRadius={8}> <Typography style={{ color: "#30A4DE" }}><CheckBoxOutlineBlankIcon style={{ transform: "rotate(45deg)" }} /> &nbsp;  Estimated Delivery: <b>10 May 22</b></Typography> </Box>
                                                                                            </Box>
                                                                                        </Box>
                                                                                    )
                                                                                })
                                                                            }

                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                            )}
                                                        </Box>
                                                    )}
                                                </Box>
                                            )}
                                        </Box>

                                    </Grid>
                                    <Grid item xs={4}>

                                        {this.state.activecart === carts.length - 1 ?
                                            (
                                                <Box>
                                                    <Box mt={4} bgcolor="#fff">
                                                        <Box p={2}>
                                                            <Typography variant="h5" style={{ fontSize: 16, fontWeight: "bold" }}>Delivery address</Typography>

                                                            <Typography variant="subtitle1" style={{ fontSize: 16, color: "#a2a8b7", lineHeight: 2 }}>John Doe,022807566554</Typography>
                                                            <Typography variant="subtitle1" style={{ fontSize: 16, color: "#a2a8b7" }}>405,saffron, opp narhari Hospital, Vadadora,Gujarat</Typography>
                                                        </Box>
                                                        <Box borderTop="1px solid lightgrey" borderBottom="1px solid lightgrey" padding={2}>
                                                            <Typography variant="h5" style={{ fontSize: 16, fontWeight: "bold" }}>Price Summary</Typography>
                                                            <Box display="flex" justifyContent="space-between" mt={2}>
                                                                <Typography variant="h5" style={{ fontSize: 16 }}>Total</Typography>
                                                                <Typography variant="h5" style={{ fontSize: 16, fontWeight: "bold" }}>₹ 24,967</Typography>
                                                            </Box>
                                                        </Box>
                                                        <Box p={2}>
                                                            <Typography variant="h5" style={{ fontSize: 16, fontWeight: "bold" }}>Payment Method</Typography>
                                                            <Box mt={1}>
                                                                <img style={{ width: 40, height: 40, padding: 0 }} src={"https://images.news18.com/ibnlive/uploads/2020/02/UPI.jpg?impolicy=website&width=510&height=356"} alt="upi" />
                                                            </Box>
                                                        </Box>

                                                    </Box>
                                                    <Box mt={2}>
                                                        <Button
                                                            onClick={this.handleNext}
                                                            style={{
                                                                background: "#CDEDE4",
                                                                width: "100%",
                                                                height: "60px",
                                                                padding: "20px",
                                                                fontSize: "18px",
                                                                fontWeight: "bolder",
                                                                color: "#000",
                                                            }}
                                                        >
                                                            Continue Buying
                                                        </Button>
                                                    </Box>
                                                </Box>


                                            ) : (
                                                <Box
                                                    sx={{

                                                        p: 2,
                                                        bgcolor: 'background.paper'

                                                    }}
                                                >
                                                    <Box mb={4} display="flex" flexDirection="column">
                                                        <Typography variant="h5">Order Summary</Typography>
                                                        <Box mt={3} style={{ borderBottom: "3px solid grey", paddingBottom: "15px" }} >
                                                            <Grid container style={{ flexDirection: "column", gridGap: "20px" }}>
                                                                <Grid item>
                                                                    <Grid container justifyContent="space-between">
                                                                        <Grid item>
                                                                            <Typography variant="subtitle2">Subtotal</Typography>

                                                                        </Grid>
                                                                        <Grid item style={{ textAlign: "end" }}>

                                                                            <Typography variant="h4" style={{ fontSize: 18, fontWeight: "bolder" }}>
                                                                                ₹{this.state.totalAmount.toLocaleString()}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item>

                                                                    <Grid container justifyContent="space-between">
                                                                        <Grid item>
                                                                            <Typography variant="subtitle2">Shipping fee</Typography>

                                                                        </Grid>
                                                                        <Grid item style={{ textAlign: "end" }}>
                                                                            <Typography variant="h4" style={{ fontSize: 18, fontWeight: "bolder" }}>₹0</Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Grid container justifyContent="space-between">
                                                                        <Grid item>
                                                                            <Typography variant="subtitle2">Tax</Typography>

                                                                        </Grid>
                                                                        <Grid item style={{ textAlign: "end" }}>
                                                                            <Typography variant="h4" style={{ fontSize: 18, fontWeight: "bolder" }}>₹0</Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Grid container justifyContent="space-between">
                                                                        <Grid item>
                                                                            <Typography variant="subtitle2">Coupon</Typography>
                                                                        </Grid>
                                                                        <Grid item style={{ textAlign: "end" }}>
                                                                            <Typography variant="h4" style={{ fontSize: 18, fontWeight: "bolder" }}>₹0</Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                        <Box mt={3}>
                                                            <Grid container justifyContent="space-between">
                                                                <Grid item>
                                                                    <Typography variant="subtitle2" style={{ fontSize: 18 }}>Order Total</Typography>
                                                                </Grid>
                                                                <Grid item style={{ textAlign: "end" }}>
                                                                    <Typography variant="h4" style={{ fontSize: 25, fontWeight: "bold" }}>₹{this.state.totalAmount.toLocaleString()}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                        <Box mt={4}>
                                                            <FormControl variant="outlined" style={{ width: "100%" }}>
                                                                <OutlinedInput
                                                                    id="outlined-adornment-weight"
                                                                    placeholder="Enter a discount code "
                                                                    // value={values.weight}
                                                                    // onChange={handleChange('weight')}
                                                                    endAdornment={<InputAdornment position="end">
                                                                        Apply
                                                                    </InputAdornment>}
                                                                    aria-describedby="outlined-weight-helper-text"
                                                                    inputProps={{
                                                                        'aria-label': 'Code',
                                                                    }}
                                                                    labelWidth={0}
                                                                />
                                                            </FormControl>
                                                        </Box>
                                                        <Box mt={4} mb={4}>
                                                            <Grid container justifyContent="space-between">
                                                                <Grid item >
                                                                    <Typography
                                                                        // variant="subtile1"
                                                                        style={{ lineHeight: "30px" }}>Use Rewards Points</Typography>
                                                                    <Typography variant="caption" display="block" style={{ color: "lightgray" }}>Available points 121</Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Switch
                                                                        // checked={state.checkedB}
                                                                        // onChange={handleChange}
                                                                        color="primary"
                                                                        name="checkedB"
                                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                        <TextField
                                                            id="outlined-textarea"
                                                            label="GST Number"
                                                            placeholder="Placeholder"
                                                            multiline
                                                            variant="outlined"
                                                        />
                                                    </Box>

                                                    <Button
                                                        onClick={this.handleNext}
                                                        style={{
                                                            background: "#5BCBA0",
                                                            width: "100%",
                                                            height: "60px",
                                                            padding: "20px",
                                                            fontSize: "18px",
                                                            fontWeight: "bolder",
                                                            color: "#000",
                                                        }}
                                                    >
                                                        {this.state.activecart === carts.length - 1
                                                            ? null
                                                            : "Checkout"}
                                                    </Button>
                                                </Box>
                                            )}
                                    </Grid>
                                </Grid>
                            </Container>
                        </div>
                    </Box>
                </div>



            </>
        );
        // Customizable Area End
    }
}





const DeliveryAddress = [
    {
        name: "John doe",
        address: "405,saffron, opp narhari Hospital, Vadadora,Gujarat"
    },
    {
        name: "John doe",
        address: "405,saffron, opp narhari Hospital, Vadadora,Gujarat"
    },
    {
        name: "John doe",
        address: "405,saffron, opp narhari Hospital, Vadadora,Gujarat"
    },
    {
        name: "John doe",
        address: "405,saffron, opp narhari Hospital, Vadadora,Gujarat"
    },

]

const carts = [
    "Checkout",
    "Address",
    "Payment",
    "Confirmation",
];

const paymentOption = [
    {
        img: "https://static.vecteezy.com/system/resources/thumbnails/006/059/850/small/credit-card-credit-card-icon-white-background-debit-card-symbol-free-vector.jpg",
        name: "BHIM"
    },
    {
        img: "https://images.hindustantimes.com/tech/img/2020/11/05/1600x900/image_-_2020-11-05T095740.083_1604550459365_1604550465218_1604550598928.jpg",
        name: "Gpay"
    },
    {
        img: "https://www.pngitem.com/pimgs/m/3-38170_phonepe-logo-png-phone-pe-transparent-png.png",
        name: "Phonepe"
    },

    {
        img: "https://aniportalimages.s3.amazonaws.com/media/details/pauyau_PlnW2rW.jpg",
        name: "Paytm"
    },
    {
        img: "https://images.news18.com/ibnlive/uploads/2020/02/UPI.jpg?impolicy=website&width=510&height=356",
        name: "Pay with another UPI id"
    },
]

const PaymentItems = [
    {
        img: "https://4.imimg.com/data4/DX/BR/MY-25311479/exide-car-battery-250x250.jpg",
        name: "EZTZ4",
        number: 4

    },
    {
        img: "https://media.istockphoto.com/photos/car-battery-closeup-3d-rendering-isolated-on-white-background-picture-id899016436?k=20&m=899016436&s=612x612&w=0&h=xCvINEJxTswiEQ4JxjWIDA31fxpjrY8jz70LJzIl0bk=",
        name: "EZTZ4",
        number: 3

    },
    {
        img: "https://images-cdn.ubuy.co.in/4AP4010-everstart-maxx-lead-acid-automotive.jpg",
        name: "EZTZ4",
        number: 2
    },
]
const BatterItems = [
    {
        img: "https://4.imimg.com/data4/DX/BR/MY-25311479/exide-car-battery-250x250.jpg",
        name: "EZTZ4",
        price: "₹9,999",
    },
    {
        img: "https://media.istockphoto.com/photos/car-battery-closeup-3d-rendering-isolated-on-white-background-picture-id899016436?k=20&m=899016436&s=612x612&w=0&h=xCvINEJxTswiEQ4JxjWIDA31fxpjrY8jz70LJzIl0bk=",
        name: "EZTZ4",
        price: "₹9,999",
    },
    {
        img: "https://images-cdn.ubuy.co.in/4AP4010-everstart-maxx-lead-acid-automotive.jpg",
        name: "EZTZ4",
        price: "₹9,999",
    },
]



const style = {
    width: "700px",
    margin: "0 auto",
    // bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 2,
    p: 4,
};