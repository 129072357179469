import React, { Component } from 'react'

import { Box, Grid, Input, InputAdornment } from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import { Card, CardHeader, CardContent, CardMedia, CardActions } from "@material-ui/core"
import Typography from '@material-ui/core/Typography';
// import Avatar from '@material-ui/core/Avatar';
// import "./assets/styles.css"


// import {
//   createStyles,
//   withStyles,
//   makeStyles,
//   Theme,
// } from "@material-ui/core/styles";
const greenTick = require("../assets/greentick.png")
const redBattery = require("../assets/red_battery.png")
const yellowStar = require("../assets/yellow_star.png")
const trippleLine = require("../assets/tripple_line.png")
const ezBattery = require("../assets/ez_battery.png")
const blackBattery = require("../assets/bla_battery.png")
const greenBattery = require("../assets/greenBattery.png")
const sosImage = require("../assets/sosImage.png")


const Styles = {
  inputSearchBox: {
    width: "25px",
    height: "35px",
    alignItems: "center",
    border: "1px solid #b30000",
    borderRadiusRight: "5px",
    backgroundColor: "#b30000",
    paddingRight: "10px",
    color: "white"
  },
  cardhead: {
    font: "10px",
    color: "grayText",
    textDecoration: "underline",
    textDecorationColor: "gray",
    textDecorationThickness: "1px",
  }
}


import ProductDescriptionController, {
    Props,
    configJSON,
  } from "./ProductDescriptionController";

export default class ProductDescription2 extends ProductDescriptionController {
  render() {
    return (
      <>
         <div style={{ overflowX: "hidden" }}>
          <div style={{ display: "flex", flexDirection: "column", overflowX: "hidden" }}>
            <Grid container style={{ backgroundColor: "red", height: "80px" }}>
              <div style={{ width: "400px", marginRight: "30px" }}>
                <div style={{ display: "flex", marginLeft: "200px" }}>
                  <img src={trippleLine} style={{ width: "20px", height: "20px", marginTop: "30px", marginRight: "20px" }} />
                  <img src={ezBattery} style={{ width: "100px", height: "40px", color: "white", marginTop: "20px" }} />
                </div>
              </div>
              {/* <div style={{ display:"flex", width: "440px",height:"30px",marginTop: "20px", marginRight:"30px",
               alignItems: "center",borderRadius: "5px", padding:"10px",border:"1px black",backgroundColor:"white"
             }}>
            
                <input type='text' style={{width:"410px",outline:"none",border: "none",background:"none"}}/> 
                <div style={{width:"40px",height:"50px",alignItems:"center",backgroundColor:"#b30000",borderRadius: "5px"}}>
                <SearchIcon style={{objectFit:"contain"}}/> 
             </div>         
         </div>  */}
              <div style={{ marginTop: "20px" }}>
                <Input
                  id="input-with-icon-adornment"
                  placeholder='Search Battery type/Category/Model Name/Model Number'
                  style={{
                    width: "470px", border: "1px solid red", borderBlock: "none", backgroundColor: "white",
                    borderRight: "2px solid #b30000",
                    borderRadius: "4px"
                  }}

                  endAdornment={
                    <InputAdornment position="end"
                      style={Styles.inputSearchBox}
                    >

                      <SearchIcon />

                    </InputAdornment>
                  }
                />

              </div>

              <div>
                <img src={sosImage} style={{ width: "40%", height: "40%", marginTop: "20px", marginLeft: "100px" }} />
              </div>
              <div style={{ display: "flex", color: "white", marginTop: "25px", marginLeft: "40px" }}>
                <div style={{ marginRight: "30px" }}>
                  <NotificationsNoneIcon />
                </div>
                <ShoppingCartIcon />
              </div>
              <div>
                <a
                  href='EmailAccountLoginBlock'
                  style={{ textDecoration: "none" }}
                >
                  <p style={{ color: "white", marginTop: "30px", marginLeft: "200px" }} >Login</p>
                </a>
              </div>

            </Grid>
            <Grid container style={{ backgroundColor: "#b30000", height: "40px" }}>
              <a
                href='Categoriessubcategories'
                style={{ textDecoration: "none" }}
              >
                <p style={{ marginLeft: "170px", marginRight: "40px", color: "white", fontSize: "13px" }}>2 WHEELER BATTERIES</p>
              </a>
              <p style={{ marginRight: "40px", color: "white", fontSize: "13px" }}>3 WHEELER BATTERIES</p>
              <p style={{ marginRight: "40px", color: "white", fontSize: "13px" }}>CAR/SUV/MUV BATTERIES</p>
              <p style={{ marginRight: "40px", color: "white", fontSize: "13px" }}>COMMERCIAL VEHICLE BATTERIES</p>
              <p style={{ marginRight: "40px", color: "white", fontSize: "13px" }} >TRACTOR BATTERIES</p>
              <p style={{ marginRight: "40px", color: "white", fontSize: "13px" }}>INVERTER BATTERIES</p>
            </Grid>

            <Grid style={{display:"flex",height:"50px", backgroundColor:"#eeeeee"}}>
              <Grid item xs={1}/>
               <Grid item xs={2} style={{display:"flex"}}>
               <p>home</p><br/>
               <p>3 Wheeler Batteries</p>
               </Grid>
               <Grid item xs={5} />
               <Grid item xs={3} style={{display:"flex"}}>
                <p><i>For better experience </i></p><br/>
                <p>Login/</p><p>Register</p>
               </Grid>
            </Grid>

            <div style={{backgroundColor: "#F4F5F6", borderBottom: "5px solid #32cba1"}}>
            {/* <Grid container style={{width:"1200px",height:"180px",marginTop:"20px",marginLeft:"157px",marginRight:"155px",
                 display:"flex",backgroundColor:"white"}}>
                 <div style={{display:"flex",flexDirection:"column",marginTop:"30px",marginLeft:"60px"}}>
                 <img src={NonSpillable} style={{width:"80px",height:"80px",marginRight:"90px",marginLeft:"12px"}} />
                  <p><strong>Non Spillable</strong></p>
                 </div>
                 <div style={{height:"40%" ,borderRight: "1px solid gray",marginTop:"45px"}}/>
                 <div style={{display:"flex",flexDirection:"column",marginTop:"30px",marginLeft:"100px"}}>
                 <img src={LowMaintanance} style={{width:"80px",height:"80px",marginRight:"90px"}} />
                  <p style={{marginLeft:"67px"}}><strong>Low Maintenance</strong></p>
                 </div>
                 <div style={{height:"40%" ,borderRight: "1px solid gray",marginTop:"45px"}}/>

                 <div style={{display:"flex",flexDirection:"column",marginTop:"30px",marginLeft:"100px"}}>
                 <img src={FactoryCharged} style={{width:"80px",height:"40px",marginRight:"100px"}} />
                  <p><strong>Factory Charged</strong></p>
                 </div>
              </Grid>  */}
            
             <div style={{marginLeft:"155px",marginRight:"155px"}}>
             <Grid container style={{display:"flex",flexDirection:"row"}} >
                {/* <Grid item xs={2} /> */}
                {this.state.productPage1Data?.attributes?.map((item: any, index: any) => {
                  return (
                  <Grid item xs={3} key={index} >
 

                      <Box m={1}>
                        <Card style={{width:"280px",height: "400px"}} >
                          <div style={{ display: "flex", flexDirection: "row" }}>
                            <img style={{ width: "10%", height: "10%", marginLeft: "10px", marginTop: "10px" }} src={greenTick} />
                            <p style={Styles.cardhead}>{item?.attributes?.warranty} warranty</p>

                            <IconButton aria-label="settings" style={{ marginTop: "5px" }}>
                              <FavoriteBorderIcon />
                            </IconButton>

                          </div>
         
                          <CardContent>
                            <img style={{ display: "block", marginLeft: "30px", marginBottom: "15px", width: "50%", height: "30%" }} src={item?.attributes?.image} />
                            <div style={{ marginLeft: "30px" }}>
                              {/* <div style={{ width: "80%", border: "1px solid white", borderRadius: "10px", backgroundColor: "#ff9999", textAlign: "center" }}>
                                Category:Inverter
                              </div> */}
                            </div>
                            <p style={{ textAlign: "center" }}>{item?.attributes?.model_no}</p>
                            <p style={{ textAlign: "center" }}>$9,999</p>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                              <div style={{ width: "35px", height: "20px", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", border: "1px solid gray", borderRadius: "10px" }}>
                                <img style={{ width: "10px", height: "10px" }} src={yellowStar} />
                                <p>4.1</p>
                              </div>
                              <div style={{ marginLeft: "10px" }}>
                                <div style={{ width: "60px", border: "1px solid gray", borderRadius: "10px", backgroundColor: "green" }}>
                                  50% off
                                </div>
                              </div>
                            </div>
                          </CardContent>
                          {/* <CardActions >
        
        </CardActions> */}
                        </Card>
                      </Box>
                    </Grid>
                   
                  
                   )
                   
                })
                
                } 
                </Grid> 
                
              
             </div>
             

            </div>-
          </div>

          <div>
     <Grid container style={{display:"flex", height:"300px"}}>

       <Grid item xs={1} />
       <Grid item xs={2} style={{marginLeft:"30px"}} >
         <img src={ezBattery} style={{width: "100px", height:"40px",color: "white", marginTop: "20px"}} />
       </Grid>
       <Grid item xs={2} >
       <p><strong>Product Categories</strong></p>
        <p>2 Wheeler Batteries</p>
        <p>3 Wheeler Batteries</p>
        <p>Car/SUV/MUV Batteries</p>
       </Grid> 
       <Grid item xs={2} style={{marginTop:"30px"}}>
        <p>commercial vehicles</p>
        <p>Tractor Batteries</p>
        <p>Inverter Batteries</p>
      </Grid>
        <Grid item xs={2}>
          <p><strong>Company</strong></p>
          <p>About Us</p>
          <p>Awards&Certifications</p>
          <div style={{display: "flex"}}>
          <p style={{marginRight:"10px"}}>careers</p> <br/> <p style={{ height: "20px",backgroundColor: "#d6f5ec",textAlign:"center"}}>We are hiring </p>
          </div>
          <p>Testimonials</p>
          <p>Contact Us</p>
          <p>Feedback</p>
      </Grid>
        <Grid item xs={2}>
           <p><strong>Follow Us</strong></p>
           <div style={{display: "flex"}}>
           <TwitterIcon style={{color:"gray"}}/>
           <YouTubeIcon style={{color:"gray"}}/>
           <CameraAltIcon style={{color:"gray"}}/>
           </div>
      </Grid>

      <Grid item xs={1}/>
     </Grid>
     </div>
     <div>
     <Grid container>
       <Grid item xs={1}/>
       <Grid item xs={10}>
         <div style={{width:"90%",borderBottom:"3px solid gray"}} />
         <div style={{display:"flex"}}>
          <p>@2022 ALL RIGHTS RESERVED BY EZ BATTERY</p>
          <a
            href='TermsCondition'
            style={{textDecoration:"none"}}
            >
          <p style={{marginLeft:"100px",marginRight:"200px"}}>TERMS & CONDITIONS . Privacy Policy</p>
          </a>
          <p>Language English</p>
         </div>
       </Grid>
      <Grid item xs={1}/>
     </Grid>
     </div>
        </div>
      </>
    )
  }
}

