import React from 'react'


// Customizable Area Start
import {  Container } from "@material-ui/core"
import CategoriessubcategoriesController from "./CategoriessubcategoriesController";
import VisionStatement from '../../../components/src/VisionStatement.web';

export default class TermsCondition extends CategoriessubcategoriesController {
  render() {
    return (
      <Container>
      <div style={{ fontWeight: 'bold',margin:'20px 0px 20px 0px' }}>Terms & Conditions</div>

      <div id='info'>
      {this.state.termsConditionsData?.description}
      </div>
        <VisionStatement navigation={this.props.navigation} id={''} classes={undefined} openSearch={undefined}/>
  </Container>
      
    
    )
  }
}
// Customizable Area End
