import React, { Component } from 'react'

import {  Container, } from "@material-ui/core";



export default class SearchBatteryResults extends Component{
    
   
  render() {

    return (
      <Container>
      
      </Container>
    )
  }
}
