// Customizable Area Start
import React, { Component } from "react";
import MerchantOrderController from "./MerchantOrderController";
import KeyLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyRight from "@material-ui/icons/KeyboardArrowRight";
import Home from "@material-ui/icons/Home";
import Person from "@material-ui/icons/Person";
import {
  Box,
  Container,
  Modal,
  Button,
  ListItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Collapse,
  List,
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
  Switch,
  Radio,
} from "@material-ui/core";

const sideNav = {
  width: "250px",
  transition: "width 0.3s ease-in-out",
  height: "100%",
  backgroundColor: "#de403a",
  paddingTop: "28px",
};
const sideNavClosed = {
  width: "65px",
  transition: "width 0.3s ease-in-out",
  height: "100%",
  backgroundColor: "#de403a",
  paddingTop: "28px",
};
const menuButton = {
  alignSelf: "center",
  justifySelf: "flex-end",
  color: "#B2BAC2",
  backgroundColor: "transparent",
  border: "none",
  cursor: "pointer",
  paddingLeft: "20px",
};

const sideItem = {
  display: "flex",
  alignItems: "center",
  padding: "10px 20px",
  cursor: "pointer",
  color: "ffffff",
  textDecoration: "none",
  overflow: "hidden",
};

const linkText = {
  paddingLeft: "16px",
  color: "#ffffff",
};
const logo=require('.././assets/logo.png');

export default class Sidebar extends MerchantOrderController {
  constructor(props: any) {
    super(props);
  }
  navData = [
    { title: "Home", icon: <Home /> },
    { title: "Upcoming Service Calls", icon: <Home /> },
    { title: "Post Srvice Calls", icon: <Home /> },
    { title: "Profile", icon: <Person /> },
  ];
  toggleOpen = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    return (
      <div style={this.state.open ? sideNav : sideNavClosed}>
        <div><img src={logo} width='50px'/></div>
        <button style={menuButton} onClick={this.toggleOpen}>
          {this.state.open ? <KeyLeft /> : <KeyRight />}
        </button>
        {this.navData.map((item) => {
          return (
            <div style={sideItem} onClick={()=>{
                if (item.title=='Upcoming Service Calls') {
                    this.props.navigation.navigate('UpcomingServiceCall')
                    
                }
                if (item.title=='Profile') {
                  this.props.navigation.navigate('TechnicianProfile')
                  
              }
                
            }} >
              <span style={{ color: "white" }}>{item.icon}</span>
              {this.state.open && <span style={linkText}>{item.title}</span>}
            </div>
          );
        })}
      </div>
    );
  }
}
// Customizable Area End