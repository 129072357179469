// Customizable Area Start
import React, { Component } from 'react'
import MerchantDashboard2Controller from './MerchantDashboard2Controller';


const ambasdar = require(".././assets/ambasdar.png");
const batterySelection = require(".././assets/batterySelection.png");
const mobile = require(".././assets/mobile.png");


export default class FooterImage extends   MerchantDashboard2Controller {
  render() {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '150px', marginBottom: '50px' }}>
        <div>
            <img src={ambasdar} width='550px' />
        </div>
        <div>
            <img src={batterySelection} width='300px' height='325px' />
        </div>
        <div>
            <img src={mobile} width='300px' height='325px' />
        </div>
    </div>
    )
  }
}
// Customizable Area End
