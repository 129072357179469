// Customizable Area Start
import React, { Component } from 'react'
import OrdermanagementController from './OrdermanagementController';

import {
    Box,
    Container,
    Modal,
    Button,
    ListItem,
    IconButton,
    ListItemIcon,
    ListItemText,
    Divider,
    Collapse,
    List,
    Grid,
    Card,
    CardContent,
    CardActions,
    Typography,
    Switch, Radio

} from "@material-ui/core";
import Rating from '@material-ui/lab/Rating'
import Info from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TextField from '@material-ui/core/TextField';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import ReturnReason from './ReturnReason.web';
import ReturnMethod from './ReturnMethod.web';
import ConfirmReturn from './ConfirmReturn.web';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import VisionStatementWeb from '../../../components/src/VisionStatement.web';

const ezBattery = require(".././assets/red_battery.png");
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    mt: 5,
    mb: 5,

    boxShadow: 24,
    p: 4,
};

export default class Exchange extends OrdermanagementController {
    arr = ['Product isnt functioning properly', 'Got a faulty product', 'Other Reason'];
    arr1 = [{ label: 'Exchange product with any other model', info: '*Extra charges may apply' }, { label: 'Exchange product with the same model', info: '*No Extra charges' }]


    handleCloseModal = () => {
        this.props.navigation.navigate('Orders')
        this.setState({ toggleReturn: false })
    }
    steps = ['Return Reason', 'Return Method', 'Confirm Return'];

    getStepContent = (step: number) => {
        switch (step) {
            case 0:
                return <ReturnReason navigation={this.props.navigation} id={this.props.id} />;
            case 1:
                return <ReturnMethod navigation={this.props.navigation} id={this.props.id} />;
            case 2:
                return <ConfirmReturn navigation={this.props.navigation} id={this.props.id} />

            default:
                throw new Error('Unknown step');
        }
    }

    handleNext = () => {
        this.setState({ activeStep: this.state.activeStep + 1 })

    };

    handleBack = () => {
        this.setState({ activeStep: this.state.activeStep - 1 })
    };
    render() {
        return (
            <>
                <div style={{ display: 'flex', justifyContent: 'flex-start', padding: '20px', backgroundColor: '#efefef' }}>
                    <Container>

                    <div style={{ color: '#a1a0a4' }}>
                        <a href='/' style={{ textDecoration: 'none', color: '#a1a0a4' }}>Home</a> <ArrowForwardIosRoundedIcon className="arrow-left" /> Orders <ArrowForwardIosRoundedIcon className="arrow-left" /> EZTZ4 <ArrowForwardIosRoundedIcon className="arrow-left" /> Exchange
                    </div>
                    </Container>

                </div>

                <Container style={{ marginTop: '20px' }}>
                    <Grid container>
                        <Grid item xs={12} md={4}>

                            <Box sx={{
                                bgcolor: 'background.paper',
                                marginRight: '25px'
                            }}>
                                <div style={{ display: 'flex', flexDirection: 'column', padding: '20px', alignItems: 'center' }}>

                                    <img src={ezBattery} width='250' height='250' />
                                    <div style={{ padding: '20px', fontWeight: 'bold', fontSize: '25px' }}>EZTZ4</div>
                                    <div style={{ padding: '20px', fontWeight: 'bold' }}>

                                        Order Number: 6789070
                                    </div>


                                </div>

                            </Box>



                        </Grid>

                        <Grid item xs={12} md={8}>
                            <Box sx={{
                                bgcolor: 'background.paper',
                                padding: '30px'
                            }}>
                                <div style={{ fontWeight: 'bold', marginBottom: '10px' }}>Reason for exchanging the product</div>
                                <div>
                                    {this.arr.map((item) => {
                                        return <div style={{ display: 'flex' }}>
                                            <Radio

                                                name="radio-buttons"
                                                inputProps={{ 'aria-label': 'A' }}
                                            />
                                            <span style={{ display: 'flex', alignItems: 'center' }}>{item}</span>
                                        </div>
                                    })}

                                </div>

                            </Box>

                            <Box sx={{
                                bgcolor: 'background.paper',
                                padding: '30px',
                                marginTop: '20px'
                            }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span style={{ fontWeight: 'bold' }}>Exchange Product</span>
                                    <span><Info /></span>
                                </div>
                                <Grid spacing={3} container>
                                    {this.arr1.map((item) => {
                                        return <Grid item xs={12} md={6}>


                                            <div style={{ padding: '10px', border: '2px solid #f3f3f6', marginTop: '15px' }}>

                                                <div style={{ display: 'flex' }}>
                                                    <Radio

                                                        name="radio-buttons"
                                                        inputProps={{ 'aria-label': 'A' }}
                                                    />
                                                    <span style={{ display: 'flex', alignItems: 'center' }}>{item.label}</span>
                                                </div>
                                                <div style={{ margin: '10px 0px 10px 35px' }}>
                                                    {item.info}
                                                </div>

                                            </div>
                                        </Grid>
                                    })}
                                </Grid>


                            </Box>

                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                                <Button
                                    variant='outlined'
                                    style={{ padding: '10px 90px 10px 90px', margin: '30px 0px 0px 20px' }}
                                >
                                    Back
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={()=>this.props.navigation.navigate('SelectProductToExchange')}

                                    style={{ padding: '10px 90px 10px 90px', margin: '30px 20px 0px 0px', backgroundColor: '#32cba0' }}

                                >
                                    Next
                                </Button>
                            </div>




                        </Grid>



                    </Grid>
                    <VisionStatementWeb id={''} classes={undefined} navigation={undefined} openSearch={undefined}/>

                </Container>

                <div>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={this.state.toggleCancelOrder}
                        onClose={() => this.handleCloseModal()}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={this.state.toggleCancelOrder}>
                            <Box sx={style}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ fontWeight: 'bold', fontSize: '20px' }}>Cancel Order?</div>
                                    <span><CloseIcon /></span>
                                </div>

                                <div style={{ marginTop: '20px', color: '#9da4b3' }}>
                                    Do you wish to cancel your order?Note: Refunds may take 5-7 business days to reflect on your account
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                                    <Button variant='outlined' onClick={() => this.handleCloseModal()} style={{ padding: '15px 45px' }}>No</Button>
                                    <Button variant='contained' style={{ padding: '15px 45px', backgroundColor: '#dd403a', marginLeft: '10px', color: 'white' }} >Yes</Button>
                                </div>








                            </Box>
                        </Fade>
                    </Modal>
                </div>

            </>
        )
    }
}
// Customizable Area End