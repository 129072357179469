import React from 'react';
import { BlockComponent } from '../../framework/src/BlockComponent';


import { Box, Grid, Container, Card, CardContent,Backdrop,CircularProgress } from "@material-ui/core";

import IconButton from '@material-ui/core/IconButton';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';


import RightIcon from '@material-ui/icons/ChevronRight';
import LeftIcon from '@material-ui/icons/ChevronLeft';

import "./assets/styles.css"
import { heart } from '../../blocks/dashboard/src/VehiclesBatteries.web';
import { ambasdar, appleStore, batterySelection, googlePlay, mobile } from '../../blocks/categoriessubcategories/src/assets';
import Filteritems from '../../blocks/filteritems/src/Filteritems.web';

const {baseURL:apiBaseUrl}=require('./../../framework/src/config')

const greenTick = require("../src/assets/first_geaan_tick.png")
const redBattery = require("../src/assets/red_battery.png")
const yellowStar = require("../src/assets/yellow_star.png")
const LoveImg = require("../src/assets/love.png")
const barcurve = require("../src/assets/barcurve.png");
const right = require("../src/assets/right.png");




const Styles = {
  inputSearchBox: {
    width: "25px",
    height: "35px",
    alignItems: "center",
    border: "1px solid #b30000",
    borderRadiusRight: "5px",
    backgroundColor: "#b30000",
    paddingRight: "10px",
    color: "white",
    cursor: "pointer"
  },
  cardhead: {
    font: "10px",
    color: "grayText",
    textDecoration: "underline",
    textDecorationColor: "gray",
    textDecorationThickness: "1px",
  }
}





interface Props {
  navigation: any;
  id: string;
  classes: any;
  t?: any;
  i18n?: any;
  location?: any;
  history?: any

  // Customizable Area Start
  openSearch: any
  // Customizable Area End
}

interface S {
  openSoS: boolean;
  isNotify: boolean;
  toggle: boolean;
  openSearch: boolean;
  searchValue: any;
  serachData: any;
  showSearchData: boolean;
  openProfile: boolean;
  open: boolean;
  recentSearchData: any;
  recentSearchCross: boolean;
  recentlyViewedData: any;
  openLanguageMenu: any
  profileModal: boolean
  toggleLanguage: boolean;
  isLoading:boolean;
  openAppChart:boolean
}

interface SS {

}



export default class HomeScreen extends BlockComponent<Props, S, SS> {
  wrapperRef: any;
  scrollRef1: any;
  scrollRef2: any;
  scrollRef3: any;
  scrollRef4: any;
  constructor(props: Props) {
    super(props);
    this.wrapperRef = React.createRef()
    this.scrollRef1 = React.createRef()
    this.scrollRef2 = React.createRef()
    this.scrollRef3 = React.createRef()
    this.scrollRef4 = React.createRef()
    this.state = {
      openSoS: false,
      isNotify: false,
      toggle: false,
      openSearch: false,
      searchValue: "",
      serachData: [],
      showSearchData: false,
      openProfile: false,
      open: false,
      recentSearchData: [],
      recentSearchCross: false,
      recentlyViewedData: [],
      openLanguageMenu: null,
      profileModal: false,
      toggleLanguage: false,
      isLoading:false,
      openAppChart:false
    }
  }

  async componentDidMount() {
    super.componentDidMount();
    this.recentlyViewedItemsCall();
    this.funCall()

  }
  funCall = () => {
    document.addEventListener('click', this.handleClickOutSide, true)
    return () => {
      document.removeEventListener('click', this.handleClickOutSide, true)
    }
  }
  handleClickOutSide = (e: any) => {
    if (this.wrapperRef.current && !this.wrapperRef.current.contains(e.target)) {
      this.setState({ openProfile: false })
      this.setState({ openSearch: false })
    }
  }
  handleSearch = () => {
    this.setState({ openSearch: true })
    if (this.state.searchValue !== "") {
      this.getSearchCall();
    } else {
      return
    }
  }
  handleClick = (event: any) => {
    // this.setState({ profileModal: !this.state.profileModal });
    this.setState({ openProfile: !this.state.openProfile });
  };

  handleMenuLang = (event: any) => {
    this.setState({ openLanguageMenu: event.currentTarget })

  };

  handleCloseMenu = () => {
    this.setState({ openLanguageMenu: null })

  }
  finalPrice=(item:any)=>{
    return item?.attributes?.amount-item?.attributes?.discount_amount
      }
  handleOpenProfile = () => {
    this.setState({ openProfile: true })
  }
  closeAppChart = () => {
    this.setState({ openAppChart: false })
}
  handleRecentItemsDelete = (index: any, item: any) => {
    let x = this.state.recentSearchData;
    x[index].search_term = !x[index].search_term
    this.setState({ recentSearchData: [...x] })
    localStorage.setItem("recentSearchId", this.state.recentSearchData?.id);
    this.deleteRecentSearchCall(item.id);
  }

  prev = (scrollRef: any) => {
    requestAnimationFrame(() => {
      const scrollLeft = scrollRef.current.scrollLeft;
      const itemWidth = parseInt(
        getComputedStyle(scrollRef.current.children[0]).width
      );
      scrollRef.current.scrollLeft = scrollLeft - itemWidth * 1;
    });
  };

  next = (scrollRef: any) => {
    requestAnimationFrame(() => {
      const scrollLeft = scrollRef.current.scrollLeft;
      const itemWidth = parseInt(
        getComputedStyle(scrollRef.current.children[0]).width
      );
      scrollRef.current.scrollLeft = scrollLeft + itemWidth * 1;
    });
  };

  getSearchCall = () => {
    let TOKEN: any = localStorage.getItem("loginSuccessToken")

    let myHeaders = new Headers();

    {
      localStorage.getItem("loginSuccessToken") &&
        myHeaders.append("token", TOKEN);
    }
    myHeaders.append("Content-Type", "application/json");



    fetch(`${apiBaseUrl}/bx_block_fedex_integration/search_data?search=${this.state.searchValue}`, {
      method: 'GET',
      headers: myHeaders,
    })
      .then(response => response.json())
      .then(result => {
        console.log("search result milrahe", result)
        this.setState({ showSearchData: true, serachData: result?.data })
      })
      .catch(error => console.log('error', error));
  }

  recentSearchCall = () => {
    let TOKEN: any = localStorage.getItem("loginSuccessToken")

    let myHeaders = new Headers();
    myHeaders.append("token", TOKEN);
    myHeaders.append("Content-Type", "application/json");



    fetch(`${apiBaseUrl}/bx_block_fedex_integration/user_search`, {
      method: 'GET',
      headers: myHeaders,
    })
      .then(response => response.json())
      .then(result => {
        console.log(result)
        this.setState({ recentSearchData: result?.data })
        // localStorage.setItem("recentSearchId",result?.data?.id)
      })
      .catch(error => console.log('error', error));
  }

  deleteRecentSearchCall = (ItemId: any) => {
    let TOKEN: any = localStorage.getItem("loginSuccessToken")
    let RecentSearchID = localStorage.getItem("recentSearchId")

    let myHeaders = new Headers();
    myHeaders.append("token", TOKEN);
    myHeaders.append("Content-Type", "application/json");


    fetch(`${apiBaseUrl}/bx_block_fedex_integration/user_search/${ItemId}`, {
      method: 'DELETE',
      headers: myHeaders,
    })
      .then(response => response.json())
      .then(result => {
        console.log(result)
        this.setState({ recentSearchCross: true })
      })
      .catch(error => console.log('error', error));
  }

  recentlyViewedItemsCall = () => {

    let TOKEN: any = localStorage.getItem("loginSuccessToken")

    let myHeaders = new Headers();
    myHeaders.append("token", TOKEN);

    var raw = "";
    var body = raw;


    fetch(apiBaseUrl+"/bx_block_fedex_integration/recent_batteries", {
      method: 'GET',
      headers: myHeaders,
    })
      .then(response => response.json())
      .then(result => {
        console.log(result)
        this.setState({ recentlyViewedData: result?.data })
        this.setState({isLoading:false})
      })
      .catch(error => console.log('error', error));
  }
  createWishList=()=>{
    this.setState({isLoading:true})
    let TOKEN: any = localStorage.getItem("loginSuccessToken");
    let item_id:any=localStorage.getItem('item_id')
  
    let myHeaders = new Headers();
   
    myHeaders.append("token", TOKEN);
  
    // myHeaders.append("Content-Type", "application/json");

    let formdata = new FormData();
    formdata.append("item_id", item_id);
  
  
  
    fetch(`${apiBaseUrl}/bx_block_fedex_integration/wishlist`, {
      method: 'POST',
      headers: myHeaders,
      body:formdata
    })
      .then(response => response.json())
      .then(result => {
        console.log( result);
        this.recentlyViewedItemsCall()
   
       
       
       
      })
      .catch(error =>{ console.log('error', error); this.setState({isLoading:false})});
  
  }
  deleteWishlist=()=>{
    this.setState({isLoading:true})
    let TOKEN: any = localStorage.getItem("loginSuccessToken");
    let item_id:any=localStorage.getItem('item_id')
  
    let myHeaders = new Headers();
   
    myHeaders.append("token", TOKEN);


    let formdata = new FormData();
    formdata.append("item_id", item_id);
  
  
  
    fetch(`${apiBaseUrl}/bx_block_fedex_integration/wishlist/${item_id}`, {
      method: 'DELETE',
      headers: myHeaders,
      body:formdata
    })
      .then(response => response.json())
      .then(result => {
        console.log( result);
      this.recentlyViewedItemsCall()
       
      })
      .catch(error =>{ console.log('error', error); this.setState({isLoading:false})});
    
  }


  toggleDrawer =
    (anchor: String, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === 'keydown' &&
          ((event as React.KeyboardEvent).key === 'Tab' ||
            (event as React.KeyboardEvent).key === 'Shift')
        ) {
          return;
        }

        this.setState({ toggle: open })
      };

  closeSoS = () => {
    this.setState({ openSoS: false })
  }
  arr = [{
    title: 'Service call request has been accepted',
    time: '2 mins ago'
  },
  { title: 'Service call request has been accepted service call has been ', time: '2 mins ago' },
  { title: 'Service call request has been accepted service is out of order', time: '2 mins ago' },
  { title: 'ABCD is out for delivery', time: '9:00' },
  { title: 'Exchange request has been accepted', time: '1 hour ago' }

  ];
  batteryCards = [0, 1, 2, 3];
  render() {

    return (
      <>
       <Backdrop
          style={{ color: '#fff', zIndex: 1 }}
          open={this.state.isLoading}

        > <CircularProgress color="inherit" /></Backdrop>
        <div>
        {this.state.showSearchData && this.state.openSearch &&
            <div style={{ marginTop: "165px", marginLeft: "155px", marginRight: "155px" }}>
              <Grid container style={{ display: "flex", flexDirection: "row", position: "relative", }} >

                {this.state.serachData?.map((item: any, index: any) => {
                  return (
                    <Grid item xs={3} key={index} >


                      <Box m={1}>
                        <Card style={{ width: "250px", height: "400px" }} >
                          <div style={{ display: "flex", flexDirection: "row" }}>
                            <img style={{ width: "10%", height: "10%", marginLeft: "10px", marginTop: "10px" }} src={greenTick} />
                            <p style={Styles.cardhead}>{item?.attributes?.warranty} warranty</p>

                            <IconButton aria-label="settings" style={{ marginTop: "5px" }}>
                              <FavoriteBorderIcon />
                            </IconButton>

                          </div>

                          <CardContent>
                            <img style={{ display: "block", marginLeft: "60px", marginBottom: "15px", width: "50%", height: "30%" }} src={item?.attributes?.thumbnail_image} />
                            <div style={{ marginLeft: "30px" }}>

                            </div>
                            <p style={{ textAlign: "center" }}>{item?.attributes?.model_no}</p>
                            <p style={{ textAlign: "center" }}>{item?.attributes?.capaciy}</p>
                            <p style={{ textAlign: "center" }}>${item?.attributes?.amount}</p>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                              <div style={{ width: "35px", height: "20px", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", border: "1px solid gray", borderRadius: "10px" }}>
                                <img style={{ width: "10px", height: "10px" }} src={yellowStar} />
                                <p>{item?.attributes?.range}</p>
                              </div>
                              <div style={{ marginLeft: "10px" }}>
                                <div style={{ width: "70px", border: "1px solid gray", borderRadius: "10px", backgroundColor: "green" }}>
                                  {item?.attributes?.applied_discount}% off
                                </div>
                              </div>
                            </div>
                          </CardContent>
                        </Card>
                      </Box>
                    </Grid>


                  )

                })

                }
              </Grid>
            </div>
          }


          <Container style={{ filter: this.state.openSearch ? 'blur(5px)' : 'blur(0px)' }}>


            <div style={{ color: "black", fontSize: "20px", marginTop: "66px", marginBottom: "25px", fontWeight: 'bold' }}>Flash Sale</div>

            <div style={{ display: 'flex', justifyContent: 'space-between', position: 'relative' }}>
              <div onClick={() => this.prev(this.scrollRef1)}
                style={{ position: 'absolute', top: '200px', left: '-4%', cursor: 'pointer', border: '1px solid ##E6E8EA', borderRadius: '50%', padding: '3px', backgroundColor: '#ECF0F3' }}><LeftIcon /></div>
              <div onClick={() => this.next(this.scrollRef1)}
                style={{ position: 'absolute', top: '200px', right: '-4%', cursor: 'pointer', border: '1px solid ##E6E8EA', borderRadius: '50%', padding: '3px', backgroundColor: '#ECF0F3' }}><RightIcon /></div>
            </div>

            {/* <Grid container spacing={2}   > */}
            <div className='carouselContainer' ref={this.scrollRef1}>



              {this.batteryCards.map(() => {
                return <div style={{ marginRight: '20px' }} className='itemScroll'>
                  <Card>
                    <div style={{ display: "flex", justifyContent: 'space-between', padding: '15px' }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>

                        <img src={greenTick} width='25px' height='25px' />
                        <small style={{ textDecoration: 'underline', marginLeft: '10px', fontSize: '15px',color:"#9CA0A9" }}>36 months warranty</small>
                      </div>

                      <div style={{ display: 'flex', alignItems: 'center' }}>

                        <img src={LoveImg} width='25px' height='20px' />
                      </div>


                    </div>
                    <CardContent>
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <div style={{ padding: '15px' }}><img src={redBattery} width='100' height='100' /></div>

                        <div style={{ padding: '5px 10px', backgroundColor: '#FBC4C7', marginTop: '20px', borderRadius: '20px' }}>
                          Category:Inverter
                        </div>
                        <div style={{ fontWeight: 'bold', marginTop: '15px', marginBottom: '20px' }}>EZ2345</div>

                        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                          <span style={{ fontWeight: 'bold', fontSize: '20px' }}>Rs.8999</span>
                          <s style={{ color: '#9498A9', marginLeft: '10px', fontSize: '20px', fontWeight: 'bold' }}>Rs.15999</s>
                        </div>
                        <div style={{ display: 'flex', marginTop: '10px' }}>
                          <div style={{ border: '1px solid gray', display: 'flex', marginRight: '10px', borderRadius: '25px', paddingLeft: '5px', paddingRight: '5px' }}>
                            <span>

                              <img src={yellowStar} width='15px' height='15px' />
                            </span>


                            <span style={{ display: 'flex', alignItems: 'center' }}>4.0</span>

                          </div>
                          <span
                            style={{
                              borderRadius: '25px', paddingLeft: '10px', color: 'white',
                              display: 'flex', alignItems: 'center',
                              paddingRight: '10px', backgroundColor: '#66bc54'
                            }}>25%</span>
                        </div>
                      </div>
                    </CardContent>


                  </Card>

                </div>
              })}
            </div>
            {/* </Grid> */}



            <div>
              <p style={{ fontSize: "20px" }}><strong>Discounts on categories</strong></p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', position: 'relative' }}>
              <div onClick={() => this.prev(this.scrollRef2)}
                style={{ position: 'absolute', top: '35px', left: '-4%', cursor: 'pointer', border: '1px solid #E6E8EA', borderRadius: '50%', padding: '3px', backgroundColor: '#ECF0F3' }}><LeftIcon /></div>
              <div onClick={() => this.next(this.scrollRef2)}
                style={{ position: 'absolute', top: '35px', right: '-4%', cursor: 'pointer', border: '1px solid #E6E8EA', borderRadius: '50%', padding: '3px', backgroundColor: '#ECF0F3' }}><RightIcon /></div>
            </div>
            <Grid container spacing={3}>

              <Grid item xs={6}>
                <div style={{ position: 'relative' }}>

                  <div>
                    <img src={barcurve} width='100%' />
                  </div>

                  <div style={{
                    position: 'absolute',
                    top: '50%',
                    left: '20%',
                    transform: 'translate(-50%, -50%)',
                    fontWeight: 'bold'
                  }}>
                    Two Wheeler Battery
                  </div>

                  <div
                    style={{
                      position: 'absolute',
                      top: '50%',
                      right: '5%',
                      transform: 'translate(-50%, -50%)',

                      borderRadius: '25px', paddingLeft: '10px', color: 'white',
                      display: 'flex', alignItems: 'center',
                      paddingRight: '10px', backgroundColor: '#66bc54'
                    }}>50%</div>

                </div>

              </Grid>
              <Grid item xs={6}>
                <div style={{ position: 'relative' }}>

                  <div>
                    <img src={barcurve} width='100%' />
                  </div>

                  <div style={{
                    position: 'absolute',
                    top: '50%',
                    left: '20%',
                    transform: 'translate(-50%, -50%)',
                    fontWeight: 'bold'
                  }}>
                    Three Wheeler Battery
                  </div>
                  <div
                    style={{
                      position: 'absolute',
                      top: '50%',
                      right: '5%',
                      transform: 'translate(-50%, -50%)',

                      borderRadius: '25px', paddingLeft: '10px', color: 'white',
                      display: 'flex', alignItems: 'center',
                      paddingRight: '10px', backgroundColor: '#66bc54'
                    }}>50%</div>

                </div>

              </Grid>
            </Grid>
                  
            {this.state.recentlyViewedData?.length > 0 &&<div>
              <p style={{ fontSize: "20px" }}><strong>Recently Viewed</strong></p>
            </div>}

            {this.state.recentlyViewedData?.length > 0 && <div style={{ display: 'flex', justifyContent: 'space-between', position: 'relative' }}>
              <div onClick={() => this.prev(this.scrollRef3)}
                style={{ position: 'absolute', top: '200px', left: '-4%', cursor: 'pointer', border: '1px solid ##E6E8EA', borderRadius: '50%', padding: '3px', backgroundColor: '#ECF0F3' }}><LeftIcon /></div>
              <div onClick={() => this.next(this.scrollRef3)}
                style={{ position: 'absolute', top: '200px', right: '-4%', cursor: 'pointer', border: '1px solid ##E6E8EA', borderRadius: '50%', padding: '3px', backgroundColor: '#ECF0F3' }}><RightIcon /></div>
            </div>}

            <div className='carouselContainer' ref={this.scrollRef3}>



              {this.state?.recentlyViewedData?.map((item: any) => {
                return <div style={{ marginRight: '20px' }} className='itemScroll'

                >


                  <Card>
                    <div style={{ display: "flex", justifyContent: 'space-between', padding: '15px' }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>

                        <img src={greenTick} width='25px' height='25px' />
                        <small style={{ textDecoration: 'underline', marginLeft: '10px', fontSize: '15px',color:"9CA0A9" }}>{item?.attributes?.warranty}</small>
                      </div>

                      <div
                        onClick={() => {
                          if (localStorage.getItem('loginSuccessToken')) {

                            if (item?.attributes?.is_wishlisted) {
                              localStorage.setItem('item_id', item?.id);
                              this.deleteWishlist()

                            }
                            else {

                              localStorage.setItem('item_id', item?.id);
                              this.createWishList()
                            }
                          }
                          else {
                            this.props.navigation.navigate('signUp')
                          }

                        }}
                        style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>

                        <img src={item?.attributes?.is_wishlisted ? heart : LoveImg} width='25px' height='20px' />
                      </div>


                    </div>
                    <CardContent>
                      <div
                        onClick={() => {
                          localStorage.setItem("applyShowBatteriesId", item.id)
                        this.props.navigation.navigate('ProductDescription1',item?.id)
                        }}
                        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }}>
                        <div style={{ padding: '15px' }}><img src={item?.attributes?.thumbnail_image} width='100' height='100' /></div>

                        <div style={{ padding: '5px 10px', backgroundColor: '#FBC4C7', marginTop: '20px', borderRadius: '20px' }}>
                          Category:{item?.attributes?.item_type}
                        </div>
                        <div style={{ fontWeight: 'bold', marginTop: '15px', marginBottom: '20px' }}>{item?.attributes?.model_no}</div>

                        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                          <span style={{ fontWeight: 'bold', fontSize: '20px' }}>&#x20b9;{this.finalPrice(item)}</span>
                          <s style={{ color: '#9498A9', marginLeft: '10px', fontSize: '20px', fontWeight: 'bold' }}>&#x20b9;{item?.attributes?.amount}</s>
                        </div>
                        <div style={{ display: 'flex', marginTop: '10px' }}>
                          <div style={{ border: '1px solid gray', display: 'flex', marginRight: '10px', borderRadius: '25px', paddingLeft: '5px', paddingRight: '5px' }}>
                            <span>

                              <img src={yellowStar} width='15px' height='15px' />
                            </span>


                            <span style={{ display: 'flex', alignItems: 'center' }}>{item?.attributes?.range}</span>

                          </div>
                          <span
                            style={{
                              borderRadius: '25px', paddingLeft: '10px', color: 'white',
                              display: 'flex', alignItems: 'center',
                              paddingRight: '10px', backgroundColor: '#66bc54'
                            }}>{Math.round(item?.attributes?.applied_discount)}% off</span>
                        </div>
                      </div>
                    </CardContent>


                  </Card>

                </div>
              })}
            </div>

            <div>
              <p style={{ fontSize: "20px" }}><strong>Recommended Batteries</strong></p>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', position: 'relative' }}>
              <div onClick={() => this.prev(this.scrollRef4)}
                style={{ position: 'absolute', top: '200px', left: '-4%', cursor: 'pointer', border: '1px solid ##E6E8EA', borderRadius: '50%', padding: '3px', backgroundColor: '#ECF0F3' }}><LeftIcon /></div>
              <div onClick={() => this.next(this.scrollRef4)}
                style={{ position: 'absolute', top: '200px', right: '-4%', cursor: 'pointer', border: '1px solid ##E6E8EA', borderRadius: '50%', padding: '3px', backgroundColor: '#ECF0F3' }}><RightIcon /></div>
            </div>

            <div className='carouselContainer' ref={this.scrollRef4} >

              {this.batteryCards.map(() => {
                return <div style={{ marginRight: '20px' }} className='itemScroll'>
                  <Card>
                    <div style={{ display: "flex", justifyContent: 'space-between', padding: '15px' }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>

                        <img src={greenTick} width='25px' height='25px' />
                        <small style={{ textDecoration: 'underline', marginLeft: '10px', fontSize: '15px',color:"#9CA0A9" }}>36 months warranty</small>
                      </div>

                      <div style={{ display: 'flex', alignItems: 'center' }}>

                        <img src={LoveImg} width='25px' height='20px' />
                      </div>


                    </div>
                    <CardContent>
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <div style={{ padding: '15px' }}><img src={redBattery} width='100' height='100' /></div>

                        <div style={{ padding: '5px 10px', backgroundColor: '#FBC4C7', marginTop: '20px', borderRadius: '20px' }}>
                          Category:Inverter
                        </div>
                        <div style={{ fontWeight: 'bold', marginTop: '15px', marginBottom: '20px' }}>EZ2345</div>

                        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                          <span style={{ fontWeight: 'bold', fontSize: '20px' }}>Rs.8999</span>
                          <s style={{ color: '#9498A9', marginLeft: '10px', fontSize: '20px', fontWeight: 'bold' }}>Rs.15999</s>
                        </div>
                        <div style={{ display: 'flex', marginTop: '10px' }}>
                          <div style={{ border: '1px solid gray', display: 'flex', marginRight: '10px', borderRadius: '25px', paddingLeft: '5px', paddingRight: '5px' }}>
                            <span>

                              <img src={yellowStar} width='15px' height='15px' />
                            </span>


                            <span style={{ display: 'flex', alignItems: 'center' }}>4.0</span>

                          </div>
                          <span

                            style={{
                              borderRadius: '25px', paddingLeft: '10px', color: 'white',
                              display: 'flex', alignItems: 'center',
                              paddingRight: '10px', backgroundColor: '#66bc54'
                            }}>25%</span>
                        </div>
                      </div>
                    </CardContent>


                  </Card>

                </div>
              })}
            </div>

            <Grid container spacing={5} style={{marginTop: '100px', marginBottom: '50px' }}>
                <Grid item xs={12} sm={6} md={6}>
                <div style={{ position: 'relative' }}>
                        <img src={ambasdar} width='605px' height={325}  />
                        <div style={{
                            position: 'absolute',
                            top: '50%',
                            left: '35%',
                            transform: 'translate(-50%, -50%)',

                        }}>
                            <div style={{ fontWeight: 'bold', color: 'white', fontSize: '25px' }}>Vision Statement</div>
                            <div style={{ width: '300px', color: '#F69794', lineHeight: '1.5', margin: '10px 0px' }}>
                                Laxmi Motors through EZ Battery {'&'}
                                Auto Spares envisions to be the first
                                energy company to reach every household
                                of this country by 2025 through our channel
                                partners by providing customized services to help
                                to make end consumer's life easy, enroute to this
                                goal, also create value for fellow citizens so
                                they lead better quality of life.
                            </div>

                        </div>
                    </div>

                </Grid>
                <Grid item xs={12} sm={6} md={3} >
                <div style={{ position: 'relative' }}>
                        <img src={batterySelection} 
                        width='290px' height='325px' 
                        />
                        <div style={{
                            position: 'absolute',
                            top: '25%',
                            left: '40%',
                            transform: 'translate(-50%, -50%)',


                        }}>

                            <div style={{ fontSize: 'x-large', color: '#D1FBF5' }}>
                                Need help with battery selection?  <span onClick={()=>{
                                    this.setState({openAppChart:true})
                                }} style={{ cursor: 'pointer', marginLeft: '10px' }}><img src={right} width='8px' /></span>
                            </div>

                        </div>
                    </div>

                </Grid>
                <Grid item  xs={12} sm={6} md={3}>
                <div style={{ position: 'relative' }}>
                        <img src={mobile} width='290px' height='325px' />
                        <div style={{
                            position: 'absolute',
                            top: '20%',
                            left: '146px',
                            transform: 'translate(-50%, -50%)'
                        }}>
                            <div style={{ color: '#BE6F6A', fontSize: 'x-large', margin: '10px' }}>Get the App now!</div>
                            <div style={{ display: 'flex' }}>
                                <img src={googlePlay} style={{ marginRight: '5px' }} width='125px' height='40px' />
                                <img src={appleStore} width='125px' height='40px' />
                            </div>
                        </div>
                    </div>
                </Grid>

            </Grid>
            {/* <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '100px', marginBottom: '50px' }}>
              <div style={{ position: 'relative' }}>
                <img src={ambasdar} width='550px' />
                <div style={{
                  position: 'absolute',
                  top: '50%',
                  left: '35%',
                  transform: 'translate(-50%, -50%)',

                }}>
                  <div style={{ fontWeight: 'bold', color: 'white', fontSize: '25px' }}>Vision Statement</div>
                  <div style={{ width: '300px', color: '#F69794', lineHeight: '1.5', margin: '10px 0px' }}>
                    Laxmi Motors through EZ Battery {'&'}
                    Auto Spares envisions to be the first
                    energy company to reach every household
                    of this country by 2025 through our channel
                    partners by providing customized services to help
                    to make end consumer's life easy, enroute to this
                    goal, also create value for fellow citizens so
                    they lead better quality of life.
                  </div>

                </div>
              </div>
              <div style={{ position: 'relative' }}>
                <img src={batterySelection} width='300px' height='325px' />
                <div style={{
                  position: 'absolute',
                  top: '25%',
                  left: '40%',
                  transform: 'translate(-50%, -50%)',


                }}>

                  <div style={{ fontSize: 'x-large', color: '#D1FBF5' }}>
                    Need help with battery selection?  <span onClick={()=>{
                      this.setState({openAppChart:true})
                    }} style={{ cursor: 'pointer', marginLeft: '10px' }}><img src={right} width='8px' /></span>
                  </div>

                </div>
              </div>
              <div style={{ position: 'relative' }}>
                <img src={mobile} width='300px' height='325px' />
                <div style={{
                  position: 'absolute',
                  top: '20%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)'
                }}>
                  <div style={{ color: '#BE6F6A', fontSize: 'x-large', margin: '10px' }}>Get the App now!</div>
                  <div style={{ display: 'flex' }}>
                    <img src={googlePlay} style={{ marginRight: '5px' }} width='125px' height='40px' />
                    <img src={appleStore} width='125px' height='40px' />
                  </div>
                </div>
              </div>
            </div> */}
            <Filteritems navigation={this.props.navigation} id={''} openAppChart={this.state.openAppChart} closeAppChart={this.closeAppChart} history={this.props.history} handleVeh={undefined} handleInv={undefined} handleCheckedOption={undefined}/>
          
          </Container>
        </div>

        {/* footer */}

      </>


    );
  }
}

// Customizable Area End





















