// Customizable Area Start
import React, { Component } from 'react'
import ContactusController from './ContactusController';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Info from '@material-ui/icons/Info'

export default class AddBatteryCollection extends ContactusController {
    constructor(props: any) {
        super(props);
        
    }
    render() {
        return (
            <Container component="main" maxWidth='lg'>
                <div style={{ margin: '20px 0px 20px 0px', fontSize: '25px', fontWeight: 'bold' }}>Add to Battery Collection</div>
                <Box
                    sx={{
                        bgcolor: 'white',
                        padding: '30px'
                    }}
                    >

                    <div style={{margin:'0px 0px 20px 0px'}}>Please add non EZ batteries that you are using</div>
        
               
                        <Grid container spacing={3}>

                        <Grid item xs={12} sm={3}>
                           
                            <TextField
                                required
                                id="country"
                                name="country"
                                label="Brand"
                                fullWidth
                                autoComplete="shipping country"
                                variant="outlined"
                                />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            
                            <TextField
                                required
                                id="country"
                                name="country"
                                label="Category"
                                fullWidth
                                autoComplete="shipping country"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          
                            <TextField
                                required
                                id="country"
                                name="country"
                                label="Unique Serial lot"
                                fullWidth
                                autoComplete="shipping country"
                                variant="outlined"
                                />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                           
                            <TextField
                                required
                                id="country"
                                name="country"
                                label="Date of Purchase(DD/MM/YY)"
                                fullWidth
                                autoComplete="shipping country"
                                variant="outlined"
                                />
                        </Grid>
                        </Grid>
                            <div style={{margin:'25px 0px 10px 0px'}}>Address</div>
                        <Grid container spacing={3} >
                        <Grid item xs={12} sm={3}>
                           
                            <TextField
                                required
                                id="country"
                                name="country"
                                label="Postal Address"
                                fullWidth
                                autoComplete="shipping country"
                                variant="outlined"
                                />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                           
                            <TextField
                                required
                                id="country"
                                name="country"
                                label="Landmark"
                                fullWidth
                                autoComplete="shipping country"
                                variant="outlined"
                                />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            
                            <TextField
                                required
                                id="country"
                                name="country"
                                label="City"
                                fullWidth
                                autoComplete="shipping country"
                                variant="outlined"
                                />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          
                            <TextField
                                required
                                id="country"
                                name="country"
                                label="PIN"
                                fullWidth
                                autoComplete="shipping country"
                                variant="outlined"
                                />
                        </Grid>
                    </Grid>
                    
                    <div style={{padding:'5px',backgroundColor:'#e5f3fa',width:'525px',marginTop:'20px',display:'flex'}}>
                    <span><Info color='primary'/></span>
                    <span style={{color:'#67bbe5',marginLeft:'5px',display:'flex',alignItems:'center'}}>We ask this information to help improve your web experience</span>
                    </div>

                </Box>
            </Container>
        )
    }
}
// Customizable Area End
