import React from "react";

// Customizable Area Start
import {
  Grid,
  TextField,
  Typography,
  InputAdornment,
   Backdrop, CircularProgress
} from "@material-ui/core";
import { GoogleLogin } from "react-google-login";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
const ClientId =require('../../social-media-account/config.json')

import Error from "@material-ui/icons/Error";
const {baseURL:apiBaseUrl}=require('./../../../framework/src/config')

import "react-toastify/dist/ReactToastify.css";

import "./login.css";




import {  BatteryHindi } from "./assets";
const FacebookLogo = require("../assets/facebook-logo.png");
const GoogleLogo = require("../assets/google-logo.png");
const call = require("../assets/call.png");


import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";





export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
  }
  handleClose = () => {
    this.setState({ isLoading: false })
  }
  googleLogin=(signInData:any)=>{
    this.setState({isLoading:true});
    let myHeaders=new Headers();
    myHeaders.append('content-type','application/json');

    fetch(`${apiBaseUrl}/account_block/accounts`, {
      method: 'POST',
      headers:myHeaders,
      body:JSON.stringify(signInData)
    }).then(response => response.ok?response.json():Promise.reject(response))
    .then(result => {
        console.log(result);
        localStorage.setItem("loginSuccessToken", result?.meta?.token);
        this.props.navigation.navigate('Home')
       this.setState({isLoading:false});
      })
      .catch(error =>{
        console.log('error', error);
        this.setState({isLoading:false})
      });
  }


  fbLogin=(signInData:any)=>{
    this.setState({isLoading:true});
    let myHeaders=new Headers();
    myHeaders.append('content-type','application/json')
    fetch(`${apiBaseUrl}/bx_block_login/social_login?language=en`, {
      method: 'POST',
      headers:myHeaders,
      body:JSON.stringify(signInData)
    }).then(response => response.ok?response.json():Promise.reject(response))
    .then(result => {
        console.log(result);
        localStorage.setItem("loginSuccessToken", result?.meta?.token);
        this.props.navigation.navigate('Home')
       this.setState({isLoading:false});
      })
      .catch(error =>{
        console.log('error', error);
        this.setState({isLoading:false})
      });
  }

   onSuccess = (res:any) => {
  const signInData = {
      language:'en',
      data:{
        type:'google',
        attributes:{
          email: res.profileObj.email,
          unique_auth_id:res.profileObj.googleId
        }
      }
     
    };
   this.googleLogin(signInData)
  };


 onFailure = (res:any) => {
    console.log(res, "failure");
  };
  fbResponse=(res:any)=>{
    
    console.log(res?.email);
    if(res.status=='unknown'){
      return null
    }
    const signInData = {
      data:{
        type:'facebook',
        attributes:{
          email: res?.email,
          access_token:res?.accessToken
        }
      }
     
    };
    this.fbLogin(signInData)

  }
  render() {
    if (this.state.token) {
      this.props.navigation.navigate('Home')
      return null

    }
    return (
    
      <>
        <Backdrop
          style={{ color: '#fff', zIndex: 1 }}
          open={this.state.isLoading}

        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="bg-img">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom:'30px',
              paddingTop:'100px'
            }}
          >
            <img src={BatteryHindi} />
          </div>

          <Grid item style={{ display: "flex", justifyContent: "center" }}>
            <div className="outer-grid">
              <div
               className="test"
              >
                <Typography
                  style={{
                    marginLeft: "180px",
                    marginRight: "40px",
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                >
                  <strong>Sign in</strong>
                </Typography>
                <a id='register' href="signUp" style={{ textDecoration: "none" }}>
                  <Typography
                    style={{
                      color: "#C6C6CE",
                      marginLeft: "50px",
                      fontSize: "20px",
                    }}
                  >
                    Register
                  </Typography>
                </a>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <TextField
                  id="testMobile"
                  type="text"
                  value={this.state.phone_number}
                  placeholder="Mobile number"
                  variant="outlined"
                  className="textfield"
                  style={{
                    width: "70%",
                    borderRadius: "5px",
                    marginLeft: "90px",
                    marginBottom: "20px",

                    border:
                      !this.state.phone_number && this.state.blankDataM
                        ? "1px solid red"
                        : "0px solid",
                    backgroundColor:
                      !this.state.phone_number && this.state.blankDataM
                        ? "#FDEFEF"
                        : "",
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={call} width="25px" />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e: any) => {
                    this.setState({ phone_number: e.target.value });
                    localStorage.setItem("PhoneNumber", e.target.value);
                    if (!this.state.phone_number) {
                      this.setState({ showErrors: false });
                    }
                  }}
                />
                {!this.state.phone_number && this.state.blankDataM && (
                  <div
                  id='errormsg'
                    style={{
                      marginLeft: "95px",
                      marginTop: "-15px",
                      marginBottom: "15px",
                      color: "red",

                      fontSize: "smaller",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ marginRight: "5px" }}>
                      <Error color="error" fontSize="small" />
                    </div>
                    <div>Mobile Number is Required</div>
                  </div>
                )}
                {this.state.phone_number && this.state.showErrors && (
                  <div
                    style={{
                      marginLeft: "95px",
                      marginTop: "-15px",
                      marginBottom: "15px",
                      color: "red",

                      fontSize: "smaller",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ marginRight: "5px" }}>
                      <Error color="error" fontSize="small" />
                    </div>
                    <div>{this.state.errorsData}</div>
                  </div>
                )}

                <div
                id='verifyButton'
                  style={{
                    width: "70%",
                    height: "50px",
                    marginLeft: "90px",
                    backgroundColor: "#32CBA1",
                    borderRadius: "4px",
                    cursor: "pointer",
                    fontWeight: "bold",
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                  onClick={() => {
                    this.setState({ blankDataM: true });
                    if (this.state.phone_number) {
                      this.sendOTP();

                    }
                  }}
                >

                  Verify mobile number{" "}

                </div>
              </div>
              <div id="orsignIn" style={{ marginTop: "110px" }}>
                <p
                  style={{ textAlign: "center", font: "8px", color: "#808080" }}
                >
                  Or sign in with
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <GoogleLogin
                  icon={true}
                  clientId={ClientId}
                  render={(renderProps) => (
        
                <div
                id="googleButton"
                onClick={renderProps.onClick}
                  style={{
                    width: "40px",
                    height: "40px",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#fae5e7",
                    borderRadius: "12px",
                    marginRight: "5px",
                    cursor:'pointer'
                  }}
                >
                  <img
                    src={GoogleLogo}
                    style={{ width: "30px", height: "30px", padding: "5px" }}
                  />
                </div>
            )}
                  prompt="consent"
                onSuccess={this.onSuccess}
                onFailure={this.onFailure}
                   cookiePolicy={"single_host_origin"}
               />
               <FacebookLogin
                    appId="868269751167749"
                    fields="name,email,picture"
                    callback={this.fbResponse}
                    render={renderProps => (
                <div
               
                onClick={renderProps.onClick}
                  style={{
                    width: "40px",
                    height: "40px",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#cce9f6",
                    borderRadius: "5px",
                    cursor:'pointer'
                  }}
                >
                  <img
                    src={FacebookLogo}
                    style={{ width: "30px", height: "30px", padding: "5px" }}
                  />
                </div>
                     
                     )}
                  />  
              </div>
            </div>

          </Grid>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <a
            data-test-id='btnSkip'
            id="skipbtn"
              href="/"
              style={{ textDecoration: "none",color:'black' }}
            >
              <div style={{ width: "135px", height: "50px", marginTop: "40px", border: "1px solid gray",backgroundColor:'#F3F3F3', cursor: "pointer" }} >
                <p style={{ textAlign: "center",fontWeight:'bold' }}>Skip</p>
              </div>
            </a>
          </div>

          
        </div>
      </>
    );
  }
}


// Customizable Area End
