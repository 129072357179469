import React, { Component } from 'react'
// Customizable Area Start



import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';


import IconButton from '@material-ui/core/IconButton';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';

import { Box, Grid, Container, Card, CardContent, withStyles,Backdrop,CircularProgress } from "@material-ui/core"
import "./dashboard.css";


const greenTick = require("../assets/greentick.png")
const yellowStar = require("../assets/yellow_star.png")

const securedGreenTick = require("../assets/securedGreenTick.png")
const love = require("../assets/love copy.png")

const Styles = {
  inputSearchBox: {
    width: "25px",
    height: "35px",
    alignItems: "center",
    border: "1px solid #b30000",
    borderRadiusRight: "5px",
    backgroundColor: "#b30000",
    paddingRight: "10px",
    color: "white",
    cursor: "pointer"
  },
  cardhead: {
    font: "10px",
    color: "grayText",
    textDecoration: "underline",
    textDecorationColor: "gray",
    textDecorationThickness: "1px",
  }
}


import DashboardController, { Props } from "./DashboardController";
import { heart } from './VehiclesBatteries.web';
import VisionStatement from '../../../components/src/VisionStatement.web';

const StyledFormControlLabel = withStyles({
  root: {
    marginLeft: '0px',
    marginRight: '0px'
  },
  label: {

  },
})(FormControlLabel);


export default class InverterBatteries extends DashboardController {
  render() {
    return (

      <>
       <Backdrop
          style={{ color: '#fff', zIndex: 1 }}
          open={this.state.isLoading}

        > <CircularProgress color="inherit" /></Backdrop>

        <div>

        <div style={{ padding: '20px', backgroundColor: '#efefef' }}>
            <Container>

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                <div style={{ color: '#a1a0a4' }}>
                  <a href='/' style={{ textDecoration: 'none', color: '#a1a0a4' }}>Home</a> <ArrowForwardIosRoundedIcon className="arrow-left" /> Inverter Batteries
                </div>
                {!localStorage.getItem("loginSuccessToken") && <div style={{ color: '#a1a0a4' }}>
                  <span>
                    <i>For Better Experience</i>
                    <span onClick={() => {
                      this.props.navigation.navigate('EmailAccountLoginBlock')
                    }} style={{ color: "red", fontWeight: 'bold', cursor: 'pointer' }}> Login/</span><span onClick={() => {
                      this.props.navigation.navigate('signUp')
                    }} style={{ color: "red", fontWeight: 'bold', cursor: 'pointer' }}>Register</span>
                  </span>
                </div>
                }
              </div>
            </Container>
          </div>

          {this.state.showSearchData && this.state.openSearch &&
            <div style={{ marginTop: "165px", marginLeft: "155px", marginRight: "155px" }}>
              <Grid container style={{ display: "flex", flexDirection: "row", position: "relative", }} >

                {this.state.serachData?.map((item: any, index: any) => {
                  return (
                    <Grid item xs={3} key={index} >


                      <Box m={1}>
                        <Card style={{ width: "250px", height: "400px" }} >
                          <div style={{ display: "flex", flexDirection: "row" }}>
                            <img style={{ width: "10%", height: "10%", marginLeft: "10px", marginTop: "10px" }} src={greenTick} />
                            <p style={Styles.cardhead}>{item?.attributes?.warranty} warranty</p>

                            <IconButton aria-label="settings" style={{ marginTop: "5px" }}>
                              <FavoriteBorderIcon />
                            </IconButton>

                          </div>

                          <CardContent>
                            <img style={{ display: "block", marginLeft: "60px", marginBottom: "15px", width: "50%", height: "30%" }} src={item?.attributes?.thumbnail_image} />
                            <div style={{ marginLeft: "30px" }}>

                            </div>
                            <p style={{ textAlign: "center" }}>{item?.attributes?.model_no}</p>
                            <p style={{ textAlign: "center" }}>{item?.attributes?.capaciy}</p>
                            <p style={{ textAlign: "center" }}>${item?.attributes?.amount}</p>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                              <div style={{ width: "35px", height: "20px", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", border: "1px solid gray", borderRadius: "10px" }}>
                                <img style={{ width: "10px", height: "10px" }} src={yellowStar} />
                                <p>{item?.attributes?.range}</p>
                              </div>
                              <div style={{ marginLeft: "10px" }}>
                                <div style={{ width: "70px", border: "1px solid gray", borderRadius: "10px", backgroundColor: "green" }}>
                                  {item?.attributes?.applied_discount}% off
                                </div>
                              </div>
                            </div>
                          </CardContent>
                        </Card>
                      </Box>
                    </Grid>


                  )

                })

                }
              </Grid>
            </div>
          }

          <Container style={{ marginTop: '30px' }}>

            <Grid container>
              <Grid item xs={12} md={3}>

                <Box sx={{
                  bgcolor: 'background.paper',
                  marginRight: '25px'
                }}>
                  <div style={{ fontWeight: 'bold', padding: '10px' }}>Filter</div>
                  <div style={{ backgroundColor: '#f1f1f1', margin: '10px' }}>
                    <div style={{ padding: '10px' }}>Battery Type</div>

                    <RadioGroup aria-label="gender" name="gender1" value={this.state.batteryType}
                      onChange={(e: any) => this.setState({ batteryType: e.target.value })}>
                      <StyledFormControlLabel value="female" control={<Radio />} label="Vehicular Batteries"
                        onClick={() => { this.props.navigation.navigate("VehiclesBatteries") }}
                      />
                      <StyledFormControlLabel value="male" control={<Radio />} label="Inverter Batteries" checked
                      // onClick={this.getInverterBatteriesCall}
                      />
                    </RadioGroup>
                  </div>

                  {/* category */}
                  <div style={{ backgroundColor: '#f1f1f1', margin: '10px' }}>
                    <div style={{ padding: '10px' }}>Inverter</div>
                    {this.state.allInverterCapacityData.map((item: any, index: any) => {
                      return (
                        <div style={{ paddingBottom: '15px', marginLeft: '10px' }}>
                          <input type="radio" name='capacity' value={item?.attributes?.inverter_capacity} id={item?.attributes?.inverter_capacity}
                            className="radio-btn"
                            onChange={(e: any) => {
                              if (e.target.checked) {

                                this.setState({ inverterCapacity: item?.attributes?.inverter_capacity })
                              }

                              // if (e.target.value === this.state.invertype) {
                              //   this.setState({ invertype: "" });
                              // } else {
                              //   this.setState({ invertype: e.target.value });
                              // }

                              let x = this.state.allInverterCapacityData;
                              console.log(x)
                              x[index].attributes.checked = !x[index].attributes.checked;
                              console.log(x)
                              this.setState({ allInverterCapacityData: x })
                              localStorage.setItem("InverterCapacity", item?.attributes?.inverter_capacity)
                              // localStorage.setItem("InputVoltage", item?.attributes?.input_voltage)
                              // localStorage.setItem("backUptime", item?.attributes?.backup_time)
                              // localStorage.setItem("batteryCapacity", item?.attributes?.battery_capacity)
                              this.getInverterCall850(item?.attributes?.inverter_capacity);
                            }}
                          />
                          <label htmlFor={item?.attributes?.inverter_capacity}
                            style={{ padding: "10px" }}
                          >{item?.attributes?.inverter_capacity}</label><br />
                        </div>
                      )
                    })}
                  </div>

                  {/* manufacturer */}
                  <div style={{ backgroundColor: '#f1f1f1', margin: '10px' }}>
                    <div style={{ padding: '10px' }}>DC Input Voltage</div>
                    {this.state.allInverterCapacityData.map((item: any, index: any) => {
                      return (
                        <div style={{ paddingBottom: '15px', marginLeft: '10px' }}>
                          <input type="radio" name='input-voltage' value={item?.attributes?.input_voltage} id={item?.attributes?.input_voltage}
                            className="radio-btn"
                            onClick={(e: any) => {
                              if (e.target.value === this.state.DCInputType) {
                                this.setState({ DCInputType: "" });
                              } else {
                                this.setState({ DCInputType: e.target.value });
                              }

                              let x = this.state.allInverterCapacityData
                              x[index].attributes.checked = !x[index].attributes.checked
                              this.setState({ allInverterCapacityData: x })
                              // localStorage.setItem("InverterCapacity", item?.attributes?.inverter_capacity)
                              localStorage.setItem("InputVoltage", item?.attributes?.input_voltage);
                              this.setState({ dcInputVoltage: item?.attributes?.input_voltage })
                              // localStorage.setItem("backUptime", item?.attributes?.backup_time)
                              // localStorage.setItem("batteryCapacity", item?.attributes?.battery_capacity)
                              this.getInputVoltageCall(item?.attributes?.input_voltage);
                            }}
                          />
                          <label htmlFor={item?.attributes?.input_voltage}
                            style={{ padding: "10px" }}
                          >{item?.attributes?.input_voltage}</label><br />
                        </div>
                      )
                    })}
                  </div>

                  {/* model */}
                  <div style={{ backgroundColor: '#f1f1f1', margin: '10px' }}>
                    <div style={{ padding: '10px' }}>Backup Time</div>
                    {this.state.allInverterCapacityData.map((item: any, index: any) => {
                      return (
                        <div style={{ paddingBottom: '15px', marginLeft: '10px' }}>
                          <input type="radio" name='backup-time' value={item?.attributes?.backup_time} id={item?.attributes?.backup_time}
                            className="radio-btn"
                            onClick={(e: any) => {
                              if (e.target.value === this.state.BackUpType) {
                                this.setState({ BackUpType: "" });
                              } else {
                                this.setState({ BackUpType: e.target.value });
                              }

                              let x = this.state.allInverterCapacityData
                              x[index].attributes.checked = !x[index].attributes.checked
                              this.setState({ allInverterCapacityData: x })
                              // localStorage.setItem("InverterCapacity", item?.attributes?.inverter_capacity)
                              // localStorage.setItem("InputVoltage", item?.attributes?.input_voltage)
                              localStorage.setItem("backUptime", item?.attributes?.backup_time);
                              this.setState({ backUpTime: item?.attributes?.backup_time })
                              // localStorage.setItem("batteryCapacity", item?.attributes?.battery_capacity)
                              this.getBackupTimeCall(item?.attributes?.backup_time);
                            }}
                          />
                          <label htmlFor={item?.attributes?.backup_time}
                            style={{ padding: "10px" }}
                          >{item?.attributes?.backup_time}</label><br />
                        </div>
                      )
                    })}
                  </div>

                  {/* Fuel type */}
                  <div style={{ backgroundColor: '#f1f1f1', margin: '10px' }}>
                    <div style={{ padding: '10px' }}>Capacity</div>
                    {this.state.allInverterCapacityData.map((item: any, index: any) => {
                      return (
                        <div style={{ paddingBottom: '15px', marginLeft: '10px' }}>
                          <input type="radio" name='cap' value={item?.attributes?.battery_capacity} id={item?.attributes?.battery_capacity}
                            className="radio-btn"
                            onClick={(e: any) => {
                              if (e.target.value === this.state.capacityType) {
                                this.setState({ capacityType: "" });
                              } else {
                                this.setState({ capacityType: e.target.value });
                              }

                              let x = this.state.allInverterCapacityData
                              x[index].attributes.checked = !x[index].attributes.checked
                              this.setState({ allInverterCapacityData: x })
                              // localStorage.setItem("InverterCapacity", item?.attributes?.inverter_capacity)
                              // localStorage.setItem("InputVoltage", item?.attributes?.input_voltage)
                              // localStorage.setItem("backUptime", item?.attributes?.backup_time)
                              localStorage.setItem("batteryCapacity", item?.attributes?.battery_capacity);
                              this.setState({ batteryCapacity: item?.attributes?.battery_capacity })
                              this.getBatteryCapacityCall(item?.attributes?.battery_capacity);
                            }}
                          />
                          <label htmlFor={item?.attributes?.battery_capacity}
                            style={{ padding: "10px" }}
                          >{item?.attributes?.battery_capacity}</label><br />
                        </div>
                      )
                    })}
                  </div>

                </Box>

                <Box sx={{
                  marginRight: '25px',
                  bgcolor: 'background.paper'
                }}>
                  <div style={{ marginTop: '20px' }}>


                    <div style={{ padding: '15px 0px 15px 15px', fontWeight: 'bold' }}>Sort By</div>
                    <RadioGroup aria-label="sort" name="sort1" value={this.state.sortType}
                      onChange={(e: any) => this.setState({ sortType: e.target.value })}>
                      <StyledFormControlLabel value="radd" control={<Radio value={'recently_added'} />} label="Recently added"
                        onClick={this.sortbyInverterRecentlyAddedCall}
                      />
                      <StyledFormControlLabel value="priceltoh" control={<Radio value={'price_l_to_h'} />} label="Price low to high"
                        onClick={this.sortbyInvPriceLowtoHighCall}
                      />
                      <StyledFormControlLabel value="pricehtol" control={<Radio value={'price_h_to_l'} />} label="Price high to low"
                        onClick={this.sortbyInvPriceHighttoLowCall}
                      />
                      <StyledFormControlLabel value="atoz" control={<Radio value={'atoz'} />} label="From A to Z"
                        onClick={this.sortbyInvFromAtoZCall}
                      />
                    </RadioGroup>



                  </div>


                </Box>



              </Grid>

              <Grid item xs={12} md={8}>
                <Grid spacing={3} container>
                  {!this.state.allFilterSelect &&
                    this.state.inverterBatteriesData?.map((item: any, index: any) => {
                      return (
                        <Grid item xs={4} key={index} >


                          <Card>
                            <div style={{ display: "flex", justifyContent: 'space-between', padding: '15px' }}>
                              <div style={{ display: 'flex', alignItems: 'center' }}>

                                <img src={securedGreenTick} width='25px' height='25px' />
                                <small style={{ textDecoration: 'underline', marginLeft: '10px',color:"#9CA0A9" }}>{item?.attributes?.warranty}</small>
                              </div>

                              <div
                           onClick={()=>{
                              if (localStorage.getItem('loginSuccessToken')) {

                                if (item?.attributes?.is_wishlisted) {
                                  localStorage.setItem('item_id', item?.id);
                                  this.deleteWishlist()

                                }
                                else {

                                  localStorage.setItem('item_id', item?.id);
                                  this.createWishList()
                                }
                              }
                              else {
                                localStorage.setItem('wishList',JSON.stringify(true))
                                this.props.navigation.navigate('LoginRequired')
                              }

                          }}
                          style={{ display: 'flex', alignItems: 'center',cursor:'pointer' }}>

                            <img src={item?.attributes?.is_wishlisted ? heart : love} width='25px' height='20px' />
                          </div>


                            </div>
                            <CardContent>
                              <div
                               onClick={() => {
                               
                                localStorage.setItem("applyShowBatteriesId", item.id)
                              this.props.navigation.navigate('ProductDescription1',item?.id)
                              }}
                              style={{ display: 'flex', flexDirection: 'column', alignItems: 'center',cursor:'pointer' }}>
                                <div style={{ padding: '15px' }}><img src={item?.attributes?.thumbnail_image} width='100' height='100' /></div>



                                <div style={{ fontWeight: 'bold', marginTop: '15px' }}>{item?.attributes?.model_no}</div>
                                <div style={{ fontWeight: 'bold', marginTop: '15px', marginBottom: '20px' }}><span style={{ color: '#B1B5C3' }}>Capacity</span> {item?.attributes?.capaciy}</div>

                                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                  <span style={{ fontWeight: 'bold', fontSize: '20px' }}>&#x20b9;{this.finalPrice(item)}</span>
                                  <s style={{ color: '#9498A9', marginLeft: '10px', fontSize: '20px', fontWeight: 'bold' }}>&#x20b9;{item?.attributes?.amount}</s>
                                </div>
                                <div style={{ display: 'flex', marginTop: '10px' }}>
                                  <div style={{ border: '1px solid gray', display: 'flex', marginRight: '10px', borderRadius: '25px', paddingLeft: '5px', paddingRight: '5px' }}>
                                    <span>

                                      <img src={yellowStar} width='15px' height='15px' />
                                    </span>


                                    <span style={{ display: 'flex', alignItems: 'center' }}>{item?.attributes?.range}</span>

                                  </div>
                                  <span
                                    style={{
                                      borderRadius: '25px', paddingLeft: '10px', color: 'white',
                                      display: 'flex', alignItems: 'center',
                                      paddingRight: '10px', backgroundColor: '#66bc54'
                                    }}> {Math.round(item?.attributes?.applied_discount)}% off</span>
                                </div>
                              </div>
                            </CardContent>


                          </Card>
                        </Grid>


                      )

                    })
                  }
                 

                 

                </Grid>
              </Grid>
            </Grid>
            {/* vision statement */}
              <VisionStatement navigation={this.props.navigation} id={''} classes={undefined} openSearch={undefined}/>
          </Container>








        </div>


      </>
    )
  }
}
// Customizable Area End
