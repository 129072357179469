// Customizable Area Start
import React from 'react'
import VideoManagementController from './VideoManagementController';
import { Card, Container, Paper, Box, Backdrop, CircularProgress, Grid, Radio, FormControlLabel, RadioGroup } from "@material-ui/core"
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess';
import { player } from './assets';
import VisionStatementWeb from '../../../components/src/VisionStatement.web';




const style = {
    position: 'absolute' as 'absolute',
    top: '105px',
    left: '10%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1,
    width: '260px',
    bgcolor: 'background.paper'

}
export default class MediaGallery extends VideoManagementController {
    render() {
        return (
            <>
                <Backdrop
                    style={{ color: '#fff', zIndex: 1 }}
                    open={this.state.isLoading}

                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <div style={{marginBottom:'50px'}}>

                
                <div style={{ padding: '20px', backgroundColor: '#efefef' }}>
                    <Container>

                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                            <div style={{ color: '#a1a0a4' }}>
                                <a href='/' style={{ textDecoration: 'none', color: '#a1a0a4' }}>Home</a> <ArrowForwardIosRoundedIcon className="arrow-left" /> Gallery
                            </div>
                            {!localStorage.getItem("loginSuccessToken") && <div style={{ color: '#a1a0a4' }}>
                                <span>
                                    <i>For Better Experience</i>
                                    <span id='fbe' onClick={() => {
                                        this.props.navigation.navigate('EmailAccountLoginBlock')
                                    }} style={{ color: "red", fontWeight: 'bold', cursor: 'pointer' }}> Login/</span><span onClick={() => {
                                        this.props.navigation.navigate('signUp')
                                    }} style={{ color: "red", fontWeight: 'bold', cursor: 'pointer' }}>Register</span>
                                </span>
                            </div>
                            }
                        </div>
                    </Container>
                </div>

                <Container>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', margin: '20px 0px', alignItems: 'center', position: 'relative' }}>

                        <div style={{ fontSize: 'small', display: 'flex', alignItems: 'center' }}>
                            <span style={{ fontWeight: 'bold' }}>{this.state.isPhoto ? 'Photo' : 'Video'}</span>{this.state.isExpand ? <span onClick={() => this.setState({ isExpand: false })} style={{ cursor: 'pointer' }}><ExpandLess /></span> : <span onClick={() => this.setState({ isExpand: true })} style={{ cursor: 'pointer' }}><ExpandMore /></span>}
                        </div>
                        {this.state.isExpand && <Box sx={style} >
                            <Paper elevation={2} style={{ padding: '20px' }}>
                                <RadioGroup
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    onChange={(e: any) => {
                                        this.setState({ galleryData: [] })
                                        this.viewGallery(e.target.value)

                                        this.setState({ isExpand: false })
                                        e.target.value == 'photo' ? this.setState({ isPhoto: true }) : this.setState({ isPhoto: false })


                                    }
                                    }
                                    id='radio'
                                >
                                    {!this.state.isPhoto ? <FormControlLabel control={<Radio value={'photo'} />} label="Photo" /> :
                                        <FormControlLabel control={<Radio value={'video'} />} label="Video" />}


                                </RadioGroup>


                            </Paper>
                        </Box>}
                    </div>
                    <Grid container spacing={3}>
                        {this.state.galleryData.map((item: any) => {
                            return <Grid id='grid' item xs={12} md={3}>
                                <Card style={{ borderRadius: '15px' }} >
                                    <div id='gallery'
                                        onClick={() => {

                                            localStorage.setItem('galleryFolder', JSON.stringify(item))
                                            this.state.isPhoto ? this.props.navigation.navigate('Photos') : this.props.navigation.navigate('Video')

                                        }}
                                        style={{ padding: '10px', position: 'relative', cursor: 'pointer' }}>
                                        <img src={item?.attributes?.thumbnail} alt='' style={{ borderRadius: '15px' }} width={270} height={200} />
                                        {!this.state.isPhoto && <div style={{ position: 'absolute', top: '15%', left: '89%', transform: 'translate(-50%,-50%)', padding: '5px' }}>

                                            <img src={player} width={30} />
                                        </div>}
                                        <div style={{ width: '110px', backgroundColor: 'white', borderRadius: '10px', position: 'absolute', top: '88%', left: '20%', transform: 'translate(-50%,-50%)', padding: '5px' }}>

                                            <small style={{ display: 'flex', justifyContent: 'center', margin: '10px 0px' }}>{item?.attributes?.category}</small>
                                        </div>

                                    </div>
                                </Card>

                            </Grid>
                        })}

                    </Grid>
                    <VisionStatementWeb navigation={this.props.navigation} id={''} classes={undefined} openSearch={undefined}/>
                </Container>
                </div>
            </>
        )
    }
}
// Customizable Area End






