// Customizable Area Start
import React, { Component } from 'react'
import MerchantOrderController from './MerchantOrderController';
import {
    Box,
    Container,
    Modal,
    Button,
    ListItem,
    IconButton,
    ListItemIcon,
    ListItemText,
    Divider,
    Collapse,
    List,
    Grid,
    Card,
    CardContent,
    CardActions,
    Typography,
    Switch,
    Radio,
    Paper,
    Avatar,
    Badge,
    Backdrop,
    Fade,
    Stepper,
    Step,
    StepLabel,
    TextField,
  } from "@material-ui/core";

export default class ActionsInitiated extends MerchantOrderController {
  render() {
    return (
     <Grid container spacing={3} style={{marginTop:'20px'}}>
        <Grid item xs={12} sm={6}>
            <div style={{display:'flex',border:'2px solid gray',padding:'10px',alignItems:'center',borderRadius:'15px',fontWeight:'bold'}}>
               <div><Radio/></div>
               <div>Recharging Process</div>
            </div>

        </Grid>
        <Grid item xs={12} sm={6}>
        <div style={{display:'flex',border:'2px solid gray',padding:'10px',alignItems:'center',borderRadius:'15px',fontWeight:'bold'}}>
               <div><Radio/></div>
               <div>Testing Process</div>
            </div>

        </Grid>


     </Grid>
    )
  }
}
// Customizable Area End