// import React from "react";

// // Customizable Area Start
// import {
//   Container,
//   Grid,
//   Button,
//   TextField,
//   Typography,
//   InputAdornment,
//   IconButton,
//   Checkbox,
// } from "@material-ui/core";
// import Box from '@material-ui/core/Box';
// import Input from "@material-ui/core/Input";

// import CallIcon from '@material-ui/icons/Call';
// import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
// import { createTheme, ThemeProvider } from "@material-ui/core/styles";
// import VisibilityOff from "@material-ui/icons/VisibilityOff";
// import Visibility from "@material-ui/icons/Visibility";

// // Customizable Area End

// import EmailAccountRegistrationController, {
//     Props
//   } from "./EmailAccountRegistrationController";

// const Styles={
//   mainbox: {
//     height: "100px",
//     width: "350px",
//   },
//   callset:{
//     // color: "white"
//   }
// }


// export default class EmailAccountRegistration extends EmailAccountRegistrationController {
//   constructor(props: Props) {
//     super(props);
//   }

//   render() {
//     return (
//       // Required for all blocks
//       <>
//        <div style={{display: "flex", flexDirection: "column"}}>
//        <Grid item style={{display:"flex", justifyContent: "center"}}>
//           <div style={{width: "600px",height: "500px",marginTop:"100px" ,border: "1px solid gray",borderRadius:"4px"}}>
//             <div style={{display: "flex",alignItems: "center", marginTop: "50px", marginBottom: "70px"}}>
//             <Typography style={{color: "black ", marginLeft:"180px", marginRight: "40px", fontSize:"20px"}} ><strong>Sign in</strong></Typography>
//             <Typography style={{color: "#808080", marginLeft: "50px", fontSize: "20px"}}><strong>Register</strong></Typography>
//              </div> 
//              <div style={{display: "flex", flexDirection: "column"}}>
//              <Input
//                       id="outlined-full-width"
//                       type="text"
//                       placeholder="Full Name"
//                       style={{
//                         width: "70%",
//                         border: "0.2px solid gray",
//                         padding: "10px",
//                         borderRadius: "4px",
//                         borderBottom: "none",
//                         outline: "0px",
//                         marginLeft: "90px",
//                         marginBottom: "20px"
//                       }}
                       
//                       startAdornment={
//                         <InputAdornment position="end" 
//                             style={Styles.callset}
//                             >
//                         <PersonOutlineOutlinedIcon/>
//                         </InputAdornment>
//                       }
//                      /> 
//              <Input
//                       id="outlined-full-width"
//                       type="text"
//                       value={this.state.phone_number}
//                       placeholder="Mobile number"
//                       style={{
//                         width: "70%",
//                         border: "0.2px solid gray",
//                         padding: "10px",
//                         borderRadius: "4px",
//                         borderBottom: "none",
//                         outline: "0px",
//                         marginLeft: "90px",
//                         marginBottom: "20px"
//                       }}
                       
//                       startAdornment={
//                         <InputAdornment position="end" 
//                             style={Styles.callset}
//                             >
//                         <CallIcon/>
//                         </InputAdornment>
//                       }
//                       onChange={(e:any)=> this.setState({phone_number: e.target.value})}
//                      /> 
//                   <div style={{width: "70%", height:"50px", marginLeft: "90px", backgroundColor: "#00ffbf", borderRadius: "4px",
//                    marginTop:"80px", cursor: "pointer"}}
//                    onClick={()=>this.sendOTP()}
//                    >
//                      <p style={{textAlign: "center", color: "black"}} > Verify mobile number </p> 
                     
//                     </div>  
//               </div> 
//             </div>

//       </Grid>
//            <div style={{ marginLeft:"740px",width:"40px",marginTop: "20px",border:"1px solid gray",justifyContent: "center"}}>
//              Skip
//            </div>
      
//         </div>
//         </>
//     );
//   }
// }
// // Customizable Area End

import React, { Component } from 'react'

export default class EmailAccountRegistration extends Component {
  render() {
    return (
      <div>
        <h1>this is me</h1>
      </div>
    )
  }
}




