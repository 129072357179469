// Customizable Area Start
import React, { Component } from 'react'
import OrdermanagementController from './OrdermanagementController';

import {
    Box,
    Container,
    Modal,
    Button,
  
    Divider,
  
    Grid,
 

} from "@material-ui/core";
import Rating from '@material-ui/lab/Rating'
import Info from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TextField from '@material-ui/core/TextField';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import VisionStatementWeb from '../../../components/src/VisionStatement.web';

const ezBattery = require(".././assets/red_battery.png");
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    mt: 5,
    mb: 5,

    boxShadow: 24,
    p: 4,
};

export default class ProductInfo extends OrdermanagementController {
    handleCloseModal = () => {
        this.setState({ toggleCancelOrder: false })
    }
    render() {
        return (
            <>
                <div style={{ display: 'flex', justifyContent: 'flex-start', padding: '20px', backgroundColor: '#efefef' }}>
                    <Container>

                    <div style={{ color: '#a1a0a4' }}>
                        <a href='/' style={{ textDecoration: 'none', color: '#a1a0a4' }}>Home</a> <ArrowForwardIosRoundedIcon className="arrow-left" /> Orders <ArrowForwardIosRoundedIcon className="arrow-left" /> EZTZ4
                    </div>
                    </Container>

                </div>

                <Container style={{ marginTop: '20px' }}>
                    <Grid container>
                        <Grid item xs={12} md={4}>

                            <Box sx={{
                                bgcolor: 'background.paper',
                                marginRight: '25px'
                            }}>
                                <div style={{ display: 'flex', flexDirection: 'column', padding: '20px', alignItems: 'center' }}>

                                    <img src={ezBattery} width='250' height='250' />
                                    <div style={{ padding: '20px', fontWeight: 'bold', fontSize: '25px' }}>EZTZ4</div>
                                    <div style={{ padding: '20px' }}>

                                        <Rating
                                            style={{ fontSize: '2.875rem' }}
                                            name="simple-controlled"
                                            value={this.state.ratingValue}
                                            onChange={(event, newValue) => {
                                                this.setState({ ratingValue: newValue })
                                            }}
                                        />
                                    </div>

                                    <Button disabled style={{ padding: '10px 30px 10px 30px', backgroundColor: '#32cba0' }}>
                                        Upload Review
                                    </Button>
                                </div>

                            </Box>



                        </Grid>

                        <Grid item xs={12} md={8}>
                            <Box sx={{
                                bgcolor: 'background.paper',
                                padding: '30px'
                            }}>

                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div>

                                        <div style={{ fontWeight: 'bold' }}>Delievery Address</div>
                                        <div style={{ padding: '20px 0px 0px 0px', color: '#acb2bf' }}>John Doe,9384994</div>
                                        <div style={{ padding: '10px 0px 15px 0px', color: '#acb2bf' }}>Bezel Pharma, Govt Indl Estate,Opp plot 99,Hindustan Naka, Kandivali west</div>
                                    </div>

                                    <Divider />
                                    <div >
                                        <div style={{ padding: '10px 0px 10px 0px', fontWeight: 'bold' }}>Price Summary</div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <div style={{ padding: '20px 0px 20px 0px' }}>Total</div>
                                            <div style={{ padding: '20px 0px 20px 0px' }}>Rs.8999</div>
                                        </div>

                                    </div>
                                    <Divider />
                                    <div >
                                        <div style={{ padding: '20px 0px 20px 0px', fontWeight: 'bold' }}>Payment Method</div>
                                        <div style={{ padding: '20px 0px 20px 0px' }}>UPI</div>


                                    </div>
                                    <Divider />
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div style={{ padding: '20px 0px 20px 0px', display: 'flex', alignItems: 'center', fontWeight: 'bold' }}>Invoice</div>
                                        <div style={{ padding: '20px 0px 20px 0px', display: 'flex', alignItems: 'center' }}>

                                            <Button style={{ backgroundColor: '#d6f5ec', padding: '10px 30px 10px 30px' }}>Download Invoice</Button>
                                        </div>
                                    </div>
                                </div>


                            </Box>

                            <Box sx={{
                                bgcolor: 'background.paper',
                                padding: '30px',
                                marginTop: '20px'
                            }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ fontWeight: 'bold' }}>Rate Product</div>
                                    <div><ChevronRightIcon /></div>
                                </div>
                            </Box>

                            {true && <Grid container spacing={2}>

                                <Grid item xs={8} sm={6}>
                                    <div
                                        onClick={() => this.props.navigation.navigate("Return")}
                                        style={{
                                            width: '100%',
                                            padding: '20px 0px 20px 0px',
                                            border: '2px solid #e3e3e4',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            marginTop: '15px'
                                        }}>Return</div>
                                </Grid>
                                <Grid item xs={8} sm={6}>
                                    <div onClick={() => this.props.navigation.navigate("Exchange")}
                                        style={{
                                            width: '100%', padding: '20px 0px 20px 0px',
                                            border: '2px solid #e3e3e4', display: 'flex', justifyContent: 'center',
                                            marginTop: '15px'
                                        }}>Exchange</div>

                                </Grid>
                            </Grid>}


                            {!true && <div style={{ display: 'flex', justifyContent: 'center' }}>

                                <div style={{ display: 'flex', justifyContent: 'center', padding: '5px', backgroundColor: '#e5f3fa', width: '425px', marginTop: '20px' }}>
                                    <span><Info color='primary' /></span>
                                    <span style={{ color: '#67bbe5', marginLeft: '5px', display: 'flex', alignItems: 'center' }}>Return/Exchange valid till 22 Apr 22, 10:00 A.M</span>
                                </div>
                            </div>}

                            {!true && <div onClick={() => this.setState({ toggleCancelOrder: true })} style={{ display: 'flex', justifyContent: 'center' }}>

                                <div style={{ width: '200px', padding: '20px 30px 20px 30px', border: '2px solid #e3e3e4', display: 'flex', justifyContent: 'center', marginTop: '15px' }}>Cancel Order</div>
                            </div>}
                        </Grid>



                    </Grid>
                    <VisionStatementWeb navigation={this.props.navigation} id={''} classes={undefined} openSearch={undefined}/>

                </Container>

                <div>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={this.state.toggleCancelOrder}
                        onClose={() => this.handleCloseModal()}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={this.state.toggleCancelOrder}>
                            <Box sx={style}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ fontWeight: 'bold', fontSize: '20px' }}>Cancel Order?</div>
                                    <span><CloseIcon /></span>
                                </div>

                                <div style={{ marginTop: '20px', color: '#9da4b3' }}>
                                    Do you wish to cancel your order?Note: Refunds may take 5-7 business days to reflect on your account
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                                    <Button variant='outlined' onClick={() => this.handleCloseModal()} style={{ padding: '15px 45px' }}>No</Button>
                                    <Button variant='contained' style={{ padding: '15px 45px', backgroundColor: '#dd403a', marginLeft: '10px', color: 'white' }} >Yes</Button>
                                </div>








                            </Box>
                        </Fade>
                    </Modal>
                </div>

            </>
        )
    }
}
// Customizable Area End