import React, { Component } from "react";
// Customizable Area Start

import {

  Grid,
  Box,

  Typography,

} from "@material-ui/core";
import Error from "@material-ui/icons/Error";
import Modal from "@material-ui/core/Modal";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";

import { createStyles, withStyles, Theme } from "@material-ui/core/styles";

import OtpInput from "react-otp-input";
import { BackGroundImg, BatteryHindi, tick } from "./assets";
import "./forgotpassword.css";

import OTPInputAuthController, {
  Props,
} from "../../otp-input-confirmation/src/OTPInputAuthController";

const styles = (theme: Theme) =>
  createStyles({
    alert_box: {
      position: "fixed",
      top: "35%",
      right: "40%",
      left: "40%",
      backgroundColor: "#fff",
      zIndex: 2000,
      padding: "35px",
      borderRadius: "15px",
    },
  });

class ForgotPasswordOTP extends OTPInputAuthController {
  id:any
  constructor(props: Props) {
    super(props);
    this.id=''
    
  }
  async componentDidMount() {
    this.id=setInterval(()=>{
      if(this.state.timer!=0){

        this.setState({timer:this.state.timer-1})
      }


    },1000);


    
    
  }
  
  async componentWillUnmount() {
   

      clearInterval(this.id)
    
    
  }
  handleChange = (otp: any) => {
    this.setState({ otpVal: otp });
    if (!this.state.otpVal) {
      this.setState({ showSignUpVerifyErrors: false });
    }
  };
 
  render() {
    const { navigation } = this.props;

    const { classes } = this.props;

    return (
      <>
        <div
          style={{
            backgroundColor: "#fff",
            backgroundImage: `url(${BackGroundImg})`,
            backgroundSize: "100% 100%",
            height: "100vh",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "30px",
              paddingTop:'100px'
            }}
          >
            <img src={BatteryHindi} />
          </div>
          <Grid item style={{ display: "flex", justifyContent: "center" }}>
            <div
              style={{
                width: "600px",
                height: "500px",
                borderRadius: "4px",
                backgroundColor: "#fff",
              }}
            >
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    display: "flex",
                    marginLeft: "25px",
                    marginTop: "25px",
                    cursor: "pointer",
                  }}
                  onClick={() => this.props.navigation.navigate("signUp")}
                >
                  <KeyboardBackspaceIcon />
                </div>
                <div
                  style={{
                    fontSize: "25px",
                    marginLeft: "120px",
                    marginTop: "25px",
                    marginBottom: '15px',
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    Verify mobile number
                  </div>
                </div>
              </div>
              <Typography
                style={{
                  color: "#808080",
                  textAlign: "center",
                  fontSize: "15px",

                }}
              >
                Please enter the verification code sent to your mobile number
              </Typography>
              <Grid container style={{ marginTop: "50px" }}>
                <Grid item xs={3} />
                <Grid item xs={7}>
                  <OtpInput
                    value={this.state.otpVal}
                    onChange={this.handleChange}

                    numInputs={4}
                    className="inputStyles"

                    inputStyle={{
                      width: "4rem",
                      height: "2.5rem",
                      margin: "0.5rem",
                      fontSize: "2rem",
                      borderRadius: 4,
                      border:  (this.state.otpVal && this.state.showSignUpVerifyErrors)|| this.state.isOtpBlank?"1px solid red":"1px solid #B0B0B0",
                      backgroundColor: (this.state.otpVal && this.state.showSignUpVerifyErrors)|| this.state.isOtpBlank?'#FDEFEF':'none'
                    }}
                  />
                  {this.state.otpVal && this.state.showSignUpVerifyErrors && (

                    <div
                      style={{
                        marginLeft: "100px",
                        marginTop: "0px",
                        marginBottom: "15px",
                        color: "red",

                        fontSize: "smaller",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ marginRight: "5px" }}>
                        <Error color="error" fontSize="small" />
                      </div>
                      <div>Code is wrong</div>
                    </div>

                  )}
                   {
                    this.state.isOtpBlank && <div
                      style={{
                        marginLeft: "100px",
                        marginTop: "0px",
                        marginBottom: "15px",
                        color: "red",

                        fontSize: "smaller",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ marginRight: "5px" }}>
                        <Error color="error" fontSize="small" />
                      </div>
                      <div> OTP required</div>
                    </div>

                  }
                 
                </Grid>
                <Grid item xs={2} />
              </Grid>
              <p
                    style={{
                      textAlign:'center',
                      color: "#808080",
                      fontSize: "15px",
                      
                    }}
                  >
                    00:{this.state.timer}
                  </p>
              <p
             
              onClick={()=>{
                if(this.state.timer==0){
                  this.setState({timer:30})
                }
              }}
                style={{
                  color: "#32cba1",
                  textAlign: "center",
                  fontSize: "15px",
                  marginTop: "80px",
                  cursor:'pointer'
                }}
              >
                Resend
              </p>

              <div
                style={{
                  width: "70%",
                  padding: "2px",
                  marginLeft: "90px",
                  backgroundColor: "#32cba1",
                  borderRadius: "4px",
                  textAlign: "center",
                  cursor: "pointer",
                }}
                // onClick={this.verifyOTP}
                onClick={()=>{
                  if (this.state.otpVal.length == 4) {
                    this.setState({ isOtpBlank: false })
                    this.verifyOTP()
                  }
                  else {
                    this.setState({ isOtpBlank: true })
                    this.setState({ showSignUpVerifyErrors: false })
                  }
                }}
              >
                <p style={{ fontWeight: "bold" }}> Verify {"&"} Register </p>
              </div>
            </div>
          </Grid>
        </div>

        <div>
          <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={this.state.signUpSuccess}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}

          // onClose={this.handleClose}
          >
            <div className={classes.alert_box}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img src={tick} width="60px" />
              </div>

              <div
                style={{
                  marginTop: "30px",
                  fontWeight: "bold",
                  fontSize: "x-large",
                }}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  Account Created
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  Successfully!
                </div>
              </div>

              <div style={{ marginTop: "30px" }}>
                <div
                  onClick={() => {
                    this.props.navigation.navigate('EmailAccountLoginBlock')

                  }}
                  style={{
                    cursor: 'pointer',
                    display: "flex",
                    justifyContent: "center",
                    backgroundColor: "#F3F3F3",
                    border: "1px solid gray",
                    padding: "15px",
                  }}
                >
                  Sign in
                </div>
              </div>
            </div>
          </Modal>
        </div>
        <div>
          <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={this.state.showSignUpErrors}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}

          // onClose={this.handleClose}
          >
            <div className={classes.alert_box}>
              <Typography style={{ textAlign: "center" }}>
                <b>First name {this.state.signUpErrorsData}</b>
              </Typography>
              <Box
                style={{
                  marginLeft: "30px",
                  marginTop: "20px",
                  width: "70%",
                  height: "70px",
                  backgroundColor: "#B0B0B0",
                  border: "1px solid black",
                  textAlign: "center",
                  cursor: "pointer",
                }}
                onClick={() => this.props.navigation.navigate("signUp")}
              >
                <p style={{ color: "red" }}>Go back to Registration page</p>
              </Box>
            </div>
          </Modal>
        </div>
      </>
    );
  }
}
export default withStyles(styles, { withTheme: true })(ForgotPasswordOTP);
// Customizable Area End
