import React from "react";


// Customizable Area Start
// Customizable Area End


// Customizable Area Start


import { Grid } from "@material-ui/core";

import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';

import {
  Card, CardContent, Container,
  Box,
  IconButton,
} from "@material-ui/core"


import VideosController, { Props, configJSON } from "./VideosController";
import VisionStatementWeb from "../../../components/src/VisionStatement.web";



const greenTick = require("../assets/greentick.png")
const yellowStar = require("../assets/yellow_star.png")



// Customizable Area End



export default class OnlineFactoryVisit extends VideosController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>

        <div >
         
        <div style={{ padding: '20px', backgroundColor: '#efefef' }}>
            <Container>

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                <div style={{ color: '#a1a0a4' }}>
                  <a href='/' style={{ textDecoration: 'none', color: '#a1a0a4' }}>Home</a> <ArrowForwardIosRoundedIcon className="arrow-left" /> Online Factory Visit
                </div>
                {!localStorage.getItem("loginSuccessToken") && <div style={{ color: '#a1a0a4' }}>
                  <span>
                    <i>For Better Experience</i>
                    <span onClick={() => {
                      this.props.navigation.navigate('EmailAccountLoginBlock')
                    }} style={{ color: "red", fontWeight: 'bold', cursor: 'pointer' }}> Login/</span><span onClick={() => {
                      this.props.navigation.navigate('signUp')
                    }} style={{ color: "red", fontWeight: 'bold', cursor: 'pointer' }}>Register</span>
                  </span>
                </div>
                }
              </div>
            </Container>
          </div>

         

          <Container>
            <Grid container>
              {this.state?.listFactoryVisitsData.map((item: any) => {
                return <Grid id='grid' key={item?.id} item md={12} style={{ margin: '20px 0px 20px 0px' }}>
                  <Box sx={{ bgcolor: 'background.paper', padding: '50px' }}>
                    <div style={{ display: 'flex',justifyContent:'space-between' }}>
                      <div style={{width:'600px'}}
                        dangerouslySetInnerHTML={{ __html: item?.attributes?.content }}
                      />
                    <div>

                      <video width='400px' height='300px' controls >
                        <source src={item?.attributes?.video} type="video/mp4" />
                      </video>
                    </div>

                    </div>

                  </Box>
                </Grid>



              })}



            </Grid>
            <VisionStatementWeb openSearch={undefined} classes={undefined} id={""} navigation={this.props.navigation}/>

          </Container>
          
        </div>


      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
