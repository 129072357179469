import React, { Component } from 'react'

import { Box, Container, Grid, Input, InputAdornment, Button, OutlinedInput } from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import { Card, CardHeader, CardContent, CardMedia, CardActions } from "@material-ui/core"
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import { NavLink } from "react-router-dom";

import MenuIcon from "@material-ui/icons/Menu";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import ExploreOutlinedIcon from "@material-ui/icons/ExploreOutlined";
import SlowMotionVideoOutlinedIcon from "@material-ui/icons/SlowMotionVideoOutlined";
import SubscriptionsOutlinedIcon from "@material-ui/icons/SubscriptionsOutlined";
import LibraryAddCheckIcon from "@material-ui/icons/LibraryAddCheck";
import HistoryIcon from "@material-ui/icons/History";
import OndemandVideoIcon from "@material-ui/icons/OndemandVideo";
import WatchLaterOutlinedIcon from "@material-ui/icons/WatchLaterOutlined";
import ThumbUpAltOutlinedIcon from "@material-ui/icons/ThumbUpAltOutlined";
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
// import logo from "../../Assests/youtubelogo.jpg";
import MicIcon from '@material-ui/icons/Mic';
import './Social1.css'

// import "./assets/styles.css"


// import {
//   createStyles,
//   withStyles,
//   makeStyles,
//   Theme,
// } from "@material-ui/core/styles";
const EzBatteryWhite = require("../assets/Artboard 1 copy@4x.png")
const greenTick = require("../assets/greentick.png")
const redBattery = require("../assets/red_battery.png")
const yellowStar = require("../assets/yellow_star.png")
const trippleLine = require("../assets/tripple_line.png")
const ezBattery = require("../assets/ez_battery.png")
const blackBattery = require("../assets/bla_battery.png")
const greenBattery = require("../assets/greenBattery.png")
const sosImage = require("../assets/sosImage.png")
const youtubeImg = require("../assets/Group.png")

const Styles = {
    inputSearchBox: {
        width: "25px",
        height: "35px",
        alignItems: "center",
        border: "1px solid #b30000",
        borderRadiusRight: "5px",
        backgroundColor: "#b30000",
        paddingRight: "10px",
        color: "white",
        cursor: "pointer"
    },
    cardhead: {
        font: "10px",
        color: "grayText",
        textDecoration: "underline",
        textDecorationColor: "gray",
        textDecorationThickness: "1px",
    }
}


import SocialMediaAccountWebController, {
    Props
} from "./SocialMediaAccountWebController";

export default class SocialMedia1 extends SocialMediaAccountWebController {
    render() {
        const { menus } = this.state

        return (
            <>
                <div style={{ overflowX: "hidden" }}>
                    <div style={{ display: "flex", flexDirection: "column", overflowX: "hidden", borderBottom: "5px solid #32cba1" }}>
                        <Grid container style={{ backgroundColor: "red", minHeight: "85px", maxHeight: "85px" }}>
                            <div style={{ width: "400px", marginRight: "30px" }}>
                                <div style={{ display: "flex", marginLeft: "200px" }}>
                                    <img src={trippleLine} style={{ width: "20px", height: "20px", marginTop: "30px", marginRight: "20px" }} />
                                    <NavLink
                                        exact
                                        activeClassName="active_class"
                                        to="/"
                                        style={{ textDecoration: "none" }}
                                    >
                                        <img src={EzBatteryWhite} style={{ width: "100px", height: "40px", color: "white", marginTop: "20px" }} />
                                    </NavLink>
                                </div>
                            </div>
                            {/* <div style={{ display:"flex", width: "440px",height:"30px",marginTop: "20px", marginRight:"30px",
               alignItems: "center",borderRadius: "5px", padding:"10px",border:"1px black",backgroundColor:"white"
             }}>
            
                <input type='text' style={{width:"410px",outline:"none",border: "none",background:"none"}}/> 
                <div style={{width:"40px",height:"50px",alignItems:"center",backgroundColor:"#b30000",borderRadius: "5px"}}>
                <SearchIcon style={{objectFit:"contain"}}/> 
             </div>         
         </div>  */}
                            <div style={{ marginTop: "20px" }}>
                                <Input
                                    id="input-with-icon-adornment"
                                    value={this.state.searchValue}
                                    placeholder='Search Battery type/Category/Model Name/Model Number'
                                    style={{
                                        width: "470px", border: "1px solid red", borderBlock: "none", backgroundColor: "white",
                                        borderRight: "2px solid #b30000",
                                        borderRadius: "4px"
                                    }}
                                    onChange={(e: any) => {
                                        this.setState({ searchValue: e.target.value })
                                    }}
                                    onClick={this.handleSearch}
                                    endAdornment={
                                        <InputAdornment position="end"
                                            style={Styles.inputSearchBox}
                                        >
                                            <SearchIcon />

                                        </InputAdornment>
                                    }
                                />
                                <div style={{ position: "relative", backgroundColor: "white" }}>
                                    {this.state.openSearch &&
                                        <div>
                                            <Typography style={{ padding: "10px", fontSize: "14px", cursor: "pointer" }}
                                                onClick={this.recentSearchCall}
                                            >
                                                Recent searches
                                            </Typography>

                                            {this.state.recentSearchData?.map((item: any, index: any) => {
                                                return (
                                                    <div key={index} style={{ display: "flex", marginTop: "-25px" }}>
                                                        <p style={{ marginLeft: "10px" }}>
                                                            {item?.search_term}
                                                        </p>
                                                        {item?.search_term &&
                                                            <p style={{ marginLeft: "355px", cursor: "pointer" }}
                                                                onClick={() => {
                                                                    let x = this.state.recentSearchData
                                                                    x[index].search_term = !x[index].search_term
                                                                    this.setState({ recentSearchData: [...x] })
                                                                    localStorage.setItem("recentSearchId", this.state.recentSearchData?.id);
                                                                    this.deleteRecentSearchCall(item.id)
                                                                }}>X</p>
                                                        }
                                                    </div>
                                                )
                                            })}



                                            <div style={{ display: "flex" }}>
                                                <a
                                                    href='Dashboard'
                                                    style={{ textDecoration: "none" }} >
                                                    <div style={{ width: "194px", margin: "10px", padding: "10px", backgroundColor: "#d6f5ed", borderRadius: "5px" }}>
                                                        <p style={{ textAlign: 'center', fontSize: "12px" }}>
                                                            <b>All Batteries</b>
                                                        </p>
                                                    </div>
                                                </a>
                                                <NavLink
                                                    exact
                                                    activeClassName="active_class"
                                                    to="/Filteritems"
                                                    style={{ textDecoration: "none" }}
                                                >
                                                    {/* <a href='Filteritems' style={{textDecoration:"none"}}> */}
                                                    <div style={{ margin: "10px", width: "195px", padding: "10px", backgroundColor: "#32cba1", borderRadius: "5px" }}
                                                    // onClick={()=>{this.setState({open:true})}}
                                                    >
                                                        <p style={{ textAlign: 'center', fontSize: "12px" }}>
                                                            <b>Need help with battery selection</b>
                                                        </p>
                                                    </div>
                                                </NavLink>
                                                {/* </a> */}
                                            </div>
                                            <Avatar
                                                style={{
                                                    width: "25px",
                                                    height: "25px",
                                                    fontSize: "20px",
                                                    margin: "17px 10px",
                                                    backgroundColor: "red",
                                                    marginLeft: "222px",
                                                    cursor: "pointer"
                                                }}
                                                onClick={() => this.setState({ openSearch: false })}
                                            >
                                                X
                                            </Avatar>
                                            {/* <Typography style={{ textAlign: "center" }}
                     >
                      <b>X</b>
                    </Typography> */}
                                        </div>
                                    }

                                </div>

                            </div>

                            <div>
                                <img src={sosImage} style={{ width: "40px", height: "30px", marginTop: "20px", marginLeft: "100px" }} />
                            </div>
                            <div style={{ display: "flex", color: "white", marginTop: "25px", marginLeft: "40px" }}>
                                <div style={{ marginRight: "30px" }}>
                                    <NotificationsNoneIcon />
                                </div>
                                {localStorage.getItem("loginSuccessToken") &&
                                    <ShoppingCartIcon style={{ cursor: "pointer" }}
                                        onClick={() => this.props.navigation.navigate("AddShoppingCartOrderItem")}
                                    />
                                }

                                {!localStorage.getItem("loginSuccessToken") &&
                                    <ShoppingCartIcon style={{ cursor: "pointer" }}
                                        onClick={() => this.props.navigation.navigate("LoginRequired")}
                                    />
                                }
                            </div>
                            <div>
                                <a
                                    href='EmailAccountLoginBlock'
                                    style={{ textDecoration: "none" }}
                                >
                                    <p style={{ color: "white", marginTop: "30px", marginLeft: "200px" }} >Login</p>
                                </a>
                            </div>

                        </Grid>
                        <Grid container style={{ backgroundColor: "#b30000", height: "40px" }}>

                            <p style={{ marginLeft: "170px", marginRight: "40px", color: "white", fontSize: "13px", cursor: "pointer" }}
                                onClick={() => this.props.navigation.navigate("Categoriessubcategories")}>
                                2 WHEELER BATTERIES</p>
                            <p style={{ marginRight: "40px", color: "white", fontSize: "13px", cursor: "pointer" }}
                                onClick={() => this.props.navigation.navigate("ThreeWheeler")}>
                                3 WHEELER BATTERIES</p>

                            <p style={{ marginRight: "40px", color: "white", fontSize: "13px", cursor: "pointer" }}
                                onClick={() => this.props.navigation.navigate("CarSuvMuvBatteries")}
                            >CAR/SUV/MUV BATTERIES</p>
                            <p style={{ marginRight: "40px", color: "white", fontSize: "13px", cursor: "pointer" }}
                                onClick={() => { this.props.navigation.navigate("CommercialVehicle") }}
                            >COMMERCIAL VEHICLE BATTERIES</p>
                            <p style={{ marginRight: "40px", color: "white", fontSize: "13px", cursor: "pointer" }}
                                onClick={() => { this.props.navigation.navigate("TractorDashboard") }}
                            >TRACTOR BATTERIES</p>
                            <p style={{ marginRight: "40px", color: "white", fontSize: "13px", cursor: "pointer" }}
                                onClick={() => { this.props.navigation.navigate("InverterDashboard") }}
                            >INVERTER BATTERIES</p>
                        </Grid>

                        <Box style={{ background: "lightgrey", padding: "10px", height: "24px" }}>
                            <Container style={{ display: "flex" }} >
                                <Typography style={{ marginLeft: "20px" }}> Home <ArrowForwardIosRoundedIcon className="arrow-left" /> 3 Wheeler Batteries
                                </Typography>
                                {!localStorage.getItem("loginSuccessToken") &&
                                    <Typography style={{ display: "flex", marginLeft: "700px" }}>For better experience &nbsp;
                                        <p onClick={() => this.props.navigation.navigate("EmailAccountLoginBlock")}
                                            style={{ color: "red", marginTop: "0px", cursor: "pointer" }}>Login/</p>
                                        <p style={{ color: "red", marginTop: "0px", cursor: "pointer" }}
                                            onClick={() => this.props.navigation.navigate("signUp")}
                                        >Register</p>
                                    </Typography>
                                }
                            </Container>
                        </Box>

                        {/* {this.state.showSearchData && this.state.openSearch && */}
                        <div style={{ marginTop: "165px", marginLeft: "155px", marginRight: "155px" }}>
                            <Grid container style={{ display: "flex", flexDirection: "row", position: "relative", }} >
                                {/* <Grid item xs={2} /> */}
                                {this.state.serachData?.map((item: any, index: any) => {
                                    return (
                                        <Grid item xs={3} key={index} >


                                            <Box m={1}>
                                                <Card style={{ width: "250px", height: "400px" }} >
                                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                                        <img style={{ width: "10%", height: "10%", marginLeft: "10px", marginTop: "10px" }} src={greenTick} />
                                                        <p style={Styles.cardhead}>{item?.attributes?.warranty} warranty</p>

                                                        <IconButton aria-label="settings" style={{ marginTop: "5px" }}>
                                                            <FavoriteBorderIcon />
                                                        </IconButton>

                                                    </div>

                                                    <CardContent>
                                                        <img style={{ display: "block", marginLeft: "60px", marginBottom: "15px", width: "50%", height: "30%" }} src={item?.attributes?.thumbnail_image} />
                                                        <div style={{ marginLeft: "30px" }}>
                                                            {/* <div style={{ width: "80%", border: "1px solid white", borderRadius: "10px", backgroundColor: "#ff9999", textAlign: "center" }}>
                                Category:Inverter
                              </div> */}
                                                        </div>
                                                        <p style={{ textAlign: "center" }}>{item?.attributes?.model_no}</p>
                                                        <p style={{ textAlign: "center" }}>{item?.attributes?.capaciy}</p>
                                                        <p style={{ textAlign: "center" }}>${item?.attributes?.amount}</p>
                                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                                            <div style={{ width: "35px", height: "20px", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", border: "1px solid gray", borderRadius: "10px" }}>
                                                                <img style={{ width: "10px", height: "10px" }} src={yellowStar} />
                                                                <p>{item?.attributes?.range}</p>
                                                            </div>
                                                            <div style={{ marginLeft: "10px" }}>
                                                                <div style={{ width: "70px", border: "1px solid gray", borderRadius: "10px", backgroundColor: "green" }}>
                                                                    {item?.attributes?.applied_discount}% off
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </CardContent>
                                                </Card>
                                            </Box>
                                        </Grid>


                                    )

                                })

                                }
                            </Grid>
                        </div>
                        {/* } */}

                        <Box style={{ backgroundColor: "#F4F5F6", marginTop: "-170px" }}>

                            <div >

                                <Box p={2}>
                                    <Grid container justifyContent="center">
                                        <Grid item xs={2} style={{ background: "#F3F5F7" }}>
                                            <Box bgcolor="#fff">
                                                <Box display="flex" alignItems="center" justifyContent="center">
                                                    <MenuIcon style={{ width: "25px", height: "25px",marginLeft:"10px"}} />
                                                    <Box justifySelf="end" style={{ display: "flex", flexDirection: "row" }} >
                                                        <img
                                                            src={youtubeImg}
                                                            style={{ width: "19%", height: "50%", marginTop:"23px"}}
                                                        />
                                                        <h1>YouTube</h1>
                                                        <h4>IN</h4>
                                                    </Box>
                                                </Box>
                                                <Box borderBottom="1px solid lightgrey">
                                                    {youtubeMenu1.map((item) => {
                                                        return (
                                                            <Box
                                                                display="grid"
                                                                style={{
                                                                    gridTemplateColumns: "30px 1fr",
                                                                    justifyItems: "center",
                                                                    gridGap: "20px",
                                                                    paddingBottom: "20px",
                                                                }}
                                                            >
                                                                <Typography className="yutb_icons">
                                                                    {item.icons}
                                                                </Typography>
                                                                <Typography style={{ justifySelf: "start" }}>
                                                                    {item.name}
                                                                </Typography>
                                                            </Box>
                                                        );
                                                    })}
                                                </Box>
                                                <Box borderBottom="1px solid lightgrey" mt={3}>
                                                    {youtubeMenu2.map((item) => {
                                                        return (
                                                            <Box
                                                                display="grid"
                                                                style={{
                                                                    gridTemplateColumns: "30px 1fr",
                                                                    justifyItems: "center",
                                                                    gridGap: "20px",
                                                                    paddingBottom: "20px",
                                                                }}
                                                            >
                                                                <Typography className="yutb_icons">
                                                                    {item.icons}
                                                                </Typography>
                                                                <Typography style={{ justifySelf: "start" }}>
                                                                    {item.name}
                                                                </Typography>
                                                            </Box>
                                                        );
                                                    })}
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={10}>
                                            <Container style={{ padding: "0px" }}>
                                                <Box mb={3}>
                                                    <Grid container justifyContent="center">
                                                        <Grid item xs={8} style={{ justifySelf: "center" }}>
                                                            <Box display="flex" alignItems="center">
                                                                <Box>
                                                                    <OutlinedInput
                                                                        style={{ width: "400px", height: "40px" }}
                                                                        id="outlined-adornment-weight"
                                                                        aria-describedby="outlined-weight-helper-text"
                                                                    />
                                                                    <Button variant="contained" style={{ padding: "7px", border: "1px solid lightgrey" }}>
                                                                        <SearchIcon style={{ width: "25px", height: "25px" }} />
                                                                    </Button>
                                                                </Box>
                                                                <MicIcon style={{ width: "25px", height: "25px", marginLeft: "20px" }} />
                                                            </Box>

                                                        </Grid>
                                                        <Grid item xs={1}>
                                                            <Box display="flex" justifyContent="space-between">
                                                                <VideocamOutlinedIcon style={{ width: "25px", height: "25px" }} />
                                                                <NotificationsNoneOutlinedIcon style={{ width: "25px", height: "25px" }} />
                                                            </Box>
                                                        </Grid>
                                                    </Grid>

                                                    <Box></Box>              </Box>
                                                <Box bgcolor="#DE403A" height="150px">
                                                    <img src={EzBatteryWhite} style={{ margin: "40px 490px" }} />
                                                </Box>
                                                <Box bgcolor="#F9F9F9" style={{ padding: "20px" }}>
                                                    <Box>
                                                        <Box
                                                            display="flex"
                                                            justifyContent="space-between"
                                                            alignItems="center"
                                                        >
                                                            <Box display="flex" alignItems="center">
                                                                <Box
                                                                    style={{
                                                                        border: "2px solid grey",
                                                                        width: "75px",
                                                                        height: "75px",
                                                                        borderRadius: "50%",
                                                                    }}
                                                                >
                                                                    <img src={ezBattery} style={{ width: "40%", height: "40%", padding: "30px" }} />

                                                                </Box>
                                                                <Box paddingLeft="20px">
                                                                    <Typography variant="h5">Dunzo</Typography>
                                                                    <Typography variant="body2">29.9k subscribe</Typography>
                                                                </Box>
                                                            </Box>
                                                            <Button
                                                                style={{
                                                                    background: "#CC0101",
                                                                    width: "150px",
                                                                    height: "40px",
                                                                    color: "#F9CBCB",
                                                                }}
                                                            >
                                                                Subscribe
                                                            </Button>
                                                        </Box>
                                                    </Box>
                                                    <Box
                                                        bgcolor="#F9F9F9"
                                                        display="flex"
                                                        alignItems="center"
                                                        justifyContent="space-between"
                                                        padding="10px"
                                                    >
                                                        <Grid
                                                            container
                                                            alignItems="center"
                                                            spacing={8}
                                                            style={{ paddingBottom: "0px" }}
                                                        >
                                                            <Grid item xs={8} style={{ paddingBottom: "0px" }}>
                                                                <Box className="menus" mt={2}>
                                                                    {menusData.map((item) => {
                                                                        return (
                                                                            <Button
                                                                                onClick={() =>
                                                                                    this.setState({ menus: item.name })
                                                                                }
                                                                                style={{
                                                                                    borderBottom:
                                                                                        item.name === menus
                                                                                            ? "2px solid #666666"
                                                                                            : "",
                                                                                    borderRadius: "0px",
                                                                                }}
                                                                            >
                                                                                {item.name}
                                                                            </Button>
                                                                        );
                                                                    })}
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={4} style={{ paddingBottom: "0px" }}>
                                                                <Box
                                                                    display="flex"
                                                                    justifyContent="space-between"
                                                                    alignItems="center"
                                                                    marginTop="15px"
                                                                >
                                                                    <SearchIcon style={{ width: "20px", height: "20px" }} />
                                                                    <ArrowForwardIosIcon />
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Box>
                                                <Box padding={2} bgcolor="#F1F1F1">
                                                    {menus === "HOME" ? (
                                                        <Box>
                                                            <Box
                                                                style={{
                                                                    borderBottom: "3px solid grey",
                                                                    paddingBottom: "20px",
                                                                }}
                                                            >
                                                                <Grid container>
                                                                    <Grid item xs={4}>
                                                                        <video width="350" height="230" controls autoPlay={true} muted playsInline >
                                                                            <source src="https://www.youtube.com/channel/UCiPuAcSBrDy2odvPLyhVqUw" type="video/mp4"></source>
                                                                        </video>
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <Grid container>
                                                                            <Grid item xs={2}>
                                                                                <Box
                                                                                    style={{
                                                                                        border: "2px solid grey",
                                                                                        width: "50px",
                                                                                        height: "50px",
                                                                                        borderRadius: "50%",
                                                                                    }}
                                                                                >
                                                                                    <img
                                                                                        src={ezBattery}
                                                                                        style={{
                                                                                            width: "27px",
                                                                                            height: "20px",
                                                                                            padding: "12px",
                                                                                        }}
                                                                                    />
                                                                                </Box>
                                                                            </Grid>
                                                                            <Grid item xs={8}>
                                                                                <Typography
                                                                                    variant="h5"
                                                                                    style={{ fontWeight: "bold", fontSize: "18px" }}
                                                                                >
                                                                                    {" "}
                                                                                    How to select the best battery for your for your
                                                                                    vehicle
                                                                                </Typography>
                                                                                <Typography
                                                                                    variant="subtitle2"
                                                                                    style={{ color: "grey", lineHeight: "40px" }}
                                                                                >
                                                                                    EZ battery . 101 view . 2years ago
                                                                                </Typography>
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    style={{ color: "#A3A3A3" }}
                                                                                >
                                                                                    Lorem Ipsum is simply dummy text of the printing
                                                                                    and typesetting industry. Lorem Ipsum has been
                                                                                    the industry's{" "}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Box>
                                                            <Box marginTop={2}>
                                                                <Box display="flex">
                                                                    <Typography
                                                                        variant="h3"
                                                                        style={{ fontSize: "18px", fontWeight: "bold" }}
                                                                    >
                                                                        Uploads
                                                                    </Typography>
                                                                    <ArrowRightIcon
                                                                        style={{ width: "23px", height: "23px" }}
                                                                    />
                                                                    <Typography
                                                                        variant="h3"
                                                                        style={{
                                                                            fontSize: "18px",
                                                                            fontWeight: "bold",
                                                                            color: "grey",
                                                                        }}
                                                                    >
                                                                        PLAY ALL
                                                                    </Typography>
                                                                </Box>
                                                                <Box marginTop={4}>
                                                                    <Box
                                                                        style={{ display: "flex", justifyContent: "space-between" }}
                                                                    >
                                                                        {uploadData.map((item) => {
                                                                            return (
                                                                                <Box>
                                                                                    <video width="96%" height="150" controls>
                                                                                        <source src={item.video} type="video/mp4" />
                                                                                    </video>
                                                                                    <Typography
                                                                                        variant="h3"
                                                                                        style={{
                                                                                            fontSize: "15px",
                                                                                            fontWeight: "bold",
                                                                                            lineHeight: "20px",
                                                                                            marginTop: "20px",
                                                                                        }}
                                                                                    >
                                                                                        {item.name}
                                                                                    </Typography>
                                                                                    <Typography
                                                                                        variant="subtitle1"
                                                                                        style={{
                                                                                            fontSize: "12px",
                                                                                            color: "grey",
                                                                                            marginTop: "10px",
                                                                                        }}
                                                                                    >
                                                                                        {item.views}
                                                                                    </Typography>
                                                                                </Box>
                                                                            );
                                                                        })}
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    ) : (
                                                        <>
                                                            {menus === "VIDEOS" ? (
                                                                <>
                                                                    <h1>videos</h1>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <h1>PLAYLIST</h1>
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </Box>
                                            </Container>
                                        </Grid>
                                    </Grid>
                                </Box>

                            </div>


                        </Box>-
                    </div>

                    <div>
                        <Grid container style={{ display: "flex", height: "300px" }}>

                            <Grid item xs={1} />
                            <Grid item xs={2} style={{ marginLeft: "30px" }} >
                                <img src={ezBattery} style={{ width: "100px", height: "40px", color: "white", marginTop: "20px" }} />
                            </Grid>
                            <Grid item xs={2} >
                                <p><strong>Product Categories</strong></p>

                                <p
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.props.navigation.navigate("Categoriessubcategories")}>
                                    2 Wheeler Batteries</p>


                                <p
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.props.navigation.navigate("ThreeWheeler")}>
                                    3 Wheeler Batteries</p>

                                <p
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.props.navigation.navigate("CarSuvMuvBatteries")}>
                                    Car/SUV/MUV Batteries</p>
                            </Grid>
                            <Grid item xs={2} style={{ marginTop: "30px" }}>
                                <p
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.props.navigation.navigate("CommercialVehicle")}>
                                    commercial vehicles</p>
                                <p
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.props.navigation.navigate("TractorDashboard")}>
                                    Tractor Batteries</p>
                                <p
                                    style={{ cursor: "pointer" }}
                                    onClick={() => { this.props.navigation.navigate("InverterDashboard") }}
                                >Inverter Batteries</p>
                            </Grid>
                            <Grid item xs={2}>
                                <p><strong>Company</strong></p>
                                <p>About Us</p>
                                <p>Awards&Certifications</p>
                                <div style={{ display: "flex" }}>
                                    <p style={{ marginRight: "10px" }}>careers</p> <br /> <p style={{ height: "20px", backgroundColor: "#d6f5ec", textAlign: "center" }}>We are hiring </p>
                                </div>
                                <p>Testimonials</p>
                                <p>Contact Us</p>
                                <p
                                    onClick={() => this.props.navigation.navigate("Feedback")}
                                    style={{ cursor: "pointer" }}
                                >
                                    Feedback
                                </p>
                            </Grid>
                            <Grid item xs={2}>
                                <p><strong>Follow Us</strong></p>
                                <div style={{ display: "flex" }}>
                                    <TwitterIcon style={{ color: "gray" }} />
                                    <YouTubeIcon style={{ color: "gray" }} />
                                    <CameraAltIcon style={{ color: "gray" }} />
                                </div>
                            </Grid>

                            <Grid item xs={1} />
                        </Grid>
                    </div>
                    <div>
                        <Grid container>
                            <Grid item xs={1} />
                            <Grid item xs={10}>
                                <div style={{ width: "90%", borderBottom: "3px solid gray" }} />
                                <div style={{ display: "flex" }}>
                                    <p>@2022 ALL RIGHTS RESERVED BY EZ BATTERY</p>
                                    <a
                                        href='TermsCondition'
                                        style={{ textDecoration: "none" }}
                                    >
                                        <p style={{ marginLeft: "100px", marginRight: "200px" }}>TERMS & CONDITIONS . Privacy Policy</p>
                                    </a>
                                    <p>Language English</p>
                                </div>
                            </Grid>
                            <Grid item xs={1} />
                        </Grid>
                    </div>
                </div>
            </>
        )
    }
}

export const menusData = [
    {
        name: "HOME",
    },
    {
        name: "VIDEOS",
    },
    {
        name: "PLAYLIST",
    },
    {
        name: "COMMUNITY",
    },
    {
        name: "CHANNELS",
    },
    {
        name: "ABOUT",
    },
]


export const uploadData = [
    {
        video: "movie.mp4",
        name: "Lorem Ipsum is simply dummy text",
        views: "200 view. 1 Month ago"

    },
    {
        video: "movie.mp4",
        name: "Lorem Ipsum is simply dummy text",
        views: "200 view. 1 Month ago"
    },
    {
        video: "movie.mp4",
        name: "Lorem Ipsum is simply dummy text",
        views: "200 view. 1 Month ago"
    },
    {
        video: "movie.mp4",
        name: "Lorem Ipsum is simply dummy text",
        views: "200 view. 1 Month ago"
    },
    {
        video: "movie.mp4",
        name: "Lorem Ipsum is simply dummy text",
        views: "200 view. 1 Month ago"
    },
]
const youtubeMenu1 = [
    {
        icons: <HomeOutlinedIcon />,
        name: "Home",
    },
    {
        icons: <ExploreOutlinedIcon />,
        name: "Explore",
    },
    {
        icons: <SlowMotionVideoOutlinedIcon />,
        name: "Shorts",
    },
    {
        icons: <SubscriptionsOutlinedIcon />,
        name: "Subscriptions",
    },
];

const youtubeMenu2 = [
    {
        icons: <LibraryAddCheckIcon />,
        name: "Library",
    },
    {
        icons: <HistoryIcon />,
        name: "History",
    },
    {
        icons: <OndemandVideoIcon />,
        name: "Your Videos",
    },
    {
        icons: <WatchLaterOutlinedIcon />,
        name: "Watch later",
    },

    {
        icons: <ThumbUpAltOutlinedIcon />,
        name: "Liked videos",
    },
];