import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";


// Customizable Area Start
import { boolean } from "yup";
// Customizable Area End

export const configJSON = require("./config.js");
const {baseURL:apiBaseUrl}=require('./../../../framework/src/config')

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  openSearch: boolean;
  searchValue: any;
  serachData: any;
  showSearchData: boolean;
  openProfile: boolean;
  open: boolean;
  recentSearchData: any;
  recentSearchCross: boolean;
  allInverterCapacityData: any;
  phone_number: any;
  allBatteriesData: any;
  vehicleBatteriesData: any;
  getSubCategoriesData: any;
  uniqueSubcategoriesData: any;
  uniqueManufacturerData: any;
  uniqueModelData: any;
  uniqueFuelData: any;
  getManufacturerData: any;
  subCategoryData: any;
  modelData: any;
  fuelData: any;
  categorySelect: boolean;
  inverterBatteriesData: any;
  batteryType: any;
  dashboardData: any;
  allFilterSelect: boolean;
  allFilterReject: boolean;
  WheelerSelect: boolean;
  getManufacturerSelect: boolean;
  modelSelect: boolean;
  fuelSelect: boolean;
  invertype: any;
  capacity: any;
  DCInputType: any;
  BackUpType: any;
  capacityType: any;
  sortType: any;
  inverterData850: any;
  inverterInputVoltageData: any;
  inverterBackupTimeData: any;
  inverterBatteryCapacityData: any;
  Select850: boolean;
  SelectInputVoltage: boolean;
  selectBackupTime: boolean;
  selectBatteryCapacity: boolean;
  token: string;
  errorMsg: string;
  loading: boolean;
  selectCategory: any;
  selectManufacturer: any;
  selectModel: any;
  selectFuel: any;
  showManufacurerList: boolean;
  showModelList: boolean;
  showFuelList: boolean;
  sortbyvehicleRecentlyAddedData: any;
  sortbyPriceLowtoHighData: any;
  sortbyPriceHightoLowData: any;
  sortbyAtoZData: any;
  sortbyvehicleRecentlyAddedSelect: boolean;
  sortbyPriceLowtoHigSelect: boolean;
  sortbyPriceHightoLowSelect: boolean;
  sortbyAtoZSelect: boolean;
  prevCheck: any
  sortedData: any;
  inverterCapacity: any;
  dcInputVoltage: any;
  backUpTime: any;
  batteryCapacity: any
  wishlistedItems:any;
  isLoading:boolean;
  isAll:boolean
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  apiSendOTPCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);
    //this.sendOTP = this.sendOTP.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      openSearch: false,
      searchValue: "",
      serachData: [],
      showSearchData: false,
      openProfile: false,
      open: false,
      recentSearchData: [],
      recentSearchCross: false,
      allInverterCapacityData: [],
      phone_number: "",
      dashboardData: [],
      allBatteriesData: [],
      vehicleBatteriesData: [],
      getSubCategoriesData: [],
      uniqueSubcategoriesData: [],
      uniqueManufacturerData: [],
      uniqueModelData: [],
      uniqueFuelData: [],
      inverterBatteriesData: [],
      getManufacturerData: [],
      subCategoryData: [],
      modelData: [],
      fuelData: [],
      categorySelect: false,
      allFilterSelect: false,
      allFilterReject: true,
      WheelerSelect: false,
      getManufacturerSelect: false,
      modelSelect: false,
      fuelSelect: false,
      batteryType: "",
      invertype: "",
      capacity: "",
      DCInputType: "",
      BackUpType: "",
      capacityType: "",
      sortType: "recently_added",
      inverterData850: [],
      inverterInputVoltageData: [],
      inverterBackupTimeData: [],
      inverterBatteryCapacityData: [],
      Select850: false,
      SelectInputVoltage: false,
      selectBackupTime: false,
      selectBatteryCapacity: false,
      errorMsg: "",
      token: "",
      loading: false,
      selectCategory: [],
      selectManufacturer: [],
      selectModel: [],
      selectFuel: [],
      showManufacurerList: false,
      showModelList: false,
      showFuelList: false,
      sortbyvehicleRecentlyAddedData: [],
      sortbyPriceLowtoHighData: [],
      sortbyPriceHightoLowData: [],
      sortbyAtoZData: [],
      sortbyvehicleRecentlyAddedSelect: false,
      sortbyPriceLowtoHigSelect: false,
      sortbyPriceHightoLowSelect: false,
      sortbyAtoZSelect: false,
      prevCheck: [],
      sortedData: [],
      inverterCapacity: "",
      dcInputVoltage: "",
      backUpTime: "",
      batteryCapacity: '',
      wishlistedItems:[],
      isLoading:false,
      isAll:true
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getAllBatteries();
    this.getVehicleBatteriesCall();
    this.getInverterCapacityCall();
    this.getSubCategoriesCall();
    this.getInverterBatteriesCall()
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener('willFocus', () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    // Customizable Area End
  }

  handleSearch = () => {
    this.setState({ openSearch: true })
    if (this.state.searchValue !== "") {
      this.getSearchCall();
    } else {
      return
    }
  }

  getSearchCall = () => {
    let TOKEN: any = localStorage.getItem("loginSuccessToken")

    let myHeaders = new Headers();

    myHeaders.append("token", TOKEN);
    myHeaders.append("Content-Type", "application/json");



    fetch(`${apiBaseUrl}/bx_block_fedex_integration/search_data?search=${this.state.searchValue}`, {
      method: 'GET',
      headers: myHeaders,
    })
      .then(response => response.json())
      .then(result => {
        console.log("search result milrahe", result)
        this.setState({ showSearchData: true, serachData: result?.data })
      })
      .catch(error => console.log('error', error));
  }


  recentSearchCall = () => {
    let TOKEN: any = localStorage.getItem("loginSuccessToken")

    let myHeaders = new Headers();
    myHeaders.append("token", TOKEN);
    myHeaders.append("Content-Type", "application/json");



    fetch(`${apiBaseUrl}/bx_block_fedex_integration/user_search`, {
      method: 'GET',
      headers: myHeaders,
    })
      .then(response => response.json())
      .then(result => {
        console.log(result)
        this.setState({ recentSearchData: result?.data })
        // localStorage.setItem("recentSearchId",result?.data?.id)
      })
      .catch(error => console.log('error', error));
  }

  deleteRecentSearchCall = (ItemId: any) => {
    let TOKEN: any = localStorage.getItem("loginSuccessToken")
    // let RecentSearchID = localStorage.getItem("recentSearchId")

    let myHeaders = new Headers();
    myHeaders.append("token", TOKEN);
    myHeaders.append("Content-Type", "application/json");


    fetch(`${apiBaseUrl}/bx_block_fedex_integration/user_search/${ItemId}`, {
      method: 'DELETE',
      headers: myHeaders,
    })
      .then(response => response.json())
      .then(result => {
        console.log(result)
        this.setState({ recentSearchCross: true })
      })
      .catch(error => console.log('error', error));
  }

  getInverterCapacityCall = () => {

    fetch(apiBaseUrl+"/bx_block_filter_items/battery_type?id=2", {
      method: 'GET',
    })
      .then(response => response.json())
      .then(result => {
        console.log(result)
        this.setState({ allInverterCapacityData: result?.data })
      })
      .catch(error => console.log('error', error));
  }

  getToken = () => {
    const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(msg);
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dashboardGetUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  getAllBatteries = () => {
    if( localStorage.getItem("loginSuccessToken")){

      let TOKEN: any = localStorage.getItem("loginSuccessToken")
     
      let myHeaders = new Headers();
      myHeaders.append("token", TOKEN);
  
      fetch(`${apiBaseUrl}/bx_block_filter_items/filter?sort_by=${this.state.sortType}`, {
        method: 'GET',
        headers:myHeaders
      })
        .then(response => response.json())
        .then(result => {
          console.log(result)
          this.setState({ allBatteriesData: result.data })
        })
        .catch(error => console.log('error', error));
    }
    else{
      
      fetch(apiBaseUrl+"/bx_block_filter_items/filter", {
        method: 'GET',
        
      })
        .then(response => response.json())
        .then(result => {
          console.log(result)
          this.setState({ allBatteriesData: result.data })
        })
        .catch(error => console.log('error', error));

    }
  }


  getVehicleBatteriesCall = () => {
    
this.setState({isLoading:true})
      
      let myHeaders = new Headers();
      if(localStorage.getItem("loginSuccessToken")){
        let TOKEN: any = localStorage.getItem("loginSuccessToken")??''

        myHeaders.append("token", TOKEN);
      }
      // baseurl+"/bx_block_filter_items/filter?battery_type=1"
    
      fetch(`${apiBaseUrl}/bx_block_filter_items/filter?battery_type=1&sub_cat_id=&man_id=&model_id=&fuel=&sort_by=${this.state.sortType}`, {
        method: 'GET',
        headers:myHeaders
      })
        .then(response => response.json())
        .then(result => {
          console.log(result)
          this.setState({ vehicleBatteriesData: result?.data, allFilterSelect: false });
          this.setState({isLoading:false})
        })
        .catch(error => {console.log('error', error);this.setState({isLoading:false})});
    
  }

  getInverterBatteriesCall = () => {
    this.setState({isLoading:true})
    let myHeaders = new Headers();
      if(localStorage.getItem("loginSuccessToken")){
        let TOKEN: any = localStorage.getItem("loginSuccessToken")??''

        myHeaders.append("token", TOKEN);
      }

    fetch(apiBaseUrl+"/bx_block_filter_items/filter?battery_type=2", {
      method: 'GET',
      headers:myHeaders
    })
      .then(response => response.json())
      .then(result => {
        console.log(result)
        this.setState({ inverterBatteriesData: result?.data,isLoading:false });

      })
      .catch(error => {console.log('error', error);this.setState({isLoading:false})});
  }



  async receive(from: string, message: Message) {
    // Customizable Area Start
    //console.log("@@@ MESSAGE EMAIL ACCOUNT REGISTRATION ===", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );


      if (apiRequestCallId === this.apiSendOTPCallId) {

        console.log(responseJson, " this is responce 1");

        if (responseJson?.errors) {
          this.showAlert("Error", responseJson?.error);
        } else {

          console.log("delete");

        }
      }
    }

    // Customizable Area End 
  }

  getInverterCall850 = (invCap: any) => {
    this.setState({isLoading:true})
    let InverterC = localStorage.getItem("InverterCapacity") ?? '';
    let InputVolt = localStorage.getItem("InputVoltage") ?? '';
    let BackUpTime = localStorage.getItem("backUptime") ?? '';
    let BatteryCapacity = localStorage.getItem("batteryCapacity") ?? '';
    let myHeaders = new Headers();
    if(localStorage.getItem("loginSuccessToken")){
      let TOKEN: any = localStorage.getItem("loginSuccessToken")??''

      myHeaders.append("token", TOKEN);
    }

    console.log(InverterC)
    // https://batteries1-180772-ruby.b180772.dev.eastus.az.svc.builder.cafe/bx_block_filter_items/filter?battery_type=2&inverter_capacity=${InverterC}&input_voltage=${InputVolt}&backup_time=${BackUpTime}&battery_capacity=${BatteryCapacity}&sort_by=${this.state.sortType}

    fetch(`${apiBaseUrl}/bx_block_filter_items/filter?battery_type=2&inverter_capacity=${invCap ?? this.state.inverterCapacity}&input_voltage=${this.state.dcInputVoltage}&backup_time=${this.state.backUpTime}&battery_capacity=${this.state.batteryCapacity}&sort_by=${this.state.sortType}`, {
      method: 'GET',
      headers:myHeaders
    })
      .then(response => response.json())
      .then(result => {
        console.log(result);
        this.setState({ inverterBatteriesData: result?.data });
        this.setState({isLoading:false})
        // this.setState({
        //   inverterData850: result?.data, allFilterSelect: true, Select850: true,
        //   SelectInputVoltage: false, selectBackupTime: false, selectBatteryCapacity: false
        // })
      })
      .catch(error =>{ console.log('error', error); this.setState({isLoading:false})});
  }

  getInputVoltageCall = (dcVol: any) => {
    this.setState({isLoading:true})
    let myHeaders = new Headers();
      if(localStorage.getItem("loginSuccessToken")){
        let TOKEN: any = localStorage.getItem("loginSuccessToken")??''

        myHeaders.append("token", TOKEN);
      }


    fetch(`${apiBaseUrl}/bx_block_filter_items/filter?battery_type=2&inverter_capacity=${this.state.inverterCapacity}&input_voltage=${dcVol ?? this.state.dcInputVoltage}&backup_time=${this.state.backUpTime}&battery_capacity=${this.state.batteryCapacity}&sort_by=${this.state.sortType}`, {
      method: 'GET',
      headers:myHeaders
    })
      .then(response => response.json())
      .then(result => {
        console.log(result);
        this.setState({ inverterBatteriesData: result?.data });
        this.setState({isLoading:false})
        // this.setState({
        //   inverterInputVoltageData: result?.data, allFilterSelect: true,
        //   selectBackupTime: false,
        //   Select850: false,
        //   SelectInputVoltage: true,
        //   selectBatteryCapacity: false
        // })
      })
      .catch(error =>{ console.log('error', error); this.setState({isLoading:false})});
  }
  getBackupTimeCall = (backupTime: any) => {
    this.setState({isLoading:true})
    let myHeaders = new Headers();
    if(localStorage.getItem("loginSuccessToken")){
      let TOKEN: any = localStorage.getItem("loginSuccessToken")??''

      myHeaders.append("token", TOKEN);
    }


    fetch(`${apiBaseUrl}/bx_block_filter_items/filter?battery_type=2&inverter_capacity=${this.state.inverterCapacity}&input_voltage=${this.state.dcInputVoltage}&backup_time=${backupTime ?? this.state.backUpTime}&battery_capacity=${this.state.batteryCapacity}&sort_by=${this.state.sortType}`, {
      method: 'GET',
      headers:myHeaders
    })
      .then(response => response.json())
      .then(result => {
        console.log(result);
        this.setState({ inverterBatteriesData: result?.data });
        this.setState({isLoading:false})
        // this.setState({
        //   inverterBackupTimeData: result?.data, allFilterSelect: true,
        //   selectBackupTime: true,
        //   Select850: false,
        //   SelectInputVoltage: false,
        //   selectBatteryCapacity: false
        // })
      })
      .catch(error =>{console.log('error', error); this.setState({isLoading:false})});
  }

  getBatteryCapacityCall = (battCap: any) => {
    this.setState({isLoading:true})
    let myHeaders = new Headers();
    if(localStorage.getItem("loginSuccessToken")){
      let TOKEN: any = localStorage.getItem("loginSuccessToken")??''

      myHeaders.append("token", TOKEN);
    }


    fetch(`${apiBaseUrl}/bx_block_filter_items/filter?battery_type=2&inverter_capacity=${this.state.inverterCapacity}&input_voltage=${this.state.dcInputVoltage}&backup_time=${this.state.backUpTime}&battery_capacity=${battCap ?? this.state.batteryCapacity}&sort_by=${this.state.sortType}`, {
      method: 'GET',
      headers:myHeaders
    })
      .then(response => response.json())
      .then(result => {
        console.log(result);
        this.setState({ inverterBatteriesData: result?.data });
        this.setState({isLoading:false})
        // this.setState({
        //   inverterBatteryCapacityData: result?.data, allFilterSelect: true, selectBatteryCapacity: true,
        //   selectBackupTime: false,
        //   Select850: false,
        //   SelectInputVoltage: false,
        // })
      })
      .catch(error => {console.log('error', error); this.setState({isLoading:true})});
  }

  sortbyInverterRecentlyAddedCall = () => {
    this.setState({isLoading:true})
    let myHeaders = new Headers();
    if(localStorage.getItem("loginSuccessToken")){
      let TOKEN: any = localStorage.getItem("loginSuccessToken")??''

      myHeaders.append("token", TOKEN);
    }


    fetch(`${apiBaseUrl}/bx_block_filter_items/filter?battery_type=2&inverter_capacity=${this.state.inverterCapacity}&input_voltage=${this.state.dcInputVoltage}&backup_time=${this.state.backUpTime}&battery_capacity=${this.state.batteryCapacity}&sort_by=recently_added`, {
      method: 'GET',
      headers:myHeaders
    })
      .then(response => response.json())
      .then(result => {
        console.log(result);
        this.setState({ inverterBatteriesData: result?.data })
        this.setState({isLoading:false})
        // this.setState({ inverterBatteryCapacityData: result?.data, allFilterSelect: true, selectBatteryCapacity: true })
      })
      .catch(error => {console.log('error', error); this.setState({isLoading:false})});
  }

  sortbyInvPriceLowtoHighCall = () => {
    this.setState({isLoading:true})
    let myHeaders = new Headers();
    if(localStorage.getItem("loginSuccessToken")){
      let TOKEN: any = localStorage.getItem("loginSuccessToken")??''

      myHeaders.append("token", TOKEN);
    }


    fetch(`${apiBaseUrl}/bx_block_filter_items/filter?battery_type=2&inverter_capacity=${this.state.inverterCapacity}&input_voltage=${this.state.dcInputVoltage}&backup_time=${this.state.backUpTime}&battery_capacity=${this.state.batteryCapacity}&sort_by=price_l_to_h`, {
      method: 'GET',
      headers:myHeaders
    })
      .then(response => response.json())
      .then(result => {
        console.log(result);
        this.setState({ inverterBatteriesData: result?.data });
        this.setState({isLoading:false})
        // this.setState({ inverterBatteryCapacityData: result?.data, allFilterSelect: true, selectBatteryCapacity: true })
      })
      .catch(error =>{ console.log('error', error); this.setState({isLoading:false})});
  }

  sortbyInvPriceHighttoLowCall = () => {
    this.setState({isLoading:true})
    let myHeaders = new Headers();
    if(localStorage.getItem("loginSuccessToken")){
      let TOKEN: any = localStorage.getItem("loginSuccessToken")??''

      myHeaders.append("token", TOKEN);
    }


    fetch(`${apiBaseUrl}/bx_block_filter_items/filter?battery_type=2&inverter_capacity=${this.state.inverterCapacity}&input_voltage=${this.state.dcInputVoltage}&backup_time=${this.state.backUpTime}&battery_capacity=${this.state.batteryCapacity}&sort_by=price_h_to_l`, {
      method: 'GET',
      headers:myHeaders
    })
      .then(response => response.json())
      .then(result => {
        console.log(result);
        this.setState({ inverterBatteriesData: result?.data });
        this.setState({isLoading:false})
        // this.setState({ inverterBatteryCapacityData: result?.data, allFilterSelect: true, selectBatteryCapacity: true })
      })
      .catch(error => {console.log('error', error); this.setState({isLoading:false})});
  }

  sortbyInvFromAtoZCall = () => {
    this.setState({isLoading:true})
    let myHeaders = new Headers();
      if(localStorage.getItem("loginSuccessToken")){
        let TOKEN: any = localStorage.getItem("loginSuccessToken")??''

        myHeaders.append("token", TOKEN);
      }

    fetch(`${apiBaseUrl}/bx_block_filter_items/filter?battery_type=2&inverter_capacity=${this.state.inverterCapacity}&input_voltage=${this.state.dcInputVoltage}&backup_time=${this.state.backUpTime}&battery_capacity=${this.state.batteryCapacity}&sort_by=atoz`, {
      method: 'GET',
      headers:myHeaders
    })
      .then(response => response.json())
      .then(result => {
        console.log(result);
        this.setState({ inverterBatteriesData: result?.data });
        this.setState({isLoading:false})
        // this.setState({ inverterBatteryCapacityData: result?.data, allFilterSelect: true, selectBatteryCapacity: true })
      })
      .catch(error => {console.log('error', error); this.setState({isLoading:false})});
  }





  getSubCategoriesCall = () => {

    fetch(apiBaseUrl+"/bx_block_filter_items/battery_type?id=1", {
      method: 'GET',
    })
      .then(response => response.json())
      .then(result => {
        console.log(result)
        this.setState({ getSubCategoriesData: result?.data })
        const z = this.state.getSubCategoriesData;
        console.log(z)
        // let filteredData = z.filter((d:any)=>d.attributes.sub_category_id !=d.attributes.sub_category_id)
        // console.log("my re.........",filteredData)
        var setObj = new Set(); // create key value pair from array of array

        var filteredSubCatData = z.reduce((acc: any, item: any) => {
          if (!setObj.has(item.attributes?.sub_category_id)) {
            setObj.add(item.attributes?.sub_category_id)
            acc.push(item)
          }
          return acc;
        }, []);//converting back to array from mapobject
        console.log("my god.........", filteredSubCatData)
        this.setState({ uniqueSubcategoriesData: filteredSubCatData })

        var setObj = new Set();
        var filteredManufacturerData = z.reduce((acc: any, item: any) => {
          if (!setObj.has(item.attributes?.manufacturer)) {
            setObj.add(item.attributes?.manufacturer)
            acc.push(item)
          }
          return acc;
        }, []);//converting back to array from mapobject
        console.log("my god.........", filteredManufacturerData)
        this.setState({ uniqueManufacturerData: filteredManufacturerData })

        var setObj = new Set();
        var filteredModelData = z.reduce((acc: any, item: any) => {
          if (!setObj.has(item.attributes?.vehicle_model)) {
            setObj.add(item.attributes?.vehicle_model)
            acc.push(item)
          }
          return acc;
        }, []);//converting back to array from mapobject
        console.log("my god.........", filteredModelData)
        this.setState({ uniqueModelData: filteredModelData })

        var setObj = new Set();
        var filteredFuelData = z.reduce((acc: any, item: any) => {
          if (!setObj.has(item.attributes?.fuel)) {
            setObj.add(item.attributes?.fuel)
            acc.push(item)
          }
          return acc;
        }, []);//converting back to array from mapobject
        let subCategoryId: any = []
        let subCategoryName: any = []
        let temp = z.map((element: any) => {
          if (element?.attributes?.fuel == 'PETROL') {
            subCategoryId.push(element?.attributes?.sub_category_id)
            subCategoryName.push(element?.attributes?.sub_category_name)
            return { fuel: 'Petrol', id: new Set(subCategoryId), name: new Set(subCategoryName) }
          }
          if (element?.attributes?.fuel == 'DIESEL') {
            subCategoryId.push(element?.attributes?.sub_category_id)
            subCategoryName.push(element?.attributes?.sub_category_name)
            return { fuel: 'Diesel', id: new Set(subCategoryId), name: new Set(subCategoryName) }
          }
        })
        console.log(temp)
        console.log("my god.........", filteredFuelData)
        this.setState({ uniqueFuelData: filteredFuelData })
      })
      .catch(error => console.log('error', error));
  }




  ApplyVehicleFilterSubCatCall = (item: any) => {

    this.setState({isLoading:true})
   

    let myHeaders = new Headers();
    if(localStorage.getItem("loginSuccessToken")){
      let TOKEN: any = localStorage.getItem("loginSuccessToken")??''

      myHeaders.append("token", TOKEN);
    }

    fetch(`${apiBaseUrl}/bx_block_filter_items/filter?battery_type=1&sub_cat_id=${this.getSubCategory(item)}&man_id=${this.getManuFactureId(item)}&model_id=${this.getModelId(item)}&fuel=${this.getFuelId(item)}&sort_by=${this.state.sortType}`, {
      method: 'GET',
      headers:myHeaders
    })
      .then(response => response.json())
      .then(result => {
        console.log(result);
        this.setState({vehicleBatteriesData:result?.data,allFilterSelect:false});
        this.setState({isLoading:false})
        // this.setState({
        //   subCategoryData: result?.data, showManufacurerList: true, WheelerSelect: true, getManufacturerSelect: false, modelSelect: false,
        //   fuelSelect: false, sortbyvehicleRecentlyAddedSelect: false, sortbyPriceHightoLowSelect: false, sortbyPriceLowtoHigSelect: false, sortbyAtoZSelect: false
        // })
      })
      .catch(error => {console.log('error', error); this.setState({isLoading:false})});
    
    
  }

  
  getManuFactureId = (item?: any) => {
    console.log(this.state.selectManufacturer)
    let a = this.state.selectManufacturer.map((element: any) => {
      return element

    })
    console.log(a)
    let temp: any = []
    if (a.length > 0) {
      a.map((element: any) => {
        temp.push(element?.id)
      })
      return temp
    }
    else {
      return ''
    }


  }

  getSubCategory = (item?: any) => {
    let a = this.state.selectCategory.map((element: any) => {
      return element

    });
    let temp: any = [];
    if (a.length > 0) {
      a.map((element: any) => {
        temp.push(element?.attributes?.sub_category_id)
      })
      return temp
    }
    else {
      return ''
    }


  }
  getFuelId = (item?: any) => {
    let a = this.state.selectFuel.map((element: any) => {
      return element

    });
    let temp: any = [];
    if (a.length > 0) {
      a.map((element: any) => {
        // temp.push(element?.attributes?.item_id)
        temp.push(element?.id)
      })
      return temp
    }
    else {
      return ''
    }


  }
  getModelId = (item?: any) => {
    let a = this.state.selectModel.map((element: any) => {
      return element

    });
    let temp: any = [];
    if (a.length > 0) {
      a.map((element: any) => {
        temp.push(element?.id)
      })
      return temp
    }
    else {
      return ''
    }

  }
  ApplyVehicleFilterManufacturerCall = (item: any) => {
    this.setState({isLoading:true})
    let myHeaders = new Headers();
    if(localStorage.getItem("loginSuccessToken")){
      let TOKEN: any = localStorage.getItem("loginSuccessToken")??''

      myHeaders.append("token", TOKEN);
    }

    fetch(`${apiBaseUrl}/bx_block_filter_items/filter?battery_type=1&sub_cat_id=${this.getSubCategory(item)}&man_id=${this.getManuFactureId(item)}&model_id=${this.getModelId(item)}&fuel=${this.getFuelId(item)}&sort_by=${this.state.sortType}`, {
      method: 'GET',
      headers:myHeaders
    })
      .then(response => response.json())
      .then(result => {
        console.log(result)
        this.setState({vehicleBatteriesData:result?.data,allFilterSelect:false});
        this.setState({isLoading:false})
        // this.setState({
        //   getManufacturerData: result?.data, allFilterSelect: true, getManufacturerSelect: true, WheelerSelect: false,
        //   sortbyvehicleRecentlyAddedSelect: false,
        //   modelSelect: false, fuelSelect: false, sortbyPriceHightoLowSelect: false, sortbyPriceLowtoHigSelect: false, sortbyAtoZSelect: false
        // })
      })
      .catch(error => {console.log('error', error); this.setState({isLoading:false})});
  }

  ApplyVehicleFilterModelCall = (item: any) => {
    this.setState({isLoading:true})
    let myHeaders = new Headers();
    if(localStorage.getItem("loginSuccessToken")){
      let TOKEN: any = localStorage.getItem("loginSuccessToken")??''

      myHeaders.append("token", TOKEN);
    }

    fetch(`${apiBaseUrl}/bx_block_filter_items/filter?battery_type=1&sub_cat_id=${this.getSubCategory(item)}&man_id=${this.getManuFactureId(item)}&model_id=${this.getModelId(item)}&fuel=${this.getFuelId(item)}&sort_by=${this.state.sortType}`, {
      method: 'GET',
      headers:myHeaders
    })
      .then(response => response.json())
      .then(result => {
        console.log(result);
        this.setState({vehicleBatteriesData:result?.data,allFilterSelect:false});
        this.setState({isLoading:false})
        // this.setState({
        //   modelData: result?.data, allFilterSelect: true, modelSelect: true, getManufacturerSelect: false,
        //   sortbyvehicleRecentlyAddedSelect: false, WheelerSelect: false, fuelSelect: false, sortbyPriceHightoLowSelect: false, sortbyPriceLowtoHigSelect: false, sortbyAtoZSelect: false
        // })
      })
      .catch(error => {console.log('error', error); this.setState({isLoading:false})});
  }

  ApplyVehicleFilterFuelCall = (item: any) => {
    this.setState({isLoading:true})
    let myHeaders = new Headers();
    if(localStorage.getItem("loginSuccessToken")){
      let TOKEN: any = localStorage.getItem("loginSuccessToken")??''

      myHeaders.append("token", TOKEN);
    }

    fetch(`${apiBaseUrl}/bx_block_filter_items/filter?battery_type=1&sub_cat_id=${this.getSubCategory(item)}&man_id=${this.getManuFactureId(item)}&model_id=${this.getModelId(item)}&fuel=${this.getFuelId(item)}&sort_by=${this.state.sortType}`, {
      method: 'GET',
      headers:myHeaders
    })
      .then(response => response.json())
      .then(result => {
        console.log(result);
        this.setState({vehicleBatteriesData:result?.data,allFilterSelect:false});
        this.setState({isLoading:false})
        // this.setState({
        //   fuelData: result?.data, allFilterSelect: true, fuelSelect: true, WheelerSelect: false, getManufacturerSelect: false,
        //   sortbyvehicleRecentlyAddedSelect: false, modelSelect: false, sortbyPriceHightoLowSelect: false, sortbyPriceLowtoHigSelect: false, sortbyAtoZSelect: false
        // })
      })
      .catch(error => {console.log('error', error); this.setState({isLoading:false})});
  }

  sortbyVehicleRecentlyAddedCall = () => {
    this.setState({isLoading:true})

    let myHeaders = new Headers();
    if(localStorage.getItem("loginSuccessToken")){
      let TOKEN: any = localStorage.getItem("loginSuccessToken")??''

      myHeaders.append("token", TOKEN);
    }

    fetch(`${apiBaseUrl}/bx_block_filter_items/filter?battery_type=1&sub_cat_id=${this.getSubCategory()}&man_id=${this.getManuFactureId()}&model_id=${this.getModelId()}&fuel=${this.getFuelId()}&sort_by=${this.state.sortType}`, {
      method: 'GET',
    })
      .then(response => response.json())
      .then(result => {
        console.log(result);
        this.setState({vehicleBatteriesData:result?.data,allFilterSelect:false})
        // this.setState({
        //   sortedData: result?.data,
        //   sortbyvehicleRecentlyAddedData: result?.data, sortbyvehicleRecentlyAddedSelect: true,
        //   allFilterSelect: true, getManufacturerSelect: false, sortbyPriceLowtoHigSelect: false, sortbyPriceHightoLowSelect: false,
        //   WheelerSelect: false, modelSelect: false, fuelSelect: false, sortbyAtoZSelect: false,
        // })
        this.setState({isLoading:false})
      })
      .catch(error =>{ console.log('error', error);this.setState({isLoading:false})});
  }

  sortbyPriceLowtoHighCall = () => {
    this.setState({isLoading:true})
    let myHeaders = new Headers();
    if(localStorage.getItem("loginSuccessToken")){
      let TOKEN: any = localStorage.getItem("loginSuccessToken")??''

      myHeaders.append("token", TOKEN);
    }

    fetch(`${apiBaseUrl}/bx_block_filter_items/filter?battery_type=1&sub_cat_id=${this.getSubCategory()}&man_id=${this.getManuFactureId()}&model_id=${this.getModelId()}&fuel=${this.getFuelId()}&sort_by=price_l_to_h`, {
      method: 'GET',
      headers:myHeaders
    })
      .then(response => response.json())
      .then(result => {
        console.log(result);
        this.setState({vehicleBatteriesData:result?.data,allFilterSelect:false})
        // this.setState({
        //   sortedData: result?.data,
        //   sortbyPriceLowtoHighData: result?.data, sortbyvehicleRecentlyAddedSelect: false, sortbyPriceLowtoHigSelect: true, sortbyPriceHightoLowSelect: false,
        //   allFilterSelect: true, getManufacturerSelect: false, modelSelect: false, fuelSelect: false, sortbyAtoZSelect: false,
        // })
        this.setState({isLoading:false})
      })
      .catch(error => {console.log('error', error); this.setState({isLoading:false})});
  }

  createWishList=()=>{
    this.setState({isLoading:true})
    let TOKEN: any = localStorage.getItem("loginSuccessToken");
    let item_id:any=localStorage.getItem('item_id')
  
    let myHeaders = new Headers();
   
    myHeaders.append("token", TOKEN);
  
    // myHeaders.append("Content-Type", "application/json");

    let formdata = new FormData();
    formdata.append("item_id", item_id);
  
  
  
    fetch(`${apiBaseUrl}/bx_block_fedex_integration/wishlist`, {
      method: 'POST',
      headers: myHeaders,
      body:formdata
    })
      .then(response => response.json())
      .then(result => {
        console.log( result);
       this.refreshAllData();
       this.refreshData();
       this.refreshInverterData()
       
       
       
      })
      .catch(error =>{ console.log('error', error); this.setState({isLoading:false})});
  
  }

  refreshData=()=>{
   
    let myHeaders = new Headers();
    if(localStorage.getItem("loginSuccessToken")){
      let TOKEN: any = localStorage.getItem("loginSuccessToken")??''

      myHeaders.append("token", TOKEN);
    }

    fetch(`${apiBaseUrl}/bx_block_filter_items/filter?battery_type=1&sub_cat_id=${this.getSubCategory()}&man_id=${this.getManuFactureId()}&model_id=${this.getModelId()}&fuel=${this.getFuelId()}&sort_by=${this.state.sortType}`, {
      method: 'GET',
      headers:myHeaders
    })
      .then(response => response.json())
      .then(result => {
        console.log(result);
        this.setState({vehicleBatteriesData:result?.data,allFilterSelect:false})
        this.setState({isLoading:false})
       
        
      })
      .catch(error =>{ console.log('error', error);this.setState({isLoading:false})});

  }
  refreshInverterData=()=>{
   
    let myHeaders = new Headers();
    if(localStorage.getItem("loginSuccessToken")){
      let TOKEN: any = localStorage.getItem("loginSuccessToken")??''

      myHeaders.append("token", TOKEN);
    }
 
    fetch(`${apiBaseUrl}/bx_block_filter_items/filter?battery_type=2&inverter_capacity=${this.state.inverterCapacity}&input_voltage=${this.state.dcInputVoltage}&backup_time=${this.state.backUpTime}&battery_capacity=${this.state.batteryCapacity}&sort_by=${this.state.sortType}`, {
      method: 'GET',
      headers:myHeaders
    })
      .then(response => response.json())
      .then(result => {
        console.log(result);
        this.setState({inverterBatteriesData:result?.data,allFilterSelect:false})
        this.setState({isLoading:false})
       
        
      })
      .catch(error =>{ console.log('error', error);this.setState({isLoading:false})});

  }

  deleteWishlist=()=>{
    this.setState({isLoading:true})
    let TOKEN: any = localStorage.getItem("loginSuccessToken");
    let item_id:any=localStorage.getItem('item_id')
  
    let myHeaders = new Headers();
   
    myHeaders.append("token", TOKEN);
  
    // myHeaders.append("Content-Type", "application/json");

    let formdata = new FormData();
    formdata.append("item_id", item_id);
  
  
  
    fetch(`${apiBaseUrl}/bx_block_fedex_integration/wishlist/${item_id}`, {
      method: 'DELETE',
      headers: myHeaders,
      body:formdata
    })
      .then(response => response.json())
      .then(result => {
        console.log( result);
       this.refreshAllData();
       this.refreshData();
       this.refreshInverterData()
      
       
      })
      .catch(error =>{ console.log('error', error); this.setState({isLoading:false})});
    
  }

  sortbyPriceHighttoLowCall = () => {
    this.setState({isLoading:true});
    let myHeaders = new Headers();
    if(localStorage.getItem("loginSuccessToken")){
      let TOKEN: any = localStorage.getItem("loginSuccessToken")??''

      myHeaders.append("token", TOKEN);
    }

    fetch(`${apiBaseUrl}/bx_block_filter_items/filter?battery_type=1&sub_cat_id=${this.getSubCategory()}&man_id=${this.getManuFactureId()}&model_id=${this.getModelId()}&fuel=${this.getFuelId()}&sort_by=price_h_to_l`, {
      method: 'GET',
      headers:myHeaders
    })
      .then(response => response.json())
      .then(result => {
        console.log(result);
        this.setState({vehicleBatteriesData:result?.data,allFilterSelect:false,allBatteriesData:result?.data})
        // this.setState({
        //   sortedData: result?.data,
        //   sortbyPriceHightoLowData: result?.data, sortbyPriceHightoLowSelect: true, sortbyvehicleRecentlyAddedSelect: false, sortbyPriceLowtoHigSelect: false,
        //   allFilterSelect: true, getManufacturerSelect: false, WheelerSelect: false, modelSelect: false, fuelSelect: false, sortbyAtoZSelect: false,
        // })
        this.setState({isLoading:false})
      })
      .catch(error =>{  this.setState({isLoading:false});
      console.log('error', error)});
  }

  sortbyFromAtoZCall = () => {
    this.setState({isLoading:true});
    let myHeaders = new Headers();
    if(localStorage.getItem("loginSuccessToken")){
      let TOKEN: any = localStorage.getItem("loginSuccessToken")??''

      myHeaders.append("token", TOKEN);
    }


    fetch(`${apiBaseUrl}/bx_block_filter_items/filter?battery_type=1&sub_cat_id=${this.getSubCategory()}&man_id=${this.getManuFactureId()}&model_id=${this.getModelId()}&fuel=${this.getFuelId()}&sort_by=atoz`, {
      method: 'GET',
      headers:myHeaders
    })
      .then(response => response.json())
      .then(result => {
        console.log(result);
        this.setState({vehicleBatteriesData:result?.data,allFilterSelect:false})
        // this.setState({
        //   sortedData: result?.data,
        //   sortbyAtoZData: result?.data, sortbyAtoZSelect: true, sortbyPriceHightoLowSelect: false, sortbyvehicleRecentlyAddedSelect: false, sortbyPriceLowtoHigSelect: false,
        //   allFilterSelect: true, getManufacturerSelect: false, WheelerSelect: false, modelSelect: false, fuelSelect: false
        // })
        this.setState({isLoading:false})

      })
      .catch(error =>{  this.setState({isLoading:false});
      console.log('error', error)});
  }

  sortAllData=(sortBy:any)=>{
    this.setState({isLoading:true});
    let myHeaders = new Headers();
    if(localStorage.getItem("loginSuccessToken")){
      let TOKEN: any = localStorage.getItem("loginSuccessToken")??''

      myHeaders.append("token", TOKEN);
    }


    fetch(`${apiBaseUrl}/bx_block_filter_items/filter?sort_by=${sortBy}`, {
      method: 'GET',
      headers:myHeaders
    })
      .then(response => response.json())
      .then(result => {
        console.log(result);
        this.setState({allBatteriesData:result?.data,allFilterSelect:false})
        
        this.setState({isLoading:false})

      })
      .catch(error =>{  this.setState({isLoading:false});
      console.log('error', error)});

  }
  refreshAllData=()=>{
    this.setState({isLoading:true});
    let myHeaders = new Headers();
    if(localStorage.getItem("loginSuccessToken")){
      let TOKEN: any = localStorage.getItem("loginSuccessToken")??''

      myHeaders.append("token", TOKEN);
    }


    fetch(`${apiBaseUrl}/bx_block_filter_items/filter?sort_by=${this.state.sortType}`, {
      method: 'GET',
      headers:myHeaders
    })
      .then(response => response.json())
      .then(result => {
        console.log(result);
        this.setState({allBatteriesData:result?.data,allFilterSelect:false})
        
        this.setState({isLoading:false})

      })
      .catch(error =>{  this.setState({isLoading:false});
      console.log('error', error)});

  }

  // Customizable Area Start 

  finalPrice=(item:any)=>{
    return item?.attributes?.amount-item?.attributes?.discount_amount
      }

  // Customizable Area End 


  // Customizable Area Start
  // Customizable Area End

}
