// Customizable Area Start
import React, { Component } from 'react';
import { Box, Container, Grid, Radio, RadioGroup, FormControlLabel, Paper, Card, CardContent,Backdrop,CircularProgress } from "@material-ui/core";
import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'
import IconButton from '@material-ui/core/IconButton';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';

import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';


const greenTick = require("../assets/greentick.png")

const yellowStar = require("../assets/yellow_star.png")



const Styles = {
  inputSearchBox: {
    width: "25px",
    height: "35px",
    alignItems: "center",
    border: "1px solid #b30000",
    borderRadiusRight: "5px",
    backgroundColor: "#b30000",
    paddingRight: "10px",
    color: "white",
    cursor: "pointer"
  },
  cardhead: {
    font: "10px",
    color: "grayText",
    textDecoration: "underline",
    textDecorationColor: "gray",
    textDecorationThickness: "1px",
  }
}
const textColor = {
  cursor: 'pointer',
  color: '#A5A7A8',
  margin: '10px 0px'
}
const style = {
  position: 'absolute' as 'absolute',
  top: '145px',
  left: '90%',
  transform: 'translate(-50%, -50%)',
  zIndex: 1,
  width: '260px',
  bgcolor: 'background.paper'

}

import CategoriessubcategoriesController, {
  Props
} from "./CategoriessubcategoriesController";
import { ambasdar, appleStore, batterySelection, car, down, ezLogo, FactoryCharged, googlePlay, love, LowMaintanance, mobile, NonSpillable, rickshaw } from './assets';
import { heart } from '../../dashboard/src/VehiclesBatteries.web';
import VisionStatementWeb from '../../../components/src/VisionStatement.web';
import '../assets/styles.css'
export default class CommercialVehicle extends CategoriessubcategoriesController {
 
  handleCloseMenu = () => {
    this.setState({ openLanguageMenu: null })

  }
  handleMenuLang = (event: any) => {
    this.setState({ openLanguageMenu: event.currentTarget })

  };
  finalPrice=(item:any)=>{
    return item?.attributes?.amount-item?.attributes?.discount_amount
      }
 
  render() {
    return (
      <>
       <Backdrop
          style={{ color: '#fff', zIndex: 1 }}
          open={this.state.isLoading}

        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <div>
          

        <div style={{ padding: '20px', backgroundColor: '#efefef' }}>
            <Container>

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                <div style={{ color: '#a1a0a4' }}>
                  <a href='/' style={{ textDecoration: 'none', color: '#a1a0a4' }}>Home</a> <ArrowForwardIosRoundedIcon className="arrow-left" /> Commercial  Batteries
                </div>
                {!localStorage.getItem("loginSuccessToken") && <div style={{ color: '#a1a0a4' }}>
                  <span>
                    <i>For Better Experience</i>
                    <span id='fbe' onClick={() => {
                      this.props.navigation.navigate('EmailAccountLoginBlock')
                    }} style={{ color: "red", fontWeight: 'bold', cursor: 'pointer' }}> Login/</span><span onClick={() => {
                      this.props.navigation.navigate('signUp')
                    }} style={{ color: "red", fontWeight: 'bold', cursor: 'pointer' }}>Register</span>
                  </span>
                </div>
                }
              </div>
            </Container>
          </div>






          <Container id='cont'>
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 0px', alignItems: 'center', position: 'relative' }}>
              <div style={{ fontWeight: 'bold' }}>Commercial Vehicle Batteries</div>
              <div style={{ padding: '5px 10px', backgroundColor: 'white', fontSize: 'small', borderRadius: '100px', display: 'flex', alignItems: 'center' }}>
                <span style={{ fontWeight: 'bold' }}>Sort By:</span><span style={{marginLeft:'5px'}}>{this.checkSort()}</span>{this.state.isExpand ? <span onClick={() => this.setState({ isExpand: false })} style={{ cursor: 'pointer' }}><ExpandLess /></span> : <span onClick={() => this.setState({ isExpand: true })} style={{ cursor: 'pointer' }}><ExpandMore /></span>}
              </div>
              {this.state.isExpand && <Box sx={style} >
                <Paper elevation={2} style={{ padding: '20px' }}>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"

                    onChange={(e: any) =>{this.setState({sort:e.target.value}); this.handleSort1(e.target.value, 'commercial', 58)}}
                    data-testid="radio-group"
                    id='radio'
                  >
                    <FormControlLabel checked={this.state.sort=='recently_added'} control={<Radio value={'recently_added'} />} label="Recently added" />
                    <FormControlLabel checked={this.state.sort=='price_l_to_h'} control={<Radio value={'price_l_to_h'} />} label="Price low to high" />
                    <FormControlLabel checked={this.state.sort=='price_h_to_l'} control={<Radio value={'price_h_to_l'} />} label="Price high to low" />
                    <FormControlLabel checked={this.state.sort=='atoz'} control={<Radio value={'atoz'} />} label="From A to Z" />
                  </RadioGroup>


                </Paper>
              </Box>}
            </div>

            <div id='banImg' style={{ position: 'relative', fontWeight: 'bold' }}>
              <div><img src={car} width='100%' /></div>
              <div style={{ position: 'absolute', top: '50%', left: '10%', transform: 'translate(-50%,-50%)' }}>
                <img src={NonSpillable}  className='specImages' />
                <small  className='specDetail'>Non Spillable</small>
              </div>
              <div style={{ borderRight: '1px solid slategray', position: 'absolute', top: '50%', left: '20%', transform: 'translate(-50%,-50%)' }} className='centerLine'></div>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'absolute', top: '50%', left: '30%', transform: 'translate(-50%,-50%)' }}>
                <img src={LowMaintanance}  className='specImages' />
                <small  className='specDetail'>Low Maintenance</small>
              </div>
              <div style={{ borderRight: '1px solid slategray', position: 'absolute', top: '50%', left: '40%', transform: 'translate(-50%,-50%)' }} className='centerLine'></div>
              <div style={{ position: 'absolute', top: '55%', left: '50%', transform: 'translate(-50%,-50%)' }}>
                <img src={FactoryCharged}  className='specImages' />
                <small  className='specDetail34'>Factory Charged</small>
              </div>

            </div>


            



            <Grid  container spacing={2} style={{ marginTop: '20px' }}>
              {this.state.commercialVehiclesData?.map((item: any, index: any) => {
                return (
                  <Grid id='grid' item xs={12} md={3} key={index}>
                    <Card>
                      <div style={{ display: "flex", justifyContent: 'space-between', padding: '15px' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>

                          <img src={greenTick} width='25px' height='25px' />
                          <small style={{ textDecoration: 'underline', marginLeft: '10px', fontSize: '15px',color:"9CA0A9" }}>{item?.attributes?.warranty}</small>
                        </div>

                        <div id='innerDiv'  onClick={()=>{
                              if (localStorage.getItem('loginSuccessToken')) {

                                if (item?.attributes?.is_wishlisted) {
                                  localStorage.setItem('item_id', item?.id);
                                  this.deleteWishlist()

                                }
                                else {

                                  localStorage.setItem('item_id', item?.id);
                                  this.createWishList()
                                }
                              }
                              else {
                                localStorage.setItem('wishList',JSON.stringify(true))
                                this.props.navigation.navigate('LoginRequired')
                              }

                          }} style={{ display: 'flex', alignItems: 'center',cursor:'pointer' }}>

                          <img src={item?.attributes?.is_wishlisted?heart:love} width='25px' height='20px' />
                        </div>


                      </div>
                      <CardContent>
                        <div id='cardContent'
                         onClick={() => {

                          localStorage.setItem("applyShowBatteriesId", item.id)
                        this.props.navigation.navigate('ProductDescription1',item?.id)
                        }}
                        
                        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center',cursor:'pointer' }}>
                          <div style={{ padding: '15px' }}><img src={item?.attributes?.thumbnail_image} width='100' height='100' /></div>



                          <div style={{ fontWeight: 'bold', marginTop: '15px' }}>{item?.attributes?.model_no}</div>
                          <div style={{ fontWeight: 'bold', marginTop: '15px', marginBottom: '20px' }}><span style={{ color: '#B1B5C3' }}>Capacity</span> {item?.attributes?.capaciy}</div>

                          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                            <span style={{ fontWeight: 'bold', fontSize: '20px' }}>&#x20b9;{this.finalPrice(item)}</span>
                            <s style={{ color: '#9498A9', marginLeft: '10px', fontSize: '20px', fontWeight: 'bold' }}>&#x20b9;{item?.attributes?.amount}</s>
                          </div>
                          <div style={{ display: 'flex', marginTop: '10px' }}>
                            <div style={{ border: '1px solid gray', display: 'flex', marginRight: '10px', borderRadius: '25px', paddingLeft: '5px', paddingRight: '5px' }}>
                              <span>

                                <img src={yellowStar} width='15px' height='15px' />
                              </span>


                              <span style={{ display: 'flex', alignItems: 'center' }}>{item?.attributes?.range}</span>

                            </div>
                            <span
                              style={{
                                borderRadius: '25px', paddingLeft: '10px', color: 'white',
                                display: 'flex', alignItems: 'center',
                                paddingRight: '10px', backgroundColor: '#66bc54'
                              }}> {Math.round(item?.attributes?.applied_discount)}% off</span>
                          </div>
                        </div>
                      </CardContent>


                    </Card>



                  </Grid>


                )

              })

              }

            </Grid>

         

            <VisionStatementWeb classes={undefined} id={''} openSearch={undefined} navigation={undefined}/>


          </Container>
        </div>








      </>
    )
  }
}
// Customizable Area End
