// Customizable Area Start
import React, { Component } from 'react'
import OrdermanagementController from './OrdermanagementController';
import {
    Box,
    Container,
    Modal,
    Button,
    ListItem,
    IconButton,
    ListItemIcon,
    ListItemText,
    Divider,
    Collapse,
    List,
    Grid,
    Card,
    CardContent,
    CardActions,
    Typography,
    Switch, Radio, Checkbox

} from "@material-ui/core";
import Info from '@material-ui/icons/Info';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import VisionStatementWeb from '../../../components/src/VisionStatement.web';

const ezBattery = require(".././assets/red_battery.png");
const backIcon = require(".././assets/backIcon.png");

export default class ExchangeSummary extends OrdermanagementController {
    arr = [{ label: 'Pickup', info: 'A representative will come and pickup the package from your address' },

    { label: 'Speed post', info: 'You need to send the package via speed post' },
    { label: 'Walk in', info: 'You need to drop the package to the nearest EZ battery office' }];

    handleCloseModal = () => {
        this.props.navigation.navigate('Orders')
        this.setState({ toggleExchangePending: false })
    }
    render() {
        return (
            <>
                <div style={{ display: 'flex', justifyContent: 'flex-start', padding: '20px', backgroundColor: '#efefef' }}>
                    <Container>

                    <div style={{ color: '#a1a0a4' }}>
                        <a href='/' style={{ textDecoration: 'none', color: '#a1a0a4' }}>Home</a> <ArrowForwardIosRoundedIcon className="arrow-left" /> Orders <ArrowForwardIosRoundedIcon className="arrow-left" /> EZTZ4 <ArrowForwardIosRoundedIcon className="arrow-left" /> Exchange
                    </div>
                    </Container>

                </div>

                <Container style={{ marginTop: '20px' }}>
                    <Grid container>
                        <Grid item xs={12} md={4}>

                            <Box sx={{
                                bgcolor: 'background.paper',
                                marginRight: '25px'
                            }}>
                                <div style={{ display: 'flex', flexDirection: 'column', padding: '20px', alignItems: 'center' }}>

                                    <img src={ezBattery} width='250' height='250' />
                                    <div style={{ padding: '20px', fontWeight: 'bold', fontSize: '25px' }}>EZTZ4</div>
                                    <div style={{ padding: '20px', fontWeight: 'bold' }}>

                                        Order Number: 6789070
                                    </div>


                                </div>

                            </Box>



                        </Grid>

                        <Grid item xs={12} md={8}>
                            {!this.state.toggleExchangePending && <Box sx={{
                                bgcolor: 'background.paper',
                                padding: '30px'
                            }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div onClick={() => this.props.navigation.navigate('SelectProductToExchange')} style={{ marginBottom: '10px', marginRight: '10px' }} >
                                        <img src={backIcon} style={{ backgroundColor: '#ebeff2', borderRadius: '50%', padding: '10px', width: '20px' }} />
                                    </div>
                                    <div style={{ fontWeight: 'bold', marginBottom: '10px' }}>Exchange summary</div>
                                </div>
                                <div style={{ padding: '10px' }}>Product</div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ display: 'flex' }}>
                                        <img src={ezBattery} width={100} height={100} style={{ padding: '20px' }} />
                                        <span style={{ display: 'flex', alignItems: 'center' }}>EZTZ5</span>

                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}>
                                        Rs.12999
                                    </div>

                                </div>
                                <Divider />
                                <div style={{ padding: '10px' }}>Exchanging with</div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ display: 'flex' }}>
                                        <img src={ezBattery} width={100} height={100} style={{ padding: '20px' }} />
                                        <span style={{ display: 'flex', alignItems: 'center' }}>EZTZ5</span>

                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}>
                                        Rs.8999
                                    </div>

                                </div>
                                <Divider />
                                <div style={{ paddingTop: '20px' }}>
                                    <span>
                                        You will get a refund of Rs.4000 at your payment source
                                    </span>
                                </div>


                            </Box>}

                            {this.state.toggleExchangePending && <Box sx={{
                                bgcolor: 'background.paper',
                                padding: '30px'
                            }}>

                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                    <div style={{ fontWeight: 'bold', fontSize: '25px' }}>
                                        Exchange Request Pending
                                    </div>
                                    <small style={{ fontWeight: 'bold', marginTop: '10px' }}>Return id:384747483</small>
                                    <div style={{ padding: '5px', backgroundColor: '#fcf9f2', width: '670px', marginTop: '40px', display: 'flex' }}>
                                        <span><Info color='action' /></span>
                                        <span style={{ color: '#f0a00d', marginLeft: '5px', display: 'flex', alignItems: 'center' }}>We are working on the Exchange request placed by you, We'll get back to you soon</span>

                                    </div>


                                    <div
                                        onClick={() => this.handleCloseModal()}
                                        style={{
                                            width: '500px', padding: '20px 0px 20px 0px',
                                            border: '2px solid #e3e3e4', display: 'flex',
                                            justifyContent: 'center', marginTop: '15px'
                                        }}>Ok</div>


                                </div>


                            </Box>}

                            {!this.state.toggleExchangePending && <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                                <Button onClick={() => this.props.navigation.navigate('ConfirmExchange')} variant='outlined' style={{ padding: '10px 90px 10px 90px' }}>Cancel</Button>
                                <Button onClick={() => this.setState({ toggleExchangePending: true })} style={{ padding: '10px 90px 10px 90px', backgroundColor: '#32cba0' }}>Confirm</Button>
                                <Button onClick={() => this.props.navigation.navigate('Payment')} style={{ padding: '10px 90px 10px 90px', backgroundColor: '#32cba0' }}>Pay Rs.4000</Button>
                            </div>}
                        </Grid>
                    </Grid>
                    <VisionStatementWeb id={''} navigation={undefined} classes={undefined} openSearch={undefined}/>

                </Container>

                {/* <div>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={this.state.toggleCancelOrder}
                        onClose={() => this.handleCloseModal()}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={this.state.toggleCancelOrder}>
                            <Box sx={style}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ fontWeight: 'bold', fontSize: '20px' }}>Cancel Order?</div>
                                    <span><CloseIcon /></span>
                                </div>

                                <div style={{ marginTop: '20px', color: '#9da4b3' }}>
                                    Do you wish to cancel your order?Note: Refunds may take 5-7 business days to reflect on your account
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                                    <Button variant='outlined' onClick={() => this.handleCloseModal()} style={{ padding: '15px 45px' }}>No</Button>
                                    <Button variant='contained' style={{ padding: '15px 45px', backgroundColor: '#dd403a', marginLeft: '10px', color: 'white' }} >Yes</Button>
                                </div>








                            </Box>
                        </Fade>
                    </Modal>
                </div> */}

            </>
        )
    }
}
// Customizable Area End