// Customizable Area Start
import React, { Component } from 'react'
import ContactusController from './ContactusController';

import { Box, TextField, Container, Grid, InputAdornment,Select,MenuItem } from '@material-ui/core'
import { email, person, phone } from './assets';

export default class ServiceCallDetails extends ContactusController {
  constructor(props: any) {
    super(props);
    
  }
  render() {
    return (
      <>
        <Container component="main" maxWidth='lg'>
          <div style={{ margin: '20px 0px 20px 0px', fontSize: '25px', fontWeight: 'bold' }}>Service call Details</div>
          <Box
            sx={{
              bgcolor: 'white',
              padding: '30px'
            }}
            >
            <div style={{ margin: '-15px 0px 20px 5px', color: '#AFB4C1',fontFamily: 'system-ui' }}>Dear customer, this facility has been given by us for you to place a battery service request. Please enter the details as per below and our customer care executive will call you back for fixing an appointment. Always a pleasure to serve you.</div>
            <fieldset style={{ border: "2px solid #f3f3f6", padding: '20px' }}>
              <legend style={{ fontWeight: 'bold' }}>Please fill the below details :</legend>
              <Grid container spacing={3}>
                <Grid item xs={4} sm={4}>
                  <TextField
                    required
                    id="firstName"
                    name="firstName"
                    fullWidth
                    placeholder='Full Name'
                    autoComplete="given-name"
                    variant="outlined"
                    InputProps={{

                      startAdornment: (
                        <InputAdornment
                        position="start"
                        
                        >
                          <img src={person} width={20}/>
                        </InputAdornment>
                      ),
                    }}
                    />
                </Grid>
                <Grid item xs={4} sm={4}>
                  <TextField
                    required
                    id="phone"
                    name="phone"
                    fullWidth
                    placeholder='Phone'
                    autoComplete="family-name"
                    variant="outlined"
                    InputProps={{
                      
                      startAdornment: (
                        <InputAdornment
                        position="start"
                        
                        >
                          <img src={phone} width={20}/>
                        </InputAdornment>
                      ),
                    }}
                    />
                </Grid>
                <Grid item xs={4} sm={4}>
                  <TextField
                    required
                    id="email"
                    name="email"
                    fullWidth
                    placeholder='Email'
                    autoComplete="given-name"
                    variant="outlined"
                    InputProps={{

                      startAdornment: (
                        <InputAdornment
                        position="start"
                        
                        >
                          <img src={email} width={20}/>
                        </InputAdornment>
                      ),
                    }}
                    />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="address1"
                    name="address1"
                    fullWidth
                    placeholder='Address'
                    autoComplete="shipping address-line1"
                    variant="outlined"
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="city"
                    name="city"
                    
                    fullWidth
                    placeholder='City'
                    autoComplete="shipping address-level2"
                    variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="state"
                    name="state"
                    
                    fullWidth
                    placeholder='State'
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="zip"
                    name="zip"
                    
                    fullWidth
                    placeholder='Country'
                    autoComplete="shipping postal-code"
                    variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="country"
                    name="country"
                    label="Country"
                    fullWidth
                    placeholder='District'
                    autoComplete="shipping country"
                    variant="outlined"
                    />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="address1"
                    name="address1"
                    
                    fullWidth
                    placeholder='Postal Code'
                    autoComplete="shipping address-line1"
                    variant="outlined"
                    />
                </Grid>
               
              </Grid>
            </fieldset>
            <div style={{ border: '2px solid #f3f3f6', padding: '20px', marginTop: '20px' }}>


              <Grid container spacing={3}>

                <Grid item xs={12} sm={6}>
                  <div style={{margin:'10px 0px',fontWeight:'bold'}}>Please select the type of service call</div>
                  <Select fullWidth  variant='outlined' displayEmpty value=''     >
                    <MenuItem value="">
                       Select
                      </MenuItem>
                      {this.state.allIssues.map((option: any, index: any) => (
                        <MenuItem key={index} value={option?.type_of_issue}>
                          {option?.type_of_issue}
                        </MenuItem>
                      ))}

                      </Select>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div style={{margin:'10px 0px',fontWeight:'bold'}}>Select the dealer which you want to register the service call with</div>
                  <Select fullWidth  variant='outlined' displayEmpty value=''     >
                    <MenuItem value="">
                       Select
                      </MenuItem>
                      {this.state?.allDealers?.map((option: any) => (
                        <MenuItem key={option?.id} value={option?.attributes?.first_name}>
                                                    {option?.attributes?.first_name}
                                                </MenuItem>
                                            ))}

                      </Select>
                </Grid>
              </Grid>
            </div>
          </Box>
        </Container>
      </>
    )
  }
}
// Customizable Area End
