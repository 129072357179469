// Customizable Area Start
import React from 'react'
import RolesPermissions2Controller from './RolesPermissions2Controller';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import InboxIcon from '@material-ui/icons/Inbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import {
    Box,
    Container,
    Modal,
    Button,
    ListItem,
    ListItemIcon,
    ListItemText,
    Divider,
    List,
    Grid,
    Card,
    CardContent,
     Typography, Radio, Backdrop, Fade

} from "@material-ui/core";
import VisionStatementWeb from '../../../components/src/VisionStatement.web';


const ezBattery = require(".././assets/red_battery.png");

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',

    boxShadow: 24,
    p: 4,
};


export default class WarrantyManagement extends RolesPermissions2Controller {
    constructor(props: any) {
        super(props);

    }
    arr = [0, 1, 2, 3, 4, 5, 6];
    handleCloseModal = () => {
        this.setState({ openModal: false })
    }
    render() {
        return (
            <div>
                {/* header */}
                <div style={{padding: '20px', backgroundColor: '#efefef' }}>
                    <Container>
                        <div style={{ display: 'flex', justifyContent: 'space-between'}}>

                    <div style={{ color: '#a1a0a4' }}>
                        <a href='/' style={{ textDecoration: 'none', color: '#a1a0a4' }}>Home</a> <ArrowForwardIosRoundedIcon className="arrow-left" /> Warranty Management
                    </div>
                    <div>
                        <span><MoreHorizIcon /></span>
                    </div>
                        </div>
                    </Container>
                </div>

                <Container component="main" maxWidth='lg' >
                    <div style={{ display: 'flex', marginTop: '30px', justifyContent: 'space-between' }}>
                        {/* aside */}
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            {/* filter */}
                            <Box sx={{
                                marginRight: '35px',
                                width: '300px',
                                bgcolor: 'background.paper'
                            }}>
                                <nav aria-label="main mailbox folders">
                                    <List>
                                        <ListItem >

                                            <ListItemIcon>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="All" />

                                        </ListItem>
                                        <Divider />
                                        <ListItem >

                                            <ListItemIcon>
                                                <DraftsIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="In Warranty" />

                                        </ListItem>
                                        <Divider />
                                        <ListItem >

                                            <ListItemIcon>
                                                <DraftsIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Expired Warranty" />

                                        </ListItem>
                                        <Divider />
                                        <ListItem >

                                            <ListItemIcon>
                                                <DraftsIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Unknown Warranty" />

                                        </ListItem>
                                    </List>
                                </nav>


                            </Box>

                            <Box sx={{
                                marginTop: '10px',
                               
                                width: '300px',
                                bgcolor: 'background.paper'
                            }}>
                                <div style={{ display: 'flex', marginTop: '20px' }}>
                                    <Radio

                                        name="radio-buttons"
                                        inputProps={{ 'aria-label': 'A' }}
                                    />
                                    <span style={{ display: 'flex', alignItems: 'center' }}>Recently Added</span>
                                </div>
                                <div style={{ display: 'flex', marginTop: '20px' }}>
                                    <Radio

                                        name="radio-buttons"
                                        inputProps={{ 'aria-label': 'A' }}
                                    />
                                    <span style={{ display: 'flex', alignItems: 'center' }}>From A to Z</span>
                                </div>


                            </Box>




                        </div>

                        {/* maincontent */}



                        <div>
                            <Grid container spacing={2}>

                                {this.arr.map(() => {
                                    return <Grid item xs={12} md={6}>
                                        <Card >
                                            <CardContent>
                                                <Typography gutterBottom>
                                                    Ordered on 21 Apr 22
                                                </Typography>
                                                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                    <div><img src={ezBattery} width='100' height='100' /></div>
                                                    <div>
                                                        <span style={{ fontWeight: 'bold' }}>EZ2345</span>
                                                        <div onClick={() => this.setState({ openModal: true })} style={{ padding: '10px', border: '1px solid #ebf9f5', backgroundColor: '#ebf9f5', width: '100%' }}>
                                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                                                                <small>Warranty Valid till</small>
                                                                <small style={{ fontWeight: 'bold' }}>20 Apr 23</small>
                                                            </div>
                                                            <div style={{ fontSize: '15px', color: '#6acdb4', padding: '10px' }}>Claim Warranty</div>
                                                        </div>
                                                        <div style={{ margin: '10px' }}><a href='' style={{ color: '#d6d9dc' }} >Register a service call</a></div>
                                                    </div>

                                                </div>
                                            </CardContent>

                                        </Card>
                                    </Grid>
                                })}


                            </Grid>
                        </div>


                    </div>
                    <VisionStatementWeb openSearch={undefined} classes={undefined} id={''} navigation={undefined}/>
                </Container>
                <div>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={this.state.openModal}
                        onClose={() => this.handleCloseModal()}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={this.state.openModal}>
                            <Box sx={style}>
                                <div>
                                    Claim Warranty?
                                </div>
                                <div>
                                    <small>Are you sure you want to claim warranty for the product EZ234?</small>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '25px', marginTop: '30px' }}>
                                    <Button onClick={() => this.handleCloseModal()} variant='outlined'>Cancel</Button>
                                    <Button style={{ marginLeft: '10px' }} variant='contained' onClick={() => this.props.navigation.navigate("WarrantyClaimRequest")}>Yes</Button>
                                </div>
                            </Box>
                        </Fade>
                    </Modal>
                </div>
            </div>
        )
    }
}
// Customizable Area End