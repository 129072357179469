import React from 'react'
// Customizable Area Start

import { Box, Container, Grid, TextField,Card, CardContent,MenuItem,Typography,IconButton,Backdrop,CircularProgress, Select } from "@material-ui/core";

import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';

import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';

const greenTick = require("../assets/greentick.png")
const yellowStar = require("../assets/yellow_star.png")

const Styles = {
    inputSearchBox: {
        width: "25px",
        height: "35px",
        alignItems: "center",
        border: "1px solid #b30000",
        borderRadiusRight: "5px",
        backgroundColor: "#b30000",
        paddingRight: "10px",
        color: "white",
        cursor: "pointer"
    },
    cardhead: {
        font: "10px",
        color: "grayText",
        textDecoration: "underline",
        textDecorationColor: "gray",
        textDecorationThickness: "1px",
    },
    alert_box: {
        position: "fixed",
        top: "35%",
        right: "40%",
        left: "40%",
        backgroundColor: "#fff",
        zIndex: 2000,
        padding: "35px",
        borderRadius: "15px"
    }
}

const GenderTypes = [
   
    {
        value: 'Male',
        label: 'Male',
    },
    {
        value: 'Female',
        label: 'Female',
    },
    {
        value: 'Trans-gender',
        label: 'Trans-gender',
    },

];


import UserProfileBasicController from "./UserProfileBasicController";
import VisionStatementWeb from '../../../components/src/VisionStatement.web';
import { verified } from './assets';


export default class UserProfileBasicBlock extends UserProfileBasicController {
    render() {
        return (

            <div>
               <Backdrop
          style={{ color: '#fff', zIndex: 1 }}
          open={this.state.isLoading}

        > <CircularProgress color="inherit" /></Backdrop>
               <div style={{ padding: '20px', backgroundColor: '#efefef' }}>
            <Container>

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                <div style={{ color: '#a1a0a4' }}>
                  <a href='/' style={{ textDecoration: 'none', color: '#a1a0a4' }}>Home</a> <ArrowForwardIosRoundedIcon className="arrow-left" /> Profile
                </div>
                {!localStorage.getItem("loginSuccessToken") && <div style={{ color: '#a1a0a4' }}>
                  <span>
                    <i>For Better Experience</i>
                    <span onClick={() => {
                      this.props.navigation.navigate('EmailAccountLoginBlock')
                    }} style={{ color: "red", fontWeight: 'bold', cursor: 'pointer' }}> Login/</span><span onClick={() => {
                      this.props.navigation.navigate('signUp')
                    }} style={{ color: "red", fontWeight: 'bold', cursor: 'pointer' }}>Register</span>
                  </span>
                </div>
                }
              </div>
            </Container>
          </div>


                

                {this.state.showSearchData && this.state.openSearch &&
                    <div style={{ marginTop: "165px", marginLeft: "155px", marginRight: "155px" }}>
                        <Grid container style={{ display: "flex", flexDirection: "row" }} >

                            {this.state.serachData?.map((item: any, index: any) => {
                                return (
                                    <Grid item xs={3} key={index} >


                                        <Box m={1}>
                                            <Card style={{ width: "250px", height: "400px" }} >
                                                <div style={{ display: "flex", flexDirection: "row" }}>
                                                    <img style={{ width: "10%", height: "10%", marginLeft: "10px", marginTop: "10px" }} src={greenTick} />
                                                    <p style={Styles.cardhead}>{item?.attributes?.warranty} warranty</p>

                                                    <IconButton aria-label="settings" style={{ marginTop: "5px" }}>
                                                        <FavoriteBorderIcon />
                                                    </IconButton>

                                                </div>

                                                <CardContent>
                                                    <img style={{ display: "block", marginLeft: "60px", marginBottom: "15px", width: "50%", height: "30%" }} src={item?.attributes?.thumbnail_image} />
                                                    <div style={{ marginLeft: "30px" }}>

                                                    </div>
                                                    <p style={{ textAlign: "center" }}>{item?.attributes?.model_no}</p>
                                                    <p style={{ textAlign: "center" }}>{item?.attributes?.capaciy}</p>
                                                    <p style={{ textAlign: "center" }}>${item?.attributes?.amount}</p>
                                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                                        <div style={{ width: "35px", height: "20px", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", border: "1px solid gray", borderRadius: "10px" }}>
                                                            <img style={{ width: "10px", height: "10px" }} src={yellowStar} />
                                                            <p>{item?.attributes?.range}</p>
                                                        </div>
                                                        <div style={{ marginLeft: "10px" }}>
                                                            <div style={{ width: "70px", border: "1px solid gray", borderRadius: "10px", backgroundColor: "green" }}>
                                                                {item?.attributes?.applied_discount}% off
                                                            </div>
                                                        </div>
                                                    </div>
                                                </CardContent>
                                            </Card>
                                        </Box>
                                    </Grid>


                                )

                            })

                            }
                        </Grid>
                    </div>
                }

                {/* <div style={{ margin: "30px 155px", display: "flex", borderBottom: "5px solid #32cba1" }}> */}
                <Container style={{ marginTop: '20px' }}>
                    <Grid container>
                        <Grid item xs={12} md={3} >
                            <div style={{
                                width: "280px", height: "55px", border: "1px solid #F4F5F6", borderBottom: "none", borderTopRightRadius: "3px",
                                borderTopLeftRadius: "3px", backgroundColor: "#fff"
                            }}>
                                <p style={{ margin: "18px", fontSize: "16px", fontWeight: "bold" }}>Profile Details</p>
                            </div>

                            <div style={{
                                width: "280px", height: "55px", border: "1px solid #F4F5F6", borderBottomRightRadius: "3px", cursor: "pointer",
                                borderBottomLeftRadius: "3px", backgroundColor: "#fff"
                            }}
                            onClick={() => this.props.navigation.navigate("UserAddressEditDelete")}
                            >
                                <p style={{ margin: "18px", fontSize: "16px", fontWeight: "bold" }}>Saved Addresses</p>
                            </div>


                        </Grid>


                        <Grid item xs={12} md={8} >
                            <Box sx={{ bgcolor: 'background.paper', padding: '30px' }}>
                                <Grid container spacing={2}>


                                    <Grid item xs={12} md={6}>
                                        <Typography variant="subtitle1">Full Name</Typography>
                                        <TextField
                                            id="outlined-full-width"
                                           
                                            value={this.state.getProfileData?.first_name}
                                            onChange={(e: any) => {
                                                this.setState({ getProfileData: { ...this.state.getProfileData, first_name: e.target.value } })
                                            }}
                                            fullWidth
                                            name="user_name"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined"
                                        />

                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography variant="subtitle1">Your email</Typography>
                                        <TextField
                                            id="outlined-full-width"
                                        
                                            onChange={(e: any) =>
                                                this.setState({ getProfileData: { ...this.state.getProfileData, email: e.target.value } })
                                            }
                                            value={this.state.getProfileData?.email}
                                            fullWidth
                                            name="user_email"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined"
                                        />


                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <div style={{display:'flex',justifyContent:'space-between'}}>
                                        <Typography variant="subtitle1">Your phone number</Typography>
                                        <img src={verified} width={25} height={25}/>
                                        </div>
                                        <TextField
                                            id="outlined-full-width"
                                            // style={{ marginRight: "10px" }}
                                            placeholder={this.state.getProfileData?.full_phone_number}
                                            // helperText="Full width!"
                                            onChange={(e: any) =>
                                                this.setState({ getProfileData: { ...this.state.getProfileData, full_phone_number: e.target.value } })
                                            }
                                            value={this.state.getProfileData?.full_phone_number}
                                            fullWidth
                                            disabled
                                            name="user_phone"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined"
                                        />

                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography variant="subtitle1">Gender</Typography>
                                        {/* <TextField
                                            fullWidth



                                            variant='outlined'
                                            id="outlined-select-currency"
                                            select
                                            value={this.state.getProfileData?.gender ?? ''}

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e: any) => {
                                                this.setState({ getProfileData: { ...this.state.getProfileData, gender: e.target.value } })

                                            }}


                                        >
                                            {GenderTypes.map((option: any) => (
                                                <MenuItem key={option.value} value={option.label}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField> */}
                                        <Select fullWidth  variant='outlined' displayEmpty   value={this.state.getProfileData?.gender ?? ''}   onChange={(e: any) => {
                                                this.setState({ getProfileData: { ...this.state.getProfileData, gender: e.target.value } })

                                            }}>
                    <MenuItem value="">
                       Select
                      </MenuItem>
                      {GenderTypes.map((option: any) => (
                                                <MenuItem key={option.value} value={option.label}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}

                      </Select>



                                    </Grid>

                                </Grid>
                                <div style={{ display: 'flex', marginTop: '75px', position: 'relative' }}>

                                    {this.state.updatedSuccess && this.state.getProfileData &&
                                        <div style={{ fontSize: "20px", fontWeight: "bold", position: 'absolute', top: "-20px", left: '20%', transform: 'translate(-50%,-50%)' }}>{this.state.updatedData}</div>
                                    }
                                    {this.state.updatedError && this.state.getProfileData && !this.state.updatedSuccess&&
                                        <div style={{ fontSize: "20px", fontWeight: "bold", position: 'absolute', top: "-20px", left: '20%', transform: 'translate(-50%,-50%)' }}>{this.state.updatedData}</div>
                                    }
                                    <div style={{ position: 'absolute', top: '-20px', right: '-15%', transform: 'translate(-50%,-50%)' }} >

                                        <div style={{

                                            borderRadius: "4px", backgroundColor: "#32cba1",
                                            cursor: "pointer", padding: '15px 65px', fontWeight: 'bold'
                                        }}
                                            onClick={this.editProfileCall}
                                        >
                                            Save Details
                                        </div>
                                    </div>
                                </div>
                            </Box>

                        </Grid>

                    </Grid>
                    <VisionStatementWeb navigation={this.props.navigation} id={''} openSearch={undefined} classes={undefined}/>




                </Container>
                {/* </div> */}



            </div>

        )
    }
}
// Customizable Area End
