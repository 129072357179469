import React from 'react'
import { BlockComponent } from '../../framework/src/BlockComponent';
import { Grid, Container, Menu, MenuItem, } from "@material-ui/core";
import { withRouter, RouteComponentProps } from 'react-router-dom';

const {baseURL:apiBaseUrl}=require('./../../framework/src/config')
interface Props {
    navigation: any;
    id: string;
    classes: any;
    t?: any;
    i18n?: any;
    match: any;
    location: any;
    history: any;


}

interface S {
    openSoS: boolean;
    isNotify: boolean;
    toggle: boolean;
    openSearch: boolean;
    searchValue: any;
    serachData: any;
    showSearchData: boolean;
    openProfile: boolean;
    open: boolean;
    recentSearchData: any;
    recentSearchCross: boolean;
    recentlyViewedData: any;
    openLanguageMenu: any
    profileModal: boolean
    toggleLanguage: boolean;
    subCategories:any
}

interface SS {

}

const ezlogo = require("../src/assets/EZ_Logo.png");
const youtube = require("../src/assets/youtube.png");
const instagram = require("../src/assets/instagram.png");
const twitter = require("../src/assets/twitter.png");
const down = require("../src/assets/down.png");


const textColor = {
    cursor: 'pointer',
    color: '#A5A7A8',
    margin: '10px 0px'
}


class FooterSection extends BlockComponent<Props, S, SS & RouteComponentProps> {
    constructor(props: Props) {
        super(props);

        this.state = {
            openSoS: false,
            isNotify: false,
            toggle: false,
            openSearch: false,
            searchValue: "",
            serachData: [],
            showSearchData: false,
            openProfile: false,
            open: false,
            recentSearchData: [],
            recentSearchCross: false,
            recentlyViewedData: [],
            openLanguageMenu: null,
            profileModal: false,
            toggleLanguage: false,
            subCategories:[]
        }
    }

    handleMenuLang = (event: any) => {
        this.setState({ openLanguageMenu: event.currentTarget })

    };

    handleCloseMenu = () => {
        this.setState({ openLanguageMenu: null })

    }
    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'

        });
    };
    getSubCategories = () => {
        fetch(`${apiBaseUrl}/bx_block_categories/all_subcategory`, {
            method: 'GET',

        })
            .then(response => response.json())
            .then(result => {
                console.log(result)
                this.setState({ subCategories: result?.data })
            })
            .catch(error => console.log('error', error));
    }
    async componentDidMount() {
        this.getSubCategories()
    }
    render() {
        console.log(apiBaseUrl)
        if (this.props.location?.pathname == '/signUp' || this.props.location?.pathname == '/EmailAccountLoginBlock' || this.props.location?.pathname == '/ForgotPasswordOTP' || this.props.location?.pathname == '/OTPInputAuth') {
            return null
        }
        return (
            <div style={{ backgroundColor: 'white' }}>


                <Container style={{ marginTop: '20px' }}>



                    <Grid container >



                        <Grid item xs={12} md={4} >
                            <img src={ezlogo} style={{ width: "300px", height: "100px", marginTop: "20px" }} />
                        </Grid>
                        <Grid item xs={12} md={4} >
                            <Grid container>

                                <Grid item xs={12} md={12}>

                                    <p><strong>Product Categories</strong></p>
                                </Grid>
                               {this.state.subCategories.map((item:any)=>{
                                return  <Grid key={item?.id} item xs={12} md={6}>

                                <div
                                    style={textColor}
                                    onClick={() => {
                                        localStorage.setItem('subCategoryId', item?.id);
                                        this.props.history.push("/Categoriessubcategories")
                                        this.scrollToTop();
                                        if (item?.attributes?.name?.toLowerCase() == '2 wheeler') {
                                            this.props.history.push("/Categoriessubcategories")
                                        }
                                        if (item?.attributes?.name?.toLowerCase() == '3 wheeler') {
                                            this.props.history.push("/ThreeWheeler")
                                        }
                                        if (item?.attributes?.name?.toLowerCase() == '4 wheeler' || item?.attributes?.name == 'Car/SUV/MUV') {
                                            this.props.history.push("/CarSuvMuvBatteries")
                                        }
                                        if (item?.attributes?.name?.toLowerCase() == 'inverter') {
                                            this.props.history.push("/InverterDashboard")
                                        }
                                        if (item?.attributes?.name?.toLowerCase() == 'tractor') {
                                            this.props.history.push("/TractorDashboard")
                                        }
                                        if (item?.attributes?.name?.toLowerCase() == 'commercial vehicle') {
                                            this.props.history.push("/CommercialVehicle")
                                        }
                                    }}>
                                    {item?.attributes?.name} Batteries</div>
                            </Grid>
                               })}
                               
                               <Grid item xs={12} md={12}>

                                    <p><strong>Company Policies</strong></p>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <div
                                        style={textColor}
                                        onClick={() => { this.props.history.push('/TermsCondition'); this.scrollToTop() }}
                                    >Terms {'&'} Conditions</div>

                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <div
                                        style={textColor}
                                        onClick={() => { this.props.history.push('/PrivacyPolicy'); this.scrollToTop() }}
                                    >Privacy Policy</div>

                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <div
                                        style={textColor}
                                        onClick={() => {
                                            this.props.history.push('/ShippingPolicy');
                                            this.scrollToTop()
                                        }}
                                    >Shipping Policy</div>

                                </Grid>



                            </Grid>
                        </Grid>


                        <Grid item xs={12} md={2}>
                            <Grid container>


                                <Grid item xs={12} md={12}>

                                    <p><strong>Company</strong></p>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <div onClick={() => {
                                        this.props.history.push('/AboutUs');
                                        this.scrollToTop()

                                    }} style={textColor}>About Us</div>
                                </Grid>
                                <Grid item xs={12} md={12}>

                                    <div style={textColor}>Awards {'&'} Certifications</div>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <div style={{ display: "flex", alignItems: 'center', margin: '10px 0px' }}>
                                        <div style={{ marginRight: "10px", color: '#A5A7A8', cursor: 'pointer' }}>Careers</div>
                                        <small style={{ color: '#6AC5AB', backgroundColor: "#D8F5ED", padding: '2px 5px', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>We are hiring! </small>
                                    </div>

                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <div onClick={() => { this.props.history.push('/Testimonials'); this.scrollToTop() }} style={textColor}>Testimonials</div>

                                </Grid>
                                <Grid item xs={12} md={12}>

                                    <div style={textColor}>Reports</div>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <div style={textColor}>Contact Us</div>

                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <div
                                        onClick={() => { this.props.history.push("/Feedback"); this.scrollToTop() }}
                                        style={textColor}
                                    >
                                        Feedback
                                    </div>

                                </Grid>





                            </Grid>
                        </Grid>



                        <Grid item xs={12} md={2}>
                            <p><strong>Follow Us</strong></p>
                            <div style={{ display: "flex" }}>
                                <div style={{ marginRight: '10px' }}>
                                <a href={'https://mobile.twitter.com/ezbatteryindia'} target='blank'>
                                    <img src={twitter} width='30px' />
                                </a>
                                </div>

                                <div>
                                <a href={"https://www.youtube.com/channel/UCiPuAcSBrDy2odvPLyhVqUw"} target='blank'>
                                    <img src={youtube} width='30px' />
                                </a>
                                </div>
                                <div style={{ marginLeft: '10px' }}>
                                <a href={'https://www.instagram.com/ez_battery_reconditioning_/'} target='blank'>
                                    <img src={instagram} width='30px' />
                                </a>
                                </div>
                            </div>
                        </Grid>






                    </Grid>
                    <hr />

                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px', alignItems: 'center' }}>
                        <div>
                            &copy; <small style={{ color: '#A5A7A8' }}>
                                2022 ALL RIGHTS RESERVED BY EZ BATTERY
                            </small>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <small
                                onClick={() => { this.props.history.push('/TermsCondition'); this.scrollToTop() }}
                                style={{ fontWeight: 'bold', color: '#A5A7A8', cursor: 'pointer' }}>  Terms {'&'} Conditions</small>
                            <div style={{ backgroundColor: '#32cba1', width: '5px', height: '5px', borderRadius: '50%', margin: '5px 10px' }}></div>
                            <small
                                onClick={() => { this.props.history.push('/PrivacyPolicy'); this.scrollToTop() }}
                                style={{ color: '#A5A7A8', cursor: 'pointer' }}>PRIVACY POLICY</small>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ marginRight: '5px' }}>Language</div>
                            <div style={{ fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                                <span>English</span>
                                <img onClick={(e) => this.handleMenuLang(e)} src={down} width='25px' height='25px' />
                            </div>
                        </div>

                    </div>
                    <Menu
                        id="basic-menu"
                        anchorEl={this.state.openLanguageMenu}
                        open={Boolean(this.state.openLanguageMenu)}
                        onClose={this.handleCloseMenu}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem onClick={this.handleCloseMenu}>English</MenuItem>
                        <MenuItem onClick={this.handleCloseMenu}>Hindi</MenuItem>

                    </Menu>



                </Container>
            </div>
        )
    }
}
export default withRouter(FooterSection)
