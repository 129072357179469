// Customizable Area Start
import React, { Component } from "react";
import MerchantDashboard2Controller, {
  Props,
  configJSON,
} from "./MerchantDashboard2Controller";
import {
  TextField,
  Box,
  Container,
  Modal,
  Button,
  ListItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Collapse,
  List,
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
  Switch,
  Radio,
  Checkbox,
  makeStyles,
  InputAdornment,
  Fade,
  Backdrop,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Avatar,
  Paper,
} from "@material-ui/core";
import ArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import Person from "@material-ui/icons/Person";

const logo = require(".././assets/logo.png");
const trippleLine = require(".././assets/tripple_line.png");
const bell = require(".././assets/bell.png");
const cart = require(".././assets/cart.png");
const sos = require(".././assets/sos.png");
const appchart = require(".././assets/appchart.png");
const terms = require(".././assets/terms.png");
const productcatalogues = require(".././assets/productcatalogues.png");
const ambasdar = require(".././assets/ambasdar.png");
const batterySelection = require(".././assets/batterySelection.png");
const mobile = require(".././assets/mobile.png");

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default class DealerReports extends MerchantDashboard2Controller {
  constructor(props: Props) {
    super(props);
  }
  handleCloseModal = () => {
    this.setState({ toggleModal: false });
  };
  
  arr = [
    "SOP on service request",
    "Dealers near me",
    "Warranty in/out Utility",
    "Invoice copy request (From Laxmi Motors/Dealers/Franchise)",
    "SOA request (From Laxmi Motors/Dealers/Franchise)",
    "Credit Notes copy request (From Laxmi Motors/Dealers/Franchise)",
    "LR Copy/ Receiving Copy Request",
    "Quotations",
    "Billing History",
    "Payment History",
    "Loyalty Points Report",
    "Ongoing Schemes Download",
  ];
  
  handleClick = (event: any) => {
    this.setState({ profileModal: !this.state.profileModal });
  };

  handleClose = () => {
    this.setState({ profileModal: false });
  };
  render() {
    return (
      <>
        {/* navbar */}

        <div style={{ backgroundColor: "#de403a" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "15px",
            }}
            >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "150px",
              }}
              >
              <div style={{ marginRight: "20px" }}>
                <img src={trippleLine} width="20px" />
              </div>
              <div style={{ marginRight: "20px" }}>
                <img src={logo} width="100px" />
              </div>
              <div>
                <TextField
                  fullWidth
                  style={{ width: "600px", backgroundColor: "white" }}
                  variant="outlined"
                  placeholder="Search Battery type/Category/Model Name/Model Number"
                  InputProps={{
                    // disableUnderline: true,
                    endAdornment: (
                      <InputAdornment
                      position="end"
                      style={{
                        backgroundColor: "#a91c17",
                        padding: "12px 15px",
                        height: "50px",
                        marginRight: "-14px",
                        }}
                      >
                        <span style={{ color: "white" }}>
                          <SearchIcon />
                        </span>
                      </InputAdornment>
                    ),
                  }}
                  />
              </div>
            </div>

            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ marginRight: "20px" }}>
                <img src={sos} width="50px" />
              </div>
              <div style={{ marginRight: "20px" }}>
                <img src={bell} width="30px" />
              </div>
              <div>
                <img src={cart} width="30px" />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                position: "relative",
              }}
            >
              <div style={{ marginRight: "10px", marginLeft: "20px" }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
              </div>
              <div
                onClick={(e) => this.handleClick(e)}
                style={{ color: "white", cursor: "pointer" }}
                >
                <ArrowDownIcon />
              </div>
              {this.state.profileModal && (
                <Paper elevation={3}>
                  <Box
                    sx={{
                      bgcolor: "background.paper",
                      width: "400px",
                      position: "absolute",
                      top: "95%",
                      right: "-33%",
                    }}
                    >
                    <div style={{ padding: "10px" }}>
                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                          >
                          <span>Profile</span>
                          <div
                            style={{
                              border: "1px solid black",
                              padding: "0px 5px",
                              borderRadius: "25px",
                            }}
                            >
                            121
                          </div>
                        </div>
                        <div>Update Your Preferences!</div>
                      </div>

                      <div>
                        <div style={{ fontSize: "small", padding: "10px 0px" }}>
                          PERSONALIZATION
                        </div>
                        <div style={{ display: "flex" }}>
                          <div style={{ padding: "0px 10px 0px 0px" }}>
                            <Person />
                          </div>
                          <div
                            onClick={() =>
                              this.props.navigation.navigate("ProfileDetails")
                            }
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              cursor: "pointer",
                            }}
                          >
                            <small style={{ fontWeight: "bold" }}>
                              Profile Details
                            </small>
                            <small>Manage Your Information</small>
                          </div>
                        </div>

                        <div style={{ display: "flex", padding: "10px 0px" }}>
                          <div style={{ padding: "0px 10px 0px 0px" }}>
                            <Person />
                          </div>
                          <div
                            onClick={() =>
                              this.setState({ toggleLanguage: true })
                            }
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              cursor: "pointer",
                            }}
                            >
                            <small style={{ fontWeight: "bold" }}>
                              Language
                            </small>
                            <small>Toggle Between English and Hindi</small>
                          </div>
                        </div>
                      </div>
                      <Divider />

                      <div>
                        <div style={{ fontSize: "small", padding: "10px 0px" }}>
                          ORDERS AND MORE
                        </div>
                        <div style={{ display: "flex" }}>
                          <div style={{ padding: "0px 10px 0px 0px" }}>
                            <Person />
                          </div>
                          <div
                            onClick={() =>
                              this.props.navigation.navigate(
                                "InvoicesBillingHistory"
                              )
                            }
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              cursor: "pointer",
                            }}
                            >
                            <small style={{ fontWeight: "bold" }}>
                              Invoices and Billing History
                            </small>
                            <small>
                              Find Invoices for upcoming and past payments
                            </small>
                          </div>
                        </div>

                        <div style={{ display: "flex", padding: "10px 0px" }}>
                          <div style={{ padding: "0px 10px 0px 0px" }}>
                            <Person />
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                            >
                            <small style={{ fontWeight: "bold" }}>Orders</small>
                            <small>Find Order Updates</small>
                          </div>
                        </div>
                        <div style={{ display: "flex", padding: "10px 0px" }}>
                          <div style={{ padding: "0px 10px 0px 0px" }}>
                            <Person />
                          </div>
                          <div
                            onClick={() =>
                              this.props.navigation.navigate(
                                "TechnicianManagement"
                                )
                              }
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                cursor: "pointer",
                              }}
                              >
                            <small style={{ fontWeight: "bold" }}>
                              Technician Management
                            </small>
                            <small>Manage Your Technicians Here</small>
                          </div>
                        </div>
                      </div>
                      <Divider />
                      <div>
                        <div style={{ fontSize: "small", padding: "10px 0px" }}>
                          FOR CUSTOMERS
                        </div>
                        <div style={{ display: "flex" }}>
                          <div style={{ padding: "0px 10px 0px 0px" }}>
                            <Person />
                          </div>
                          <div
                            onClick={() =>
                              this.props.navigation.navigate(
                                "ServiceCallRequest"
                                )
                              }
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                cursor: "pointer",
                              }}
                          >
                            <small style={{ fontWeight: "bold" }}>
                              Service Call Request
                            </small>
                            <small>Check Request Placed by Customers</small>
                          </div>
                        </div>

                        <div style={{ display: "flex", padding: "10px 0px" }}>
                          <div style={{ padding: "0px 10px 0px 0px" }}>
                            <Person />
                          </div>
                          <div
                            onClick={() =>
                              this.props.navigation.navigate(
                                "WarrantyRegistration"
                              )
                            }
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              cursor: "pointer",
                            }}
                          >
                            <small style={{ fontWeight: "bold" }}>
                              Register Warranty
                            </small>
                            <small>
                              Register warranty of Batteries placed offline
                            </small>
                          </div>
                        </div>
                        <div style={{ display: "flex", padding: "10px 0px" }}>
                          <div style={{ padding: "0px 10px 0px 0px" }}>
                            <Person />
                          </div>
                          <div
                            onClick={() =>
                              this.props.navigation.navigate(
                                "ManageBatteryCollection"
                                )
                              }
                              style={{
                                display: "flex",
                              flexDirection: "column",
                              cursor: "pointer",
                            }}
                            >
                            <small style={{ fontWeight: "bold" }}>
                              Manage Battery Collection
                            </small>
                            <small>Manage collections of customers</small>
                          </div>
                        </div>
                        <div style={{ display: "flex", padding: "10px 0px" }}>
                          <div style={{ padding: "0px 10px 0px 0px" }}>
                            <Person />
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                            >
                            <small style={{ fontWeight: "bold" }}>
                              Service Call
                            </small>
                            <small>
                              Register s service call for a customer
                            </small>
                          </div>
                        </div>
                      </div>
                      <div style={{ padding: "10px" }}>Logout</div>
                    </div>
                  </Box>
                </Paper>
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            backgroundColor: "#cc302a",
            padding: "20px",
            color: "white",
            display: "flex",
            justifyContent: "center",
          }}
          >
          <div style={{ marginRight: "20px" }}>2 WHEELER BATTERIES</div>
          <div style={{ marginRight: "20px" }}>3 WHEELER BATTERIES</div>
          <div style={{ marginRight: "20px" }}>CAR/SUV/MUV BATTERIES</div>
          <div style={{ marginRight: "20px" }}>
            COMMERCIAL VEHICLE BATTERIES
          </div>
          <div style={{ marginRight: "20px" }}>TRACTOR BATTERIES</div>
          <div style={{ marginRight: "20px" }}>INVERTER BATTERIES</div>
        </div>

        <div
          style={{
            display: "flex",
            padding: "20px",
            backgroundColor: "#efefef",
          }}
        >
          <div style={{ color: "#a1a0a4", marginLeft: "100px" }}>
            <a href="/" style={{ textDecoration: "none", color: "#a1a0a4" }}>
              Home
            </a>{" "}
            {">"} Reports
          </div>
        </div>

        <Container>
          <Grid container spacing={3} style={{ marginTop: "20px" }}>
            <Grid item xs={12} md={8}>
              <TextField
                variant="outlined"
                fullWidth
                placeholder="Search Reports"
                />
            </Grid>
            <Grid item xs={12} md={4}>
              <Button
                style={{
                  backgroundColor: "#32cba1",
                  padding: "15px 120px",
                  position: "initial",
                }}
                >
                Request Reports
              </Button>
            </Grid>
          </Grid>
          <div>Available Reports</div>
          <Grid container spacing={3} style={{ marginTop: "20px" }}>
            <Grid item xs={12} md={4}>
              <Box
                sx={{
                  bgcolor: "background.paper",
                  padding: "10px",
                  display: "flex",
                }}
                >
                <div>
                  <img src={appchart} width="100px" height="100px" />
                </div>
                <div
                  onClick={() => this.setState({ toggleModal: true })}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "30px",
                    fontWeight: "bold",
                  }}
                  >
                  Application Chart
                </div>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box
                sx={{
                  bgcolor: "background.paper",
                  padding: "10px",
                  display: "flex",
                }}
                >
                <div>
                  <img src={productcatalogues} width="100px" height="100px" />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "30px",
                    fontWeight: "bold",
                  }}
                  >
                  Product Catalogues
                </div>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box
                sx={{
                  bgcolor: "background.paper",
                  padding: "10px",
                  display: "flex",
                }}
                >
                <div>
                  <img src={terms} width="100px" height="100px" />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "30px",
                    fontWeight: "bold",
                  }}
                  >
                  Warranty Terms {"& "}Conditions
                </div>
              </Box>
            </Grid>
          </Grid>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "150px",
              marginBottom: "50px",
            }}
            >
            <div>
              <img src={ambasdar} width="550px" />
            </div>
            <div>
              <img src={batterySelection} width="300px" height="325px" />
            </div>
            <div>
              <img src={mobile} width="300px" height="325px" />
            </div>
          </div>
        </Container>

        <div>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={this.state.toggleModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={this.state.toggleModal}>
              <Box sx={style}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                  >
                  <div>Select Report to Request</div>
                  <div onClick={() => this.handleCloseModal()}>
                    <CloseIcon />
                  </div>
                </div>
                <div style={{ marginTop: "30px" }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-outlined-label">
                      Select
                    </InputLabel>

                    <Select
                      variant="outlined"
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={""}
                      // onChange={handleChange}
                      label="Select"
                      >
                      {this.arr.map((item) => {
                        return <MenuItem value={item}>{item}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </div>
              </Box>
            </Fade>
          </Modal>
        </div>

        {/* togglelanguage */}
        <div>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={this.state.toggleLanguage}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            >
            <Fade in={this.state.toggleLanguage}>
              <Box sx={style}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                  >
                  <div>Select Language of the App</div>
                  <div onClick={() => this.handleCloseModal()}>
                    <CloseIcon />
                  </div>
                </div>
                <div style={{ marginTop: "30px" }}>
                  <div
                    style={{
                      border: "1px solid black",
                      padding: "10px",
                      marginBottom: "10px",
                      borderRadius: "20px",
                    }}
                    >
                    <Radio id="demo-simple-select-outlined" value={""} />
                    <span style={{ marginLeft: "10px" }}>English</span>
                  </div>
                  <div
                    style={{
                      border: "1px solid black",
                      padding: "10px",
                      borderRadius: "20px",
                    }}
                  >
                    <Radio id="demo-simple-select-outlined" value={""} />
                    <span style={{ marginLeft: "10px" }}>Hindi</span>
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <Button
                      style={{
                        backgroundColor: "#32cba1",
                        padding: "15px 175px",
                      }}
                      >
                      Save
                    </Button>
                  </div>
                </div>
              </Box>
            </Fade>
          </Modal>
        </div>
      </>
    );
  }
}
// Customizable Area End
