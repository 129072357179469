// Customizable Area Start
import React, { Component } from 'react'
import OrdermanagementController from './OrdermanagementController';
import {
    Box,
    Container,
    Modal,
    Button,
    ListItem,
    IconButton,
    ListItemIcon,
    ListItemText,
    Divider,
    Collapse,
    List,
    Grid,
    Card,
    CardContent,
    CardActions,
    Typography,
    Switch, Radio,Checkbox

} from "@material-ui/core";

export default class ConfirmReturn extends OrdermanagementController {
    render() {
        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div>

                    <div style={{ fontWeight: 'bold' }}>Confirm Return</div>
                    <div style={{ padding: '20px 0px 0px 0px', color: '#acb2bf' }}>John Doe,9384994</div>
                    <div style={{ padding: '10px 0px 15px 0px', color: '#acb2bf' }}>A refund of Rs.8999 will reach within 5-7 business days after we receive the package</div>
                </div>

                <Divider />
                <div >

                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ padding: '20px 0px 20px 0px' }}>Back to source</div>
                        <div style={{ padding: '20px 0px 20px 0px' }}>Rs.8999</div>
                    </div>
                    <div style={{ padding: '10px 0px 15px 0px', color: '#acb2bf' }}>Money will be refunded in the payment method used for placing the order</div>

                </div>
                <Divider />
                <div >
                    <div style={{ padding: '20px 0px 20px 0px', fontWeight: 'bold' }}>Pickup Address</div>
                    <div style={{ padding: '10px 0px 0px 0px', color: '#acb2bf' }}>John Doe,9384994</div>
                    <div style={{ padding: '10px 0px 15px 0px', color: '#acb2bf' }}>Bezel Pharma, Govt Indl Estate,Opp plot 99,Hindustan Naka, Kandivali west</div>
                    <div>
                        <Button style={{backgroundColor:'#d6f5ec',padding:'10px 90px'}}>Change Address</Button>
                    </div>
                    <div style={{display:'flex',marginTop:'25px'}}>
                        <div><Checkbox color='default'/></div>
                        <div style={{display:'flex',alignItems:'center'}}>
                            <small style={{color:'#acb2bf'}}>I agree to return the items in the condition they were received along with the price tags and other accessories</small>
                        </div>

                    </div>

                </div>

            </div>
        )
    }
}
// Customizable Area End