// Customizable Area Start
import React from 'react'
import MoreOptions from '../../blocks/catalogue/src/MoreOptions.web';

import Divider from '@material-ui/core/Divider';
import { Box, InputAdornment, TextField, Paper, Container, Grid, Card, CardContent, Backdrop, CircularProgress, Typography } from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';

import Close from '@material-ui/icons/Close';


import ArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import Person from '@material-ui/icons/Person';
import Account from '@material-ui/icons/AccountCircle';
import SoS from '../../blocks/contactus/src/SoS.web';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import Filteritems from '../../blocks/filteritems/src/Filteritems.web';
import { love, securedGreenTick } from '../../blocks/filteritems/src/assets';
import { BlockComponent } from '../../framework/src/BlockComponent';
import { heart } from '../../blocks/dashboard/src/VehiclesBatteries.web';
import './assets/styles.css';

const {baseURL:apiBaseUrl}=require('./../../framework/src/config')


const trippleLine = require("../src/assets/tripple_line.png")
const yellowStar = require("../src/assets/yellow_star.png")

const LoveImg = require("../src/assets/love.png")
const ProfileMan = require("../src/assets/profile-man.png")
const FaqImg = require("../src/assets/faq.png")
const Orders = require("../src/assets/orders.png");
const MyBatteryCollection = require("../src/assets/my-battery-collection.png")



const sos = require("../src/assets/sos.png");
const bell = require("../src/assets/bell.png");
const cart = require("../src/assets/cart.png");
const logo = require("../src/assets/logo.png");



interface Props {
    navigation: any;
    id: string;
    classes: any;
    t?: any;
    i18n?: any;
    match: any;
    location: any;
    history: any;
    openSearch: any


}

interface S {
    openSoS: boolean;
    isNotify: boolean;
    toggle: boolean;
    openSearch: boolean;
    searchValue: any;
    serachData: any;
    showSearchData: boolean;
    openProfile: boolean;
    open: boolean;
    recentSearchData: any;
    recentSearchCross: boolean;
    recentlyViewedData: any;
    openLanguageMenu: any
    profileModal: boolean
    toggleLanguage: boolean;
    openAppChart: boolean;
    isLoading: boolean;
    subCategories: any
}


class Nav extends BlockComponent<Props, S, RouteComponentProps> {
    wrapperRef: any;
    constructor(props: Props) {
        super(props);
        this.wrapperRef = React.createRef()

        this.state = {
            openSoS: false,
            isNotify: false,
            toggle: false,
            openSearch: false,
            searchValue: "",
            serachData: [],
            showSearchData: false,
            openProfile: false,
            open: false,
            recentSearchData: [],
            recentSearchCross: false,
            recentlyViewedData: [],
            openLanguageMenu: null,
            profileModal: false,
            toggleLanguage: false,
            openAppChart: false,
            isLoading: false,
            subCategories: []
        }
    }


    toggleDrawer =
        (anchor: string, open: boolean) =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
                if (
                    event.type === 'keydown' &&
                    ((event as React.KeyboardEvent).key === 'Tab' ||
                        (event as React.KeyboardEvent).key === 'Shift')
                ) {
                    return;
                }

                this.setState({ toggle: open })
            };

    handleOpenProfile = () => {
        this.setState({ openProfile: true })
    }

    handleRecentItemsDelete = (index: any, item: any) => {
        let x = this.state.recentSearchData;
        x[index].search_term = !x[index].search_term;
        console.log(x);
       
        this.setState({
            recentSearchData: x.filter((element: any) => {
                return element?.id != item?.id
            })
        })
        localStorage.setItem("recentSearchId", this.state.recentSearchData?.id);
        this.deleteRecentSearchCall(item.id);
    }

    getSearchCall = () => {
        this.setState({ isLoading: true })
        let TOKEN: any = localStorage.getItem("loginSuccessToken")

        let myHeaders = new Headers();

        {
            localStorage.getItem("loginSuccessToken") &&
                myHeaders.append("token", TOKEN);
        }
        myHeaders.append("Content-Type", "application/json");



        fetch(`${apiBaseUrl}/bx_block_fedex_integration/search_data?search=${this.state.searchValue}`, {
            method: 'GET',
            headers: myHeaders,
        })
            .then(response => response.json())
            .then(result => {
                this.setState({ showSearchData: true, serachData: result?.data, isLoading: false })
                localStorage.setItem('searchedData', JSON.stringify(result?.data))
            })
            .catch(error => { console.log('error', error); this.setState({ isLoading: false }) });
    }

    recentSearchCall = () => {
        let TOKEN: any = localStorage.getItem("loginSuccessToken")

        let myHeaders = new Headers();
        myHeaders.append("token", TOKEN);
        myHeaders.append("Content-Type", "application/json");



        fetch(`${apiBaseUrl}/bx_block_fedex_integration/user_search`, {
            method: 'GET',
            headers: myHeaders,
        })
            .then(response => response.json())
            .then(result => {
                console.log(result)
                this.setState({ recentSearchData: result?.data })
               
            })
            .catch(error => console.log('error', error));
    }

    deleteRecentSearchCall = (ItemId: any) => {
        let TOKEN: any = localStorage.getItem("loginSuccessToken")


        let myHeaders = new Headers();
        myHeaders.append("token", TOKEN);
        myHeaders.append("Content-Type", "application/json");


        fetch(`${apiBaseUrl}/bx_block_fedex_integration/user_search/${ItemId}`, {
            method: 'DELETE',
            headers: myHeaders,
        })
            .then(response => response.json())
            .then(result => {
                console.log(result)
                this.setState({ recentSearchCross: true })
            })
            .catch(error => console.log('error', error));
    }

    closeSoS = () => {
        this.setState({ openSoS: false })
    }
    closeAppChart = () => {
        this.setState({ openAppChart: false })
    }
    finalPrice=(item:any)=>{
        return item?.attributes?.amount-item?.attributes?.discount_amount
          }
    getSubCategories = () => {
        fetch(`${apiBaseUrl}/bx_block_categories/all_subcategory`, {
            method: 'GET',

        })
            .then(response => response.json())
            .then(result => {
                console.log(result)
                this.setState({ subCategories: result?.data })
            })
            .catch(error => console.log('error', error));
    }
    async componentDidMount() {
        this.funCall();
        this.getSubCategories()

    }
    funCall = () => {
        document.addEventListener('click', this.handleClickOutSide, true)
        return () => {
            document.removeEventListener('click', this.handleClickOutSide, true)
        }
    }
    handleClickOutSide = (e: any) => {
        if (this.wrapperRef.current && !this.wrapperRef.current.contains(e.target)) {
            this.setState({ openProfile: false })
            this.setState({ openSearch: false })
        }
    }
    handleSearch = () => {

        this.props.history?.push(`/SearchBatteryResult/${this.state.searchValue}`)

        this.getSearchCall();

    }
    handleClick = (event: any) => {

        this.setState({ openProfile: !this.state.openProfile });
    };

    createWishList = () => {
        this.setState({ isLoading: true })
        let TOKEN: any = localStorage.getItem("loginSuccessToken");
        let item_id: any = localStorage.getItem('item_id')

        let myHeaders = new Headers();

        myHeaders.append("token", TOKEN);

        let formdata = new FormData();
        formdata.append("item_id", item_id);



        fetch(`${apiBaseUrl}/bx_block_fedex_integration/wishlist`, {
            method: 'POST',
            headers: myHeaders,
            body: formdata
        })
            .then(response => response.json())
            .then(result => {
                console.log(result);
                this.getSearchCall()



            })
            .catch(error => { console.log('error', error); this.setState({ isLoading: false }) });

    }
    deleteWishlist = () => {
        this.setState({ isLoading: true })
        let TOKEN: any = localStorage.getItem("loginSuccessToken");
        let item_id: any = localStorage.getItem('item_id')

        let myHeaders = new Headers();

        myHeaders.append("token", TOKEN);

       
        let formdata = new FormData();
        formdata.append("item_id", item_id);



        fetch(`${apiBaseUrl}/bx_block_fedex_integration/wishlist/${item_id}`, {
            method: 'DELETE',
            headers: myHeaders,
            body: formdata
        })
            .then(response => response.json())
            .then(result => {
                console.log(result);
                this.getSearchCall()



            })
            .catch(error => { console.log('error', error); this.setState({ isLoading: false }) });

    }
    clearSearch = () => {
        this.setState({ serachData: [], searchValue: '' });

    }

    render() {


        if (this.props.location?.pathname == '/signUp' || this.props.location?.pathname == '/EmailAccountLoginBlock' || this.props.location?.pathname == '/ForgotPasswordOTP' || this.props.location?.pathname == '/OTPInputAuth') {
            return null
        }
        return (
            <>
                <Backdrop
                    style={{ color: '#fff', zIndex: 1 }}
                    open={this.state.isLoading}

                > <CircularProgress color="inherit" /></Backdrop>

                <MoreOptions navigation={this.props.navigation} history={this.props.history} id={this.props.id} toggle={this.state.toggle} toggleDrawer={this.toggleDrawer} />

                <div style={{ backgroundColor: "#de403a" }}>
                    <div className='nav'
                       
                    >
                        <div className='inner-nav'
                          
                        >
                            <div onClick={() => this.setState({ serachData: [] })} style={{ marginRight: "20px", cursor: 'pointer' }}>
                                <img onClick={this.toggleDrawer('left', true)} src={trippleLine} width="20px" />
                            </div>
                            <div style={{ marginRight: "20px", cursor: 'pointer' }} onClick={() => {
                                this.props.history.push('/');
                                this.setState({ serachData: [] })
                            }}>
                                <img src={logo} width="100px" />
                            </div>
                            <div className='parent-search-bar'>
                           
                                <TextField
                                    fullWidth
                                    value={this.state.searchValue}
                                   style={{backgroundColor:'white',borderRadius:'5px'}}
                                    variant="outlined"
                                    placeholder="Search Battery type/Category/Model Name/Model Number"
                                    onChange={(e: any) => {
                                        this.setState({ searchValue: e.target.value });
                                        this.setState({ openSearch: true });
                                        this.recentSearchCall()
                                    }}

                                    InputProps={{

                                        endAdornment: (
                                            <InputAdornment
                                                position="end"
                                                style={{
                                                    backgroundColor: "#a91c17",
                                                    padding: "12px 15px",
                                                    height: "50px",
                                                    marginRight: "-14px",
                                                }}
                                            >
                                                <span onClick={this.handleSearch} style={{ color: "white", cursor: 'pointer' }}>
                                                    <SearchIcon />
                                                </span>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                            <div className='search-bar'>
                           
                                <TextField
                                    fullWidth
                                    value={this.state.searchValue}
                                   style={{width:'600px',backgroundColor:'white',borderRadius:'5px'}}
                                    variant="outlined"
                                    placeholder="Search Battery type/Category/Model Name/Model Number"
                                    onClick={()=>{
                                        this.setState({ openSearch: true });
                                        this.recentSearchCall()
                                    }}
                                    onChange={(e: any) => {
                                        this.setState({ searchValue: e.target.value });
                                        // this.setState({ openSearch: true });
                                    }}

                                    InputProps={{

                                        endAdornment: (
                                            <InputAdornment
                                                position="end"
                                                style={{
                                                    backgroundColor: "#a91c17",
                                                    padding: "12px 15px",
                                                    height: "50px",
                                                    marginRight: "-14px",
                                                }}
                                            >
                                                <span onClick={this.handleSearch} style={{ color: "white", cursor: 'pointer' }}>
                                                    <SearchIcon />
                                                </span>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                        </div>


                        <div style={{ position: 'relative' }}  >
                            {this.state.openSearch &&
                                <div ref={this.wrapperRef} style={{ position: "absolute", top: '95%', left: '-750px', width: '600px', backgroundColor: "white", zIndex: 1 }}>
                                    <Typography style={{ padding: "10px", fontSize: "14px", cursor: "pointer" }}

                                    >
                                        Recent searches
                                    </Typography>

                                    {this.state.recentSearchData?.map((item: any, index: any) => {
                                        return (
                                            <div key={index} style={{ display: "flex", justifyContent: 'space-between', padding: '10px' }}>
                                                <div >
                                                    {item?.search_term}
                                                </div>
                                                {/* {item?.search_term && */}
                                                <div style={{ cursor: "pointer" }}
                                                    onClick={() => { this.handleRecentItemsDelete(index, item) }}><Close />
                                                </div>
                                                {/* } */}
                                            </div>
                                        )
                                    })}


                                    <div style={{ display: "flex", justifyContent: 'space-between' }}>
                                        
                                            <div 
                                            onClick={()=>{
                                                this.clearSearch()
                                                this.props.history?.push('/AllBatteries')
                                            }}
                                            style={{ width: "260px", margin: "10px", padding: "20px 10px", backgroundColor: "#d6f5ed", borderRadius: "5px",cursor: 'pointer'  }}>
                                                <div style={{ textAlign: 'center', fontWeight: 'bold' }}>

                                                    All Batteries
                                                </div>

                                            </div>
                                      

                                        <div onClick={() => {
                                            this.clearSearch()
                                            this.setState({ openAppChart: true });
                                            this.setState({ openSearch: false })
                                        }} style={{ margin: "10px", width: "260px", padding: "20px 10px", backgroundColor: "#32cba1", borderRadius: "5px", cursor: 'pointer' }}>

                                            <div style={{ textAlign: 'center', fontWeight: 'bold' }}>Need help with battery selection</div>

                                        </div>

                                    </div>

                                </div>
                            }

                        </div>

                        {/* application chart */}
                        <Filteritems navigation={this.props.navigation} history={this.props.history} id={this.props.id} openAppChart={this.state.openAppChart} closeAppChart={this.closeAppChart} handleVeh={undefined} handleInv={undefined} handleCheckedOption={undefined} />

                        <div style={{ display: "flex", alignItems: "center" }}>
                            <div style={{ marginRight: "20px", cursor: 'pointer' }}>
                                <img onClick={() => {
                                    if (localStorage.getItem("loginSuccessToken")) {
                                        this.setState({ openSoS: true });
                                    }
                                    else {
                                        localStorage.setItem('wishList', JSON.stringify(false))
                                        this.props.history.push("/LoginRequired")
                                    }
                                }} src={sos} width="50px" />
                            </div>
                            <div style={{ marginRight: "20px", cursor: 'pointer' }}>
                                <img src={bell} width="30px" onClick={() => this.setState({ isNotify: !this.state.isNotify })} />
                            </div>
                            <div style={{ cursor: 'pointer' }}>
                                <img src={cart} width="30px" onClick={() => {
                                    this.clearSearch()
                                    if (localStorage.getItem("loginSuccessToken")) {
                                        this.props.history.push("/AddShoppingCartOrderItem")
                                    } else {
                                        this.clearSearch()
                                        localStorage.setItem('wishList', JSON.stringify(false))
                                        this.props.history.push("/LoginRequired")
                                    }
                                }} />
                            </div>
                        </div>

                        {/* sos */}
                        <SoS navigation={this.props.navigation} history={this.props.history} id={this.props.id} openSoS={this.state.openSoS} closeSoS={this.closeSoS} clearSearch={this.clearSearch} />
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                position: "relative",
                            }}
                        >
                            {!localStorage.getItem("loginSuccessToken") ? <div onClick={() => this.props.history.push("/EmailAccountLoginBlock")} style={{ marginRight: "10px", marginLeft: "20px", color: 'white', cursor: 'pointer' }}>
                                Login

                            </div> : <div style={{ marginRight: "10px", marginLeft: "20px", color: 'white', cursor: 'pointer' }}><Account /></div>}
                            {localStorage.getItem("loginSuccessToken") && <div
                                onClick={(e) => {
                                    this.handleClick(e);

                                }}
                                style={{ color: "white", cursor: "pointer" }}
                            >
                                <ArrowDownIcon />
                            </div>}

                            {this.state.openProfile &&
                                <div ref={this.wrapperRef} style={{
                                    width: "300px", height: "375px", position: "absolute", top: '95%', right: '-33%', backgroundColor: "white", border: "1px solid #F4F5F6",
                                    marginLeft: "-140px", marginTop: "13px", borderRadius: "5px", zIndex: 1
                                }}>


                                    <div style={{ display: "flex", marginBottom: "-35px" }}>
                                        <div style={{ margin: "30px 20px", display: "flex", cursor: "pointer" }}
                                            onClick={() => { this.setState({ serachData: [] }); this.setState({ searchValue: '' }); this.setState({ openProfile: false }); this.props.history.push("/UserProfileBasicBlock") }}
                                        >
                                            <img src={ProfileMan} style={{ width: "20px", height: "20px" }} />
                                            <p style={{ marginLeft: "20px", marginTop: "0px" }}>Profile</p>
                                        </div>

                                    </div>

                                    <div style={{ margin: "30px 20px", display: "flex", cursor: "pointer" }}
                                        onClick={() => { this.setState({ serachData: [] }); this.setState({ searchValue: '' }); this.setState({ openProfile: false }); this.props.history.push("/MyBatteryCollection") }}
                                    >
                                        <img src={MyBatteryCollection} style={{ width: "20px", height: "20px" }} />
                                        <p style={{ marginLeft: "20px", marginTop: "0px" }}>My Battery Collection</p>
                                    </div>
                                    <div style={{ margin: "30px 20px", display: "flex", cursor: "pointer" }}
                                        onClick={() => { this.setState({ serachData: [] }); this.setState({ searchValue: '' }); this.setState({ openProfile: false }); this.props.history.push("/Orders") }}
                                    >
                                        <img src={Orders} style={{ width: "20px", height: "20px" }} />
                                        <p style={{ marginLeft: "20px", marginTop: "0px" }}>Orders</p>
                                    </div>
                                    <div style={{ margin: "30px 20px", display: "flex", cursor: "pointer" }}
                                        onClick={() => { this.setState({ serachData: [] }); this.setState({ searchValue: '' }); this.setState({ openProfile: false }); this.props.history.push("/Wishlist") }}
                                    >
                                        <img src={LoveImg} style={{ width: "20px", height: "20px" }} />
                                        <p style={{ marginLeft: "20px", marginTop: "0px" }}>Wishlist</p>
                                    </div>
                                    <div style={{ margin: "30px 20px", display: "flex", cursor: "pointer" }}
                                        onClick={() => { this.setState({ serachData: [] }); this.setState({ searchValue: '' }); this.setState({ openProfile: false }); this.props.history.push("/Faq") }}
                                    >
                                        <img src={FaqImg} style={{ width: "20px", height: "20px" }} />
                                        <p style={{ marginLeft: "20px", marginTop: "0px" }}

                                        >FAQs</p>
                                    </div>
                                    <p style={{ marginLeft: "20px", marginTop: "-16px", cursor: "pointer" }}
                                        onClick={() => {
                                            localStorage.removeItem("loginSuccessToken")
                                            this.props.history.push("/EmailAccountLoginBlock")
                                        }}
                                    >Logout</p>
                                </div>
                            }



                            {this.state.profileModal && (
                                <Paper elevation={3}>
                                    <Box
                                        sx={{
                                            bgcolor: "background.paper",
                                            width: "400px",
                                            position: "absolute",
                                            top: "95%",
                                            right: "-33%",
                                        }}
                                    >
                                        <div style={{ padding: "10px" }}>
                                            <div>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    <span>Profile</span>
                                                    <div
                                                        style={{
                                                            border: "1px solid black",
                                                            padding: "0px 5px",
                                                            borderRadius: "25px",
                                                        }}
                                                    >
                                                        121
                                                    </div>
                                                </div>
                                                <div>Update Your Preferences!</div>
                                            </div>

                                            <div>
                                                <div style={{ fontSize: "small", padding: "10px 0px" }}>
                                                    PERSONALIZATION
                                                </div>
                                                <div style={{ display: "flex" }}>
                                                    <div style={{ padding: "0px 10px 0px 0px" }}>
                                                        <Person />
                                                    </div>
                                                    <div
                                                        onClick={() =>
                                                            this.props.history.push("/ProfileDetails")
                                                        }
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        <small style={{ fontWeight: "bold" }}>
                                                            Profile Details
                                                        </small>
                                                        <small>Manage Your Information</small>
                                                    </div>
                                                </div>

                                                <div style={{ display: "flex", padding: "10px 0px" }}>
                                                    <div style={{ padding: "0px 10px 0px 0px" }}>
                                                        <Person />
                                                    </div>
                                                    <div
                                                        onClick={() =>
                                                            this.setState({ toggleLanguage: true })
                                                        }
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        <small style={{ fontWeight: "bold" }}>
                                                            Language
                                                        </small>
                                                        <small>Toggle Between English and Hindi</small>
                                                    </div>
                                                </div>
                                            </div>
                                            <Divider />

                                            <div>
                                                <div style={{ fontSize: "small", padding: "10px 0px" }}>
                                                    ORDERS AND MORE
                                                </div>
                                                <div style={{ display: "flex" }}>
                                                    <div style={{ padding: "0px 10px 0px 0px" }}>
                                                        <Person />
                                                    </div>
                                                    <div
                                                        onClick={() =>
                                                            this.props.history.push("/InvoicesBillingHistory")
                                                        }
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        <small style={{ fontWeight: "bold" }}>
                                                            Invoices and Billing History
                                                        </small>
                                                        <small>
                                                            Find Invoices for upcoming and past payments
                                                        </small>
                                                    </div>
                                                </div>

                                                <div style={{ display: "flex", padding: "10px 0px" }}>
                                                    <div style={{ padding: "0px 10px 0px 0px" }}>
                                                        <Person />
                                                    </div>
                                                    <div
                                                        style={{ display: "flex", flexDirection: "column" }}
                                                    >
                                                        <small style={{ fontWeight: "bold" }}>Orders</small>
                                                        <small>Find Order Updates</small>
                                                    </div>
                                                </div>
                                                <div style={{ display: "flex", padding: "10px 0px" }}>
                                                    <div style={{ padding: "0px 10px 0px 0px" }}>
                                                        <Person />
                                                    </div>
                                                    <div
                                                        onClick={() =>
                                                            this.props.history.push(
                                                                "/TechnicianManagement"
                                                            )
                                                        }
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        <small style={{ fontWeight: "bold" }}>
                                                            Technician Management
                                                        </small>
                                                        <small>Manage Your Technicians Here</small>
                                                    </div>
                                                </div>
                                            </div>
                                            <Divider />
                                            <div>
                                                <div style={{ fontSize: "small", padding: "10px 0px" }}>
                                                    FOR CUSTOMERS
                                                </div>
                                                <div style={{ display: "flex" }}>
                                                    <div style={{ padding: "0px 10px 0px 0px" }}>
                                                        <Person />
                                                    </div>
                                                    <div
                                                        onClick={() =>
                                                            this.props.history.push(
                                                                "/ServiceCallRequest"
                                                            )
                                                        }
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        <small style={{ fontWeight: "bold" }}>
                                                            Service Call Request
                                                        </small>
                                                        <small>Check Request Placed by Customers</small>
                                                    </div>
                                                </div>

                                                <div style={{ display: "flex", padding: "10px 0px" }}>
                                                    <div style={{ padding: "0px 10px 0px 0px" }}>
                                                        <Person />
                                                    </div>
                                                    <div
                                                        onClick={() =>
                                                            this.props.history.push(
                                                                "/WarrantyRegistration"
                                                            )
                                                        }
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        <small style={{ fontWeight: "bold" }}>
                                                            Register Warranty
                                                        </small>
                                                        <small>
                                                            Register warranty of Batteries placed offline
                                                        </small>
                                                    </div>
                                                </div>
                                                <div style={{ display: "flex", padding: "10px 0px" }}>
                                                    <div style={{ padding: "0px 10px 0px 0px" }}>
                                                        <Person />
                                                    </div>
                                                    <div
                                                        onClick={() =>
                                                            this.props.history.push(
                                                                "/ManageBatteryCollection"
                                                            )
                                                        }
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        <small style={{ fontWeight: "bold" }}>
                                                            Manage Battery Collection
                                                        </small>
                                                        <small>Manage collections of customers</small>
                                                    </div>
                                                </div>
                                                <div style={{ display: "flex", padding: "10px 0px" }}>
                                                    <div style={{ padding: "0px 10px 0px 0px" }}>
                                                        <Person />
                                                    </div>
                                                    <div
                                                        style={{ display: "flex", flexDirection: "column" }}
                                                    >
                                                        <small style={{ fontWeight: "bold" }}>
                                                            Service Call
                                                        </small>
                                                        <small>
                                                            Register s service call for a customer
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ padding: "10px" }}>Logout</div>
                                        </div>
                                    </Box>
                                </Paper>
                            )}
                        </div>
                    </div>
                </div>
                <div className='sub-categories'>
                <Container>
                    <div style={{display:'flex',justifyContent:'space-between'}}>


                    {this.state.subCategories?.map((item: any) => {
                        return <div key={item?.id} className='sub-category'
                        onClick={() => {
                            this.setState({ searchValue: '' });
                            this.setState({ serachData: [] });
                            localStorage.setItem('subCategoryId', item?.id);
                            
                            if (item?.attributes?.name?.toLowerCase() == '2 wheeler') {
                                this.props.history.push("/Categoriessubcategories")
                            }
                            if (item?.attributes?.name?.toLowerCase() == '3 wheeler') {
                                    this.props.history.push("/ThreeWheeler")
                                }
                                if (item?.attributes?.name?.toLowerCase() == '4 wheeler' || item?.attributes?.name == 'Car/SUV/MUV') {
                                    this.props.history.push("/CarSuvMuvBatteries")
                                }
                                if (item?.attributes?.name?.toLowerCase() == 'inverter') {
                                    this.props.history.push("/InverterDashboard")
                                }
                                if (item?.attributes?.name?.toLowerCase() == 'tractor') {
                                    this.props.history.push("/TractorDashboard")
                                }
                                if (item?.attributes?.name?.toLowerCase() == 'commercial vehicle') {
                                    this.props.history.push("/CommercialVehicle")
                                }
                            }}>{item?.attributes?.name?.toUpperCase()} BATTERIES</div>
                        })}
                        </div>

                        </Container>
                </div>


                <Container>
                    {this.state.serachData?.length > 0 && <div style={{ fontWeight: 'bold', margin: '20px 0px', fontSize: '15px' }}>Search results for {this.state.searchValue}</div>}
                    {this.state.serachData?.length > 0 &&<Grid container spacing={3} style={{marginBottom:'50px'}}>
                        {this.state.serachData.map((item: any) => {
                            return <Grid item xs={12} md={4}>
                                <Card>
                                    <div style={{ display: "flex", justifyContent: 'space-between', padding: '15px' }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>

                                            <img src={securedGreenTick} width='25px' height='25px' />
                                            <small style={{ textDecoration: 'underline', marginLeft: '10px', color: "#9CA0A9" }}>{item?.attributes?.warranty}</small>
                                        </div>

                                        <div onClick={() => {
                                            if (localStorage.getItem('loginSuccessToken')) {

                                                if (item?.attributes?.is_wishlisted) {
                                                    localStorage.setItem('item_id', item?.id);
                                                    this.deleteWishlist()

                                                }
                                                else {

                                                    localStorage.setItem('item_id', item?.id);
                                                    this.createWishList()
                                                }
                                            }
                                            else {
                                                this.clearSearch()
                                                localStorage.setItem('wishList', JSON.stringify(true))
                                                this.props.history?.push('/LoginRequired')
                                            }

                                        }}
                                            style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>

                                            <img src={item?.attributes?.is_wishlisted ? heart : love} width='25px' height='20px' />
                                        </div>


                                    </div>
                                    <CardContent>
                                        <div
                                            onClick={() => {
                                                this.setState({ serachData: [] })
                                                this.setState({ searchValue: '' })
                                                localStorage.setItem("applyShowBatteriesId", item.id)
                                                this.props.history?.push(`/ProductDescription1/${item?.id}`)
                                            }}
                                            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }}>
                                            <div style={{ padding: '15px' }}><img src={item?.attributes?.thumbnail_image} width='100' height='100' /></div>



                                            <div style={{ fontWeight: 'bold', marginTop: '15px' }}>{item?.attributes?.model_no}</div>
                                            <div style={{ fontWeight: 'bold', marginTop: '15px', marginBottom: '20px' }}><span style={{ color: '#B1B5C3' }}>Capacity</span> {item?.attributes?.capaciy}</div>

                                            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                <span style={{ fontWeight: 'bold', fontSize: '20px' }}>&#x20b9;{this.finalPrice(item)}</span>
                                                <s style={{ color: '#9498A9', marginLeft: '10px', fontSize: '20px', fontWeight: 'bold' }}>&#x20b9;{item?.attributes?.amount}</s>
                                            </div>
                                            <div style={{ display: 'flex', marginTop: '10px' }}>
                                                <div style={{ border: '1px solid gray', display: 'flex', marginRight: '10px', borderRadius: '25px', paddingLeft: '5px', paddingRight: '5px' }}>
                                                    <span>

                                                        <img src={yellowStar} width='15px' height='15px' />
                                                    </span>


                                                    <span style={{ display: 'flex', alignItems: 'center' }}>{item?.attributes?.range}</span>

                                                </div>
                                                <span
                                                    style={{
                                                        borderRadius: '25px', paddingLeft: '10px', color: 'white',
                                                        display: 'flex', alignItems: 'center',
                                                        paddingRight: '10px', backgroundColor: '#66bc54'
                                                    }}> {Math.round(item?.attributes?.applied_discount)}% off</span>
                                            </div>
                                        </div>
                                    </CardContent>


                                </Card>

                            </Grid>
                        })}

                    </Grid>}
                    {(this.props.location?.pathname.includes('/SearchBatteryResult') && this.state?.serachData?.length == 0) && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '100px', marginBottom: '100px' }}>
                        No results found
                    </div>}

                </Container>



            </>
        )
    }
}
export default withRouter(Nav)
// Customizable Area End
