// Customizable Area Start
import React, { Component } from "react";
import MerchantOrderController from "./MerchantOrderController";
import Sidebar from "./Sidebar.web";
import {
  Box,
  Container,
  Modal,
  Button,
  ListItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Collapse,
  List,
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
  Switch,
  Radio,
  Paper,
  Avatar,
  Badge,
  Backdrop,
  Fade,
} from "@material-ui/core";
import Person from "@material-ui/icons/Person";
import Right from "@material-ui/icons/ArrowForward";
import Close from "@material-ui/icons/Close";
import Bell from "@material-ui/icons/NotificationsNone";
import Place from "@material-ui/icons/Place";
import Mail from "@material-ui/icons/Mail";
import Copy from "@material-ui/icons/FileCopy";
import DateRange from "@material-ui/icons/DateRange";
import Notification from "./Notification.web";

const ezBattery = require(".././assets/red_battery.png");
const report = require(".././assets/report.png");

const buttonColor = {
  backgroundColor: "#32cba1",
  padding: "10px 50px",
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  mt: 5,
  mb: 5,

  boxShadow: 24,
  p: 2,
  borderRadius: "20px",
};

export default class TechnicianDashboard extends MerchantOrderController {
  arr = [0, 1, 2];
  arr1 = [0, 1, 2];
  render() {
    return (
      <>
        <div style={{ display: "flex" }}>
          {/* sidebar */}

          <Sidebar navigation={this.props.navigation} id={this.props.id} />

          <Container style={{ marginTop: "20px" }}>
            <Notification
              navigation={this.props.navigation}
              id={this.props.id}
            />

            <Grid container spacing={3}>
              <Grid item xs={12} md={8} style={{ marginRight: "70px" }}>
                <Grid spacing={2} container>
                  <Grid item xs={12} md={12}>
                    <div style={{ padding: "20px 0px" }}>
                      Hi John Good morning
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ fontWeight: "bold", fontSize: "25px" }}>
                        Upcoming Service Calls
                      </div>
                      <div>
                        <Button style={buttonColor}>See All</Button>
                      </div>
                    </div>
                  </Grid>

                  {this.arr.map((item) => {
                    return (
                      <Grid item xs={12} md={6}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            margin: "10px 0px",
                          }}
                        >
                          <span>
                            <DateRange />
                          </span>
                          <div>10 Apr 22 - 4:30PM</div>
                        </div>
                        <Paper elevation={2}>
                          <Box
                            onClick={() =>
                              this.setState({ toggleServiceModal: true })
                            }
                            sx={{
                              bgcolor: "background.paper",
                              borderRadius: "20px",
                              padding: "5px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <div
                                style={{
                                  borderRight: "1px solid black",
                                  borderBottom: "1px solid black",
                                  padding: "5px",
                                }}
                              >
                                <div
                                  style={{
                                    marginRight: "30px",
                                    marginTop: "10px",
                                  }}
                                >
                                  <small>Type of service call</small>
                                  <div>
                                    <small style={{ fontWeight: "bold" }}>
                                      Car Battery Problem
                                    </small>
                                  </div>
                                  <div
                                    style={{
                                      border: "1px solid black",
                                      borderRadius: "25px",
                                      padding: "0px 5px",
                                      width: "max-content",
                                      margin: "5px 0px",
                                    }}
                                  >
                                    <span style={{ fontSize: "small" }}>
                                      Other Battery
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div
                                style={{
                                  borderBottom: "1px solid black",
                                  padding: "5px",
                                }}
                              >
                                <div
                                  style={{
                                    marginLeft: "15px",
                                    marginTop: "10px",
                                  }}
                                >
                                  <small>Assigned Technician</small>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Avatar
                                      style={{ width: "25px", height: "25px" }}
                                      alt="Remy Sharp"
                                      src="/static/images/avatar/1.jpg"
                                    />
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        padding: "10px",
                                      }}
                                    >
                                      <small>John Doe</small>
                                      <small>+91 788394994</small>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div
                                style={{ fontSize: "small", padding: "5px" }}
                              >
                                Customer Details
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div style={{ fontSize: "small" }}>
                                  <Person />
                                </div>
                                <div
                                  style={{
                                    fontSize: "small",
                                    paddingLeft: "5px",
                                  }}
                                >
                                  John Doe, +91788448848
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div>
                                  <Place fontSize="small" />
                                </div>
                                <div
                                  style={{
                                    fontSize: "small",
                                    paddingLeft: "5px",
                                  }}
                                >
                                  Bezel Pharma, Opp Plot 99,Govt Indl
                                  Estate,Mumbai,7233
                                </div>
                              </div>
                            </div>
                          </Box>
                        </Paper>
                      </Grid>
                    );
                  })}
                </Grid>
                <div style={{ margin: "20px 0px" }}>
                  <Divider />
                </div>

                <Grid spacing={2} container style={{ marginTop: "15px" }}>
                  <Grid item xs={12} md={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ fontWeight: "bold", fontSize: "25px" }}>
                        Past Service Calls
                      </div>
                      <div>
                        <Button style={buttonColor}>See All</Button>
                      </div>
                    </div>
                  </Grid>

                  {this.arr.map((item) => {
                    return (
                      <Grid item xs={12} md={6}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            margin: "10px 0px",
                          }}
                        >
                          <span>
                            <DateRange />
                          </span>
                          <div>10 Apr 22 - 4:30PM</div>
                        </div>
                        <Paper elevation={2}>
                          <Box
                            sx={{
                              bgcolor: "background.paper",
                              borderRadius: "20px",
                              padding: "5px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <div
                                style={{
                                  borderRight: "1px solid black",
                                  borderBottom: "1px solid black",
                                  padding: "5px",
                                }}
                              >
                                <div
                                  style={{
                                    marginRight: "30px",
                                    marginTop: "10px",
                                  }}
                                >
                                  <small>Type of service call</small>
                                  <div>
                                    <small style={{ fontWeight: "bold" }}>
                                      Car Battery Problem
                                    </small>
                                  </div>
                                  <div
                                    style={{
                                      border: "1px solid black",
                                      borderRadius: "25px",
                                      padding: "0px 5px",
                                      width: "max-content",
                                      margin: "5px 0px",
                                    }}
                                  >
                                    <span style={{ fontSize: "small" }}>
                                      Other Battery
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div
                                style={{
                                  borderBottom: "1px solid black",
                                  padding: "5px",
                                }}
                              >
                                <div
                                  style={{
                                    marginLeft: "15px",
                                    marginTop: "10px",
                                  }}
                                >
                                  <small>Assigned Technician</small>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Avatar
                                      style={{ width: "25px", height: "25px" }}
                                      alt="Remy Sharp"
                                      src="/static/images/avatar/1.jpg"
                                    />
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        padding: "10px",
                                      }}
                                    >
                                      <small>John Doe</small>
                                      <small>+91 788394994</small>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div
                                style={{ fontSize: "small", padding: "5px" }}
                              >
                                Customer Details
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div style={{ fontSize: "small" }}>
                                  <Person />
                                </div>
                                <div
                                  style={{
                                    fontSize: "small",
                                    paddingLeft: "5px",
                                  }}
                                >
                                  John Doe, +91788448848
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div>
                                  <Place fontSize="small" />
                                </div>
                                <div
                                  style={{
                                    fontSize: "small",
                                    paddingLeft: "5px",
                                  }}
                                >
                                  Bezel Pharma, Opp Plot 99,Govt Indl
                                  Estate,Mumbai,7233
                                </div>
                              </div>
                            </div>
                          </Box>
                        </Paper>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>

              <Grid item xs={12} md={3} style={{ marginTop: "65px" }}>
                <Paper elevation={3}>
                  <Box
                    sx={{
                      bgcolor: "background.paper",
                      marginRight: "25px",
                      padding: "20px 25px",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: "20px",
                        padding: "20px 20px 0px 20px",
                      }}
                    >
                      Vision Statement
                    </div>
                    <div
                      style={{
                        width: "70x",
                        fontSize: "smaller",
                        padding: "15px 20px 20px 20px",
                      }}
                    >
                      Laxmi Motors through EZ Battery {"&"} Auto Spares
                      envisions to be the first
                    </div>
                  </Box>
                </Paper>

                <div
                  style={{
                    padding: "15px",
                    margin: "35px 0px",
                    backgroundColor: "#32cba1",
                    borderRadius: "10px",
                    fontWeight: "bold",
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "small",
                  }}
                >
                  Manage Customer Battery Collection
                </div>

                <div style={{ margin: "20px 0px", fontWeight: "bold" }}>
                  Request Reports
                </div>
                <div style={{ display: "flex" }}>
                  {this.arr1.map(() => {
                    return (
                      <Box
                        sx={{
                          bgcolor: "background.paper",
                          padding: "10px",
                          margin: "20px 10px 20px 0px",
                          borderRadius: "10px",
                        }}
                      >
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <img src={report} width="60px" height="60px" />
                        </div>
                        <div
                          style={{
                            fontSize: "small",
                            padding: "10px 0px 0px 0px",
                            fontWeight: "bold",
                          }}
                        >
                          Technician's{" "}
                        </div>
                        <div
                          style={{
                            display: "small",
                            justifyContent: "center",
                            fontSize: "15px",
                            padding: "0px 0px 10px 0px",
                            fontWeight: "bold",
                          }}
                        >
                          Report
                        </div>
                      </Box>
                    );
                  })}
                </div>

                <div style={{ fontWeight: "bold", marginTop: "55px" }}>
                  Contact Us
                </div>

                <Paper elevation={3}>
                  <Box
                    sx={{
                      marginRight: "25px",
                      marginTop: "30px",
                      bgcolor: "background.paper",
                      width: "300px",
                      padding: "10px",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "small",
                        padding: "10px 10px 0px 10px",
                      }}
                    >
                      Dealer contact details
                    </div>
                    <div
                      style={{
                        display: "flex",
                        fontSize: "small",
                        alignItems: "center",
                        padding: "5px",
                      }}
                    >
                      <div>
                        <Person fontSize="small" />
                      </div>
                      <div style={{ fontWeight: "bold" }}>
                        Aaron Almaraz, +91 73535672
                      </div>
                      <div style={{ color: "#32cba1" }}>
                        <Copy fontSize="small" />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        fontSize: "small",
                        alignItems: "center",
                        padding: "5px",
                      }}
                    >
                      <div>
                        <Mail fontSize="small" />
                      </div>
                      <div style={{ fontWeight: "bold" }}>
                        aaron@hotmail.com
                      </div>
                      <div style={{ color: "#32cba1" }}>
                        <Copy fontSize="small" />
                      </div>
                    </div>
                    <Divider />

                    <div
                      style={{
                        fontSize: "small",
                        padding: "10px 10px 0px 10px",
                      }}
                    >
                      EZ Battery contact details
                    </div>
                    <div
                      style={{
                        display: "flex",
                        fontSize: "small",
                        alignItems: "center",
                        padding: "5px",
                      }}
                    >
                      <div>
                        <Person fontSize="small" />
                      </div>
                      <div style={{ fontWeight: "bold" }}>
                        Carol D, +91 73535672
                      </div>
                      <div style={{ color: "#32cba1" }}>
                        <Copy fontSize="small" />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        fontSize: "small",
                        alignItems: "center",
                        padding: "5px",
                      }}
                    >
                      <div>
                        <Mail fontSize="small" />
                      </div>
                      <div style={{ fontWeight: "bold" }}>
                        carol@ezbattery.com
                      </div>
                      <div style={{ color: "#32cba1" }}>
                        <Copy fontSize="small" />
                      </div>
                    </div>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </div>

        {/* modal */}
        <div>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={this.state.toggleServiceModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={this.state.toggleServiceModal}>
              <Box sx={style}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>Service Details</div>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => this.setState({ toggleServiceModal: false })}
                  >
                    <Close />
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "15px",
                  }}
                >
                  <div
                    style={{
                      fontSize: "small",
                      display: "flex",
                      fontWeight: "bold",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <Person />
                    </div>
                    <div style={{ marginLeft: "5px" }}>John Doe, </div>
                    <div>+91 73883783</div>
                  </div>
                  <div
                    style={{
                      color: "red",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Call
                  </div>
                </div>

                <div
                  style={{
                    fontSize: "small",
                    fontWeight: "bold",
                    display: "flex",
                    alignItems: "center",
                    margin: "10px 0px",
                  }}
                >
                  <div>
                    <Place />
                  </div>
                  <div style={{ marginLeft: "5px" }}>
                    Bezel Pharma, Opp Plot 99,Govt Indl Estate,Mumbai,7233
                  </div>
                </div>

                <Divider />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "10px 0px",
                  }}
                >
                  <div>Type of service call</div>
                  <div style={{ fontWeight: "bold" }}>Car Battery Problem</div>
                </div>
                <Divider />

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "10px 30px",
                  }}
                >
                  <div
                    style={{
                      fontSize: "small",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    Click on out for service when you are on your way to
                    Customer's location
                  </div>
                </div>

                {!true && (
                  <div
                    style={{ backgroundColor: "#32cba1", borderRadius: "10px" }}
                  >
                    <div
                      style={{
                        padding: "15px 50px",
                        display: "flex",
                        justifyContent: "center",
                        fontWeight: "bold",
                      }}
                    >
                      Out for service
                    </div>
                  </div>
                )}

                <div
                  style={{
                    backgroundColor: "#32cba1",
                    borderRadius: "28px",
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      borderRadius: "50%",
                      padding: "15px",
                      backgroundColor: "white",
                      color: "#32cba1",
                      margin: "3px",
                    }}
                  >
                    <Right />
                  </div>
                  <div
                    style={{
                      padding: "15px 50px",
                      display: "flex",
                      justifyContent: "center",
                      fontWeight: "bold",
                      alignItems: "center",
                    }}
                  >
                    Click to start service
                  </div>
                </div>
              </Box>
            </Fade>
          </Modal>
        </div>
      </>
    );
  }
}
// Customizable Area End