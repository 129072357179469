import React, { Component } from "react";
// Customizable Area Start

import {

    Stepper,
    Step,
    StepLabel,
    Button, withStyles, Grid, Avatar, Menu, Modal,Container,makeStyles
} from "@material-ui/core";
import Info from '@material-ui/icons/Info';
import FilteritemsController from "./FilteritemsController";
import FilterVehicleCategory from "./FilterVehicleCategory.web";
import FilterVehicleManufacturer from "./FilterVehicleManufacturer.web";
import FileterVehicleModel from "./FileterVehicleModel.web";
import FilterVehicleFuel from "./FilterVehicleFuel.web";
import VehicleBatteryType from "./VehicleBatteryType.web";
import FilterBatteryCapacity from "./FilterBatteryCapacity.web";
import FilterInverterDCInput from "./FilterInverterDCInput.web";
import FilterInverterCapacity from "./FilterInverterCapacity.web";
import FilterInverterBackup from "./FilterInverterBackup.web";

import { downloadImg } from "./assets";





const useStyles = makeStyles({
    completedStep: {
      border: '2px solid #43CBA0',
      padding: '10px',
      backgroundColor: '#EBEEF1',
      "& .MuiStepIcon-completed": { 
          color: "#43CBA0",
          },
     
     
    },
    unCompletedStep:{
        padding: '10px',
         backgroundColor: '#EBEEF1',
         "& .MuiStepIcon-active": { 
             color: "#42454E",
             
      }
       
        
    },
   
  });
  
  const CustomStepper = (props:any) => {
    const classes = useStyles();
    const { children, activeStep } = props;
  
    return (
      <Stepper {...props}>
        {children.map((child:any, index:any) => (
          <Step key={index} className={activeStep > index ? classes.completedStep : classes.unCompletedStep}>
            {child.props.children}
          </Step>
        ))}
      </Stepper>
    );
  };





export default class Filteritems extends FilteritemsController {
    steps = [
        "Battery Type",
        "Vehicle Category",
        "Vehicle Manufacturer",
        "Vehicle Model",
        "Fuel Type",
    ];
    inverterSteps = [
        "Battery Type",
        "Inverter/H- Ups Capacity",
        "DC Input Voltage",
        "Backup time required",
        "Capacity",
    ];

    handleVeh = () => {
        this.setState({ isVehicular: true });
       
    };
    handleInv = () => {
        this.setState({ isVehicular: false });
        
    };
    handleCheckedOption=()=>{
        this.setState({ isSelected: true });
    }
    getStepContent = (step: number) => {
        switch (step) {
            case 0:
                return (
                    <VehicleBatteryType
                        handleVeh={this.handleVeh}
                        handleInv={this.handleInv}
                        navigation={this.props.navigation}
                        history={this.props.history}
                        id={this.props.id}
                        openAppChart={this.props.openAppChart}
                        closeAppChart={this.props.closeAppChart} handleCheckedOption={this.handleCheckedOption}/>
                );
            case 1:
                return (
                    <FilterVehicleCategory
                        navigation={this.props.navigation}
                        id={this.props.id}
                    />
                );
            case 2:
                return (
                    <FilterVehicleManufacturer
                        navigation={this.props.navigation}
                        id={this.props.id}
                    />
                );
            case 3:
                return (
                    <FileterVehicleModel
                        navigation={this.props.navigation}
                        id={this.props.id}
                    />
                );
            case 4:
                return (
                    <FilterVehicleFuel
                        navigation={this.props.navigation}
                        id={this.props.id}
                    />
                );
            default:
                throw new Error("Unknown step");
        }
    };

    getStepContentForInv = (step: number) => {
        switch (step) {
            case 0:
                return (
                    <VehicleBatteryType
                        handleVeh={this.handleVeh}
                        handleInv={this.handleInv}
                        navigation={this.props.navigation}
                        history={this.props.history}
                        id={this.props.id}
                        openAppChart={this.props.openAppChart}
                        closeAppChart={this.props.closeAppChart} handleCheckedOption={this.handleCheckedOption}/>
                );
            case 1:
                return (
                    <FilterInverterCapacity
                        navigation={this.props.navigation}
                        id={this.props.id}
                    />
                );
            case 2:
                return (
                    <FilterInverterDCInput
                        navigation={this.props.navigation}
                        id={this.props.id}
                    />
                );
            case 3:
                return (
                    <FilterInverterBackup
                        navigation={this.props.navigation}
                        id={this.props.id}
                    />
                );
            case 4:
                return (
                    <FilterBatteryCapacity
                        navigation={this.props.navigation}
                        id={this.props.id}
                    />
                );
            default:
                throw new Error("Unknown step");
        }
    };
    handleNext = () => {
        console.log(this.state.isSelected);
        this.setState({ activeStep: this.state.activeStep + 1 });
    };

    handleBack = () => {
        this.setState({ activeStep: this.state.activeStep - 1 });
    };
    getHeading = (step: any) => {
        switch (step) {
            case 0:
                return 'Select Battery Type';
            case 1:
                return 'Select Vehicle Category';
            case 2:
                return 'Select Vehicle Manufacturer';
            case 3:
                return 'Select Vehicle Model'
            case 4:
                return 'Select Fuel Type'
            default:
                throw new Error("Unknown step");
        }

    }
    getHeadingInv = (step: any) => {
        switch (step) {
            case 0:
                return 'Select Battery Type';
            case 1:
                return 'Inverter/H-Ups Capacity (VA)';
            case 2:
                return 'DC Input Voltage(V)';
            case 3:
                return 'Backup time required'
            case 4:
                return 'Capacity (Ah)'
            default:
                throw new Error("Unknown step");
        }

    }
    render() {

        return (
            <div>
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.props.openAppChart}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <div
                        style={{
                            width: "936px",
                            height: "600px",
                            backgroundColor: "#fff",
                            border: "none",
                        }}
                    >
                        {/* {!this.state.checkNextVehicle && !this.state.checkNextInverter && */}
                        <>
                            <div>
                                {this.state.isVehicular ? (
                                    <CustomStepper  activeStep={this.state.activeStep}>
                                        {this.steps.map((label: any,index:any) => (
                                            <Step  key={label}>
                                                < StepLabel >
                                                    {label}
                                                </ StepLabel>
                                            </Step>
                                        ))}
                                    </CustomStepper>
                                ) : (
                                    <CustomStepper activeStep={this.state.activeStep}>
                                        {this.inverterSteps.map((label: any) => (
                                            <Step key={label}>
                                                <StepLabel

                                                >
                                                    {label}
                                                </StepLabel>
                                            </Step>
                                        ))}
                                    </CustomStepper>
                                )}
                            </div>

                            {this.state.isVehicular ? (
                                <div>
                                    {/* <Grid container style={{ marginLeft: "110px" }}> */}
                                        <Container >
                                        <div style={{display:'flex',marginLeft:'100px'}}>

                                        <Avatar
                                            onClick={(e) => {
                                                this.setState({ iMenu: e.currentTarget })
                                            }}
                                            style={{
                                                cursor: 'pointer',
                                                width: "25px",
                                                height: "25px",
                                                fontSize: "15px",
                                                margin: "17px 10px",
                                                backgroundColor: "#E0E0E0",
                                                color: '#B0B2B6'
                                            }}
                                            >
                                            i
                                        </Avatar>
                                       
                                            </div>
                                        </Container>

                                    {/* </Grid> */}
                                   
                                        <Container>


                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent:'space-around'
                                                
                                            }}
                                            >
                                            <h2>{this.getHeading(this.state.activeStep)}</h2>
                                            <div style={{display:'flex',alignItems:'center'}}>

                                            <img
                                                src={downloadImg}
                                                style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    color: "red",
                                                   
                                                }}
                                            />
                                            <div style={{ color: "red",marginLeft:'15px' }}>
                                                Download Application Chart
                                            </div>
                                                    </div>
                                        </div>
                                    </Container>
                                    
                                    {this.getStepContent(this.state.activeStep)}
                                  

                                </div>
                            ) : (
                                <div>
                                    <Container>
                                        <div style={{display:'flex',marginLeft:'100px'}}>


                                        <Avatar
                                            onClick={(e) => {
                                                this.setState({ iMenu: e.currentTarget })
                                            }}
                                            style={{
                                                cursor: 'pointer',
                                                width: "25px",
                                                height: "25px",
                                                fontSize: "15px",
                                                margin: "17px 10px",
                                                backgroundColor: "#E0E0E0",
                                                color: '#B0B2B6'
                                            }}
                                        >
                                            i
                                        </Avatar>
                                            </div>

                                            </Container>
                                   
                                    
                                    <Container>


                                        <div
                                            style={{
                                                display: "flex",
                                               justifyContent:'space-around'
                                            }}
                                            >
                                                <h2>{this.getHeadingInv(this.state.activeStep)}</h2>
                                                <div style={{display:'flex',alignItems:'center'}}>

                                            <img
                                                src={downloadImg}
                                                style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    color: "red",
                                                   
                                                }}
                                                />
                                            <div style={{ color: "red", marginLeft:'15px' }}>
                                                Download Application Chart
                                            </div>
                                                </div>
                                        </div>
                                    </Container>
                                  
                                    {this.getStepContentForInv(this.state.activeStep)}</div>
                            )}
                            <Menu
                                id="basic-menu"
                                anchorEl={this.state.iMenu}
                                open={Boolean(this.state.iMenu)}
                                onClose={() => this.setState({ iMenu: null })}
                                getContentAnchorEl={null}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                            >
                                <div style={{ width: '350px',textAlign:'justify',padding:'10px' }}>
                                    Welcome to the application chart section. Here you will be able to find the most suitable battery Vehicle.
                                    Please search for the model name of your vehicle in the chart below to find the battery model against it.
                                    You can then check the details page of that product.You will find technical as well as commercial details on
                                    that page enabling you to make a purchase decision
                                </div>

                            </Menu>
                            <Container>
                           {(this.state.activeStep===4 && this.state.isVehicular==false) && <div style={{ padding: '10px', backgroundColor: '#E6F3FA', width: '650px', marginTop: '40px', display: 'flex',marginLeft:'100px' }}>
                                        <span><Info style={{color:'#238ED5'}} /></span>
                                        <span style={{ color: '#71A9C4', marginLeft: '10px', display: 'flex', alignItems: 'center',fontSize:'small',fontWeight:"bold" }}>'Number' * 'Capacity', here 'number' represents number of batteries that need to be purchased </span>

                                    </div>}
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    margin: "55px 65px",
                                }}
                                >
                                <div
                                    onClick={() => {
                                        localStorage.removeItem('batteryType')
                                        this.props.closeAppChart();
                                        this.setState({ isSelected: false });
                                        this.setState({ activeStep: 0 })
                                    }}
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        fontWeight: "bold",
                                        cursor: "pointer",
                                    }}
                                >
                                    Cancel
                                </div>

                                {this.state.activeStep === this.steps.length - 1 ? (
                                    <div style={{ display: "flex" }}>
                                        <Button
                                            variant="outlined"
                                            onClick={this.handleBack}
                                            style={{
                                                padding: "10px 65px",
                                                margin: "30px 20px 0px 0px",
                                            }}
                                        >
                                            Back
                                        </Button>
                                        <Button
                                            variant="contained"
                                            onClick={() => {
                                                this.setState({ activeStep: 0 });
                                                this.props.closeAppChart()

                                                if (this.state.isVehicular) {
                                                    if (this.props?.history?.location?.pathname == '/FilterShowBatteries') {
                                                        window.location.reload()
                                                    }
                                                    else {
                                                        console.log(this.props.history?.push);
                                                        this.props.history?.push ? this.props.history.push("/FilterShowBatteries") : this.props.navigation.navigate('FilterShowBatteries')
                                                        

                                                    }
                                                } else {
                                                    this.props.history?.push ? this.props.history.push("/FilterInverterShowBatteries") : this.props.navigation.navigate("FilterInverterShowBatteries")
                                                    
                                                }
                                            }}
                                            style={{
                                                padding: "10px 30px",
                                                margin: "30px 20px 0px 0px",
                                                backgroundColor: "#32cba0",
                                            }}
                                        >
                                            Show Batteries
                                        </Button>
                                    </div>
                                ) : (
                                    <div>
                                        {this.state.activeStep !== 0 && (
                                            <Button
                                                onClick={this.handleBack}
                                                variant="outlined"
                                                style={{ padding: "10px 65px", marginRight: "15px" }}
                                                >
                                                Back
                                            </Button>
                                        )}

                                        <Button
                                            variant="contained"
                                            onClick={()=>{
                                                this.handleNext()
                                                // if(this.state.isSelected){
                                                    // }
                                                    // else{
                                                        //     this.setState({isError:true})
                                                        // }
                                                    }}
                                                    style={{
                                                        padding: "10px 65px",
                                                        backgroundColor: "#32cba0",
                                                    }}
                                           disabled={!this.state.isSelected}
                                           
                                        >
                                            Next
                                        </Button>
                                    </div>
                                )}
                            </div>
                                </Container>
                        </>




                    </div>
                </Modal>


            </div>
        );
    }
}

// Customizable Area End
