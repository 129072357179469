import React, { Component } from 'react'

import { Box, Container, Grid, Input, InputAdornment } from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import { Card, CardHeader, CardContent, CardMedia, CardActions } from "@material-ui/core"
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import { NavLink } from "react-router-dom";

// import "./assets/styles.css"


// import {
//   createStyles,
//   withStyles,
//   makeStyles,
//   Theme,
// } from "@material-ui/core/styles";
const EzBatteryWhite = require("../assets/Artboard 1 copy@4x.png")
const greenTick = require("../assets/greentick.png")
// const redBattery = require("../assets/red_battery.png")
const yellowStar = require("../assets/yellow_star.png")
const trippleLine = require("../assets/tripple_line.png")
const ezBattery = require("../assets/ez_battery.png")
const sosImage = require("../assets/sosImage.png")


const Styles = {
    inputSearchBox: {
        width: "25px",
        height: "35px",
        alignItems: "center",
        border: "1px solid #b30000",
        borderRadiusRight: "5px",
        backgroundColor: "#b30000",
        paddingRight: "10px",
        color: "white",
        cursor: "pointer"
    },
    cardhead: {
        font: "10px",
        color: "grayText",
        textDecoration: "underline",
        textDecorationColor: "gray",
        textDecorationThickness: "1px",
    }
}


import UserProfileBasicController, {
    Props
} from "./UserProfileBasicController";
import VisionStatement from '../../../components/src/VisionStatement.web';

export default class Faq extends UserProfileBasicController {
    render() {
        return (
            <>
                <Container >
                <div style={{ backgroundColor: "#f3f5f7" }}>
                            <div >
                                <h2>FAQs</h2>
                                {this.state.getFaqData.map((item: any, index: any) => {
                                    return (
                                        <Box key={index} style={{ backgroundColor: "#fff",marginBottom:"20px"}}>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                <Typography>{item?.attributes?.question}</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography style={{color:"#e1e4ec"}}>
                                                       <b>{item?.attributes?.answer}</b> 
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                            
                                        </Box>
                                    )
                                })}
                            </div>
                        </div>
                          <VisionStatement navigation={this.props.navigation} id={''} classes={undefined} openSearch={undefined} />
                </Container>
            </>
        )
    }
}
