// Customizable Area Start
import React, { Component } from 'react'
import OrdermanagementController from './OrdermanagementController';
import {
    Box,
    Container,
    Modal,
    Button,
    ListItem,
    IconButton,
    ListItemIcon,
    ListItemText,
    Divider,
    Collapse,
    List,
    Grid,
    Card,
    CardContent,
    CardActions,
    Typography,
    Switch, Radio, Checkbox

} from "@material-ui/core";
import Info from '@material-ui/icons/Info';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import VisionStatementWeb from '../../../components/src/VisionStatement.web';

const ezBattery = require(".././assets/red_battery.png");
const backIcon = require(".././assets/backIcon.png");
const card1 = require(".././assets/card1.png");
const card2 = require(".././assets/card2.png");
const gpay = require(".././assets/gpay.png");
const phonePay = require(".././assets/phonePay.png");
const paytm = require(".././assets/paytm.png");
const upi = require(".././assets/upi.png");
const bank = require(".././assets/bank.png");
const ambasdar = require(".././assets/ambasdar.png");
const batterySelection = require(".././assets/batterySelection.png");
const mobile = require(".././assets/mobile.png");

export default class Payment extends OrdermanagementController {
    arr = [{ label: 'Pickup', info: 'A representative will come and pickup the package from your address' },

    { label: 'Speed post', info: 'You need to send the package via speed post' },
    { label: 'Walk in', info: 'You need to drop the package to the nearest EZ battery office' }];

    handleCloseModal = () => {
        this.props.navigation.navigate('Orders')
        this.setState({ toggleExchangePending: false })
    }
    render() {
        return (
            <>
                <div style={{ display: 'flex', justifyContent: 'flex-start', padding: '20px', backgroundColor: '#efefef' }}>
                    <Container>

                    <div style={{ color: '#a1a0a4' }}>
                        <a href='/' style={{ textDecoration: 'none', color: '#a1a0a4' }}>Home</a> <ArrowForwardIosRoundedIcon className="arrow-left" /> Orders <ArrowForwardIosRoundedIcon className="arrow-left" /> EZTZ4 <ArrowForwardIosRoundedIcon className="arrow-left" /> Exchange
                    </div>
                    </Container>

                </div>

                <Container style={{ marginTop: '20px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div onClick={() => this.props.navigation.navigate('SelectProductToExchange')} style={{ marginBottom: '10px', marginRight: '10px' }} >
                            <img src={backIcon} style={{ backgroundColor: '#ebeff2', borderRadius: '50%', padding: '10px', width: '20px' }} />
                        </div>
                        <div style={{ fontWeight: 'bold', marginBottom: '10px' }}>Payment</div>
                    </div>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={8}>
                            <Box sx={{
                                bgcolor: 'background.paper',
                                // padding: '20px'
                            }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '15px 10px 0px 10px' }}>
                                    <div style={{ padding: '10px' }}>Credit/Debit Card</div>
                                    <div style={{ padding: '10px' }}><i>Tap on the card to pay</i></div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '15px' }}>
                                    <div>
                                        <img src={card1} width='250px' />
                                    </div>
                                    <div>
                                        <img src={card1} width='250px' />
                                    </div>
                                    <div>
                                        <img src={card1} width='250px' />
                                    </div>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <img src={card2} width='35px' />
                                        <span style={{ marginLeft: '10px' }}> Add a card</span>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <ChevronRightIcon />
                                    </div>
                                </div>
                                <Divider />
                                <div style={{ padding: '20px' }}>
                                    <div style={{ fontWeight: 'bold' }}>UPI</div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px 0px' }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <img src={card2} width='35px' />
                                            <span style={{ marginLeft: '10px' }}>BHIM</span>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <ChevronRightIcon />
                                        </div>
                                    </div>
                                    <Divider />
                                    <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px 0px' }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <img src={gpay} width='35px' />
                                            <span style={{ marginLeft: '10px' }}>GPay</span>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <ChevronRightIcon />
                                        </div>
                                    </div>
                                    <Divider />
                                    <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px 0px' }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <img src={phonePay} width='35px' />
                                            <span style={{ marginLeft: '10px' }}>PhonePe</span>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <ChevronRightIcon />
                                        </div>
                                    </div>
                                    <Divider />
                                    <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px 0px' }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <img src={paytm} width='35px' />
                                            <span style={{ marginLeft: '10px' }}>Paytm</span>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <ChevronRightIcon />
                                        </div>
                                    </div>
                                    <Divider />
                                    <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px 0px' }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <img src={upi} width='35px' />
                                            <span style={{ marginLeft: '10px' }}>Pay with another UPI id</span>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <ChevronRightIcon />
                                        </div>
                                    </div>


                                </div>
                                <Divider />
                                <div style={{ fontWeight: 'bold', padding: '15px' }}>Internet Banking</div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '15px' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <img src={bank} width='35px' />
                                        <span style={{ marginLeft: '10px' }}> Add a Bank</span>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <ChevronRightIcon />
                                    </div>
                                </div>


                            </Box>

                            {this.state.toggleExchangePending && <Box sx={{
                                bgcolor: 'background.paper',
                                padding: '30px'
                            }}>

                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                    <div style={{ fontWeight: 'bold', fontSize: '25px' }}>
                                        Exchange Request Pending
                                    </div>
                                    <small style={{ fontWeight: 'bold', marginTop: '10px' }}>Return id:384747483</small>
                                    <div style={{ padding: '5px', backgroundColor: '#fcf9f2', width: '670px', marginTop: '40px', display: 'flex' }}>
                                        <span><Info color='action' /></span>
                                        <span style={{ color: '#f0a00d', marginLeft: '5px', display: 'flex', alignItems: 'center' }}>We are working on the Exchange request placed by you, We'll get back to you soon</span>

                                    </div>


                                    <div
                                        onClick={() => this.handleCloseModal()}
                                        style={{
                                            width: '500px', padding: '20px 0px 20px 0px',
                                            border: '2px solid #e3e3e4', display: 'flex',
                                            justifyContent: 'center', marginTop: '15px'
                                        }}>Ok</div>


                                </div>


                            </Box>}

                            {!this.state.toggleExchangePending && <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                                <Button onClick={() => this.props.navigation.navigate('ConfirmExchange')} variant='outlined' style={{ padding: '10px 90px 10px 90px' }}>Cancel</Button>
                                {/* <Button onClick={() => this.setState({ toggleExchangePending: true })} style={{ padding: '10px 90px 10px 90px', backgroundColor: '#32cba0' }}>Confirm</Button> */}
                            </div>}
                        </Grid>


                        <Grid item xs={12} md={4}>

                            <Box sx={{
                                bgcolor: 'background.paper',

                            }}>
                                <div style={{ fontWeight: 'bold', padding: '20px', fontSize: '25px' }}>Order Summary</div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px' }}>
                                    <div>
                                        Subtotal
                                    </div>
                                    <div>Rs.4000</div>
                                </div>
                                <Divider />
                                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px' }}>
                                    <div>
                                        Order Total
                                    </div>
                                    <div style={{ fontWeight: 'bold', fontSize: '25px' }}>Rs.4000</div>
                                </div>
                                <Divider />
                                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px 20px 0px 20px' }}>
                                    <div>
                                        Use Reward Points
                                    </div>
                                    <div ><Switch /></div>
                                </div>
                                <div style={{ padding: '0px 20px 10px 20px' }}>
                                    <small>Available Points : 121</small>
                                </div>

                            </Box>



                        </Grid>

                    </Grid>

                   <VisionStatementWeb navigation={undefined} id={""} classes={undefined} openSearch={undefined}/>


                </Container>

                {/* <div>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={this.state.toggleCancelOrder}
                        onClose={() => this.handleCloseModal()}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={this.state.toggleCancelOrder}>
                            <Box sx={style}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ fontWeight: 'bold', fontSize: '20px' }}>Cancel Order?</div>
                                    <span><CloseIcon /></span>
                                </div>

                                <div style={{ marginTop: '20px', color: '#9da4b3' }}>
                                    Do you wish to cancel your order?Note: Refunds may take 5-7 business days to reflect on your account
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                                    <Button variant='outlined' onClick={() => this.handleCloseModal()} style={{ padding: '15px 45px' }}>No</Button>
                                    <Button variant='contained' style={{ padding: '15px 45px', backgroundColor: '#dd403a', marginLeft: '10px', color: 'white' }} >Yes</Button>
                                </div>








                            </Box>
                        </Fade>
                    </Modal>
                </div> */}

            </>
        )
    }
}
// Customizable Area End