// Customizable Area Start
import React, { Component } from 'react'
import VideosController from './VideosController';
import {
  Card, CardContent, Container, Grid,Backdrop,CircularProgress} from "@material-ui/core"
  import { love, securedGreenTick } from '../../filteritems/src/assets';
  import { heart, yellowStar } from '../../dashboard/src/VehiclesBatteries.web';
  import VisionStatement from '../../../components/src/VisionStatement.web';
  import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
  
  export default class Wishlist extends VideosController {
  render() {
    if (!localStorage.getItem("loginSuccessToken")) {
      this.props.navigation.navigate('signUp');
      return null
      
    }
    return (
      <>
       <Backdrop
          style={{ color: '#fff', zIndex: 1 }}
          open={this.state.isLoading}
          
          > <CircularProgress color="inherit" /></Backdrop>
        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px', backgroundColor: '#efefef' }}>
          <Container>

          <div style={{ color: '#a1a0a4' }}>
            <a href='/' style={{ textDecoration: 'none', color: '#a1a0a4' }}>Home</a> <ArrowForwardIosRoundedIcon className="arrow-left" /> Wishlist
          </div>
          {!localStorage.getItem("loginSuccessToken") && <div style={{ color: '#a1a0a4' }}>
            <span><i>For Better Experience</i> <span style={{ color: "red" }} onClick={() => this.props.navigation.navigate("EmailAccountLoginBlock")}>Login/Register</span></span>
          </div>}
          </Container>
        </div>

        <Container>
          <div style={{ fontWeight: 'bold', margin: '20px 0px 20px 0px' }}>Wishlist</div>
          <Grid container spacing={3}>
            {this.state.wishlistedItems?.map((item: any) => {
              return <Grid key={item?.id} id='grid' item xs={12} md={3}>
                <Card>
                  <div style={{ display: "flex", justifyContent: 'space-between', padding: '15px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>

                      <img src={securedGreenTick} width='25px' height='25px' />
                      <small style={{ textDecoration: 'underline', marginLeft: '10px',color:"#9CA0A9" }}>{item?.attributes?.warranty}</small>
                    </div>

                    <div  id='innerDiv'
                           onClick={()=>{
                              if (localStorage.getItem('loginSuccessToken')) {
                                
                                if (item?.attributes?.is_wishlisted) {
                                  localStorage.setItem('item_id', item?.id);
                                  this.deleteWishlist()
                                  
                                }
                                else {
                                  
                                  localStorage.setItem('item_id', item?.id);
                                  this.createWishList()
                                }
                              }
                              else {
                                this.props.navigation.navigate('Login')
                              }
                              
                            }}
                          style={{ display: 'flex', alignItems: 'center',cursor:'pointer' }}>

                            <img src={item?.attributes?.is_wishlisted ? heart : love} width='25px' height='20px' />
                          </div>


                  </div>
                  <CardContent>
                    <div id='cardContent' onClick={() => {
                      
                      localStorage.setItem("applyShowBatteriesId", item.id)
                      this.props.navigation.navigate('ProductDescription1',item?.id)
                    }}
                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }}>
                      <div style={{ padding: '15px' }}><img src={item?.attributes?.thumbnail_image} width='100' height='100' /></div>



                      <div style={{ fontWeight: 'bold', marginTop: '15px' }}>{item?.attributes?.model_no}</div>
                      <div style={{ fontWeight: 'bold', marginTop: '15px', marginBottom: '20px' }}><span style={{ color: '#B1B5C3' }}>Capacity</span> {item?.attributes?.capaciy}</div>

                      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                        <span style={{ fontWeight: 'bold', fontSize: '20px' }}>&#x20b9;{this.finalPrice(item)}</span>
                        <s style={{ color: '#9498A9', marginLeft: '10px', fontSize: '20px', fontWeight: 'bold' }}>&#x20b9;{item?.attributes?.amount}</s>
                      </div>
                      <div style={{ display: 'flex', marginTop: '10px' }}>
                        <div style={{ border: '1px solid gray', display: 'flex', marginRight: '10px', borderRadius: '25px', paddingLeft: '5px', paddingRight: '5px' }}>
                          <span>

                            <img src={yellowStar} width='15px' height='15px' />
                          </span>


                          <span style={{ display: 'flex', alignItems: 'center' }}>{item?.attributes?.range}</span>

                        </div>
                        <span
                          style={{
                            borderRadius: '25px', paddingLeft: '10px', color: 'white',
                            display: 'flex', alignItems: 'center',
                            paddingRight: '10px', backgroundColor: '#66bc54'
                          }}> {Math.round(item?.attributes?.applied_discount)}% off</span>
                      </div>
                    </div>
                  </CardContent>


                </Card>

              </Grid>
            })}

          </Grid>
          <VisionStatement navigation={this.props.navigation} id={''} classes={undefined} openSearch={undefined} />
        </Container>


      </>
    )
  }
}
// Customizable Area End
