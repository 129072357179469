// Customizable Area Start
import React, { Component } from "react";
import MerchantDashboard2Controller from "./MerchantDashboard2Controller";

import {
  Box,
  Container,
  Modal,
  Button,
  ListItem,
  ListItemText,
  List,
  Grid,
  TextField,
  Backdrop,
  Fade,Select,MenuItem
} from "@material-ui/core";

import PublishIcon from "@material-ui/icons/Publish";
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import VisionStatementWeb from "../../../components/src/VisionStatement.web";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  mt: 5,
  mb: 5,
  
  boxShadow: 24,
  p: 8,
  borderRadius: "20px",
};

const tick = require(".././assets/tick.png");
export default class AddTechnician extends MerchantDashboard2Controller {

  handleCloseModal = () => {
    this.setState({ toggleAdd: false });
  };
  handleImage=(e:any)=>{
  
   
    this.setState({technicianData:{...this.state.technicianData,profile:e.target.files[0],profilePic:URL.createObjectURL(e.target.files[0])}})
  }
  handleChange=(e:any)=>{
    this.setState({technicianData:{...this.state.technicianData,[e.target.name]:e.target.value}})

  }
  render() {
    const {profilePic,fullName,mobileNumber,address,country,city,cityId,state,stateId,district,districtId,zip,adharNo,panNo,licenseNo,toolkitNo}=this.state.technicianData;
    const { profileErr,
    profilePicErr,fullNameErr,
    mobileNumberErr,addressErr,
    countryErr,stateIdErr,
    cityIdErr,
    cityErr,
    stateErr,districtErr,districtIdErr,
    zipErr,adharNoErr,panNoErr,licenseNoErr,toolkitNoErr}=this.state.techDetailErrors

    const checkErrors=()=>{
     
      if(profileErr||fullNameErr||panNoErr||mobileNumberErr||zipErr||adharNoErr||licenseNoErr||toolkitNoErr){
        return true
      }
      else{
        return false
      }
    }
    return (
      <>
       <div style={{ padding: '20px', backgroundColor: '#efefef' }}>
                    <Container>

                        <div  style={{ display: 'flex', justifyContent: 'space-between' }}>

                            <div  style={{ color: '#a1a0a4' }}>
                                <a   href='/' style={{ textDecoration: 'none', color: '#a1a0a4' }}>Home</a> <ArrowForwardIosRoundedIcon className="arrow-left" /> <span id="home" style={{ cursor: 'pointer' }} onClick={() => this.props.navigation.goBack()}>Profile</span>
                                <ArrowForwardIosRoundedIcon className="arrow-left" />Technician Management
                            </div>
                            {!localStorage.getItem("loginSuccessToken") && <div style={{ color: '#a1a0a4' }}>
                                <span>
                                    <i>For Better Experience</i>
                                    <span id="fbe" onClick={() => {
                                        this.props.navigation.navigate('EmailAccountLoginBlock')
                                    }} style={{ color: "red", fontWeight: 'bold', cursor: 'pointer' }}> Login/
                                    </span>
                                    <span id="signup" onClick={() => {
                                        this.props.navigation.navigate('signUp')
                                    }} style={{ color: "red", fontWeight: 'bold', cursor: 'pointer' }}>Register
                                    </span>
                                </span>
                            </div>
                            }
                        </div>
                    </Container>
                </div>

        <Container style={{ marginTop: "20px" }}>
          <Grid container>
            <Grid item xs={12} md={3}>
              <Box
                sx={{
                  bgcolor: "background.paper",
                  marginRight: "25px",
                }}
                >
                <nav aria-label="main mailbox folders">
                  <List>
                    <ListItem>
                      <ListItemText primary={"Add Technician"} />
                    </ListItem>
                  </List>
                </nav>
              </Box>
            </Grid>

            <Grid item xs={12} md={8}>
              <Box
                sx={{
                  bgcolor: "background.paper",
                  padding: "30px",
                }}
                >
                  <form onSubmit={(e:any)=>{
                    if (!checkErrors()) {
                      this.createTechnician(e)
                      
                    }
                   

                    
                    }}>

                <Grid container spacing={3}>
                  <Grid item xs={8} sm={3}>
                    {!profilePic?<div
                      style={{
                        border: "1px dashed black",
                        borderRadius: "20px",
                        width: "150px",
                        height: "150px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#c24b48",
                      }}
                      >
                      <PublishIcon />
                    </div>:<div><img src={profilePic} alt='profile pic' width='150'/></div>}
                    <div  onClick={()=>{
                      this.imgRef.current.click()
                    }} style={{textAlign:'center',color:'#B55E5C',marginRight:'20px',marginTop:"5px",fontWeight:'bold',cursor:'pointer'}}>
                      <input ref={this.imgRef} value='' onChange={(e:any)=>this.handleImage(e)} type='file' hidden/>
                      Add picture</div>
                  </Grid>

                  <Grid item xs={8} sm={9}>
                    <div style={{ marginBottom: "10px" }}>Full Name</div>
                    <TextField
                      name="fullName"
                      fullWidth
                      id="firstName"
                      placeholder="Technician full name here"
                      value={fullName||''}
                      onChange={(e:any)=>{
                        this.handleChange(e)
                      }}
                      variant="outlined"
                      required
                      />

                    <div style={{ margin: "20px 0px 10px 0px" }}>
                      Mobile Number
                    </div>
                    <TextField
                    error={mobileNumberErr}
                    helperText={mobileNumberErr?'Invalid mobile number':''}
                      name="mobileNumber"
                      fullWidth
                      id="mobileNumber"
                      value={mobileNumber||''}
                      onChange={(e:any)=>{
                        let regex=/^[0-9]{10}$/
                    if(regex.test(e.target.value)){
                      this.setState({techDetailErrors:{...this.state.techDetailErrors,mobileNumberErr:false}})
                      this.handleChange(e)
                    }else{
                      this.setState({techDetailErrors:{...this.state.techDetailErrors,mobileNumberErr:true}})
                      this.handleChange(e)
                    }
                       
                      }}
                      placeholder="Technician mobile numeber"
                      variant="outlined"
                      required
                      />
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginTop: "20px" }}>
                  <Grid item xs={8} sm={12}>
                    <div style={{ margin: "10px 0px" }}>Address</div>
                    <TextField
                      name="address"
                      fullWidth
                      id="address"
                      value={address||''}
                      onChange={(e:any)=>{
                        this.handleChange(e)
                      }}
                      placeholder="Your Address"
                      variant="outlined"
                      required
                      />
                  </Grid>
                  <Grid item xs={8} sm={12}>
                 
                  <TextField
                      name="country"
                      fullWidth
                      id="country"
                      value={country||''}
                      onChange={(e:any)=>{
                        this.handleChange(e)
                      }}
                      placeholder="Country"
                      variant="outlined"
                      required
                      />
                  </Grid>
                  <Grid item xs={8} sm={6}>
                  <Select id="state" required fullWidth variant='outlined' displayEmpty name="stateId"  value={stateId??''}
                 onChange={(e:any)=>this.handleChange(e)}
                 >
                    <MenuItem value={''}>State</MenuItem>
                      {this.state.allState?.map((option: any) => (
                        <MenuItem key={option?.id} value={option?.id}>
                      {option?.state}
                    </MenuItem>
                        ))}

                  </Select>
                  </Grid>
                  <Grid item xs={8} sm={6}>
                  <Select id="city" required fullWidth variant='outlined' displayEmpty name="cityId" value={cityId??''}
                   onChange={(e:any)=>this.handleChange(e)}
                   >
                    <MenuItem value={''}>City</MenuItem>
                      {this.state.cityDropDown?.map((option: any) => (
                        <MenuItem key={option?.id} value={option?.id}>
                      {option?.city}
                    </MenuItem>
                        ))}

                  </Select>
                  </Grid>
                  <Grid item xs={8} sm={6}>
                  <Select id="district" required fullWidth variant='outlined' name="districtId" displayEmpty value={districtId??''}
                  onChange={(e:any)=>this.handleChange(e)}
                  >
                    <MenuItem value={''}>District</MenuItem>
                      {this.state.allDistrict?.map((option: any) => (
                        <MenuItem key={option?.id} value={option?.id}>
                      {option?.district}
                    </MenuItem>
                        ))}

                  </Select>
                  </Grid>
                  <Grid item xs={8} sm={6}>
                    <TextField
                    error={zipErr}
                    helperText={zipErr?'invalid zip code':''}
                      name="zip"
                      fullWidth
                      id="zip"
                      value={zip||''}
                      onChange={(e:any)=>{
                      
                          let regex=/^[0-9]{6}$/
                      if(regex.test(e.target.value)){
                        this.setState({techDetailErrors:{...this.state.techDetailErrors,zipErr:false}})
                        this.handleChange(e)
                      }else{
                        this.setState({techDetailErrors:{...this.state.techDetailErrors,zipErr:true}})
                        this.handleChange(e)
                      }
                      }}
                      placeholder="Pin"
                      variant="outlined"
                      required
                    />
                  </Grid>
                  {/* ---------------------------------------------------------------------------------------------- */}

                  <Grid item xs={8} sm={6}>
                    <div style={{ marginBottom: "10px" }}>PAN Number</div>
                    <TextField
                    error={panNoErr}
                    helperText={panNoErr?'Invalid pan number':''}
                      required
                      name="panNo"
                      fullWidth
                      id="panNo"
                      value={panNo||''}
                      onChange={(e:any)=>{
                        let pattern=/[A-Z]{5}[0-9]{4}[A-Z]{1}/
                        if(pattern.test(e.target.value)){
                          this.handleChange(e)
                          this.setState({techDetailErrors:{...this.state.techDetailErrors,panNoErr:false}})
                        }
                        else{
                          this.setState({techDetailErrors:{...this.state.techDetailErrors,panNoErr:true}})
                          this.handleChange(e)
                        }
                      }}
                      placeholder="Technician PAN number"
                      variant="outlined"
                      />
                  </Grid>

                  <Grid item xs={8} sm={6}>
                    <div style={{ marginBottom: "10px" }}>
                      Driving License Number
                    </div>
                    <TextField
                      name="licenseNo"
                      fullWidth
                      id="licenseNo"
                      value={licenseNo||''}
                      onChange={(e:any)=>{
                        let pattern=/^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/
                        if(pattern.test(e.target.value)){
                          this.handleChange(e)
                          this.setState({techDetailErrors:{...this.state.techDetailErrors,licenseNoErr:false}})
                        }
                        else{
                          this.setState({techDetailErrors:{...this.state.techDetailErrors,licenseNoErr:true}})
                          this.handleChange(e)
                        }
                      }}
                      variant="outlined"
                      placeholder="Technician driving license number"
                      required
                      error={licenseNoErr}
                      helperText={licenseNoErr?'Invalid license number':''}
                      />
                  </Grid>

                  <Grid item xs={8} sm={6}>
                    <div style={{ marginBottom: "10px" }}>Adhar Number</div>
                    <TextField
                      name="adharNo"
                      fullWidth
                      id="adharNo"
                      value={adharNo||''}
                      onChange={(e:any)=>{
                        let pattern=/^[2-9]{1}[0-9]{3}\s[0-9]{4}\s[0-9]{4}$/
                        if(pattern.test(e.target.value)){
                          this.handleChange(e)
                          this.setState({techDetailErrors:{...this.state.techDetailErrors,adharNoErr:false}})
                        }
                        else{
                          this.setState({techDetailErrors:{...this.state.techDetailErrors,adharNoErr:true}})
                          this.handleChange(e)
                        }
                      }}
                      variant="outlined"
                      placeholder="Technician aadhar number"
                      required
                      error={adharNoErr}
                      helperText={adharNoErr?'Invalid adhar number':''}
                      />
                  </Grid>

                  <Grid item xs={8} sm={6}>
                    <div style={{ marginBottom: "10px" }}>
                      Toolkit Assigned Number
                    </div>
                    <TextField
                      name="toolkitNo"
                      fullWidth
                      id="toolkitNo"
                      value={toolkitNo||''}
                      onChange={(e:any)=>{
                        let pattern=/^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+$/
                        if(pattern.test(e.target.value)){
                          this.handleChange(e)
                          this.setState({techDetailErrors:{...this.state.techDetailErrors,toolkitNoErr:false}})
                        }
                        else{
                          this.setState({techDetailErrors:{...this.state.techDetailErrors,toolkitNoErr:true}})
                          this.handleChange(e)
                        }
                      }}
                      variant="outlined"
                      placeholder="Enter toolkit assigned number"
                      required
                      error={toolkitNoErr}
                      helperText={toolkitNoErr?'Invalid toolkit number':''}
                      />
                  </Grid>
                </Grid>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                  >
                  <Button type="submit"
                    id="create"
                    style={{
                      backgroundColor: "#32cba0",
                      padding: "10px 30px 10px 30px",
                      fontWeight:'bold'
                    }}
                    >
                    Add Technician
                  </Button>
                </div>
                      </form>
              </Box>
            </Grid>
          </Grid>
          <VisionStatementWeb classes={undefined} id={""} navigation={undefined} openSearch={undefined}/>

          {/* <FooterImage navigation={this.props.navigation} id={this.props.id} /> */}
        </Container>

        <div>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={this.state.toggleAdd}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            >
            <Fade in={this.state.toggleAdd}>
              <Box sx={style}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img src={tick} width={80} />
                </div>
                <div
                  style={{
                    fontWeight: "bold",
                    display: "flex",
                    justifyContent: "center",
                    margin: "30px 0px 20px 0px",
                    fontSize: "20px",
                  }}
                >
                  Technician Successfully Added
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                  >
                  <div
                  id="ok"
                    onClick={() => this.handleCloseModal()}
                    style={{
                      width: "100%",
                      padding: "20px 0px 20px 0px",
                      border: "2px solid #e3e3e4",
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "15px",
                    }}
                    >
                    Ok
                  </div>
                </div>
              </Box>
            </Fade>
          </Modal>
        </div>
      </>
    );
  }
}
// Customizable Area End
