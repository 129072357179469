// Customizable Area Start
import React, { Component } from 'react'
import OrdermanagementController from './OrdermanagementController';
import {
    Box,
    Container,
    Modal,
    Button,
    ListItem,
    IconButton,
    ListItemIcon,
    ListItemText,
    Divider,
    Collapse,
    List,
    Grid,
    Card,
    CardContent,
    CardActions,
    Typography,
    Switch, Radio

} from "@material-ui/core";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CloseIcon from '@material-ui/icons/Close';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import VisionStatementWeb from '../../../components/src/VisionStatement.web';

const ezBattery = require(".././assets/red_battery.png");
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    mt: 5,
    mb: 5,

    boxShadow: 24,
    p: 4,
};


export default class Orders extends OrdermanagementController {
    arr = ['Track Order', 'Rate Product', 'Rate Product'];
    timeline = ['Ordered', 'Packed', 'Shipped', 'Delievered'];

    handleCloseModal = () => {
        this.setState({ toggleTrack: false })
    }
    render() {
        return (
            <>
                <div style={{ display: 'flex', justifyContent: 'flex-start', padding: '20px', backgroundColor: '#efefef' }}>
                    <Container>

                    <div style={{ color: '#a1a0a4' }}>
                        <a href='/' style={{ textDecoration: 'none', color: '#a1a0a4' }}>Home</a> <ArrowForwardIosRoundedIcon className="arrow-left" /> Orders
                    </div>
                    </Container>

                </div>

                <Container maxWidth='lg' style={{ marginTop: '20px' }}>
                    <Grid spacing={3} container>
                        {this.arr.map((item) => {
                            return <Grid item xs={12} md={4}>
                                <Card  >
                                    <CardContent>
                                        <Typography gutterBottom>
                                            Ordered on 21 Apr 22
                                        </Typography>

                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <div style={{ padding: '15px' }}><img src={ezBattery} width='100' height='100' /></div>

                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <div style={{ fontWeight: 'bold', paddingTop: '35px', paddingLeft: '10px' }}>EZ2345</div>
                                                <div style={{ paddingLeft: '10px' }}>X2</div>
                                            </div>
                                            <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'flex-start', paddingTop: '35px' }} >

                                                <span onClick={()=>this.props.navigation.navigate("ProductInfo")}><ChevronRightIcon /></span>
                                            </div>
                                        </div>
                                        <Divider />
                                        <div style={{ padding: '20px 0px 0px 10px', color: 'red', display: 'flex', alignItems: 'center' }}>
                                            <span onClick={() => {
                                                if (item == 'Track Order') {

                                                    this.setState({ toggleTrack: true });
                                                }
                                                else{
                                                    this.props.navigation.navigate("RateProduct")
                                                }
                                            }}>{item}</span>
                                        </div>
                                    </CardContent>

                                </Card>
                            </Grid>
                        })}
                    </Grid>
                    <VisionStatementWeb classes={undefined} id={''} navigation={this.props?.navigation} openSearch={undefined}/>

                </Container>


                <div>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={this.state.toggleTrack}
                        onClose={() => this.handleCloseModal()}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={this.state.toggleTrack}>
                            <Box sx={style}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span>
                                        Shipment Tracking
                                    </span>
                                    <span onClick={() => this.handleCloseModal()}>
                                        <CloseIcon />
                                    </span>
                                </div>

                                <div style={{ display: 'flex', border: '1px solid #f4f5f6', borderRadius: '10px', backgroundColor: '#f4f5f6', padding: '10px' }}>
                                    <div style={{ border: '1px solid', padding: '5px', backgroundColor: 'white', borderRadius: '5px' }}>
                                        <div>Aug</div>
                                        <div style={{ color: '#ff7779', display: 'flex', justifyContent: 'center' }}>29</div>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                                        Get it by 29  Aug,2022
                                    </div>

                                </div>

                                <Stepper activeStep={0} orientation='vertical'

                                >
                                    {this.timeline.map((label: any) => (
                                        <Step key={label}>
                                            <StepLabel>{label}</StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                                {/* <div className="container">
                                    <div className="rightbox">
                                        <div className="rb-container">
                                            <ul className="rb">
                                                {this.timeline.map((item)=>{
                                                    return  <li className="rb-item" ng-repeat="itembx">
                                                    <div className="timestamp">
                                                       {item}
                                                    </div>
                                                    

                                                </li>
                                                })}
                                               
                                               

                                            </ul>

                                        </div>
                                    </div>
                                </div> */}


                            </Box>
                        </Fade>
                    </Modal>
                </div>

            </>
        )
    }
}
// Customizable Area End