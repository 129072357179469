import React, { Component } from 'react'
// Customizable Area Start

import {
  Grid,
  Typography,
  Backdrop, CircularProgress
} from "@material-ui/core";
import Modal from '@material-ui/core/Modal';
import Error from "@material-ui/icons/Error";
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';

import OtpInput from "react-otp-input";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import { BackGroundImg, BatteryHindi, tick } from "./assets";


import OTPInputAuthController, { Props } from "./OTPInputAuthController";
import "./otp.css"


const styles = (theme: Theme) =>
  createStyles({
    alert_box: {
      position: "fixed",
      top: "35%",
      right: "40%",
      left: "40%",
      backgroundColor: "#fff",
      zIndex: 2000,
      padding: "35px",
      borderRadius: "15px"
    },

  })

class OTPInputAuth extends OTPInputAuthController {
  id:any
  constructor(props: Props) {
    super(props);
    this.id=''
    
  }
  
  async componentDidMount() {
    console.log('re-render')
    this.id=setInterval(()=>{
      if(this.state.timer!=0){

        this.setState({timer:this.state.timer-1})
      }


    },1000);


    
    
  }
  
  async componentWillUnmount() {
   

      clearInterval(this.id)
    
    
  }
  handleChange = (otp: any) => {
    this.setState({ otpVal: otp })
    if (!this.state.otpVal) {
      this.setState({ showLoginVerifyErrors: false })
    }
  }
  render() {

    const { classes } = this.props;


    return (
      <>
        <Backdrop
          style={{ color: '#fff', zIndex: 1 }}
          open={this.state.isLoading}

        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div style={{
          backgroundColor: "#fff",
          backgroundImage: `url(${BackGroundImg})`,
          backgroundSize: "100% 100%",
          height: "100vh",
        }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "30px", paddingTop:'100px',
            }}
          >
            <img src={BatteryHindi} />
          </div>
          {/* <img src={BatteryHindi} className="main-body" /> */}
          <Grid item style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ width: "600px", height: "500px", borderRadius: "4px", backgroundColor: "#fff" }}>
              <div style={{ display: "flex" }}>
                <span style={{ cursor: 'pointer' }} onClick={() => this.props.navigation.navigate('EmailAccountLoginBlock')}>
                  <KeyboardBackspaceIcon style={{ marginLeft: "25px", marginTop: "25px" }} />
                </span>
                <p style={{ fontSize: "25px", marginLeft: "120px", marginTop: "25px", color: "black" }}>
                  <b>Verify mobile number </b>
                </p>
              </div>
              <Typography style={{ color: "#808080", textAlign: "center", fontSize: "15px", marginTop: "-10px" }}>
                Please enter the verification code sent to your mobile number
              </Typography>
              <Grid container style={{ marginTop: "65px" }}>
                <Grid item xs={2} />
                <Grid item xs={8} style={{ marginLeft: "40px" }}>

                  <OtpInput
                    value={this.state.otpVal}
                    onChange={this.handleChange}
                    numInputs={4}
                    className={classes.inputStyles}
                    inputStyle={{
                      width: "4rem",
                      height: "2.5rem",
                      margin: "0.5rem",
                      fontSize: "2rem",
                      borderRadius: 4,
                      border:  (this.state.otpVal && this.state.showLoginVerifyErrors)|| this.state.isOtpBlank?"1px solid red":"1px solid #B0B0B0",
                      backgroundColor: (this.state.otpVal && this.state.showLoginVerifyErrors)|| this.state.isOtpBlank?'#FDEFEF':'none'
                      
                      
                    }}
                  />
                  {
                    this.state.otpVal && this.state.showLoginVerifyErrors &&

                    <div
                      style={{
                        marginLeft: "100px",
                        marginTop: "0px",
                        marginBottom: "15px",
                        color: "red",

                        fontSize: "smaller",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ marginRight: "5px" }}>
                        <Error color="error" fontSize="small" />
                      </div>
                      <div>Code is wrong</div>
                    </div>

                  }
                  {
                    this.state.isOtpBlank && <div
                      style={{
                        marginLeft: "100px",
                        marginTop: "0px",
                        marginBottom: "15px",
                        color: "red",

                        fontSize: "smaller",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ marginRight: "5px" }}>
                        <Error color="error" fontSize="small" />
                      </div>
                      <div> OTP required</div>
                    </div>

                  }
                </Grid>
                <Grid item xs={2} />
              </Grid>
                  <p style={{textAlign:'center', color: "#808080", fontSize: "15px" }}>00:{this.state.timer}</p>
              <p  onClick={()=>{
                if(this.state.timer==0){
                  this.setState({timer:30})
                }
               
              }} style={{ color: "#32cba1", textAlign: "center", fontSize: "15px",cursor:'pointer',marginTop:'65px' }}><span style={{fontWeight:'bold'}}>Resend</span></p>
              {/* <a
              href="/"
              style={{ textDecoration: "none" }}
            > */}
              <div style={{
                width: "60%", padding: "2px", marginLeft: "120px", backgroundColor: "#32cba1",
                borderRadius: "4px", textAlign: "center", cursor: "pointer"
              }}
                onClick={() => {
                  console.log(this.state.otpVal);
                  if (this.state.otpVal.length == 4) {
                    this.setState({ isOtpBlank: false })
                    this.loginVerifyOTP()
                  }
                  else {
                    this.setState({ isOtpBlank: true })
                    this.setState({ showLoginVerifyErrors: false })
                  }
                  // this.loginSuccess()
                }}
              >
                <p style={{ fontWeight: 'bold' }}> Verify and sign in </p>
              </div>
              {/* </a> */}
            </div>
          </Grid>
        </div>


        {/* <div>

          <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={this.state.loginSuccess}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}


          >
            <div className={classes.alert_box}>

              <CheckCircleIcon style={{ color: "#29bf04", marginLeft: "40%", fontSize: "50px" }} />

              <Typography style={{ textAlign: "center" }}><b>Login</b> <br />
                <b>Successfully Done</b>
              </Typography>
              <a
                href='/'
              >
                <Box style={{
                  marginLeft: "30px", marginTop: "20px", width: "70%", height: "20px", backgroundColor: "#B0B0B0",
                  border: "1px solid black", textAlign: "center", cursor: "pointer"
                }} >
                  OK
                </Box>
              </a>

            </div>
          </Modal>
        </div> */}

        <div>
          <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={this.state.loginSuccess}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}

          // onClose={this.handleClose}
          >
            <div className={classes.alert_box}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img src={tick} width="60px" />
              </div>

              <div
                style={{
                  marginTop: "30px",
                  fontWeight: "bold",
                  fontSize: "x-large",
                }}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  Login
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  Successfully Done
                </div>
              </div>

              <div style={{ marginTop: "30px" }}>
                <a href='/'

                  style={{
                    cursor: 'pointer',
                    display: "flex",
                    justifyContent: "center",
                    backgroundColor: "#F3F3F3",
                    border: "1px solid gray",
                    padding: "15px 100px",
                    textDecoration: 'none'
                  }}
                >
                  Ok
                </a>
              </div>
            </div>
          </Modal>
        </div>


      </>

    )
  }
}
export default withStyles(styles, { withTheme: true })(OTPInputAuth);

// Customizable Area End
