import React, { Component } from 'react'
import ContactusController from './ContactusController';
import {
    Box,
    Container,
    Modal,
    Button,
    ListItem,
    IconButton,
    ListItemIcon,
    ListItemText,
    Divider,
    Collapse,
    List,
    Grid,
    Card,
    CardContent,
    CardActions,
    Typography, styled, Paper,

} from "@material-ui/core";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(3),
  display:'flex',
  justifyContent:'space-between',
    color: theme.palette.text.secondary,
}));
export default class ReportsRequest extends ContactusController {
    arr = [0, 1, 2, 3]
    render() {
        return (


            // <Box sx={{ flexGrow: 1 }}>
            <>
                <Grid container spacing={2}>

                    {this.arr.map(() => {
                        return <Grid item xs={12} md={4}>
                            <Item >
                               <span>
                                 SOA request
                                </span>
                                <span style={{color:'green'}}><ChevronRightIcon/></span>
                               
                            </Item>
                            
                        </Grid>
                    })}

                </Grid>
                    
                    </>
            // </Box>


        )
    }
}
