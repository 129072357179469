// Customizable Area Start
import React, { Component } from 'react'
import MerchantDashboard2Controller from './MerchantDashboard2Controller';

import {
    Box,
    Container,
    Modal,
    Button,
    ListItem,
    IconButton,
    ListItemIcon,
    ListItemText,
    Divider,
    Collapse,
    List,
    Grid,
    Card,
    CardContent,
    CardActions,
    Typography,
    Switch, Radio, TextField, Backdrop, Fade

} from "@material-ui/core";
import FooterImage from './FooterImage.web';
import PublishIcon from '@material-ui/icons/Publish';
import AddIcon from '@material-ui/icons/Add';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'background.paper',
    mt: 5,
    mb: 5,
    boxShadow: 24,
    p: 8,
    borderRadius: '20px'
};

const tick = require('.././assets/tick.png')
const barcode = require('.././assets/barcode.png')

export default class ManageBatteryCollection extends MerchantDashboard2Controller {
    handleCloseModal = () => {
        this.setState({ toggleWarrantyRegistration: false })
    }
    render() {
        return (
            <>
                <div style={{ display: 'flex', justifyContent: 'flex-start', padding: '20px', backgroundColor: '#efefef' }}>
                    <div style={{ color: '#a1a0a4', marginLeft: '40px' }}>
                        <a href='/' style={{ textDecoration: 'none', color: '#a1a0a4' }}>Home</a>  <ArrowForwardIosRoundedIcon className="arrow-left" /> Profile <ArrowForwardIosRoundedIcon className="arrow-left" /> Manage Battery Collection
                    </div>

                </div>

                <Container style={{ marginTop: '20px' }}>
                    <div style={{ fontWeight: 'bold', margin: '10px 0px', fontSize: '25px' }}>Enter Customer Details Below to manage Batteries</div>
                    <Box sx={{
                        bgcolor: 'background.paper',
                        padding: '30px'
                    }}>

                        <Grid container spacing={5} >


                            <Grid item xs={8} sm={6}>
                                <div style={{ margin: '10px 0px' }}>Full Name</div>
                                <TextField
                                    autoComplete="given-name"
                                    name="firstName"
                                    fullWidth
                                    id="firstName"
                                    label="Name of the end customer"
                                    placeholder='Name of the end customer'
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item xs={8} sm={6}>
                                <div style={{ margin: '10px 0px' }}>Email</div>
                                <TextField
                                    fullWidth
                                    id="lastName"
                                    label="Mobile Number"
                                    name="Mobile Number"
                                    autoComplete="family-name"
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item xs={8} sm={6}>
                                <div style={{ margin: '10px 0px' }}>Phone Number</div>
                                <TextField
                                    autoComplete="given-name"
                                    name="firstName"
                                    fullWidth
                                    id="firstName"
                                    label="First Name"
                                    variant='outlined'
                                />
                            </Grid>
                            
                          
                          

                        </Grid>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>

                            <Button onClick={() => this.setState({ toggleWarrantyRegistration: true })} style={{ backgroundColor: '#32cba0', padding: '10px 100px' }}>Next</Button>
                        </div>
                    </Box>
                    <FooterImage navigation={this.props.navigation} id={this.props.id} />
                </Container>

{/* 
                <div>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={this.state.toggleWarrantyRegistration}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={this.state.toggleWarrantyRegistration}>
                            <Box sx={style}>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <img src={tick} width={80} />
                                </div>
                                <div style={{ fontWeight: 'bold', display: 'flex', justifyContent: 'center', margin: '30px 0px 20px 0px', fontSize: '20px' }}>
                                    Warranty Registered
                                </div>
                                <div style={{ fontWeight: 'bold', display: 'flex', justifyContent: 'center', fontSize: '20px' }}>
                                     Successfully!
                                </div>



                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                                    <div
                                        onClick={() => this.handleCloseModal()}
                                        style={{
                                            width: '100%', padding: '20px 0px 20px 0px',
                                            border: '2px solid #e3e3e4', display: 'flex',
                                            justifyContent: 'center', marginTop: '15px'
                                        }}>Ok</div>
                                </div>








                            </Box>
                        </Fade>
                    </Modal>
                </div> */}



            </>
        )
    }
}
// Customizable Area End