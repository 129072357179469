import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { bool } from "yup";
// Customizable Area End

export const configJSON = require("./config");
const {baseURL:apiBaseUrl}=require('./../../../framework/src/config')

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start

  // Customizable Area End
}

interface S {
  openSearch: boolean;
  searchValue: any;
  showSearchData: boolean;
  serachData: any;
  recentSearchData: any;
  recentSearchCross: boolean;
  openReport2: boolean;
  openProductCatDownload4: boolean;
  openSubbrandtDownload6: boolean;
  openCatalogueDownload8: boolean;
  openSOP10: boolean;
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  openModal:boolean
  closeModal:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class RolesPermissions2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      openSearch: false,
      searchValue: "",
      showSearchData: false,
      recentSearchCross: false,
      serachData: [],
      recentSearchData: [],
      openReport2: false,
      openProductCatDownload4: false,
      openSubbrandtDownload6: false,
      openCatalogueDownload8: false,
      openSOP10: true,
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      openModal:false,
      closeModal:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  async componentDidMount() {
    super.componentDidMount();
   
  }


   
  handleSearch = () => {
    this.setState({ openSearch: true })
    if (this.state.searchValue !== "") {
      this.getSearchCall();
    } else {
      return
    }
  }

  getSearchCall = () => {
    let TOKEN: any = localStorage.getItem("loginSuccessToken")

    let myHeaders = new Headers();

    {localStorage.getItem("loginSuccessToken") &&  
    myHeaders.append("token", TOKEN);
    }
    myHeaders.append("Content-Type", "application/json");



    fetch(`${apiBaseUrl}/bx_block_fedex_integration/search_data?search=${this.state.searchValue}`, {
      method: 'GET',
      headers: myHeaders,
    })
      .then(response => response.json())
      .then(result => {
        console.log("search result milrahe", result)
        this.setState({ showSearchData: true, serachData: result?.data })
      })
      .catch(error => console.log('error', error));
  }


  recentSearchCall = () => {
    let TOKEN: any = localStorage.getItem("loginSuccessToken")

    let myHeaders = new Headers();
    myHeaders.append("token", TOKEN);
    myHeaders.append("Content-Type", "application/json");



    fetch(`${apiBaseUrl}/bx_block_fedex_integration/user_search`, {
      method: 'GET',
      headers: myHeaders,
    })
      .then(response => response.json())
      .then(result => {
        console.log(result)
        this.setState({ recentSearchData: result?.data })
        // localStorage.setItem("recentSearchId",result?.data?.id)
      })
      .catch(error => console.log('error', error));
  }

  deleteRecentSearchCall = (ItemId: any) => {
    let TOKEN: any = localStorage.getItem("loginSuccessToken")
    let RecentSearchID = localStorage.getItem("recentSearchId")

    let myHeaders = new Headers();
    myHeaders.append("token", TOKEN);
    myHeaders.append("Content-Type", "application/json");


    fetch(`${apiBaseUrl}/bx_block_fedex_integration/user_search/${ItemId}`, {
      method: 'DELETE',
      headers: myHeaders,
    })
      .then(response => response.json())
      .then(result => {
        console.log(result)
        this.setState({ recentSearchCross: true })
      })
      .catch(error => console.log('error', error));
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  // Customizable Area End
}
