import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { SpawnSyncOptions } from "child_process";

export const configJSON = require("./config");
const {baseURL:apiBaseUrl}=require('./../../../framework/src/config')


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  openSearch: boolean;
  searchValue: any;
  showSearchData: boolean;
  serachData: any;
  recentSearchData: any;
  recentSearchCross: boolean;
  categoriesArray: any;
  twoWheelerData: any;
  threeWheelerData: any;
  carSuvMuvData: any;
  commercialVehiclesData: any;
  tractorData: any;
  inverterData: any;
  termsConditionsData: any;
  first_name: any;
  firstName: any;
  lastName: any;
  email: any;
  gender: any;
  age: any;
  address: any;
  countryName: any;
  stateName: any;
  cityName: any;
  distName: any;
  yourSubject: any;
  yourFeedback: any;
  feedbackSuccess: boolean;
  pin: any;
  createdAddressData: any;
  stateDropdownData: any;
  distDropdownData: any;
  cityDropdownData: any;
  category: string;
  subCategory: string;
  isVisible: boolean;
  dropdownCategoryStatus: boolean;
  activeModalType: string;
  selectedCategoryID: any;
  getProfileData: any;
  giveSubject: boolean;
  giveFeedback: boolean;
  openLanguageMenu: any;
  isExpand: boolean;
  isLoading:boolean;
  sort:any;
  maxWords:any;
  errorMsg:any
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CategoriessubcategoriesController extends BlockComponent<
  Props,
  S,
  SS
> {
  getCategoriesApiCallId: any;
  deleteCategoriesApiCallId: any;
  deleteSubCategoriesApiCallId: any;
  addCategoryApiCallId: any;
  addSubCategoryApiCallId: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      token: "",
      openSearch: false,
      searchValue: "",
      showSearchData: false,
      recentSearchCross: false,
      serachData: [],
      recentSearchData: [],
      categoriesArray: [],
      twoWheelerData: [],
      threeWheelerData: [],
      carSuvMuvData: [],
      commercialVehiclesData: [],
      tractorData: [],
      inverterData: [],
      termsConditionsData: "",
      first_name: "",
      firstName: "",
      lastName: "",
      email: "",
      gender: "",
      age: "",
      address: "",
      countryName: "India",
      stateName: [],
      cityName: [],
      distName: "",
      yourSubject: "",
      yourFeedback: "",
      feedbackSuccess: false,
      pin: "",
      createdAddressData: "",
      stateDropdownData: [],
      distDropdownData: [],
      cityDropdownData: [],
      category: "",
      subCategory: "",
      isVisible: false,
      dropdownCategoryStatus: false,
      activeModalType: "",
      selectedCategoryID: [],
      getProfileData: "",
      giveSubject: false,
      giveFeedback: false,
      openLanguageMenu: null,
      isExpand: false,
      isLoading:false,
      sort:'recently_added',
      maxWords:200,
      errorMsg:{
        isGenderReq:false,isAgeReq:false,isCityReq:false,isStateReq:false,isSub:false,isMsg:false
      }
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    this.getTwoWheelerItems();
    this.getThreeWheelerItems();
    this.getCarSuvMuvBatteries();
    this.getCommercialVehiclesCall();
    this.TractorCall();
    this.InverterCall();
    this.getTermsConditions();
    this.getProfileCall();
    this.cityDropdownCall();
    this.stateDropdownCall();

    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
  }

  handleSearch = () => {
    this.setState({ openSearch: true })
    if (this.state.searchValue !== "") {
      this.getSearchCall();
    } else {
      return
    }
  }

  getSearchCall = () => {
    let TOKEN: any = localStorage.getItem("loginSuccessToken")

    let myHeaders = new Headers();

    {
      localStorage.getItem("loginSuccessToken") &&
        myHeaders.append("token", TOKEN);
    }
    myHeaders.append("Content-Type", "application/json");



    fetch(`${apiBaseUrl}/bx_block_fedex_integration/search_data?search=${this.state.searchValue}`, {
      method: 'GET',
      headers: myHeaders,
    })
      .then(response => response.json())
      .then(result => {
        console.log("search result milrahe", result)
        this.setState({ showSearchData: true, serachData: result?.data })
      })
      .catch(error => console.log('error', error));
  }


  recentSearchCall = () => {
    let TOKEN: any = localStorage.getItem("loginSuccessToken")

    let myHeaders = new Headers();
    myHeaders.append("token", TOKEN);
    myHeaders.append("Content-Type", "application/json");



    fetch(`${apiBaseUrl}/bx_block_fedex_integration/user_search`, {
      method: 'GET',
      headers: myHeaders,
    })
      .then(response => response.json())
      .then(result => {
        console.log(result)
        this.setState({ recentSearchData: result?.data })
      })
      .catch(error => console.log('error', error));
  }

  deleteRecentSearchCall = (ItemId: any) => {
    let TOKEN: any = localStorage.getItem("loginSuccessToken")
    let RecentSearchID = localStorage.getItem("recentSearchId")

    let myHeaders = new Headers();
    myHeaders.append("token", TOKEN);
    myHeaders.append("Content-Type", "application/json");


    fetch(`${apiBaseUrl}/bx_block_fedex_integration/user_search/${ItemId}`, {
      method: 'DELETE',
      headers: myHeaders,
    })
      .then(response => response.json())
      .then(result => {
        console.log(result)
        this.setState({ recentSearchCross: true })
      })
      .catch(error => console.log('error', error));
  }

  getProfileCall = () => {
    let baseToken = localStorage.getItem("loginSuccessToken")

    fetch(`${apiBaseUrl}/account_block/get_profile?language=en&token=${baseToken}`, {
      method: 'GET',
    })
      .then(response => response.json())
      .then(result => {
        console.log("get profile data.........", result?.data?.attributes)
        this.setState({ getProfileData: result.data?.attributes })
        localStorage.setItem("profileToken", result?.meta?.token)
      })
      .catch(error => console.log('error', error));
  }
  checkSort=()=>{
    if(this.state.sort=='recently_added'){
      return 'Recently added'
    }
    if(this.state.sort=='price_l_to_h'){
      return 'Price low to high'
    }
    if(this.state.sort=='price_h_to_l'){
      return 'Price high to low'
    }
    if(this.state.sort=='atoz'){
      return 'From A to Z'
    }
  }


  

  cityDropdownCall = () => {

    let Id = localStorage.getItem("stateId")


    fetch(`${apiBaseUrl}/bx_block_address/city?id=1`, {
      method: 'GET',
    })
      .then(response => response.json())
      .then(result => {
        console.log("city mil rahe...", result)
        this.setState({ cityDropdownData: result?.data })
      })
      .catch(error => console.log('error', error));
  }
  handleSort1 = (sortBy: any, vehicle: any, id: any = '') => {
    console.log(sortBy)
    this.setState({isLoading:true})
    let myHeaders = new Headers();
    if(localStorage.getItem("loginSuccessToken")){
      let TOKEN: any = localStorage.getItem("loginSuccessToken")??''

      myHeaders.append("token", TOKEN);
    }
    fetch(`${apiBaseUrl}/bx_block_filter_items/filter?battery_type=${vehicle !== 'inverter' ? '1' : '2'}&sub_cat_id=${localStorage.getItem('subCategoryId')}&sort_by=${sortBy}`, {
      method: 'GET',
      headers:myHeaders
    })
      .then(response => response.json())
      .then(result => {
      
        if (vehicle == 'two') {
          this.setState({ twoWheelerData: result?.data })

        }
        if (vehicle == 'three') {
          this.setState({ threeWheelerData: result?.data })

        }
        if (vehicle == 'car/suv') {
          this.setState({ carSuvMuvData: result?.data })
        }
        if (vehicle == 'commercial') {
          this.setState({ commercialVehiclesData: result?.data })
        }
        if (vehicle == 'tractor') {
          this.setState({ tractorData: result?.data })
        }
        
        this.setState({isLoading:false})
      })
      .catch(error => {console.log('error', error); this.setState({isLoading:false})});
  }
  handleSort2 = (sortBy: any, vehicle: any, id: any = '') => {
    console.log(sortBy)
    this.setState({isLoading:true})
    let myHeaders = new Headers();
    if(localStorage.getItem("loginSuccessToken")){
      let TOKEN: any = localStorage.getItem("loginSuccessToken")??''

      myHeaders.append("token", TOKEN);
    }
    fetch(`${apiBaseUrl}/bx_block_filter_items/filter?battery_type=2&sort_by=${sortBy}`, {
      method: 'GET',
      headers:myHeaders
    })
      .then(response => response.json())
      .then(result => {
        this.setState({ inverterData: result?.data })
      
      this.setState({isLoading:false})
    })
    .catch(error => {console.log('error', error); this.setState({isLoading:false})});
}
      
        
        
  stateDropdownCall = () => {

    fetch(apiBaseUrl+"/bx_block_address/states", {
      method: 'GET',
    })
      .then(response => response.json())
      .then(result => {
        console.log("###", result)
        this.setState({ stateDropdownData: result?.data })
        localStorage.setItem("stateId", result?.data.id)
      })
      .catch(error => console.log('error', error));
  }


  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  getTwoWheelerItems = () => {
    this.setState({isLoading:true})
    let myHeaders = new Headers();
    if(localStorage.getItem("loginSuccessToken")){
      let TOKEN: any = localStorage.getItem("loginSuccessToken")??''

      myHeaders.append("token", TOKEN);
    }
   fetch(`${apiBaseUrl}/bx_block_fedex_integration/items?sub_category_id=${localStorage.getItem('subCategoryId')}&sort_by=${this.state.sort}`, {
      method: 'GET',
      headers: myHeaders,
      
    })
      .then(response => response.json())
      .then(result => {
        this.setState({ twoWheelerData: result.data })
        this.setState({isLoading:false})
      })
      .catch(error => {console.log('error', error);this.setState({isLoading:false})});
  }

  getThreeWheelerItems = () => {
    let myHeaders = new Headers();
    if(localStorage.getItem("loginSuccessToken")){
      let TOKEN: any = localStorage.getItem("loginSuccessToken")??''

      myHeaders.append("token", TOKEN);
    }



    fetch(`${apiBaseUrl}/bx_block_fedex_integration/items?sub_category_id=${localStorage.getItem('subCategoryId')}&sort_by=${this.state.sort}`, {
      method: 'GET',
      headers: myHeaders,
     
    })
      .then(response => response.json())
      .then(result => {
        console.log("3 wheeler mil rahe...", result)
        this.setState({ threeWheelerData: result.data })
      })
      .catch(error => console.log('error', error));
  }

  getCarSuvMuvBatteries = () => {
    let myHeaders = new Headers();
    if(localStorage.getItem("loginSuccessToken")){
      let TOKEN: any = localStorage.getItem("loginSuccessToken")??''

      myHeaders.append("token", TOKEN);
    }



    fetch(`${apiBaseUrl}/bx_block_fedex_integration/items?sub_category_id=${localStorage.getItem('subCategoryId')}&sort_by=${this.state.sort}`, {
      method: 'GET',
      headers: myHeaders,
    
    })
      .then(response => response.json())
      .then(result => {
        console.log("4 wheeler mil rahe...", result)
        this.setState({ carSuvMuvData: result.data })
      })
      .catch(error => console.log('error', error));
  }

  getCommercialVehiclesCall = () => {
    let myHeaders = new Headers();
    if(localStorage.getItem("loginSuccessToken")){
      let TOKEN: any = localStorage.getItem("loginSuccessToken")??''

      myHeaders.append("token", TOKEN);
    }

    console.log(localStorage.getItem('subCategoryId'))

    fetch(`${apiBaseUrl}/bx_block_fedex_integration/items?sub_category_id=${localStorage.getItem('subCategoryId')}&sort_by=${this.state.sort}`, {
      method: 'GET',
      headers: myHeaders,
    
    })
      .then(response => response.json())
      .then(result => {
        console.log("4 wheeler mil rahe...", result)
        this.setState({ commercialVehiclesData: result.data })
      })
      .catch(error => console.log('error', error));
  }

  TractorCall = () => {
    let myHeaders = new Headers();
    if(localStorage.getItem("loginSuccessToken")){
      let TOKEN: any = localStorage.getItem("loginSuccessToken")??''

      myHeaders.append("token", TOKEN);
    }


    fetch(`${apiBaseUrl}/bx_block_fedex_integration/items?sub_category_id=${localStorage.getItem('subCategoryId')}&sort_by=${this.state.sort}`, {
      method: 'GET',
      headers: myHeaders,
    
    })
      .then(response => response.json())
      .then(result => {
        console.log("4 wheeler mil rahe...", result)
        this.setState({ tractorData: result.data })
      })
      .catch(error => console.log('error', error));
  }

  InverterCall = () => {
    let myHeaders = new Headers();
    if(localStorage.getItem("loginSuccessToken")){
      let TOKEN: any = localStorage.getItem("loginSuccessToken")??''

      myHeaders.append("token", TOKEN);
    }



    fetch(`${apiBaseUrl}/bx_block_fedex_integration/items?sub_category_id=${localStorage.getItem('subCategoryId')}&sort_by=${this.state.sort}`, {
      method: 'GET',
      headers: myHeaders,
      
    })
      .then(response => response.json())
      .then(result => {
        console.log("4 wheeler mil rahe...", result)
        this.setState({ inverterData: result.data })
      })
      .catch(error => console.log('error', error));
  }


  getTermsConditions = () => {

    fetch(apiBaseUrl+"/bx_block_settings/static_pages?page_type=Terms Conditions", {
      method: 'GET',
    })
      .then(response => response.json())
      .then(result => {
        console.log(result)
        this.setState({ termsConditionsData: result })
      })
      .catch(error => console.log('error', error));
  }
  createWishList=()=>{
    this.setState({isLoading:true})
    let TOKEN: any = localStorage.getItem("loginSuccessToken");
    let item_id:any=localStorage.getItem('item_id')
  
    let myHeaders = new Headers();
   
    myHeaders.append("token", TOKEN);


    let formdata = new FormData();
    formdata.append("item_id", item_id);
  
  
  
    fetch(`${apiBaseUrl}/bx_block_fedex_integration/wishlist`, {
      method: 'POST',
      headers: myHeaders,
      body:formdata
    })
      .then(response => response.json())
      .then(result => {
        console.log( result);
       this.refreshData()
       
       
       
      })
      .catch(error =>{ console.log('error', error); this.setState({isLoading:false})});
  
  }

  refreshData=()=>{
   
    let myHeaders = new Headers();
    if(localStorage.getItem("loginSuccessToken")){
      let TOKEN: any = localStorage.getItem("loginSuccessToken")??''

      myHeaders.append("token", TOKEN);
    }
    let id=localStorage.getItem('subCategoryId')

    fetch(`${apiBaseUrl}/bx_block_fedex_integration/items?sub_category_id=${id}&sort_by=${this.state.sort}`, {
      method: 'GET',
      headers:myHeaders
    })
      .then(response => response.json())
      .then(result => {
        console.log(result);
        
        this.setState({ twoWheelerData: result?.data });
          this.setState({ threeWheelerData: result?.data });
           this.setState({ carSuvMuvData: result?.data })
           this.setState({ commercialVehiclesData: result?.data })
           this.setState({ tractorData: result?.data })
           this.setState({ inverterData: result?.data })
           this.setState({isLoading:false})
          })
          .catch(error =>{ console.log('error', error);this.setState({isLoading:false})});
    
      }
        
      
      
        
    
      
        
       
        

  deleteWishlist=()=>{
    this.setState({isLoading:true})
    let TOKEN: any = localStorage.getItem("loginSuccessToken");
    let item_id:any=localStorage.getItem('item_id')
  
    let myHeaders = new Headers();
   
    myHeaders.append("token", TOKEN);

    let formdata = new FormData();
    formdata.append("item_id", item_id);
  
  
  
    fetch(`${apiBaseUrl}/bx_block_fedex_integration/wishlist/${item_id}`, {
      method: 'DELETE',
      headers: myHeaders,
      body:formdata
    })
      .then(response => response.json())
      .then(result => {
        console.log( result);
       this.refreshData()
      
       
      })
      .catch(error =>{ console.log('error', error); this.setState({isLoading:false})});
    
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      runEngine.debugLog("Message Recived", message);
      let token = message.getData(getName(MessageEnum.SessionResponseToken));

      this.setState({ token: token }, () => {
        this.getCategories();
      });

    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      runEngine.debugLog("API Message Recived", message);
      if (responseJson.data) {
        if (apiRequestCallId === this.getCategoriesApiCallId) {
          let array = responseJson.data;
          for (let i = 0; i < array.length; i++) {
            array[i].expand = false;
            array[i].Check = false;
          }
          this.setState({ categoriesArray: array });
        } else if (apiRequestCallId === this.addCategoryApiCallId) {
          this.setState({ isVisible: false, category: "" }, () => {
            this.getCategories();
          });
        } else if (apiRequestCallId === this.addSubCategoryApiCallId) {
          this.setState(
            {
              isVisible: false,
              category: "",
              subCategory: "",
              selectedCategoryID: []
            },
            () => {
              this.getCategories();
            }
          );
        }
        //this.setState({ isVisible: false });
      } else if (
        apiRequestCallId === this.deleteCategoriesApiCallId && !responseJson.error
      ) {
        this.getCategories();
      }
      else if (apiRequestCallId === this.deleteCategoriesApiCallId && responseJson.error) {
        this.parseApiCatchErrorResponse(responseJson.error.message);
      }
      else if (
        apiRequestCallId === this.deleteSubCategoriesApiCallId && !responseJson.error
      ) {
        this.getCategories();
      }
      else if (
        apiRequestCallId === this.deleteSubCategoriesApiCallId && responseJson.error
      ) {
        this.parseApiCatchErrorResponse(responseJson.error.message);
      }
      else if (responseJson.errors) {
        this.parseApiErrorResponse(responseJson);
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  setCategoryTxt = (text: string) => {
    this.setState({ category: text });
  };
  setSubCategoryTxt = (text: string) => {
    this.setState({ subCategory: text });
  };
  clickCategory = (item: any, Index: number) => {
    let array = this.state.categoriesArray;
    let idarray = this.state.selectedCategoryID;
    let index = idarray.indexOf(item.attributes.id);

    if (index > -1) {
      idarray.splice(index, 1);
      array[Index].Check = false;
      this.setState({ categoriesArray: array });
    } else {
      idarray.push(item.attributes.id);
      array[Index].Check = true;
      this.setState({ categoriesArray: array });
      this.setState({ selectedCategoryID: idarray });
    }
  };

  toggleModal = (type: string) => {
    this.setState({ activeModalType: type, isVisible: !this.state.isVisible });
  };

  expandCategoryView = () => {
    this.setState({
      dropdownCategoryStatus: !this.state.dropdownCategoryStatus
    });
  };
  expand = (id: string) => {
    let array = this.state.categoriesArray;
    for (let i = 0; i < array.length; i++) {
      if (array[i].id === id) {
        array[i].expand = !array[i].expand;
      }
    }
    this.setState({ categoriesArray: array });
  };

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  addCategory = () => {
    if (this.isStringNullOrBlank(this.state.category)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    } else {
      let data = {
        categories: [{ name: this.state.category }]
      };
      const header = {
        "Content-Type": configJSON.categoryApiContentType,
        token: this.state.token
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addCategoryApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.categoryAPIEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };
  addSubCategory = () => {
    if (this.isStringNullOrBlank(this.state.subCategory)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    } else if (this.state.selectedCategoryID.length === 0) {
      this.showAlert(configJSON.errorTitle, configJSON.errorCategory);
      return false;
    } else {
      let data = {
        sub_category: {
          name: this.state.subCategory
        },
        parent_categories: this.state.selectedCategoryID
      };
      const header = {
        "Content-Type": configJSON.categoryApiContentType,
        token: this.state.token
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addSubCategoryApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.subCategoryAPIEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  deleteCategories = (id: number) => {
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteCategoriesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryAPIEndPoint + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  deleteSubCategories = (id: number) => {
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteSubCategoriesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.subCategoryAPIEndPoint + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getCategories = () => {

    if (!this.state.token) {
      return;
    }

    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCategoriesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  
  createFeedbackCall = () => {

    let feedbackToken: any = localStorage.getItem("loginSuccessToken")

    let formdata = new FormData();
    formdata.append("data[age]", this.state.age);
    formdata.append("data[country]", this.state.countryName);
    formdata.append("data[state_id]", this.state.stateName);
    formdata.append("data[city_id]", this.state.cityName);
    formdata.append("data[subject]", this.state.yourSubject);
    formdata.append("data[description]", this.state.yourFeedback);
    formdata.append("token", feedbackToken);


    fetch(apiBaseUrl+"/bx_block_settings/feedbacks", {
      method: 'POST',
      body: formdata,
    })
      .then(response => response.ok?response.json():Promise.reject(response))
      .then(result => {
        console.log(result)
        this.setState({ feedbackSuccess: true })
      })
      .catch(error => console.log('error', error));
  }
  // Customizable Area End
}
