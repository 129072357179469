// Customizable Area Start
import React, { Component } from "react";
import MerchantOrderController from "./MerchantOrderController";
import { Grid, Switch, TextField, Checkbox } from "@material-ui/core";

export default class ExtraCharges extends MerchantOrderController {
  render() {
    return (
      <Grid container spacing={3}>
        <Grid item xs={8} sm={6}>
          <div style={{ display: "flex", alignItems: "center",marginTop:'20px' }}>
            <div style={{ fontWeight: "bold" }}>Add extra charges</div>
            <div>
              <Switch onChange={(e)=>{
                if(e.target.checked){
                    this.setState({extraCharge:false})
                }else{
                    this.setState({extraCharge:true})

                }
              }} />
            </div>
          </div>
        </Grid>
        <Grid item xs={8} sm={6} />

        <Grid item xs={8} sm={6}>
          <div style={{ display: "flex", alignItems: "center",marginLeft:'-10px' }}>
            <div>
              <Checkbox disabled={this.state.extraCharge} checked={!this.state.extraCharge} onChange={(e)=>{
                if (e.target.checked) {

                }else{

                }

              }} />
            </div>
            <div style={{ margin: "10px 0px", fontWeight: "bold" }}>
              Recharging Charges
            </div>
          </div>
          <TextField
            name="firstName"
            fullWidth
            id="firstName"
            variant="outlined"
            disabled={this.state.extraCharge} 
          />
        </Grid>
        <Grid item xs={8} sm={6}>
          <div style={{ display: "flex", alignItems: "center",marginLeft:'-10px'  }}>
            <div>
              <Checkbox disabled={this.state.extraCharge}  />
            </div>
            <div style={{ margin: "10px 0px", fontWeight: "bold" }}>
              Delievery Charges
            </div>
          </div>
          <TextField
            autoComplete="given-name"
            name="firstName"
            fullWidth
            id="firstName"
            variant="outlined"
            disabled={this.state.extraCharge} 
          />
        </Grid>
        <Grid item xs={8} sm={6}>
          <div style={{ display: "flex", alignItems: "center" ,marginLeft:'-10px' }}>
            <div>
              <Checkbox disabled={this.state.extraCharge}  />
            </div>
            <div style={{ margin: "10px 0px", fontWeight: "bold" }}>
              Testing Charges
            </div>
          </div>
          <TextField
            autoComplete="given-name"
            name="firstName"
            fullWidth
            id="firstName"
            variant="outlined"
            disabled={this.state.extraCharge} 
          />
        </Grid>

        <Grid item xs={8} sm={6}>
          <div style={{ display: "flex", alignItems: "center",marginLeft:'-10px'  }}>
            <div>
              <Checkbox disabled={this.state.extraCharge}  />
            </div>
            <div style={{ margin: "10px 0px", fontWeight: "bold" }}>
              Other Charges
            </div>
          </div>
          <TextField
            autoComplete="given-name"
            name="firstName"
            fullWidth
            id="firstName"
            variant="outlined"
            disabled={this.state.extraCharge} 
          />
        </Grid>
        <Grid item xs={8} sm={6}>
          <div style={{ margin: "10px 0px", fontWeight: "bold" }}>
            Total Extra Charges
          </div>

          <TextField
            autoComplete="given-name"
            name="firstName"
            fullWidth
            id="firstName"
            variant="outlined"
          />
        </Grid>
      </Grid>
    );
  }
}
// Customizable Area End