import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");
const {baseURL:apiBaseUrl}=require('./../../../framework/src/config')

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  openSearch: boolean;
  searchValue: any;
  serachData: any;
  showSearchData: boolean;
  openProfile: boolean;
  open: boolean;
  recentSearchData: any;
  recentSearchCross: boolean;
  listFactoryVisitsData: any;
  testimonialsData: any;
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  wishlistedItems:any;
  isLoading:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class VideosController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      openSearch: false,
      searchValue: "",
      serachData: [],
      showSearchData: false,
      openProfile: false,
      open: false,
      recentSearchData: [],
      recentSearchCross: false,
      listFactoryVisitsData: [],
      testimonialsData: [],
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      wishlistedItems:[],
      isLoading:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.listFactoryVisits();
    this.viewTestimonialsCall();
    // Customizable Area Start
    this.getWishlistedItem()
    // Customizable Area End
  }


   
  
  
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }
  
  // Customizable Area Start
  getWishlistedItem=async()=>{
    let TOKEN: any = localStorage.getItem("loginSuccessToken")
  
    let myHeaders = new Headers();
    
    myHeaders.append("token", TOKEN);
    
    myHeaders.append("Content-Type", "application/json");
  
  
  
   fetch(`${apiBaseUrl}/bx_block_fedex_integration/wishlist`, {
      method: 'GET',
      headers: myHeaders,
    })
   
      .then(response => response.json())
      .then(result => {
        console.log( result)
        this.setState({ wishlistedItems:result?.data,isLoading:false })
      })
      .catch(error => {console.log('error', error);this.setState({isLoading:false})});
  
  }
   createWishList=()=>{
    let TOKEN: any = localStorage.getItem("loginSuccessToken")
  
    let myHeaders = new Headers();
    {localStorage.getItem("loginSuccessToken") &&  
    myHeaders.append("token", TOKEN);
    }
    myHeaders.append("Content-Type", "application/json");
  
  
  
    fetch(`${apiBaseUrl}/bx_block_fedex_integration/wishlist`, {
      method: 'POST',
      headers: myHeaders,
    })
      .then(response => response.json())
      .then(result => {
        console.log( result)
        this.setState({ wishlistedItems:result?.data })
      })
      .catch(error => console.log('error', error));
  
  }
  deleteWishlist=()=>{
    this.setState({isLoading:true})
    let TOKEN: any = localStorage.getItem("loginSuccessToken");
    let item_id:any=localStorage.getItem('item_id')
  
    let myHeaders = new Headers();
   
    myHeaders.append("token", TOKEN);
  
  
  
    let formdata = new FormData();
    formdata.append("item_id", item_id);
  
  
  
    fetch(`${apiBaseUrl}/bx_block_fedex_integration/wishlist/${item_id}`, {
      method: 'DELETE',
      headers: myHeaders,
      body:formdata
    })
      .then(response => response.json())
      .then(result => {
        console.log( result);
        this.getWishlistedItem()
     
      
       
      })
      .catch(error =>{ console.log('error', error); this.setState({isLoading:false})});
    
  }
  
  
   
  
    listFactoryVisits=()=>{
      
      fetch(apiBaseUrl+"/bx_block_settings/online_factory_visit", {
        method: 'GET',
      })
        .then(response => response.json())
        .then(result => {console.log(result)
          this.setState({listFactoryVisitsData:result?.data})
         })
        .catch(error => console.log('error', error));
    }
   
    viewTestimonialsCall=()=>{
      this.setState({isLoading:true})
      fetch(apiBaseUrl+"/bx_block_settings/testimonial", {
        method: 'GET',
      })
        .then(response => response.json())
        .then(result => {console.log(result)
           this.setState({testimonialsData:result?.data})
           this.setState({isLoading:false})
         })
         
        .catch(error => {console.log('error', error);this.setState({isLoading:false})});
    }
  finalPrice=(item:any)=>{
    return item?.attributes?.amount-item?.attributes?.discount_amount
      }
  // Customizable Area End
}
