import React from 'react'
import CatalogueController from './CatalogueController'

import {
    Box,
    ListItem,
    IconButton,
    ListItemIcon,
    ListItemText,
    Divider,
    Collapse,
    List,
    Drawer,
    Link
} from "@material-ui/core";

import ExpandMore from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Twitter from '@material-ui/icons/Twitter';
import YouTube from '@material-ui/icons/YouTube';
import Instagram from '@material-ui/icons/Instagram';

import Close from '@material-ui/icons/Close';



export default class MoreOptions extends CatalogueController {
    constructor(props: any) {
        super(props)

    }

    toggleDrawer =
        (anchor: String, open: boolean) =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
                if (
                    event.type === 'keydown' &&
                    ((event as React.KeyboardEvent).key === 'Tab' ||
                        (event as React.KeyboardEvent).key === 'Shift')
                ) {
                    return;
                }

                this.setState({ toggle: open })
            };
    handleNestedItems = (item: any) => {
        const temp = this.state.items.map((elem: any) => {
            if (elem.title == item.title) {
                return { ...elem, hasSubMenu: !elem.hasSubMenu }
            }
            else {
                return elem
            }

        })
        this.setState({ items: temp })

    }
    handleSideBar=(item:any)=>{
        if (item.title == 'Warranty Management') {
            this.props.history.push("/WarrantyManagement")
        };
        if (item.title == 'My Requests') {
            this.props.history.push("/MyRequest")

        }

        if (item.title == 'Online Factory Visit') {
            this.props.history.push("/OnlineFactoryVisit")

        }
        if (item.title == 'Testimonials') {
            this.props.history.push("/Testimonials")

        }
        if (item.title == 'Reports') {
            this.props.history.push("/Reports1")

        }

    }
    handleSubSideBar=(subItem:any)=>{
        if(subItem.type=='Advertisements'){
            this.props.history.push("/MediaAdvertisements")
        }
        if(subItem.type=='Gallery'){
            this.props.history.push("/Gallery")
        }
    }
    render() {

        const list = (anchor: String) => {


            return <Box
                sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 400 }}
                role="presentation"
                onClick={this.toggleDrawer('left', false)}
                onKeyDown={this.toggleDrawer('left', false)}
            >
                <IconButton onClick={this.props.toggleDrawer('left', false)}><Close fontSize='large' /></IconButton>
                <Divider />
                {this.state.items.map((item: any, index: number) => (
                    <>
                        <List >
                            <ListItem key={item.title} style={{ cursor: 'pointer' }} onClick={() => {
                                this.handleSideBar(item);
                                this.handleNestedItems(item); this.setState({ open: !this.state.open })
                            }} >

                                <ListItemIcon style={{ color: '#79cfb6', minWidth: '35px' }} >
                                    {item.icon}
                                </ListItemIcon>
                                <ListItemText primary={<span style={{ fontWeight: 'bolder' }}>{item.title}</span>} />
                                {item.title == 'Media' || item.title == 'Business Opportunities' ? item.hasSubMenu ? <ExpandMore /> : <ChevronRightIcon /> : null}


                            </ListItem>
                            {(item.title == 'Media' || item.title == 'Business Opportunities') && <Collapse in={item.hasSubMenu} timeout="auto" unmountOnExit>
                                {item.subItems.map((subItem: any, index: number) => {
                                    return <List style={{ display: 'flex',cursor:'pointer' }} key={index} component="div" 
                                    onClick={()=>{
                                       this.handleSubSideBar(subItem)
                                    }}
                                    >
                                    <ListItemText primary={<span style={{ paddingLeft: "50px", color: 'gray' }}>{subItem.type}</span>} />
                                    {subItem.type == 'Social Media' && <div style={{ color: "gray" }}>
                                        <Link href="https://mobile.twitter.com/ezbatteryindia" target='blank'>
                                            <Twitter style={{ padding: "0px 5px 0px 5px" }} />
                                        </Link>
                                        <Link  href="https://www.youtube.com/channel/UCiPuAcSBrDy2odvPLyhVqUw" target='blank' >
                                           <YouTube style={{ padding: "0px 5px 0px 5px" }} />
                                        </Link>
                                        <Link href="https://www.instagram.com/ez_battery_reconditioning_/" target='blank'>
                                            <Instagram style={{ padding: "0px 10px 0px 5px" }} />
                                        </Link>
                                    </div>}

                                </List>

                            })}

                        </Collapse>}
                    </List>
                    <Divider />
                </>
            ))}

        </Box>
    }
    return (
        <div><React.Fragment>

            <Drawer
                anchor={'left'}
                open={this.props.toggle}
                onClose={this.props.toggleDrawer('left', false)}
            >
                {list('left')}
            </Drawer>
        </React.Fragment></div>
    )
}
}
                                       
                                       
                                            


