// Customizable Area Start
import React from 'react'
import ContactusController from './ContactusController';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import { Box, Container, Grid, TextField, MenuItem, Button, Select } from "@material-ui/core";
const tick = require('.././assets/tick.png');


export default class RequestCallback extends ContactusController {
  handleWords=(e:any)=>{
    this.setState({maxWords:200-e.target.value.length})

   
  }
  checkFields=()=>{
if(this.state.issueObj?.message.length==0){
  this.setState({isRequired:true})
return false
}
else if(this.state.issueObj?.type_of_issue==''){
  this.setState({isRequired:false})
  this.setState({isTypeOfIssueReq:true})
return false
 
}
else{
  this.setState({isRequired:false});
  this.setState({isTypeOfIssueReq:false})
  return true
}
  }
  render() {
    return (
      <div>
        <div style={{ padding: '20px', backgroundColor: '#efefef' }}>
            <Container>

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                <div id='home' style={{ color: '#a1a0a4' }}>
                  <a href='/' style={{ textDecoration: 'none', color: '#a1a0a4' }}>Home</a> <ArrowForwardIosRoundedIcon className="arrow-left" /> Request a Call Back from EZ Battery
                </div>
                
              </div>
            </Container>
          </div>
        {!this.state.toggle && <div style={{ padding: '30px' }} >

          <Container component="main" style={{ maxWidth: '800px' }}>

            <Box
              sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                bgcolor: 'white',
                padding: '30px'
              }}
            >
              <span style={{ display: 'flex', alignItems: 'start', fontWeight: 'bolder' }}>Please Fill the Details Below</span>

              <Box component="form" sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="given-name"
                      name="firstName"
                      value={this.state.profileDetails?.attributes?.first_name ?? ''}
                      fullWidth
                      id="firstName"
                      disabled
                      variant='outlined'
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      value={this.state.profileDetails?.attributes?.phone_number ?? ''}
                      fullWidth
                      id="lastName"
                      disabled
                      name="Mobile Number"
                      autoComplete="family-name"
                      variant='outlined'
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField

                      fullWidth
                      multiline
                      minRows={4}
                      inputProps={{ maxLength: 200 }}
                      id="message"
                      placeholder="Type your message here"
                      value={this.state.issueObj?.message}
                      onChange={(e) => {
                        this.handleWords(e);
                        this.setState({issueObj:{...this.state.issueObj,message:e.target.value},isRequired:false})
                      }}
                     style={{
                      border:this.state.isRequired ? '1px solid red' : '0px solid',
                      backgroundColor:this.state.isRequired? "#FDEFEF" : '',
                     }}
                      name="message"
                      autoComplete="message"
                      variant='outlined'
                    />
                    {this.state.isRequired&&<small style={{color:'red'}}>Message required</small>}
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}><i>{this.state.maxWords} words remaining</i></div>
                  </Grid>
                  <br />
                  <Grid item xs={12}>
                    <div style={{ fontWeight: 'bold', marginBottom: '10px' }}>What type of issue you are facing</div>
                   
                    <Select 
                    style={{
                      border:this.state?.isTypeOfIssueReq ? '1px solid red' : '0px solid',
                      backgroundColor:this.state?.isTypeOfIssueReq? "#FDEFEF" : '',
                    }}
                    fullWidth id='issue' variant='outlined' displayEmpty  value={this.state.issueObj?.type_of_issue ?? ''} 
                      onChange={(e) => {
                        
                        this.setState({ issueObj: { ...this.state.issueObj, type_of_issue: e.target.value },isTypeOfIssueReq:false })
                        }}>
                    
                    <MenuItem value="">
                       Select
                      </MenuItem>
                      {this.state.allIssues.map((option: any, index: any) => (
                        <MenuItem id='allIssues' key={index} value={option?.type_of_issue}>
                          {option?.type_of_issue}
                        </MenuItem>
                      ))}

                      </Select>
                      {this.state?.isTypeOfIssueReq&&<small style={{color:'red'}}>This is required</small>}

                  </Grid>
                </Grid>

                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Button id='button' onClick={() => {
                     if( this.checkFields()){

                       this.setState({ toggle: true });
                       console.log(this.state.issueObj);
                       this.requestCallBack()
                     }
                    }} style={{ padding: '10px 40px 10px 40px', marginTop: '20px', backgroundColor: '#32cba1' }}>Send</Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>

          </Container>

        </div>}

        {this.state.toggle && <div style={{ marginTop: '50px', marginBottom: '170px' }}>

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={tick} alt="" width={'80px'} />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>

            <span style={{ fontSize: '25px', fontWeight: 'bold' }}>Callback from EZ Batteries </span>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>

            <span style={{ fontSize: '25px', fontWeight: 'bold' }}>Requested </span>
          </div>
        </div>}
      </div>





    )
  }
}
// Customizable Area End
