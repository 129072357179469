// Customizable Area Start
import React, { Component } from 'react'
import OrdermanagementController from './OrdermanagementController';
import {
    Box,
    Container,
    Modal,
    Button,
    ListItem,
    IconButton,
    ListItemIcon,
    ListItemText,
    Divider,
    Collapse,
    List,
    Grid,
    Card,
    CardContent,
    CardActions,
    Typography,
    Switch, Radio

} from "@material-ui/core";

export default class ReturnReason extends OrdermanagementController {
    arr = ['Product isnt functioning properly', 'I dont like the product', 'Got a better product outsise EZ Battery', 'Reason 4', 'Reason 5', 'Reason 6', 'Other Reason']
    render() {
        return (
            <>
                <div style={{ fontWeight: 'bold', marginBottom: '10px' }}>Reason for returning the product</div>
                <div>
                    {this.arr.map((item) => {
                        return <div style={{ display: 'flex' }}>
                            <Radio

                                name="radio-buttons"
                                inputProps={{ 'aria-label': 'A' }}
                            />
                            <span style={{ display: 'flex', alignItems: 'center' }}>{item}</span>
                        </div>
                    })}

                </div>
            </>
        )
    }
}
// Customizable Area End