// Customizable Area Start
import React, { Component } from 'react'
import MerchantDashboard2Controller from './MerchantDashboard2Controller';

import {
    Box,
    Container,
    Modal,
    Button,
    ListItem,
    IconButton,
    ListItemIcon,
    ListItemText,
    Divider,
    Collapse,
    List,
    Grid,
    Card,
    CardContent,
    CardActions,
    Typography,
    Switch, Radio, TextField

} from "@material-ui/core";
import FooterImage from './FooterImage.web';
import PublishIcon from '@material-ui/icons/Publish';
import AddIcon from '@material-ui/icons/Add';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';

export default class ProfileDetails extends MerchantDashboard2Controller {
    render() {
        return (
            <>
                <div style={{ display: 'flex', justifyContent: 'flex-start', padding: '20px', backgroundColor: '#efefef' }}>
                    <div style={{ color: '#a1a0a4', marginLeft: '40px' }}>
                        <a href='/' style={{ textDecoration: 'none', color: '#a1a0a4' }}>Home</a> <ArrowForwardIosRoundedIcon className="arrow-left" /> Orders <ArrowForwardIosRoundedIcon className="arrow-left" /> Profile
                    </div>

                </div>

                <Container style={{ marginTop: '20px' }}>
                    <Grid container>
                        <Grid item xs={12} md={4}>

                            <Box sx={{
                                bgcolor: 'background.paper',
                                marginRight: '25px'
                            }}>
                                <nav aria-label="main mailbox folders">
                                    <List>

                                        <ListItem  >
                                            <ListItemText primary={'Profile Detail'} />

                                        </ListItem>
                                        <Divider />
                                        <ListItem  >
                                            <ListItemText primary={'Address'} />

                                        </ListItem>
                                    </List>
                                </nav>

                            </Box>



                        </Grid>

                        {/* <Grid item xs={12} md={8}>
                            <Box sx={{
                                bgcolor: 'background.paper',
                                padding: '30px'
                            }}>
                                <Grid container spacing={2}>
                                   
                                    <Grid item xs={8} sm={6}>
                                        <TextField
                                            autoComplete="given-name"
                                            name="firstName"

                                            fullWidth
                                            id="firstName"
                                            label="First Name"
                                            autoFocus
                                            variant='outlined'
                                        />
                                    </Grid>
                                    <Grid item xs={8} sm={6}>
                                        <TextField

                                            fullWidth
                                            id="lastName"
                                            label="Mobile Number"
                                            name="Mobile Number"
                                            autoComplete="family-name"
                                            variant='outlined'
                                        />
                                    </Grid>
                                    <Grid item xs={8} sm={6}>
                                        <TextField
                                            autoComplete="given-name"
                                            name="firstName"

                                            fullWidth
                                            id="firstName"
                                            label="First Name"
                                            autoFocus
                                            variant='outlined'
                                        />
                                    </Grid>
                                    <Grid item xs={8} sm={6}>
                                        <TextField
                                            autoComplete="given-name"
                                            name="firstName"

                                            fullWidth
                                            id="firstName"
                                            label="First Name"
                                            autoFocus
                                            variant='outlined'
                                        />
                                    </Grid>
                                   
                                  

                                   
                                   

                                </Grid>
                                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>

                                <Button  style={{ backgroundColor: '#32cba0', padding: '10px 30px 10px 30px' }}>Update Details</Button>
                            </div>
                            </Box>



                        </Grid> */}

                        <Grid item xs={12} md={8}>
                            <Box sx={{
                                bgcolor: 'background.paper',
                                padding: '30px'
                            }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={8} sm={12}>
                                        <TextField
                                            autoComplete="given-name"
                                            name="firstName"

                                            fullWidth
                                            id="firstName"
                                            label="First Name"
                                            autoFocus
                                            variant='outlined'
                                        />
                                    </Grid>
                                    <Grid item xs={8} sm={12}>
                                        <TextField
                                            autoComplete="given-name"
                                            name="firstName"

                                            fullWidth
                                            id="firstName"
                                            label="First Name"
                                            autoFocus
                                            variant='outlined'
                                        />
                                    </Grid>
                                    <Grid item xs={8} sm={12}>
                                        <TextField
                                            autoComplete="given-name"
                                            name="firstName"

                                            fullWidth
                                            id="firstName"
                                            label="First Name"
                                            autoFocus
                                            variant='outlined'
                                        />
                                    </Grid>

                                    <Grid item xs={8} sm={6}>
                                        <TextField
                                            autoComplete="given-name"
                                            name="firstName"

                                            fullWidth
                                            id="firstName"
                                            label="First Name"
                                            autoFocus
                                            variant='outlined'
                                        />
                                    </Grid>
                                    <Grid item xs={8} sm={6}>
                                        <TextField

                                            fullWidth
                                            id="lastName"
                                            label="Mobile Number"
                                            name="Mobile Number"
                                            autoComplete="family-name"
                                            variant='outlined'
                                        />
                                    </Grid>
                                    <Grid item xs={8} sm={6}>
                                        <TextField
                                            autoComplete="given-name"
                                            name="firstName"

                                            fullWidth
                                            id="firstName"
                                            label="First Name"
                                            autoFocus
                                            variant='outlined'
                                        />
                                    </Grid>
                                    <Grid item xs={8} sm={6}>
                                        <TextField
                                            autoComplete="given-name"
                                            name="firstName"

                                            fullWidth
                                            id="firstName"
                                            label="First Name"
                                            autoFocus
                                            variant='outlined'
                                        />
                                    </Grid>
                                    {/* ---------------------------------------------------------------------------------------------- */}
                                    <Grid item xs={8} sm={6}>
                                        <div style={{ marginBottom: '10px' }}>Outlet Images</div>
                                        <Grid container spacing={1}>
                                            <Grid item xs={6} sm={6}>
                                                <div style={{ border: '1px dashed black', borderRadius: '20px', height: '150px', display: 'flex', alignItems: 'center', justifyContent: 'center',color:'#c24b48' }}>
                                                    <PublishIcon />
                                                </div>

                                            </Grid>
                                            <Grid item xs={6} sm={6}>
                                                <div style={{ border: '1px dashed black', borderRadius: '20px', height: '150px', display: 'flex', alignItems: 'center', justifyContent: 'center',color:'#c24b48' }}>
                                                    <PublishIcon />
                                                </div>

                                            </Grid>

                                        </Grid>
                                            <div style={{display:'flex',justifyContent:'center',color:'#c24b48',margin:'10px 0px'}}><span style={{marginRight:'5px'}}><AddIcon/></span>Add More</div>

                                    </Grid>
                                    <Grid item xs={8} sm={6}>
                                        <div style={{ marginBottom: '10px' }}>Contact Person</div>
                                        <TextField
                                            autoComplete="given-name"
                                            name="firstName"

                                            fullWidth
                                            id="firstName"
                                            label="First Name"
                                            autoFocus
                                            variant='outlined'
                                        />
                                    </Grid>

                                    <Grid item xs={8} sm={6}>
                                        <div style={{ marginBottom: '10px' }}>Contact Person Image</div>
                                        <Grid container spacing={1}>
                                            <Grid item xs={6} sm={12}>
                                                <div 
                                                style={{ border: '1px dashed black',
                                                 borderRadius: '20px', height: '250px', 
                                                 display: 'flex', alignItems: 'center', 
                                                 justifyContent: 'center',color:'#c24b48',
                                                 width:'280px' }}>
                                                    <PublishIcon />
                                                </div>

                                            </Grid>
                                           

                                        </Grid>
                                           

                                    </Grid>

                                    <Grid item xs={8} sm={6}>
                                        <div style={{ marginBottom: '10px' }}>GST Number</div>
                                        <TextField
                                            autoComplete="given-name"
                                            name="firstName"

                                            fullWidth
                                            id="firstName"
                                            label="First Name"
                                            autoFocus
                                            variant='outlined'
                                        />
                                    </Grid>






                                </Grid>
                                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>

                                    <Button style={{ backgroundColor: '#32cba0', padding: '10px 30px 10px 30px' }}>Update Details</Button>
                                </div>
                            </Box>



                        </Grid>
                    </Grid>

                    <FooterImage navigation={this.props.navigation} id={this.props.id} />

                </Container>



            </>
        )
    }
}
// Customizable Area End
