import React, { Component } from 'react'
// Customizable Area Start


import { Grid, RadioGroup, FormControlLabel, Radio, Typography, Avatar,withStyles } from "@material-ui/core"

import SearchIcon from '@material-ui/icons/Search';



const downloadImg = require("../assets/bi_download.png")
const checkTick = require("../assets/Group 29.png")
const greenTick = require("../assets/first_geaan_tick.png")
const redBattery = require("../assets/red_battery.png")
const yellowStar = require("../assets/yellow_star.png")
const trippleLine = require("../assets/tripple_line.png")
const ezBattery = require("../assets/ez_battery.png")
const blackBattery = require("../assets/bla_battery.png")
const greenBattery = require("../assets/greenBattery.png")
const sosImage = require("../assets/sosImage.png")
const LoveImg = require("../assets/love.png")

const Styles = {
    inputSearchBox: {
        width: "25px",
        height: "35px",
        alignItems: "center",
        border: "1px solid #b30000",
        borderRadiusRight: "5px",
        backgroundColor: "#b30000",
        paddingRight: "10px",
        color: "white"
    },
    cardhead: {
        font: "10px",
        color: "grayText",
        textDecoration: "underline",
        textDecorationColor: "gray",
        textDecorationThickness: "1px",
    }
}


import FilteroptionsController, {
    Props,
} from "./FilteroptionsController";


const CssRadio = withStyles({
    colorSecondary: {
        color: '#757575',
        '&$checked': {
            color: '#43CBA0',
          },
    },
    checked: {}
  })(Radio)
export default class FilterVehicleCategory extends FilteroptionsController {

    render() {
        return (
            <div>


                <div style={{ backgroundColor: "#fff" }}>

                    
                    <div style={{ marginRight: "118px", marginLeft: "118px" }}>
                        <Grid item style={{ marginTop: "30px", overflowY: "scroll", maxHeight: "227px" }}>

                            {this.state.uniqueSubcategoriesData?.map((item: any, index: any) => {

                                return (
                                    <div key={index}
                                        style={{
                                            padding: "7px",
                                            border: "1px solid lightgrey",

                                            marginBottom: "10px"
                                        }}
                                    >
                                        <Grid container style={{ display: "flex" }}>
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label1"
                                                name="radio-buttons-group1">
                                                <FormControlLabel
                                                    checked={this.state.selectedValue == item?.attributes.sub_category_name}
                                                    value={item?.attributes.sub_category_name}
                                                    onChange={(e: any) => {
                                                        this.setState({ selectedValue: e.target.value });
                                                        localStorage.setItem("categoryCheckedId", item?.attributes?.sub_category_id);
                                                        localStorage.setItem('categoryName', item?.attributes.sub_category_name)

                                                    }
                                                    }
                                                    control={<CssRadio />} label={item?.attributes.sub_category_name} />

                                            </RadioGroup>
                                           
                                        </Grid>
                                    </div>
                                )
                            })}

                        </Grid>
                    </div>

                   
                </div>


            </div>
        )
    }
}

// Customizable Area End                           







