// Customizable Area Start
import React, { Component } from 'react'
import ContactusController from './ContactusController';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

export default class SlotServiceCall extends ContactusController {
  
  constructor(props: any) {
    super(props);
    
  }
  arr=[0,1,2,3,4,5]
  render() {
    return (
      <Container component="main" maxWidth='lg'>
        <div style={{margin:'20px 0px 20px 0px',fontSize:'25px',fontWeight:'bold'}}>Select Preferred time slot for call</div>
    <Box
      sx={{
        bgcolor:'white',
        padding:'30px'
      }}
      >
        <div>
        <div>21 JAN 2022</div>
        <div style={{display:'flex'}}>
            {this.arr.map(()=>{
              return <div style={{marginRight:'10px'}}>
                    <div style={{padding:'10px',border:'1px solid #e8e9ed'}}>
                    10:00 AM - 11:00 AM
                </div>
                </div>
            })}

        </div>

        </div>
      

</Box>
  </Container>
    )
  }
}
// Customizable Area End
