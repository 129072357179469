import React from "react";


// Customizable Area Start
// Customizable Area End


// Customizable Area Start



import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import { Card, Container, Paper, Box, Backdrop, CircularProgress, Grid, Radio, RadioGroup, FormControlLabel } from "@material-ui/core"
import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'





const style = {
  position: 'absolute' as 'absolute',
  top: '105px',
  left: '10%',
  transform: 'translate(-50%, -50%)',
  zIndex: 1,
  width: '260px',
  bgcolor: 'background.paper'

}


// Customizable Area End

import VideoManagementController, {
  Props,
  configJSON,
} from "./VideoManagementController";


export default class MediaAdvertisements extends VideoManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleMore = (id: any) => {
    this.setState({
      viewAdvertisementsData: this.state.viewAdvertisementsData.map((item: any) => {
        if (item?.id == id) {
          return { ...item, showMore: true }
        }
        else {
          return { ...item }

        }

      })
    })

  }
  handleLess = (id: any) => {
    this.setState({
      viewAdvertisementsData: this.state.viewAdvertisementsData.map((item: any) => {
        if (item?.id == id) {
          return { ...item, showMore: false }
        }
        else {
          return { ...item }

        }

      })
    })

  }
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <>
        <Backdrop
          style={{ color: '#fff', zIndex: 1 }}
          open={this.state.isLoading}

        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <div style={{ marginBottom: '50px' }}>


          <div style={{ padding: '20px', backgroundColor: '#efefef' }}>
            <Container>

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                <div style={{ color: '#a1a0a4' }}>
                  <a href='/' style={{ textDecoration: 'none', color: '#a1a0a4' }}>Home</a> <ArrowForwardIosRoundedIcon className="arrow-left" /> Advertisements
                </div>
                {!localStorage.getItem("loginSuccessToken") && <div style={{ color: '#a1a0a4' }}>
                  <span>
                    <i>For Better Experience</i>
                    <span onClick={() => {
                      this.props.navigation.navigate('EmailAccountLoginBlock')
                    }} style={{ color: "red", fontWeight: 'bold', cursor: 'pointer' }}> Login/</span><span onClick={() => {
                      this.props.navigation.navigate('signUp')
                    }} style={{ color: "red", fontWeight: 'bold', cursor: 'pointer' }}>Register</span>
                  </span>
                </div>
                }
              </div>
            </Container>
          </div>

          <Container>
            <div style={{ display: 'flex', justifyContent: 'flex-start', margin: '20px 0px', alignItems: 'center', position: 'relative' }}>

              <div style={{ padding: '5px 10px', backgroundColor: 'white', fontSize: 'small', borderRadius: '100px', display: 'flex', alignItems: 'center' }}>
                <span style={{ fontWeight: 'bold' }}>Sort By:</span> {this.state.sort=='recently_added'?<span style={{marginLeft:'5px'}}>Recently added</span>:<span style={{marginLeft:'5px'}}>From old to new</span>}{this.state.isExpand ? <span onClick={() => this.setState({ isExpand: false })} style={{ cursor: 'pointer' }}><ExpandLess /></span> : <span onClick={() => this.setState({ isExpand: true })} style={{ cursor: 'pointer' }}><ExpandMore /></span>}
              </div>
              {this.state.isExpand && <Box sx={style} >
                <Paper elevation={2} style={{ padding: '20px' }}>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    onChange={(e: any) => {
                      this.setState({ sort: e.target.value });
                      this.sortAdvertisements(e.target.value)

                    }
                    }
                    id='radio'
                  >
                    <FormControlLabel checked={this.state.sort=='recently_added'} control={<Radio value={'recently_added'} />} label="Recently added" />
                    <FormControlLabel checked={this.state.sort=='old_to_new'} control={<Radio value={'old_to_new'} />} label="From old to new" />

                  </RadioGroup>


                </Paper>
              </Box>}
            </div>

            <Grid container spacing={3}>
              {this.state.viewAdvertisementsData.map((item: any) => {
                return <Grid id='grid' key={item?.id} item md={6}>
                  <Card style={{ borderRadius: '15px' }} >
                    <div style={{ display: 'flex' }}>
                      <div style={{ padding: '10px' }}>
                        <img src={item?.attributes?.image} style={{ borderRadius: '15px' }} width={220} height={285} />

                      </div>
                      <div>

                        <div style={{
                          display: 'inline-block',
                          backgroundColor: '#000000', color: 'white', padding: '10px',
                          fontSize: '10px', margin: '20px 0px 15px 10px'
                        }}>
                          <span> {item?.attributes?.date_published}</span>
                        </div>
                        {item?.attributes?.title?<div style={{ fontWeight: 'bold', fontSize: '20px', padding: '0px 10px' }}>
                         
                         {item?.attributes?.title?.slice(0, 40)}
                        


                       </div>:<div style={{ fontWeight: 'bold', fontSize: '20px', padding: '0px 10px' }}>
                         
                       Title
                        


                       </div>}
                       
                        <div style={{ margin: '10px 0px', padding: '0px 10px' }}
                          dangerouslySetInnerHTML={{ __html: item?.showMore ? item?.attributes?.content : item?.attributes?.content?.slice(0, 300) }}
                        />


                        {item?.showMore ? <div id="less" onClick={() => {
                          this.handleLess(item?.id)
                        }} style={{ display: 'inline-block', float: 'right', backgroundColor: '#43CBA1', color: 'black', padding: '20px 45px', borderRadius: '15px 0px ', cursor: 'pointer' }}>
                          Read Less
                        </div> : <div id="more" onClick={() => {
                          this.handleMore(item?.id)
                        }} style={{ display: 'inline-block', float: 'right', backgroundColor: '#43CBA1', color: 'black', padding: '20px 45px', borderRadius: '15px 0px ', cursor: 'pointer' }}>
                          Read More
                        </div>}


                      </div>

                    </div>





                  </Card>

                </Grid>
              })}

            </Grid>






          </Container>




        </div>



      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End
