// Customizable Area Start
import React, { Component } from 'react'
import MerchantDashboard2Controller from './MerchantDashboard2Controller';

import {
    Box,
    Container,
    Modal,
    Button,
    ListItem,
    IconButton,
    ListItemIcon,
    ListItemText,
    Divider,
    Collapse,
    List,
    Grid,
    Card,
    CardContent,
    CardActions,
    Typography,
    Switch, Radio, TextField

} from "@material-ui/core";
import FooterImage from './FooterImage.web';
import ChevronRight from '@material-ui/icons/ChevronRight';
import GetApp from '@material-ui/icons/GetApp';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';

export default class InvoicesBillingHistory extends MerchantDashboard2Controller {
    arr = [0, 1, 2, 3]
    render() {
        return (
            <>
                <div style={{ display: 'flex', justifyContent: 'flex-start', padding: '20px', backgroundColor: '#efefef' }}>
                    <div style={{ color: '#a1a0a4', marginLeft: '40px' }}>
                        <a href='/' style={{ textDecoration: 'none', color: '#a1a0a4' }}>Home</a> <ArrowForwardIosRoundedIcon className="arrow-left" /> Profile <ArrowForwardIosRoundedIcon className="arrow-left" /> Invoices {'&'} Billing History
                    </div>

                </div>

                <Container style={{ marginTop: '20px' }}>
                    <Grid container>
                        <Grid item xs={12} md={4}>

                            <Box sx={{
                                bgcolor: 'background.paper',
                                marginRight: '25px'
                            }}>
                                <nav aria-label="main mailbox folders">
                                    <List>

                                        <ListItem  >
                                            <ListItemText primary={'Due'} />

                                        </ListItem>
                                        <Divider />
                                        <ListItem  >
                                            <ListItemText primary={'Paid'} />

                                        </ListItem>
                                    </List>
                                </nav>

                            </Box>

                            <Box sx={{
                                marginRight: '25px',
                                bgcolor: 'background.paper'
                            }}>
                                <div style={{ marginTop: '20px' }}>


                                    <div style={{ padding: '15px 0px 15px 15px', fontWeight: 'bold' }}>Sort By</div>
                                    <div style={{ display: 'flex' }}>

                                        <Radio

                                            name="radio-buttons"
                                            inputProps={{ 'aria-label': 'A' }}
                                        />
                                        <span style={{ display: 'flex', alignItems: 'center' }}>All</span>
                                    </div>
                                    <div style={{ display: 'flex', marginTop: '20px' }}>
                                        <Radio

                                            name="radio-buttons"
                                            inputProps={{ 'aria-label': 'A' }}
                                        />
                                        <span style={{ display: 'flex', alignItems: 'center' }}>This Month</span>
                                    </div>
                                    <div style={{ display: 'flex', marginTop: '20px' }}>
                                        <Radio

                                            name="radio-buttons"
                                            inputProps={{ 'aria-label': 'A' }}
                                        />
                                        <span style={{ display: 'flex', alignItems: 'center' }}>Next Year</span>
                                    </div>
                                    <div style={{ display: 'flex', marginTop: '20px' }}>
                                        <Radio

                                            name="radio-buttons"
                                            inputProps={{ 'aria-label': 'A' }}
                                        />
                                        <span style={{ display: 'flex', alignItems: 'center' }}>This Year</span>
                                    </div>
                                </div>


                            </Box>



                        </Grid>

                        {/* <Grid item xs={12} md={8}>
                            <Box sx={{
                                bgcolor: 'background.paper',
                                padding: '30px'
                            }}>
                                <Grid container spacing={2}>
                                   
                                    <Grid item xs={8} sm={6}>
                                        <TextField
                                            autoComplete="given-name"
                                            name="firstName"

                                            fullWidth
                                            id="firstName"
                                            label="First Name"
                                            autoFocus
                                            variant='outlined'
                                        />
                                    </Grid>
                                    <Grid item xs={8} sm={6}>
                                        <TextField

                                            fullWidth
                                            id="lastName"
                                            label="Mobile Number"
                                            name="Mobile Number"
                                            autoComplete="family-name"
                                            variant='outlined'
                                        />
                                    </Grid>
                                    <Grid item xs={8} sm={6}>
                                        <TextField
                                            autoComplete="given-name"
                                            name="firstName"

                                            fullWidth
                                            id="firstName"
                                            label="First Name"
                                            autoFocus
                                            variant='outlined'
                                        />
                                    </Grid>
                                    <Grid item xs={8} sm={6}>
                                        <TextField
                                            autoComplete="given-name"
                                            name="firstName"

                                            fullWidth
                                            id="firstName"
                                            label="First Name"
                                            autoFocus
                                            variant='outlined'
                                        />
                                    </Grid>
                                   
                                  

                                   
                                   

                                </Grid>
                                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>

                                <Button  style={{ backgroundColor: '#32cba0', padding: '10px 30px 10px 30px' }}>Update Details</Button>
                            </div>
                            </Box>



                        </Grid> */}

                        {true && <Grid item xs={12} md={8}>
                            <Box sx={{
                                bgcolor: 'background.paper',
                                padding: '30px'
                            }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={8} sm={6}>
                                        <span style={{ fontSize: 'larger' }}>Invoices {'&'} Billing History</span>
                                    </Grid>
                                    <Grid item xs={8} sm={6}>
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <span>Total Due</span>

                                            </Grid>
                                            <Grid item xs={6}>
                                                <span style={{ fontWeight: 'bold', fontSize: 'larger', display: 'flex', justifyContent: 'end' }}>Rs.40,000</span>

                                            </Grid>

                                        </Grid>
                                    </Grid>
                                    {this.arr.map(() => {
                                        return <Grid item xs={8} sm={6}>
                                            <div style={{ border: '3px solid #f3f2f3', padding: '10px', borderRadius: '10px' }}>
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                        <span style={{ padding: '10px 10px 0px 10px', fontSize: 'medium' }}>Invoice Due Date</span>
                                                        <span style={{ padding: '5px 10px 15px 10px', fontSize: 'small' }}>12 July 22</span>

                                                    </div>
                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                        <span style={{ padding: '10px 0px 0px 10px', fontSize: 'medium', display: 'flex', justifyContent: 'flex-end' }}>Amount</span>
                                                        <span style={{ padding: '5px 0px 15px 10px', fontSize: '15px', fontWeight: 'bold' }}>Rs.15,999</span>

                                                    </div>
                                                </div>
                                                <Divider />
                                                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
                                                    <div style={{ color: '#bcbbc1', display: 'flex', alignItems: 'center' }}>Download Invoice <span><GetApp /></span></div>
                                                    <div style={{ color: '#6fbea8', display: 'flex', alignItems: 'center' }}>Pay <span><ChevronRight /></span></div>

                                                </div>

                                            </div>
                                        </Grid>
                                    })}



                                </Grid>

                            </Box>



                        </Grid>}


                        {/* paid */}
                        {!true&&<Grid item xs={12} md={8}>
                            <Box sx={{
                                bgcolor: 'background.paper',
                                padding: '30px'
                            }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={8} sm={12}>
                                        <span style={{ fontSize: 'larger' }}>Invoices {'&'} Billing History</span>
                                    </Grid>

                                    {this.arr.map(() => {
                                        return <Grid item xs={8} sm={6}>
                                            <div style={{ border: '3px solid #f3f2f3', padding: '10px', borderRadius: '10px' }}>
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                        <span style={{ padding: '10px 10px 0px 10px', fontSize: 'medium' }}>Invoice Created Date</span>
                                                        <span style={{ padding: '5px 10px 15px 10px', fontSize: 'small' }}>12 July 22</span>

                                                    </div>
                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                        <span style={{ padding: '10px 0px 0px 10px', fontSize: 'medium', display: 'flex', justifyContent: 'flex-end' }}>Amount</span>
                                                        <span style={{ padding: '5px 0px 15px 10px', fontSize: '15px', fontWeight: 'bold' }}>Rs.15,999</span>

                                                    </div>
                                                </div>

                                                <div>
                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                        <span style={{ padding: '10px 10px 0px 10px', fontSize: 'medium' }}>Invoice Paid</span>
                                                        <span style={{ padding: '5px 10px 15px 10px', fontSize: 'small' }}>12 July 22</span>

                                                    </div>

                                                </div>
                                                <Divider />
                                                <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
                                                    <div style={{ color: '#bcbbc1', display: 'flex', alignItems: 'center' }}>Download Invoice <span><GetApp /></span></div>
                                                   

                                                </div>

                                            </div>
                                        </Grid>
                                    })}



                                </Grid>

                            </Box>



                        </Grid>}
                    </Grid>

                    <FooterImage navigation={this.props.navigation} id={this.props.id} />

                </Container>



            </>
        )
    }
}
// Customizable Area End