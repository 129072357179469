import React, { Component } from 'react'
import RolesPermissions2Controller from './RolesPermissions2Controller'
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import HourglassIcon from '@material-ui/icons/HourglassEmpty';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
export default class WarrantyClaimRequest extends RolesPermissions2Controller {
    constructor(props: any) {
        super(props);

    }
    render() {
        return (
            <div>
                <div style={{ display: 'flex', justifyContent: 'flex-start', padding: '20px', backgroundColor: '#efefef' }}>
                    <Container>

                    <div style={{ color: '#a1a0a4' }}>
                        <a href='/' style={{ textDecoration: 'none', color: '#a1a0a4' }}>Home</a> <ArrowForwardIosRoundedIcon className="arrow-left" /> Warranty Management
                    </div>
                    </Container>

                </div>

                <div style={{ marginTop: '50px', marginBottom: '170px' }}>

                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <HourglassIcon/>
                        {/* <img src={tick} alt="" width={'80px'} /> */}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>

                        <span style={{ fontSize: '25px', fontWeight: 'bold' }}>Warranty claim request placed </span>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>

                        <span style={{ fontSize: '25px', fontWeight: 'bold' }}>Click here to track your warranty claim </span>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>

                        <Button variant='outlined' style={{ padding: '10px 25px 10px 25px' }}>Warranty Claims</Button>
                    </div>
                </div>
            </div>
        )
    }
}
