import React, { Component } from 'react';
import Filteritems from '../../blocks/filteritems/src/Filteritems.web';
import { withRouter} from 'react-router-dom'
import { Box, Grid, Container, Card, CardContent,Backdrop,CircularProgress } from "@material-ui/core";

const ambasdar = require("../src/assets/ambasdar.png");
const batterySelection = require("../src/assets/batterySelection.png");
const mobile = require("../src/assets/mobile.png");
const googleplay = require("../src/assets/googlePlay.png");
const applestore = require("../src/assets/appleStore.png");
const right = require("../src/assets/right.png");

interface Props {
    navigation: any;
    id: string;
    classes: any;
    t?: any;
    i18n?: any;
    match: any;
    location: any;
    history: any;
    openSearch: any


}
interface S {
    openAppChart: boolean

}


 class VisionStatement extends Component<Props, S> {
    constructor(props: Props) {
        super(props);


        this.state = {

            openAppChart: false
        }
    }
    closeAppChart = () => {
        this.setState({ openAppChart: false })
    }
    render() {
        return (
            <>
            <Grid container spacing={5} style={{marginTop: '100px', marginBottom: '50px' }}>
                <Grid item xs={12} sm={6} md={6}>
                <div style={{ position: 'relative' }}>
                        <img src={ambasdar} width='605px' height={325}  />
                        <div style={{
                            position: 'absolute',
                            top: '50%',
                            left: '35%',
                            transform: 'translate(-50%, -50%)',

                        }}>
                            <div style={{ fontWeight: 'bold', color: 'white', fontSize: '25px' }}>Vision Statement</div>
                            <div style={{ width: '300px', color: '#F69794', lineHeight: '1.5', margin: '10px 0px' }}>
                                Laxmi Motors through EZ Battery {'&'}
                                Auto Spares envisions to be the first
                                energy company to reach every household
                                of this country by 2025 through our channel
                                partners by providing customized services to help
                                to make end consumer's life easy, enroute to this
                                goal, also create value for fellow citizens so
                                they lead better quality of life.
                            </div>

                        </div>
                    </div>

                </Grid>
                <Grid item xs={12} sm={6} md={3} >
                <div style={{ position: 'relative' }}>
                        <img src={batterySelection} 
                        width='290px' height='325px' 
                        />
                        <div style={{
                            position: 'absolute',
                            top: '25%',
                            left: '40%',
                            transform: 'translate(-50%, -50%)',


                        }}>

                            <div style={{ fontSize: 'x-large', color: '#D1FBF5' }}>
                                Need help with battery selection?  <span onClick={()=>{
                                    this.setState({openAppChart:true})
                                }} style={{ cursor: 'pointer', marginLeft: '10px' }}><img src={right} width='8px' /></span>
                            </div>

                        </div>
                    </div>

                </Grid>
                <Grid item  xs={12} sm={6} md={3}>
                <div style={{ position: 'relative' }}>
                        <img src={mobile} width='290px' height='325px' />
                        <div style={{
                            position: 'absolute',
                            top: '20%',
                            left: '146px',
                            transform: 'translate(-50%, -50%)'
                        }}>
                            <div style={{ color: '#BE6F6A', fontSize: 'x-large', margin: '10px' }}>Get the App now!</div>
                            <div style={{ display: 'flex' }}>
                                <img src={googleplay} style={{ marginRight: '5px' }} width='125px' height='40px' />
                                <img src={applestore} width='125px' height='40px' />
                            </div>
                        </div>
                    </div>
                </Grid>

            </Grid>
                {/* <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '100px', marginBottom: '50px' }}>
                    <div style={{ position: 'relative' }}>
                        <img src={ambasdar} width='550px' />
                        <div style={{
                            position: 'absolute',
                            top: '50%',
                            left: '35%',
                            transform: 'translate(-50%, -50%)',

                        }}>
                            <div style={{ fontWeight: 'bold', color: 'white', fontSize: '25px' }}>Vision Statement</div>
                            <div style={{ width: '300px', color: '#F69794', lineHeight: '1.5', margin: '10px 0px' }}>
                                Laxmi Motors through EZ Battery {'&'}
                                Auto Spares envisions to be the first
                                energy company to reach every household
                                of this country by 2025 through our channel
                                partners by providing customized services to help
                                to make end consumer's life easy, enroute to this
                                goal, also create value for fellow citizens so
                                they lead better quality of life.
                            </div>

                        </div>
                    </div>
                    <div style={{ position: 'relative' }}>
                        <img src={batterySelection} width='300px' height='325px' />
                        <div style={{
                            position: 'absolute',
                            top: '25%',
                            left: '40%',
                            transform: 'translate(-50%, -50%)',


                        }}>

                            <div style={{ fontSize: 'x-large', color: '#D1FBF5' }}>
                                Need help with battery selection?  <span onClick={()=>{
                                    this.setState({openAppChart:true})
                                }} style={{ cursor: 'pointer', marginLeft: '10px' }}><img src={right} width='8px' /></span>
                            </div>

                        </div>
                    </div>
                    <div style={{ position: 'relative' }}>
                        <img src={mobile} width='300px' height='325px' />
                        <div style={{
                            position: 'absolute',
                            top: '20%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)'
                        }}>
                            <div style={{ color: '#BE6F6A', fontSize: 'x-large', margin: '10px' }}>Get the App now!</div>
                            <div style={{ display: 'flex' }}>
                                <img src={googleplay} style={{ marginRight: '5px' }} width='125px' height='40px' />
                                <img src={applestore} width='125px' height='40px' />
                            </div>
                        </div>
                    </div>
                </div> */}
                <Filteritems navigation={this.props.navigation} history={this.props.history} id={this.props.id} openAppChart={this.state.openAppChart} closeAppChart={this.closeAppChart} handleVeh={undefined} handleInv={undefined} handleCheckedOption={undefined} />
            </>
        )
    }
}
export default withRouter(VisionStatement)
