// Customizable Area Start
import React from 'react'
import VideoManagementController from './VideoManagementController';
import { Box, Container, Grid, Radio, RadioGroup, FormControlLabel, Paper,Modal,Backdrop,Fade } from "@material-ui/core";
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'
import { battery1, battery2, battery3, battery4, closeCircle } from './assets';
import VisionStatementWeb from '../../../components/src/VisionStatement.web';

const style = {
    position: 'absolute' as 'absolute',
    top: '105px',
    left: '10%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1,
    width: '260px',
    bgcolor: 'background.paper'

}
const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
   
  };

export default class Photos extends VideoManagementController {
    closeImgModal=()=>{
        this.setState({imgModal:false})
    }
    imgArr=[battery1,battery2,battery3,battery4,battery4,battery1,battery3,battery2]
    render() {
        return (
            <>
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px', backgroundColor: '#efefef' }}>
                    <div style={{ color: '#a1a0a4', marginLeft: '300px' }}>
                        <a href='/' style={{ textDecoration: 'none', color: '#a1a0a4' }}>Home</a> <ArrowForwardIosRoundedIcon className="arrow-left" /> <span style={{cursor:'pointer'}} onClick={()=>this.props.navigation.goBack()}>Gallery</span> <ArrowForwardIosRoundedIcon className="arrow-left" /> Photos
                    </div>
                    {!localStorage.getItem("loginSuccessToken") && <div style={{ color: '#a1a0a4', marginRight: '300px' }}>
                        <span>
                            <i>For Better Experience</i>
                            <span id='fbe' onClick={() => {
                                this.props.navigation.navigate('EmailAccountLoginBlock')
                            }} style={{ color: "red", fontWeight: 'bold', cursor: 'pointer' }}> Login/</span><span onClick={() => {
                                this.props.navigation.navigate('signUp')
                            }} style={{ color: "red", fontWeight: 'bold', cursor: 'pointer' }}>Register</span>
                        </span>
                    </div>}
                </div>
                <Container>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', margin: '20px 0px', alignItems: 'center', position: 'relative' }}>

                        <div style={{ padding: '5px 10px', backgroundColor: 'white', fontSize: 'small', borderRadius: '100px', display: 'flex', alignItems: 'center' }}>
                            <span style={{ fontWeight: 'bold' }}>Sort By:</span>Recently added{this.state.isExpand ? <span onClick={() => this.setState({ isExpand: false })} style={{ cursor: 'pointer' }}><ExpandLess /></span> : <span onClick={() => this.setState({ isExpand: true })} style={{ cursor: 'pointer' }}><ExpandMore /></span>}
                        </div>
                        {this.state.isExpand && <Box sx={style} >
                            <Paper elevation={2} style={{ padding: '20px' }}>
                                <RadioGroup
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    onChange={(e: any) => {
                                        this.setState({ sort: e.target.value });
                                    }}
                                    id='radio'
                                >
                                    <FormControlLabel control={<Radio value={'recently_added'} />} label="Recently added" />
                                    <FormControlLabel control={<Radio value={'old_to_new'} />} label="From old to new" />

                                </RadioGroup>


                            </Paper>
                        </Box>}
                    </div>
                    <Grid container spacing={3}>
                        {this.imgArr.map((item: any,index:any) => {
                            return <Grid id='grid' key={index} item xs={12} sm={6} md={3}>

                                <img id='img'  style={{cursor:'pointer'}} onClick={()=>{
                                    this.setState({imgModal:true});
                                    this.setState({singleImg:item})
                                }} src={item} width={300} height={250} />


                            </Grid>
                        })}

                    </Grid>
                    <VisionStatementWeb navigation={this.props.navigation} id={''} classes={undefined} openSearch={undefined}/>
                </Container>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={this.state.imgModal}
                    onClose={this.closeImgModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.imgModal}>
                        <Box sx={modalStyle}>
                            <div id='imgModal' onClick={this.closeImgModal} style={{display:'flex',justifyContent:'flex-end',position:'relative',cursor:'pointer'}}><img style={{position:'absolute',  transform: 'translate(-50%, -50%)',left:'100%'}} src={closeCircle} width={30}/></div>
                        <img src={this.state?.singleImg} width={400} height={350} />

                        </Box>
                    </Fade>
                </Modal>
            </>
        )
    }
}
// Customizable Area End