// Customizable Area Start
import React, { Component } from 'react'
import MerchantDashboard2Controller from './MerchantDashboard2Controller';

import {
    Box,
    Container,
    Modal,
    Button,
    ListItem,
    IconButton,
    ListItemIcon,
    ListItemText,
    Divider,
    Collapse,
    List,
    Grid,
    Card,
    CardContent,
    CardActions,
    Typography,
    Switch, Radio, Avatar, TextField, InputAdornment, Paper

} from "@material-ui/core";
import LeftIcon from '@material-ui/icons/KeyboardBackspace';
import DateRange from '@material-ui/icons/DateRange';
import Person from '@material-ui/icons/Person';
import Place from '@material-ui/icons/Place';
import SearchIcon from '@material-ui/icons/Search'
import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import FooterImage from './FooterImage.web';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';

const logo = require(".././assets/logo.png");
const trippleLine = require(".././assets/tripple_line.png");
const bell = require(".././assets/bell.png");
const cart = require(".././assets/cart.png");
const sos = require(".././assets/sos.png");
const appchart = require(".././assets/appchart.png");
const terms = require(".././assets/terms.png");
const productcatalogues = require(".././assets/productcatalogues.png");
const ambasdar = require(".././assets/ambasdar.png");
const batterySelection = require(".././assets/batterySelection.png");
const mobile = require(".././assets/mobile.png");


export default class Report extends MerchantDashboard2Controller {
    arr = [0, 1, 2, 3]
    render() {
        return (
            <>
                <div style={{ backgroundColor: '#de403a' }}>

                    <div style={{ display: 'flex', justifyContent: 'space-around', padding: '15px' }}>


                        <div style={{ display: 'flex', alignItems: 'center' }}>

                            <div style={{ marginRight: '20px' }}><img src={trippleLine} width='20px' /></div>
                            <div style={{ marginRight: '20px' }}><img src={logo} width='100px' /></div>
                            <div>
                                <TextField fullWidth style={{ width: '600px', backgroundColor: 'white' }}
                                    variant='outlined'
                                    placeholder='Search Battery type/Category/Model Name/Model Number'
                                    InputProps={{
                                        // disableUnderline: true,
                                        endAdornment: (
                                            <InputAdornment position="end"
                                                style={{ backgroundColor: '#a91c17', padding: '12px 15px', height: '50px', marginRight: '-14px' }}
                                            >
                                                <span style={{ color: 'white' }}>
                                                    <SearchIcon />
                                                </span>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                        </div>

                        <div style={{ display: 'flex', alignItems: 'center' }}>


                            <div style={{ marginRight: '20px' }}><img src={sos} width='50px' /></div>
                            <div style={{ marginRight: '20px' }}><img src={bell} width='30px' /></div>
                            <div><img src={cart} width='30px' /></div>
                        </div>

                        <div style={{display:'flex'}}>
                            <div>
                                <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                            </div>
                            <div><ArrowDownIcon /></div>
                        </div>

                    </div>
                </div>
                <div style={{ backgroundColor: '#cc302a', padding: '20px', color: 'white', display: 'flex', justifyContent: 'center' }}>


                    <div style={{ marginRight: '20px' }}>2 WHEELER BATTERIES</div>
                    <div style={{ marginRight: '20px' }}>3 WHEELER BATTERIES</div>
                    <div style={{ marginRight: '20px' }}>CAR/SUV/MUV BATTERIES</div>
                    <div style={{ marginRight: '20px' }}>COMMERCIAL VEHICLE BATTERIES</div>
                    <div style={{ marginRight: '20px' }}>TRACTOR BATTERIES</div>
                    <div style={{ marginRight: '20px' }}>INVERTER BATTERIES</div>


                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-start', padding: '20px', backgroundColor: '#efefef' }}>
                    <div style={{ color: '#a1a0a4', marginLeft: '40px' }}>
                        <a href='/' style={{ textDecoration: 'none', color: '#a1a0a4' }}>Home</a> <ArrowForwardIosRoundedIcon className="arrow-left" /> Reports
                    </div>

                </div>

                <Container maxWidth='lg' style={{ marginTop: '20px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                        <div style={{ fontWeight: 'bold', display: 'flex', alignItems: 'center' }}><span ><LeftIcon /></span> John's Report</div>
                        <div>Total Completed  <span style={{ fontWeight: 'bold' }}>119</span></div>
                    </div>
                    <Grid spacing={2} container>
                        {this.arr.map((item) => {
                            return <Grid item xs={12} md={4}>
                                <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0px' }}>
                                    <span><DateRange /></span>
                                    <div>10 Apr 22 - 4:30PM</div>
                                </div>
                                <Paper elevation={2}>

                                    <Box
                                        sx={{ bgcolor: 'background.paper', borderRadius: '20px', padding: '5px' }}
                                    >
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>

                                            <div style={{ borderRight: '1px solid black', borderBottom: '1px solid black', padding: '5px' }}>
                                                <div style={{ marginRight: '30px', marginTop: '10px' }}>

                                                    <small>Type of service call</small>
                                                    <div><small style={{ fontWeight: 'bold' }}>Car Battery Problem</small></div>
                                                    <div style={{ border: '1px solid black', borderRadius: '25px', padding: '0px 5px', width: 'max-content', margin: '5px 0px' }}><span style={{ fontSize: 'small' }}>Other Battery</span></div>
                                                </div>

                                            </div>

                                            <div style={{ borderBottom: '1px solid black', padding: '5px' }}>
                                                <div style={{ marginLeft: '15px', marginTop: '10px' }}>

                                                    <small>Assigned Technician</small>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <Avatar style={{ width: '25px', height: '25px' }} alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                                                        <div style={{ display: 'flex', flexDirection: 'column', padding: '10px' }}>
                                                            <small>John Doe</small>
                                                            <small>+91 788394994</small>
                                                        </div>

                                                    </div>

                                                </div>


                                            </div>

                                        </div>
                                        <div>
                                            <div style={{ fontSize: 'small', padding: '5px' }}>Customer Details</div>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div style={{ fontSize: 'small' }}><Person /></div>
                                                <div style={{ fontSize: 'small', paddingLeft: '5px' }}>John Doe, +91788448848</div>

                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div><Place fontSize='small' /></div>
                                                <div style={{ fontSize: 'small', paddingLeft: '5px' }}>Bezel Pharma, Opp Plot 99,Govt Indl Estate,Mumbai,7233</div>

                                            </div>

                                        </div>

                                        <div style={{ display: "flex", justifyContent: 'space-between', backgroundColor: '#ebf9f5', padding: '10px', margin: '10px' }}>
                                            <div style={{ fontSize: 'small' }}>Service Charge</div>
                                            <div style={{ fontSize: 'small' }}>Rs.500</div>
                                        </div>

                                    </Box>
                                </Paper>
                            </Grid>
                        })}
                    </Grid>

                    <FooterImage navigation={this.props.navigation} id={this.props.id}/>

                </Container></>
        )
    }
}
// Customizable Area End