import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { boolean } from "yup";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");
const {baseURL:apiBaseUrl}=require('./../../../framework/src/config')

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  otp: string;
  first_name: any;
  phone_number: any;
  otpVal: any;
  signUpSuccess: boolean;
  loginSuccess: boolean;
  showSignUpVerifyErrors: boolean;
  showLoginVerifyErrors: boolean;
  SignUpVerifyErrorsData: any;
  signUpErrorsData: any;
  showSignUpErrors: boolean;
  LoginVerifyErrorsData: any;
  otpAuthToken: string;
  userAccountID: string;
  labelInfo: string;
  toMessage: string;
  isFromForgotPassword: boolean;
  timer: any;
  isOtpBlank: boolean;
  isLoading: boolean
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  navigation: any;
  // Customizable Area End
}

export default class OTPInputAuthController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  otpAuthApiCallId: any;
  apiSendOTPCallId: any;
  btnTxtSubmitOtp: string;
  placeHolderOtp: string;
  labelInfo: string;
  submitButtonColor: any = configJSON.submitButtonColor;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
      otp: "",
      otpVal: "",
      first_name: "",
      phone_number: "",
      signUpSuccess: false,
      loginSuccess: false,
      showSignUpVerifyErrors: false,
      showLoginVerifyErrors: false,
      SignUpVerifyErrorsData: "",
      signUpErrorsData: "",
      showSignUpErrors: false,
      LoginVerifyErrorsData: "",
      otpAuthToken: "",
      userAccountID: "",
      labelInfo: configJSON.labelInfo,
      toMessage: "",
      isFromForgotPassword: false, timer: 30, isOtpBlank: false, isLoading: false
    };

    this.btnTxtSubmitOtp = configJSON.btnTxtSubmitOtp;
    this.placeHolderOtp = configJSON.placeHolderOtp;
    // Customizable Area End
  }

  async receive(from: String, message: Message) {
    // Customizable Area Start
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiSendOTPCallId != null &&
      this.apiSendOTPCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (
        responseJson &&
        (responseJson.messages ||
          (responseJson.meta && responseJson.meta.token))
      ) {
        if (responseJson.meta && responseJson.meta.token) {
          console.log("getting response.....", responseJson.meta.token);

          this.setState({
            otpAuthToken: responseJson.meta.token
          });
        }

        if (this.state.isFromForgotPassword) {
          // runEngine.debugLog("about to send NavigationNewPasswordMessage");
          const msg: Message = new Message(
            getName(MessageEnum.NavigationNewPasswordMessage)
          );
          msg.addData(
            getName(MessageEnum.AuthTokenDataMessage),
            this.state.otpAuthToken
          );

          msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

          this.send(msg);
        } else {
          const msg: Message = new Message(
            getName(MessageEnum.NavigationMobilePhoneAdditionalDetailsMessage)
          );

          msg.addData(
            getName(MessageEnum.AuthTokenDataMessage),
            this.state.otpAuthToken
          );

          msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

          this.send(msg);
        }
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (errorReponse != null) {
        this.parseApiCatchErrorResponse(errorReponse);
      }
    } else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const phoneAuthToken = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );

      const phoneNumber = message.getData(
        getName(MessageEnum.AuthTokenPhoneNumberMessage)
      );

      const forgotPasswordBool = message.getData(
        getName(MessageEnum.EnterOTPAsForgotPasswordMessage)
      );

      const emailValue = message.getData(
        getName(MessageEnum.AuthTokenEmailMessage)
      );

      const userAccountID = phoneNumber ? "" + phoneNumber : "" + emailValue;

      let updatedLabel = this.state.labelInfo;
      if (userAccountID && userAccountID !== "undefined") {
        updatedLabel = updatedLabel.replace("phone", userAccountID);
      }

      this.setState({
        otpAuthToken:
          phoneAuthToken && phoneAuthToken.length > 0
            ? phoneAuthToken
            : this.state.otpAuthToken,
        userAccountID: userAccountID,
        labelInfo: updatedLabel,
        isFromForgotPassword:
          forgotPasswordBool === undefined
            ? this.state.isFromForgotPassword
            : forgotPasswordBool
      });
    }
    // Customizable Area End
  }

  // Customizable Area Start

  getModalStyle = () => {
    return {
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    };
  }



  verifyOTP = () => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let TOKEN = localStorage.getItem("token")

    var raw = JSON.stringify({
      "token": TOKEN,
      "pin": this.state.otpVal,
      "account_type": "sign_up"
    });



    fetch(apiBaseUrl+"/account/accounts/sms_confirmation?language=en",
      {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        // redirect: 'follow'
      })
      .then(response => response.json())
      .then(result => {
        if (result.errors) {
          this.setState({ showSignUpVerifyErrors: true, SignUpVerifyErrorsData: result.errors[0]?.phone || result.errors[0]?.pin })
          return
        }

        console.log("mil rahe ...", result)

        this.signUp()

      })
      .catch(error => console.log('error', error));
  }

  signUp = () => {
    let TOKEN: any = localStorage.getItem("token")
    let FIRSTNAME = localStorage.getItem("FirstName")
    let PHONENUMBER = localStorage.getItem("PhoneNumber")
    let myHeaders = new Headers();
    myHeaders.append("token", TOKEN);
    myHeaders.append("Content-Type", "application/json");
    // let phone = this.state.phone_number.replace("+91", "");

    var raw = JSON.stringify({
      "data": {
        "type": "sms_account",
        "attributes": {
          "first_name": FIRSTNAME,
          "full_phone_number": '+91' + PHONENUMBER,
        }
      }
    });



    fetch(apiBaseUrl+"/account_block/accounts",
      {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        // redirect: 'follow'
      }
    )
      .then(response => response.json())
      .then(result => {
        if (result.errors) {
          this.setState({ showSignUpErrors: true, signUpErrorsData: result?.errors[0]?.full_phone_number || result?.errors[0]?.first_name })
          // alert(result?.errors[0]?.full_phone_number || result?.errors[0]?.first_name)
          return
        }
        console.log("signup....", result)

        this.setState({ signUpSuccess: true })

        // this.props.navigation.navigate("EmailAccountLoginBlock")
      })
      .catch(error => console.log('error', error));
  }


  loginVerifyOTP = () => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let TokenLoginVerify = localStorage.getItem("loginToken")

    var raw = JSON.stringify({
      "token": TokenLoginVerify,
      "pin": this.state.otpVal,
      "account_type": "login"
    });

    fetch(apiBaseUrl+"/account/accounts/sms_confirmation?language=en",
      {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        // redirect: 'follow'
      })
      .then(response => response.json())
      .then(result => {
        if (result.errors) {
          this.setState({ showLoginVerifyErrors: true, LoginVerifyErrorsData: result.errors[0]?.phone || result.errors[0]?.pin })
          return
        }
        console.log("login verify ho rahe....", result)
        localStorage.setItem("loginVerifyToken", result.meta.token)
        this.loginSuccess()
      })
      .catch(error => console.log('error', error));
  }

  loginSuccess = () => {
    let myHeaders = new Headers();
    this.setState({ isLoading: true })
    myHeaders.append("Content-Type", "application/json");
    let TokenLoginSuccess = localStorage.getItem("loginVerifyToken")
    var raw = JSON.stringify({
      "token": TokenLoginSuccess,
      "language": "en",
      "data": {
        "type": "sms_account"
      }
    });

    fetch(apiBaseUrl+"/login/login?language=en", {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      // redirect: 'follow'
    })
      .then(response => response.json())
      .then(result => {
        this.setState({ isLoading: false })
        console.log("login..success...", result)
        localStorage.setItem("loginSuccessToken", result.meta.token)
        this.setState({ loginSuccess: true })
        // this.props.navigation.navigate("/")
      })
      .catch(error => { console.log('error', error); this.setState({ isLoading: false }) });

  }


  async submitOtp() {
    if (!this.state.otp || this.state.otp.length === 0) {
      this.showAlert(configJSON.errorTitle, configJSON.errorOtpNotValid);
      return;
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    if (this.state.isFromForgotPassword) {
      // console.log("entered is from forgot password!");
      const header = {
        "Content-Type": configJSON.apiVerifyOtpContentType
      };

      //GO TO REQUEST STATE
      this.otpAuthApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.apiVerifyForgotPasswordOtpEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      const data = {
        token: this.state.otpAuthToken ? this.state.otpAuthToken : "",
        otp_code: this.state.otp ? this.state.otp : ""
      };

      const httpBody = {
        data: data
      };

      //requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
    } else {
      const headers = {
        "Content-Type": configJSON.apiVerifyOtpContentType,
        token: this.state.otpAuthToken
      };

      this.otpAuthApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.apiVerifyOtpEndPoint + this.state.otp
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(JSON.stringify({}))
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiVerifyOtpMethod
    );
    // console.log("requestMessage.id is: " + requestMessage.id);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  btnSubmitOTPProps = {
    onPress: () => this.submitOtp()
  };

  txtMobilePhoneOTPWebProps = {
    onChangeText: (text: string) => this.setState({ otp: text })
  };

  txtMobilePhoneOTPMobileProps = {
    ...this.txtMobilePhoneOTPWebProps,
    keyboardType: "numeric"
  };

  txtMobilePhoneOTPProps = this.isPlatformWeb()
    ? this.txtMobilePhoneOTPWebProps
    : this.txtMobilePhoneOTPMobileProps;

  // Customizable Area End
}
