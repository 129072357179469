import React, { Component } from 'react'

import { Box, Container, Grid, Input, InputAdornment, Button, Link } from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import { Card, CardHeader, CardContent, CardMedia, CardActions } from "@material-ui/core"
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import { NavLink } from "react-router-dom";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import GridOnIcon from '@material-ui/icons/GridOn';
import PermContactCalendarOutlinedIcon from '@material-ui/icons/PermContactCalendarOutlined';
import PlayCircleFilledWhiteOutlinedIcon from '@material-ui/icons/PlayCircleFilledWhiteOutlined';
// import './Social1.css'

// import "./assets/styles.css"


// import {
//   createStyles,
//   withStyles,
//   makeStyles,
//   Theme,
// } from "@material-ui/core/styles";
const EzBatteryWhite = require("../assets/Artboard 1 copy@4x.png")
const greenTick = require("../assets/greentick.png")
const redBattery = require("../assets/red_battery.png")
const yellowStar = require("../assets/yellow_star.png")
const trippleLine = require("../assets/tripple_line.png")
const ezBattery = require("../assets/ez_battery.png")
const blackBattery = require("../assets/bla_battery.png")
const greenBattery = require("../assets/greenBattery.png")
const sosImage = require("../assets/sosImage.png")
const youtubeImg = require("../assets/Group.png")

const Styles = {
    inputSearchBox: {
        width: "25px",
        height: "35px",
        alignItems: "center",
        border: "1px solid #b30000",
        borderRadiusRight: "5px",
        backgroundColor: "#b30000",
        paddingRight: "10px",
        color: "white",
        cursor: "pointer"
    },
    cardhead: {
        font: "10px",
        color: "grayText",
        textDecoration: "underline",
        textDecorationColor: "gray",
        textDecorationThickness: "1px",
    }
}


import SocialMediaAccountWebController, {
    Props
} from "./SocialMediaAccountWebController";

export default class SocialMedia3 extends SocialMediaAccountWebController {
    render() {
        const { menus } = this.state

        return (
            <>
                <div style={{ overflowX: "hidden" }}>
                    <div style={{ display: "flex", flexDirection: "column", overflowX: "hidden", borderBottom: "5px solid #32cba1" }}>
                        <Grid container style={{ backgroundColor: "red", minHeight: "85px", maxHeight: "85px" }}>
                            <div style={{ width: "400px", marginRight: "30px" }}>
                                <div style={{ display: "flex", marginLeft: "200px" }}>
                                    <img src={trippleLine} style={{ width: "20px", height: "20px", marginTop: "30px", marginRight: "20px" }} />
                                    <NavLink
                                        exact
                                        activeClassName="active_class"
                                        to="/"
                                        style={{ textDecoration: "none" }}
                                    >
                                        <img src={EzBatteryWhite} style={{ width: "100px", height: "40px", color: "white", marginTop: "20px" }} />
                                    </NavLink>
                                </div>
                            </div>
                            {/* <div style={{ display:"flex", width: "440px",height:"30px",marginTop: "20px", marginRight:"30px",
               alignItems: "center",borderRadius: "5px", padding:"10px",border:"1px black",backgroundColor:"white"
             }}>
            
                <input type='text' style={{width:"410px",outline:"none",border: "none",background:"none"}}/> 
                <div style={{width:"40px",height:"50px",alignItems:"center",backgroundColor:"#b30000",borderRadius: "5px"}}>
                <SearchIcon style={{objectFit:"contain"}}/> 
             </div>         
         </div>  */}
                            <div style={{ marginTop: "20px" }}>
                                <Input
                                    id="input-with-icon-adornment"
                                    value={this.state.searchValue}
                                    placeholder='Search Battery type/Category/Model Name/Model Number'
                                    style={{
                                        width: "470px", border: "1px solid red", borderBlock: "none", backgroundColor: "white",
                                        borderRight: "2px solid #b30000",
                                        borderRadius: "4px"
                                    }}
                                    onChange={(e: any) => {
                                        this.setState({ searchValue: e.target.value })
                                    }}
                                    onClick={this.handleSearch}
                                    endAdornment={
                                        <InputAdornment position="end"
                                            style={Styles.inputSearchBox}
                                        >
                                            <SearchIcon />

                                        </InputAdornment>
                                    }
                                />
                                <div style={{ position: "relative", backgroundColor: "white" }}>
                                    {this.state.openSearch &&
                                        <div>
                                            <Typography style={{ padding: "10px", fontSize: "14px", cursor: "pointer" }}
                                                onClick={this.recentSearchCall}
                                            >
                                                Recent searches
                                            </Typography>

                                            {this.state.recentSearchData?.map((item: any, index: any) => {
                                                return (
                                                    <div key={index} style={{ display: "flex", marginTop: "-25px" }}>
                                                        <p style={{ marginLeft: "10px" }}>
                                                            {item?.search_term}
                                                        </p>
                                                        {item?.search_term &&
                                                            <p style={{ marginLeft: "355px", cursor: "pointer" }}
                                                                onClick={() => {
                                                                    let x = this.state.recentSearchData
                                                                    x[index].search_term = !x[index].search_term
                                                                    this.setState({ recentSearchData: [...x] })
                                                                    localStorage.setItem("recentSearchId", this.state.recentSearchData?.id);
                                                                    this.deleteRecentSearchCall(item.id)
                                                                }}>X</p>
                                                        }
                                                    </div>
                                                )
                                            })}



                                            <div style={{ display: "flex" }}>
                                                <a
                                                    href='Dashboard'
                                                    style={{ textDecoration: "none" }} >
                                                    <div style={{ width: "194px", margin: "10px", padding: "10px", backgroundColor: "#d6f5ed", borderRadius: "5px" }}>
                                                        <p style={{ textAlign: 'center', fontSize: "12px" }}>
                                                            <b>All Batteries</b>
                                                        </p>
                                                    </div>
                                                </a>
                                                <NavLink
                                                    exact
                                                    activeClassName="active_class"
                                                    to="/Filteritems"
                                                    style={{ textDecoration: "none" }}
                                                >
                                                    {/* <a href='Filteritems' style={{textDecoration:"none"}}> */}
                                                    <div style={{ margin: "10px", width: "195px", padding: "10px", backgroundColor: "#32cba1", borderRadius: "5px" }}
                                                    // onClick={()=>{this.setState({open:true})}}
                                                    >
                                                        <p style={{ textAlign: 'center', fontSize: "12px" }}>
                                                            <b>Need help with battery selection</b>
                                                        </p>
                                                    </div>
                                                </NavLink>
                                                {/* </a> */}
                                            </div>
                                            <Avatar
                                                style={{
                                                    width: "25px",
                                                    height: "25px",
                                                    fontSize: "20px",
                                                    margin: "17px 10px",
                                                    backgroundColor: "red",
                                                    marginLeft: "222px",
                                                    cursor: "pointer"
                                                }}
                                                onClick={() => this.setState({ openSearch: false })}
                                            >
                                                X
                                            </Avatar>
                                            {/* <Typography style={{ textAlign: "center" }}
                     >
                      <b>X</b>
                    </Typography> */}
                                        </div>
                                    }

                                </div>

                            </div>

                            <div>
                                <img src={sosImage} style={{ width: "40px", height: "30px", marginTop: "20px", marginLeft: "100px" }} />
                            </div>
                            <div style={{ display: "flex", color: "white", marginTop: "25px", marginLeft: "40px" }}>
                                <div style={{ marginRight: "30px" }}>
                                    <NotificationsNoneIcon />
                                </div>
                                {localStorage.getItem("loginSuccessToken") &&
                                    <ShoppingCartIcon style={{ cursor: "pointer" }}
                                        onClick={() => this.props.navigation.navigate("AddShoppingCartOrderItem")}
                                    />
                                }

                                {!localStorage.getItem("loginSuccessToken") &&
                                    <ShoppingCartIcon style={{ cursor: "pointer" }}
                                        onClick={() => this.props.navigation.navigate("LoginRequired")}
                                    />
                                }
                            </div>
                            <div>
                                <a
                                    href='EmailAccountLoginBlock'
                                    style={{ textDecoration: "none" }}
                                >
                                    <p style={{ color: "white", marginTop: "30px", marginLeft: "200px" }} >Login</p>
                                </a>
                            </div>

                        </Grid>
                        <Grid container style={{ backgroundColor: "#b30000", height: "40px" }}>

                            <p style={{ marginLeft: "170px", marginRight: "40px", color: "white", fontSize: "13px", cursor: "pointer" }}
                                onClick={() => this.props.navigation.navigate("Categoriessubcategories")}>
                                2 WHEELER BATTERIES</p>
                            <p style={{ marginRight: "40px", color: "white", fontSize: "13px", cursor: "pointer" }}
                                onClick={() => this.props.navigation.navigate("ThreeWheeler")}>
                                3 WHEELER BATTERIES</p>

                            <p style={{ marginRight: "40px", color: "white", fontSize: "13px", cursor: "pointer" }}
                                onClick={() => this.props.navigation.navigate("CarSuvMuvBatteries")}
                            >CAR/SUV/MUV BATTERIES</p>
                            <p style={{ marginRight: "40px", color: "white", fontSize: "13px", cursor: "pointer" }}
                                onClick={() => { this.props.navigation.navigate("CommercialVehicle") }}
                            >COMMERCIAL VEHICLE BATTERIES</p>
                            <p style={{ marginRight: "40px", color: "white", fontSize: "13px", cursor: "pointer" }}
                                onClick={() => { this.props.navigation.navigate("TractorDashboard") }}
                            >TRACTOR BATTERIES</p>
                            <p style={{ marginRight: "40px", color: "white", fontSize: "13px", cursor: "pointer" }}
                                onClick={() => { this.props.navigation.navigate("InverterDashboard") }}
                            >INVERTER BATTERIES</p>
                        </Grid>

                        <Box style={{ background: "lightgrey", padding: "10px", height: "24px" }}>
                            <Container style={{ display: "flex" }} >
                                <Typography style={{ marginLeft: "20px" }}> Home <ArrowForwardIosRoundedIcon className="arrow-left" /> 3 Wheeler Batteries
                                </Typography>
                                {!localStorage.getItem("loginSuccessToken") &&
                                    <Typography style={{ display: "flex", marginLeft: "700px" }}>For better experience &nbsp;
                                        <p onClick={() => this.props.navigation.navigate("EmailAccountLoginBlock")}
                                            style={{ color: "red", marginTop: "0px", cursor: "pointer" }}>Login/</p>
                                        <p style={{ color: "red", marginTop: "0px", cursor: "pointer" }}
                                            onClick={() => this.props.navigation.navigate("signUp")}
                                        >Register</p>
                                    </Typography>
                                }
                            </Container>
                        </Box>

                        {/* {this.state.showSearchData && this.state.openSearch && */}
                        <div style={{ marginTop: "165px", marginLeft: "155px", marginRight: "155px" }}>
                            <Grid container style={{ display: "flex", flexDirection: "row", position: "relative", }} >
                                {/* <Grid item xs={2} /> */}
                                {this.state.serachData?.map((item: any, index: any) => {
                                    return (
                                        <Grid item xs={3} key={index} >


                                            <Box m={1}>
                                                <Card style={{ width: "250px", height: "400px" }} >
                                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                                        <img style={{ width: "10%", height: "10%", marginLeft: "10px", marginTop: "10px" }} src={greenTick} />
                                                        <p style={Styles.cardhead}>{item?.attributes?.warranty} warranty</p>

                                                        <IconButton aria-label="settings" style={{ marginTop: "5px" }}>
                                                            <FavoriteBorderIcon />
                                                        </IconButton>

                                                    </div>

                                                    <CardContent>
                                                        <img style={{ display: "block", marginLeft: "60px", marginBottom: "15px", width: "50%", height: "30%" }} src={item?.attributes?.thumbnail_image} />
                                                        <div style={{ marginLeft: "30px" }}>
                                                            {/* <div style={{ width: "80%", border: "1px solid white", borderRadius: "10px", backgroundColor: "#ff9999", textAlign: "center" }}>
                                Category:Inverter
                              </div> */}
                                                        </div>
                                                        <p style={{ textAlign: "center" }}>{item?.attributes?.model_no}</p>
                                                        <p style={{ textAlign: "center" }}>{item?.attributes?.capaciy}</p>
                                                        <p style={{ textAlign: "center" }}>${item?.attributes?.amount}</p>
                                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                                            <div style={{ width: "35px", height: "20px", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", border: "1px solid gray", borderRadius: "10px" }}>
                                                                <img style={{ width: "10px", height: "10px" }} src={yellowStar} />
                                                                <p>{item?.attributes?.range}</p>
                                                            </div>
                                                            <div style={{ marginLeft: "10px" }}>
                                                                <div style={{ width: "70px", border: "1px solid gray", borderRadius: "10px", backgroundColor: "green" }}>
                                                                    {item?.attributes?.applied_discount}% off
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </CardContent>
                                                </Card>
                                            </Box>
                                        </Grid>


                                    )

                                })

                                }
                            </Grid>
                        </div>
                        {/* } */}
                        <Container maxWidth="md" style={{ border: "1px solid lightgrey", padding: "20px", background: "#FAFAFA" }}>
                <Box>
                    <Box>
                        <Grid container>
                            <Grid item xs={3}>
                                <Box style={{ border: "3px solid rgb(131,58,180)", width: "150px", height: "150px", borderRadius: "50%", }}>
                                    <img src="https://images.pexels.com/photos/3998365/pexels-photo-3998365.png?auto=compress&cs=tinysrgb&w=600" alt="" style={{ width: "100%", height: "100%", borderRadius: "50%", padding: "0px" }} />
                                </Box>
                            </Grid>
                            <Grid item xs={9}>
                                <Grid container spacing={3} direction='column' >
                                    <Grid item xs={7}>
                                        <Box display="flex" alignItems="center" justifyContent="space-between">
                                            <Box style={{ marginRight: "50px" }}>
                                                <Typography>PaviNane</Typography>
                                            </Box>
                                            <Box display="flex" justifyContent="space-between" alignItems="center" style={{ width: "100%" }}>
                                                <Button variant="outlined" style={{ fontWeight: "bold" }} >Message</Button>
                                                <Button variant="outlined" style={{ fontWeight: "bold" }} >Follow</Button>
                                                <MoreHorizIcon />

                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box display="flex" justifyContent="space-between">
                                            <Typography > <span style={{ fontWeight: "bold" }}>1,151</span> posts </Typography>
                                            <Typography><span style={{ fontWeight: "bold" }}>8.9k</span> followers</Typography>
                                            <Typography><span style={{ fontWeight: "bold" }}>1</span> following</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant='h3' style={{ fontSize: "18px", fontWeight: "bold" }}>Pavinane</Typography>
                                        <Typography style={{ marginTop: "10px", color: "#A0A0A2", marginBottom: "10px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</Typography>
                                        <Link href="#" color="inherit" style={{ color: "#1B3E5F", fontWeight: "bold", }}>
                                            bit./jshjsh
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box>
                        <Box display="flex"mt={4} alignItems="center" mb={4}>
                            {
                                ReelsData.map((item) => {
                                    return (

                                        <Box marginLeft={4}  style={{ border: "1px solid lightgrey", width: "100px", height: "100px", borderRadius: "50%",  }}>
                                            <img src={item.img} alt="" style={{ width: "100%", height: "100%", borderRadius: "50%", padding: "0px" }} />
                                            <Typography style={{textAlign:"center"}}>
                                                {  ( item.name).slice(0,10) + ".."  }
                                                </Typography>
                                        </Box>


                                    )
                                })
                            }
                        </Box>
                    </Box>
                </Box>
                <Box mt={10} style={{ borderTop: "2px solid lightgrey" }}>
                    <Box display="flex" justifyContent="center">
                        {
                            InstaData.map((item) => {
                                return <Button onClick={() => this.setState({ menus: item.name })} style={{ borderTop: item.name === menus ? "2px solid #666666" : "", borderRadius: "0px",marginLeft:"40px" }}>{item.icon}&nbsp;{item.name}</Button>
                            })
                        }
                    </Box>
                    <Box mt={4}>
                        {
                            menus === "POSTS" ? (
                                <Grid container spacing={4} >
                                    {
                                        PostData.map((post) => {
                                            return (
                                                <Grid item xs={4}>
                                                    <img src={post.img} alt="" style={{padding:"0px",width:"100%",borderRadius:"10px"}}/>
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            ):(
                                <></>
                            )
                        }
                    </Box>
                </Box>
            </Container>
                        
                    </div>

                    <div>
                        <Grid container style={{ display: "flex", height: "300px" }}>

                            <Grid item xs={1} />
                            <Grid item xs={2} style={{ marginLeft: "30px" }} >
                                <img src={ezBattery} style={{ width: "100px", height: "40px", color: "white", marginTop: "20px" }} />
                            </Grid>
                            <Grid item xs={2} >
                                <p><strong>Product Categories</strong></p>

                                <p
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.props.navigation.navigate("Categoriessubcategories")}>
                                    2 Wheeler Batteries</p>


                                <p
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.props.navigation.navigate("ThreeWheeler")}>
                                    3 Wheeler Batteries</p>

                                <p
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.props.navigation.navigate("CarSuvMuvBatteries")}>
                                    Car/SUV/MUV Batteries</p>
                            </Grid>
                            <Grid item xs={2} style={{ marginTop: "30px" }}>
                                <p
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.props.navigation.navigate("CommercialVehicle")}>
                                    commercial vehicles</p>
                                <p
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.props.navigation.navigate("TractorDashboard")}>
                                    Tractor Batteries</p>
                                <p
                                    style={{ cursor: "pointer" }}
                                    onClick={() => { this.props.navigation.navigate("InverterDashboard") }}
                                >Inverter Batteries</p>
                            </Grid>
                            <Grid item xs={2}>
                                <p><strong>Company</strong></p>
                                <p>About Us</p>
                                <p>Awards&Certifications</p>
                                <div style={{ display: "flex" }}>
                                    <p style={{ marginRight: "10px" }}>careers</p> <br /> <p style={{ height: "20px", backgroundColor: "#d6f5ec", textAlign: "center" }}>We are hiring </p>
                                </div>
                                <p>Testimonials</p>
                                <p>Contact Us</p>
                                <p
                                    onClick={() => this.props.navigation.navigate("Feedback")}
                                    style={{ cursor: "pointer" }}
                                >
                                    Feedback
                                </p>
                            </Grid>
                            <Grid item xs={2}>
                                <p><strong>Follow Us</strong></p>
                                <div style={{ display: "flex" }}>
                                    <TwitterIcon style={{ color: "gray" }} />
                                    <YouTubeIcon style={{ color: "gray" }} />
                                    <CameraAltIcon style={{ color: "gray" }} />
                                </div>
                            </Grid>

                            <Grid item xs={1} />
                        </Grid>
                    </div>
                    <div>
                        <Grid container>
                            <Grid item xs={1} />
                            <Grid item xs={10}>
                                <div style={{ width: "90%", borderBottom: "3px solid gray" }} />
                                <div style={{ display: "flex" }}>
                                    <p>@2022 ALL RIGHTS RESERVED BY EZ BATTERY</p>
                                    <a
                                        href='TermsCondition'
                                        style={{ textDecoration: "none" }}
                                    >
                                        <p style={{ marginLeft: "100px", marginRight: "200px" }}>TERMS & CONDITIONS . Privacy Policy</p>
                                    </a>
                                    <p>Language English</p>
                                </div>
                            </Grid>
                            <Grid item xs={1} />
                        </Grid>
                    </div>
                </div>
            </>
        )
    }
}

export const InstaData = [
    {
        icon:<GridOnIcon />,
        name:"POSTS"
    },
    {
        icon:<PermContactCalendarOutlinedIcon />,
        name:"REELS"
    },
    {
        icon:<PlayCircleFilledWhiteOutlinedIcon />,
        name:"TAGGED"
    },
]

const PostData = [
    {
        img:"https://images.pexels.com/photos/3998365/pexels-photo-3998365.png?auto=compress&cs=tinysrgb&w=600"
    },
    {
        img:"https://images.pexels.com/photos/3998365/pexels-photo-3998365.png?auto=compress&cs=tinysrgb&w=600"
    },
    {
        img:"https://images.pexels.com/photos/3998365/pexels-photo-3998365.png?auto=compress&cs=tinysrgb&w=600"
    },
    {
        img:"https://images.pexels.com/photos/3998365/pexels-photo-3998365.png?auto=compress&cs=tinysrgb&w=600"
    },
]

 const ReelsData = [
    {
        img:"https://images.pexels.com/photos/3998365/pexels-photo-3998365.png?auto=compress&cs=tinysrgb&w=600",
        name:"Featured"
    },
    {
        img:"https://images.pexels.com/photos/3998365/pexels-photo-3998365.png?auto=compress&cs=tinysrgb&w=600",
        name:"Batteries"
    },
    {
        img:"https://images.pexels.com/photos/3998365/pexels-photo-3998365.png?auto=compress&cs=tinysrgb&w=600",
        name:"Happy Customer"
    },
]