import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { apiCallNoStringify } from '../../../components/src/ApiCall'
import Loader from "../../../components/src/Loader.web";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Customizable Area Start
export const configJSON = require("./config");
const {baseURL:apiBaseUrl}=require('./../../../framework/src/config')
import { apiCall } from '../../../components/src/ApiCall';
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: any;
  history?: any;
  push: any;
  location?: any;
  match?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  first_name: any;
  phone_number: any;
  showErrors: boolean;
  errorsData: any;
  blankDataM: boolean;
  blankDataF: boolean;
  isLoading: boolean;
  nameError:any;
  phoneError:any
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  token: any;
  // Customizable Area End
}

export default class signUpController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  surveyreportdetailsApiCallId: any
  apiSendOTPCallId: any;
  // Customizable Area End

  constructor(props: Props) {

    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start

    //alert(date)
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials)
    ];

    this.state = {
      first_name: "",
      phone_number: "",
      showErrors: false,
      errorsData: "",
      blankDataM: false,
      blankDataF: false,
      isLoading: false,
      nameError:'',
      phoneError:'',
      
      

    };

    this.receive = this.receive.bind(this);
    this.sendOTP = this.sendOTP.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }


  componentDidUpdate() {

  }
  //   async componentDidMount() {
  //     this.PoPostListData();
  //  this.getSurveyReportDeatilsdata();

  //   }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    //console.log("@@@ MESSAGE EMAIL ACCOUNT REGISTRATION ===", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.apiSendOTPCallId) {
        alert("done")

        console.log(responseJson, " this is responce 1");

        if (responseJson?.errors) {
          alert("error")
          this.showAlert("Error", responseJson?.error);
        } else {
          alert("else 111")
          //       this.setState({surevyData:responseJson?.data.attributes})
          //       this.setState({questionList:responseJson?.data.attributes.survey.attributes.questions.data})
          console.log("@@@@@@@@@@@@@");


        }
      }

    }

    // Customizable Area End 
  }

  validate = () => {

  }




  sendOTP() {
    // alert("okk.....")
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    this.setState({ isLoading: true })

    let phone = this.state.phone_number.replace("+91", "");
    var raw = JSON.stringify({
      "data": {
        "attributes": {
          "full_phone_number": '+91' + phone
        }
      },
      "account_type": "sign_up"
    });

    fetch(apiBaseUrl+"/account/accounts/send_otp?language=en", {
      // mode: 'no-cors',
      method: 'POST',
      headers: myHeaders,
      body: raw,
      // redirect: 'follow'
    })
      .then(response => response.json())
      .then(result => {
        if (result.errors) {
          // alert(result.errors[0]?.full_phone_number || result.errors)

          this.setState({ showErrors: true, errorsData: result.errors[0]?.full_phone_number || result.errors, isLoading: false })
          return
        }

        localStorage.setItem("token", result?.meta?.token)

        console.log(result?.meta?.token);
        this.setState({ isLoading: false })


        this.props.navigation.navigate("ForgotPasswordOTP")
      })
      .catch(error => {
        console.log('error', error.errors)
        alert(error.errors[0]?.full_phone_number)
      });
  }






  // sendOTP= async() => {


  //   if (this.state.phone_number !== '') {
  //     // this.setState({ isLoading: true })
  //     alert("send otp ...")
  //     let payLoad = {
  //       data: {
  //         attributes: {
  //           full_phone_number: '+91' + this.state.phone_number
  //         }
  //       }
  //     }
  //     let language = 'en'
  //     this.apiSendOTPCallId = await apiCall({


  //       contentType: "application/json",
  //       method: "POST",
  //       endPoint: `account/accounts/send_otp?language=en`,
  //       body: payLoad,
  //       // //type: 'formData'
  //       // console.log("andasr mil rahe...."),

  //     })
  //   }
  //  }



  // getSurveyReportDeatilsdata = async () => {
  //     let eveID = this.props?.match?.params?.id;
  //     //alert(eveID)
  //     this.surveyreportdetailsApiCallId = await apiCall({
  //         contentType: configJSON.validationApiContentType,
  //         method: configJSON.validationApiMethodType,
  //         token: `${this.tokenType} ${this.token}`,
  //         //endPoint: `events/${eveID}`,
  //         endPoint: `account/accounts/send_otp?language=en`,
  //     })
  // }

}  