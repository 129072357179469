// Customizable Area Start
import React from 'react';
import {  Container } from "@material-ui/core";
import VisionStatement from '../../../components/src/VisionStatement.web';
import CategoriessubcategoriesController from './CategoriessubcategoriesController'



export default class PrivacyPolicy extends CategoriessubcategoriesController {
    render() {
        return (
            <>
                <Container>
                    <div id='policy' style={{ fontWeight: 'bold',margin:'20px 0px 20px 0px' }}>Privacy Policy</div>

                    <div id='info'>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. A sint impedit provident laudantium ut commodi omnis adipisci vitae sed eaque voluptatum reiciendis quam, tempora natus vel sunt dicta debitis temporibus?
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                    </div>
                      <VisionStatement navigation={this.props.navigation} id={''} classes={undefined} openSearch={undefined}/>
                </Container>

            </>
        )
    }
}
// Customizable Area End

