import React from 'react'
// Customizable Area Start
import RightIcon from '@material-ui/icons/ChevronRight';
import LeftIcon from '@material-ui/icons/ChevronLeft';
import { Box, Grid, InputAdornment, TextField, Backdrop, CircularProgress, MenuItem, Menu, } from "@material-ui/core";
import { Paper } from '@material-ui/core';
import Carousel from 'react-material-ui-carousel';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Container from '@material-ui/core/Container';

import { Card, CardContent } from "@material-ui/core"
import Typography from '@material-ui/core/Typography';
import ShareIcon from '@material-ui/icons/Share';
import GetAppIcon from '@material-ui/icons/GetApp';

import StarIcon from '@material-ui/icons/Star';

import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import Button from '@material-ui/core/Button';
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    WhatsappIcon,
    LinkedinIcon,
    FacebookIcon,
    TwitterIcon,
} from "react-share";

import './Production.css';


const greenTick = require("../assets/greentick.png")
const redBattery = require("../assets/red_battery.png")
const LoveImg = require("../assets/love.png")
const yellowStar = require("../assets/yellow_star.png")





import ProductDescriptionController, {
    Props,
    configJSON,
} from "./ProductDescriptionController";
import { factory, genuine, quality } from './assets';
import { love, securedGreenTick } from '../../filteritems/src/assets';
import { heart } from '../../dashboard/src/VehiclesBatteries.web';
import VisionStatementWeb from '../../../components/src/VisionStatement.web';

const style = {
    position: 'absolute' as 'absolute',
    top: '37%',
    left: '80%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1,
    width: '260px',
    bgcolor: 'background.paper'

}
export default class ProductDescription1 extends ProductDescriptionController {
    batteryCards = [0, 1, 2, 3];
    handleMenuLang = (event: any) => {
        this.setState({ openLanguageMenu: event.currentTarget })

    };


    handleCloseMenu = () => {
        this.setState({ openLanguageMenu: null })

    }
    finalPrice = () => {
        return (this.state.productPage1Data?.attributes?.amount - this.state.productPage1Data?.attributes?.discount_amount).toLocaleString()
    }
    openShareModal = (e: any) => {
        this.setState({ openShare: e.currentTarget })
    }
    handleCloseShare = () => {
        this.setState({ openShare: null })
    }
    downloadFile =  () => {
        const input: any = document.getElementById('product');
        html2canvas(input)
            .then((canvas) => {
                const imgData: any = canvas.toDataURL('image/png');
                const pdf = new jsPDF();
                pdf.addImage(imgData,'jpg',0,0,250,250);
                pdf.save(`${this.state.productPage1Data?.attributes?.model_no}.pdf`);
            })
    }
    prev = (scrollRef: any) => {
        requestAnimationFrame(() => {
          const scrollLeft = scrollRef.current.scrollLeft;
          const itemWidth = parseInt(
            getComputedStyle(scrollRef.current.children[0]).width
          );
          scrollRef.current.scrollLeft = scrollLeft - itemWidth * 1;
        });
      };
    
      next = (scrollRef: any) => {
        requestAnimationFrame(() => {
          const scrollLeft = scrollRef.current.scrollLeft;
          const itemWidth = parseInt(
            getComputedStyle(scrollRef.current.children[0]).width
          );
          scrollRef.current.scrollLeft = scrollLeft + itemWidth * 1;
        });
      };
    render() {

        
        return (
            <div id='product'>
                <Backdrop
                    style={{ color: '#fff', zIndex: 1 }}
                    open={this.state.isLoading}

                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <div style={{ padding: '20px', backgroundColor: '#EFEFEF' }}>
                    <Container>

                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                            <div id='home' style={{ color: '#a1a0a4' }}>
                                <a href='/' style={{ textDecoration: 'none', color: '#a1a0a4' }}>Home</a> <ArrowForwardIosRoundedIcon className="arrow-left" /> <span style={{ cursor: 'pointer' }} onClick={() => this.props.navigation.goBack()}>Battery Results</span>
                                <ArrowForwardIosRoundedIcon className="arrow-left" />{this.state.productPage1Data?.attributes?.model_no}
                            </div>
                            {!localStorage.getItem("loginSuccessToken") && <div style={{ color: '#a1a0a4' }}>
                                <span>
                                    <i>For Better Experience</i>
                                    <span id='fbe' onClick={() => {
                                        this.props.navigation.navigate('EmailAccountLoginBlock')
                                    }} style={{ color: "red", fontWeight: 'bold', cursor: 'pointer' }}> Login/</span><span id='fbe2' onClick={() => {
                                        this.props.navigation.navigate('signUp')
                                    }} style={{ color: "red", fontWeight: 'bold', cursor: 'pointer' }}>Register</span>
                                </span>
                            </div>
                            }
                        </div>
                    </Container>
                </div>

                <div style={{ backgroundColor: "#FFFFFF",marginTop:'10px' }}>

                    <Container >
                        <Grid container spacing={3}>
                            <Grid item md={4}>
                                <Carousel
                                    interval={4000}
                                    indicators={true}
                                    autoPlay={false}
                                    navButtonsAlwaysVisible
                                    swipe
                                    className="my-carousel"
                                    NextIcon={<ArrowForwardIosIcon fontSize='small' />}
                                    PrevIcon={<ArrowBackIosIcon fontSize='small' />}
                                >

                                    {
                                        this.state.productpageImgData?.map((item: any) => {
                                            return (
                                                <div className='batt-img'>

                                                    <img className="batter-img" src={item} alt="imgs" />

                                                </div>
                                            )
                                        })
                                    }

                                </Carousel>

                            </Grid>
                            <Grid item md={8}>
                                <div>
                                    <div style={{ color: "#8E95A8", lineHeight: "30px" }}>S no : {this.state.productPage1Data?.attributes?.s_no} </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div style={{ fontWeight: 'bold', fontSize: 'xx-large' }}> {this.state.productPage1Data?.attributes?.model_no}</div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>

                                            <div id='share' onClick={(e) => {
                                                this.openShareModal(e)
                                            }} style={{ marginRight: '20px', cursor: 'pointer', padding: '5px 0px' }}><ShareIcon />
                                            </div>
                                            <Menu
                                                id="basic-menu"
                                                anchorEl={this.state.openShare}
                                                open={Boolean(this.state.openShare)}
                                                onClose={this.handleCloseShare}
                                                getContentAnchorEl={null}
                                                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                                            >
                                                <MenuItem data-test-id='menuItem'  onClick={this.handleCloseShare}>
                                                    < WhatsappShareButton
                                                        url={`http://localhost:3000/ProductDescription1/${this.state.productPage1Data?.id}`}
                                                        title="EZ Battery description"
                                                    >
                                                        <WhatsappIcon size={32} round={true} />
                                                    </ WhatsappShareButton>
                                                </MenuItem>
                                                <MenuItem onClick={this.handleCloseShare}>
                                                    < LinkedinShareButton
                                                        url={`http://localhost:3000/ProductDescription1/${this.state.productPage1Data?.id}`}
                                                        title="EZ Battery description"
                                                    >
                                                        < LinkedinIcon size={32} round={true} />
                                                    </ LinkedinShareButton>
                                                </MenuItem>

                                                <MenuItem onClick={this.handleCloseShare}>
                                                    < FacebookShareButton
                                                        url={`http://localhost:3000/ProductDescription1/${this.state.productPage1Data?.id}`}
                                                        title="EZ Battery description"
                                                    >
                                                        < FacebookIcon size={32} round={true} />
                                                    </ FacebookShareButton>
                                                </MenuItem>

                                                <MenuItem onClick={this.handleCloseShare}>
                                                    <TwitterShareButton
                                                        url={`http://localhost:3000/ProductDescription1/${this.state.productPage1Data?.id}`}
                                                        title="EZ Battery description"                                                 >
                                                        < TwitterIcon size={32} round={true} />
                                                    </ TwitterShareButton>
                                                </MenuItem>
                                            </Menu>

                                            <div id='download' style={{ cursor: 'pointer' }} onClick={() => this.downloadFile()}>

                                             
                                                <GetAppIcon />
                                               

                                            </div>
                                            <div id='wishlist'
                                                onClick={() => {
                                                    if (localStorage.getItem('loginSuccessToken')) {

                                                        if (this.state.productPage1Data?.attributes?.is_wishlisted) {
                                                            localStorage.setItem('item_id', this.state.productPage1Data?.id);
                                                            this.deleteWishlist()

                                                        }
                                                        else {

                                                            localStorage.setItem('item_id', this.state.productPage1Data?.id);
                                                            this.createWishList()
                                                        }
                                                    }
                                                    else {
                                                        localStorage.setItem('wishList', JSON.stringify(true))
                                                        this.props.navigation.navigate('LoginRequired')
                                                    }
                                                }}

                                                style={{ marginLeft: '20px', cursor: 'pointer' }}> <img src={this.state.productPage1Data?.attributes?.is_wishlisted ? heart : love} width='25px' height='20px' /></div>

                                        </div>
                                    </div>
                                    <div style={{ fontWeight: 'bold', fontSize: '20px', margin: '10px 0px' }}>
                                        <span style={{ marginRight: '10px' }}> ₹{this.finalPrice() || ''}</span>
                                        <s style={{ color: '#8991A4' }}> ₹{this.state.productPage1Data?.attributes?.amount.toLocaleString()}</s>
                                    </div>
                                </div>


                                <div style={{ display: 'flex' }}>
                                    <div style={{ marginRight: '5px' }}><img src={securedGreenTick} width='20px' height='20px' /></div>
                                    <div style={{ textDecoration: 'underline', fontWeight: 'bold' }}>

                                        {this.state.productPage1Data?.attributes?.warranty}
                                    </div>

                                </div>

                                <div style={{ display: 'flex', margin: '10px 0px' }}>

                                    <div style={{ display: 'flex', border: '1px solid gray', marginRight: '10px', borderRadius: '25px', paddingLeft: '5px', paddingRight: '5px' }}>
                                        <span>
                                            <img src={yellowStar} width='15px' height='15px' />
                                        </span>
                                        <span style={{ display: 'flex', alignItems: 'center' }}>{this.state.productPage1Data?.attributes?.range}</span>

                                    </div>
                                </div>

                                <div style={{ display: 'flex', margin: '25px 0px' }}>


                                    <div style={{ display: 'flex', fontSize: '15px', fontWeight: 'bold' }}>
                                        <div style={{ marginRight: '40px' }}>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}><img src={genuine} width='25px' /></div>
                                            <div>Genuine</div>
                                            <div>Product</div>
                                        </div>

                                        <div>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}> <img src={factory} width='45px' /></div>
                                            <div style={{ marginTop: '14px' }}>Factory</div>
                                            <div>Charged</div>
                                        </div>

                                        <div style={{ marginLeft: '40px' }}>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}> <img src={quality} width='25px' /></div>
                                            <div style={{ marginTop: '10px' }}>Quality</div>
                                            <div>Checked</div>
                                        </div>

                                    </div>

                                </div>

                                <div style={{ display: 'flex' }}>

                                    <div style={{ fontSize: 'small', marginRight: '15px', display: 'flex', alignItems: 'center' }}>DELIVERY</div>
                                    <div>

                                        <TextField variant='outlined' fullWidth size='medium'
                                            placeholder='Enter your pincode'
                                            InputProps={{

                                                endAdornment: (
                                                    <InputAdornment
                                                        position="end">
                                                        <span style={{ color: "red", cursor: 'pointer' }}>
                                                            Check
                                                        </span>
                                                    </InputAdornment>
                                                ),
                                            }} />
                                        <div>  <small><i>*usually delivered in 7 days</i></small></div>
                                    </div>
                                </div>


                                <div style={{ display: 'flex', margin: '20px 0px' }}>
                                    {localStorage.getItem("loginSuccessToken") &&
                                        <Button
                                            style={{ backgroundColor: "#32CBA0", color: "black", marginRight: '15px', padding: '25px 50px' }}
                                            variant="contained"
                                            className="pur-btn"
                                            size="medium"
                                            startIcon={<LocalMallIcon />}
                                            onClick={this.AddToCartCall}
                                        >
                                            Add to cart
                                        </Button>
                                    }

                                    {localStorage.getItem("loginSuccessToken") &&
                                        <Button style={{ background: "#D6F5EC", padding: '0px 65px' }} variant="outlined" >
                                            Buynow
                                        </Button>
                                    }

                                </div>





                            </Grid>

                        </Grid>

                    </Container>
                    </div>
                    <div style={{ background: "#F3F5F7", margin: "10px" }}>
                        {/* <DetailsReviews /> */}
                        <Container style={{ marginTop: "5px"}}>
                            <Box padding={2}>
                                <div id='buttons' className="details_reviews">
                                    <Button  className={this.state.product === false ? "btn-active" : "btn-disactive"} id='productDetails' onClick={() => this.setState({ product: false })} >Product Details</Button>
                                    <Button  className={this.state.product === true ? "btn-active" : "btn-disactive"} id='reviewRatings' onClick={() => this.setState({ product: true })}>Ratings & Reviews</Button>
                                </div>
                                <div style={{ backgroundColor: "#fff" }}>
                                    <Box padding={1} borderRadius={5}>
                                        {
                                            this.state.product === false ? (

                                                <Box padding={2}>
                                                    <Typography variant="subtitle2" style={{ color: "#B0B5C2" }}>
                                                        {this.state.productPage1Data?.attributes?.description}
                                                    </Typography>
                                                    <Box marginTop={3} mb={2}>
                                                        <Grid container className="details-box" >
                                                            <Grid item xs={4} style={{ border: "1px solid lightgrey", fontWeight: "bolder" }} >
                                                                RANGE
                                                            </Grid>
                                                            <Grid item xs={8} style={{ border: "1px solid lightgrey", color: " #98999D", fontSize: "14px" }}>
                                                                {this.state.productPage1Data?.attributes?.range}
                                                            </Grid>
                                                            <Grid item xs={4} style={{ border: "1px solid lightgrey", fontWeight: "bolder" }}>
                                                                BATTERY TYPE
                                                            </Grid>
                                                            <Grid item xs={8} style={{ border: "1px solid lightgrey", color: " #98999D", fontSize: "14px" }}>
                                                                {this.state.productPage1Data?.attributes?.type}
                                                            </Grid>
                                                            <Grid item xs={4} style={{ border: "1px solid lightgrey", fontWeight: "bolder" }}>
                                                                VOLTAGE
                                                            </Grid>
                                                            <Grid item xs={8} style={{ border: "1px solid lightgrey", color: " #98999D", fontSize: "14px" }}>
                                                                {this.state.productPage1Data?.attributes?.voltage}
                                                            </Grid>
                                                            <Grid item xs={4} style={{ border: "1px solid lightgrey", fontWeight: "bolder" }}>
                                                                CAPACITY
                                                            </Grid>
                                                            <Grid item xs={8} style={{ border: "1px solid lightgrey", color: " #98999D", fontSize: "14px" }}>
                                                                {this.state.productPage1Data?.attributes?.capaciy}
                                                            </Grid>
                                                        </Grid>

                                                    </Box>
                                                    <Box>
                                                        <Typography className="compatible" variant="subtitle2" component="h2">Compatible with</Typography>
                                                        <Typography className="comp-desc">  {this.state.productPage1Data?.attributes?.compatible_with}</Typography>
                                                    </Box>
                                                   
                                                </Box>
                                            ) : (
                                                <Box padding={3}>
                                                    <Box marginTop={2} borderBottom="1px solid #F0F0F0" paddingBottom={2} >
                                                        <Grid container
                                                            direction="row"
                                                            alignItems="center"
                                                            spacing={8}
                                                        >
                                                            <Grid item  style={{ borderRight: "2px solid #F0F0F0" }}>
                                                                <StarIcon className="star" />
                                                                <Typography style={{ fontWeight: "bold", fontSize: "22px" }}>4.1</Typography>
                                                            </Grid>
                                                            <Grid item xs={8} alignItems="center" >
                                                                <Grid container alignItems="center" spacing={3}>
                                                                    <Grid item>

                                                                        <Typography className='ratingStar'>5 &nbsp;<StarIcon className="review-star" /></Typography>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Box style={{ width: "250px", height: "6px", borderRadius: "5px", background: "#F0F0F0" }}>
                                                                            <Box style={{ width: "180px", height: "6px", borderRadius: "5px", background: "#B0AFB4" }}></Box>
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Typography>12</Typography>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container alignItems="center" spacing={3}>
                                                                    <Grid item>
                                                                        <Typography className='ratingStar'>4 &nbsp;<StarIcon className="review-star" /></Typography>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Box style={{ width: "250px", height: "6px", borderRadius: "5px", background: "#F0F0F0" }}>
                                                                            <Box style={{ width: "150px", height: "6px", borderRadius: "5px", background: "#B0AFB4" }}></Box>
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Typography>10</Typography>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container alignItems="center" spacing={3}>
                                                                    <Grid item>
                                                                        <Typography className='ratingStar'>3 &nbsp;<StarIcon className="review-star" /></Typography>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Box style={{ width: "250px", height: "6px", borderRadius: "5px",background: "#F0F0F0" }}>
                                                                            <Box style={{ width: "100px", height: "6px", borderRadius: "5px",background: "#B0AFB4" }}></Box>
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Typography>8</Typography>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container alignItems="center" spacing={3}>
                                                                    <Grid item>
                                                                        <Typography className='ratingStar' >2 &nbsp;<StarIcon className="review-star" /></Typography>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Box style={{ width: "250px", height: "6px", borderRadius: "5px",background: "#F0F0F0" }}>
                                                                            <Box style={{ width: "80px", height: "6px", borderRadius: "5px",background: "#B0AFB4" }}></Box>
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Typography>6</Typography>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container alignItems="center" spacing={3}>
                                                                    <Grid item>
                                                                        <Typography className='ratingStar'>1 &nbsp;<StarIcon className="review-star" /></Typography>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Box style={{ width: "250px", height: "6px", borderRadius: "5px", background: "#F0F0F0" }}>
                                                                            <Box style={{ width: "60px", height: "6px", borderRadius: "5px",background: "#B0AFB4" }}></Box>
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Typography>5</Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                    <Box>
                                                        {/* <ReviewCards /> */}
                                                        <div>
                                                            {
                                                                ReviewerData.map((data) => {
                                                                    return (
                                                                        <Box mt={3} paddingBottom={3} style={{ borderBottom: "1px solid #F0F0F0" }}>
                                                                            <Grid container >
                                                                                <Grid item  >
                                                                                    
                                                                                    <div style={{display:'flex',alignItems:'center'}}>
                                                                                    <StarIcon className="rating-start" /> &nbsp;
                                                                                    {data.ratingnumber}

                                                                                    </div>
                                                                                </Grid>
                                                                                <Grid item xs={8} style={{marginLeft:'20px'}}>
                                                                                    <Grid container direction="column" spacing={3}>
                                                                                        <Grid item>
                                                                                            <Typography className="review-descp">{data.description}</Typography>
                                                                                        </Grid>
                                                                                        <Grid item>
                                                                                            <img src={data.reviewerImg} alt={data.reviewerImg} className="review-img" />
                                                                                        </Grid>
                                                                                        <Grid item>
                                                                                            <Typography className="review-profile">{data.name} &nbsp; | &nbsp; {data.date}</Typography>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Box>
                                                                    )
                                                                })
                                                            }
                                                            <Typography variant="body2" style={{ textAlign: "end", color: "red", padding: "10px" }}>See more</Typography>
                                                        </div>
                                                    </Box>
                                                </Box>
                                            )
                                        }
                                    </Box>
                                </div>

                                <Box sx={{mt:2}}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', position: 'relative' }}>
              <div onClick={() => this.prev(this.scrollRef1)}
                style={{ position: 'absolute', top: '200px', left: '-4%', cursor: 'pointer', border: '1px solid ##E6E8EA', borderRadius: '50%', padding: '3px', backgroundColor: '#ECF0F3' }}><LeftIcon /></div>
              <div onClick={() => this.next(this.scrollRef1)}
                style={{ position: 'absolute', top: '200px', right: '-4%', cursor: 'pointer', border: '1px solid ##E6E8EA', borderRadius: '50%', padding: '3px', backgroundColor: '#ECF0F3' }}><RightIcon /></div>
            </div>

            {/* <Grid container spacing={2}   > */}
                <div style={{fontWeight:'bold',margin:'20px 0px 10px 0px',fontSize:'20px'}}>Similar Batteries</div>
            <div className='carouselContainer' ref={this.scrollRef1}>



              {this.batteryCards.map(() => {
                return <div style={{ marginRight: '20px' }} className='itemScroll'>
                  <Card>
                    <div style={{ display: "flex", justifyContent: 'space-between', padding: '15px' }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>

                        <img src={greenTick} width='25px' height='25px' />
                        <small style={{ textDecoration: 'underline', marginLeft: '10px', fontSize: '15px',color:"#9CA0A9" }}>36 months warranty</small>
                      </div>

                      <div style={{ display: 'flex', alignItems: 'center' }}>

                        <img src={LoveImg} width='25px' height='20px' />
                      </div>


                    </div>
                    <CardContent>
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <div style={{ padding: '15px' }}><img src={redBattery} width='100' height='100' /></div>

                        <div style={{ padding: '5px 10px', backgroundColor: '#FBC4C7', marginTop: '20px', borderRadius: '20px' }}>
                          Category:Inverter
                        </div>
                        <div style={{ fontWeight: 'bold', marginTop: '15px', marginBottom: '20px' }}>EZ2345</div>

                        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                          <span style={{ fontWeight: 'bold', fontSize: '20px' }}>Rs.8999</span>
                          <s style={{ color: '#9498A9', marginLeft: '10px', fontSize: '20px', fontWeight: 'bold' }}>Rs.15999</s>
                        </div>
                        <div style={{ display: 'flex', marginTop: '10px' }}>
                          <div style={{ border: '1px solid gray', display: 'flex', marginRight: '10px', borderRadius: '25px', paddingLeft: '5px', paddingRight: '5px' }}>
                            <span>

                              <img src={yellowStar} width='15px' height='15px' />
                            </span>


                            <span style={{ display: 'flex', alignItems: 'center' }}>4.0</span>

                          </div>
                          <span
                            style={{
                              borderRadius: '25px', paddingLeft: '10px', color: 'white',
                              display: 'flex', alignItems: 'center',
                              paddingRight: '10px', backgroundColor: '#66bc54'
                            }}>25%</span>
                        </div>
                      </div>
                    </CardContent>


                  </Card>

                </div>
              })}
            </div>
                                                    </Box>
                            </Box>
                            <VisionStatementWeb navigation={undefined} id={''} classes={undefined} openSearch={undefined}/>

                        </Container>
                    </div>
                

            </div>
        )
    }
}













var items = [
    {

        img: "https://4.imimg.com/data4/DX/BR/MY-25311479/exide-car-battery-250x250.jpg",

    },
    {

        img: "https://media.istockphoto.com/photos/car-battery-closeup-3d-rendering-isolated-on-white-background-picture-id899016436?k=20&m=899016436&s=612x612&w=0&h=xCvINEJxTswiEQ4JxjWIDA31fxpjrY8jz70LJzIl0bk=",

    },
    {
        img: "https://images-cdn.ubuy.co.in/4AP4010-everstart-maxx-lead-acid-automotive.jpg"
    },
    {
        img: "https://images-cdn.ubuy.co.in/4AP4010-everstart-maxx-lead-acid-automotive.jpg"
    }
]

const ReviewerData = [
    {
        ratingnumber: 4,
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's ",
        reviewerImg: "https://media.istockphoto.com/photos/headshot-portrait-of-smiling-male-employee-in-office-picture-id1309328823?b=1&k=20&m=1309328823&s=170667a&w=0&h=a-f8vR5TDFnkMY5poQXfQhDSnK1iImIfgVTVpFZi_KU=",
        name: "John Dao",
        date: "24 Aug 22"
    },
    {
        ratingnumber: 3,
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's ",
        reviewerImg: "https://media.istockphoto.com/photos/millennial-male-team-leader-organize-virtual-workshop-with-employees-picture-id1300972574?b=1&k=20&m=1300972574&s=170667a&w=0&h=2nBGC7tr0kWIU8zRQ3dMg-C5JLo9H2sNUuDjQ5mlYfo=",
        name: "John Dao",
        date: "24 Aug 22"
    },
    {
        ratingnumber: 2,
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's ",
        reviewerImg: "https://media.istockphoto.com/photos/headshot-portrait-of-smiling-male-employee-in-office-picture-id1309328823?b=1&k=20&m=1309328823&s=170667a&w=0&h=a-f8vR5TDFnkMY5poQXfQhDSnK1iImIfgVTVpFZi_KU=",
        name: "John Dao",
        date: "24 Aug 22"
    },
    {
        ratingnumber: 3,
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's ",
        reviewerImg: "https://media.istockphoto.com/photos/millennial-male-team-leader-organize-virtual-workshop-with-employees-picture-id1300972574?b=1&k=20&m=1300972574&s=170667a&w=0&h=2nBGC7tr0kWIU8zRQ3dMg-C5JLo9H2sNUuDjQ5mlYfo=",
        name: "John Dao",
        date: "24 Aug 22"
    },
]
// Customizable Area End



