import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import React from "react";
// Customizable Area End

export const configJSON = require("./config");
const {baseURL:apiBaseUrl}=require('./../../../framework/src/config')

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  imagesData:any [];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PhotoViewerController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  getImagesApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start

   

    this.state = {
      
      // Customizable Area Start
      imagesData:[]
      // Customizable Area End
    };
    this.subScribedMessages = [
        getName(MessageEnum.RestAPIResponceMessage),
       // Customizable Area Start
       // Customizable Area End
     ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getProductImages();
    this.getimages()
  }
  // Customizable Area Start

  imagesResponseHandle = (responseJson: any) => {
    console.log(3)
    if (responseJson && responseJson.data && responseJson.data.lenth>0 && responseJson.data[0].attributes.images.length > 0) {
      let image_data = responseJson.data[0].attributes.images.map(function (item: any) {
        return  item.url ;
      });
      if (image_data.length > 0) {
        this.setState({ imagesData: image_data})
      } else {
        this.setState({ imagesData: []})
      }
    } else {
      this.setState({ imagesData: [] })
    }
  }


  getProductImages = () => {
    console.log(1)
    const header: any = {
        "Content-Type": configJSON.validationApiContentType
      };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getImagesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.imagesAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),{headers: header}
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  getimages= () => {
    // let myHeaders = new Headers();
    // fetch(`${apiBaseUrl}${configJSON.imagesAPiEndPoint}`, {
    //     method: 'GET',
    //     headers:{
            
    //             "Accept": 'application.json',
    //             'Content-Type': 'application/json'  
    //     }
    //   })
    //     .then(response => response.json())
    //     .then(result => {
    //       console.log("getimages",result)
    //       let image_data = result.data[0].attributes.images.map(function (item: any) {
    //         return  item.url ;
    //       });
    //       this.setState({ imagesData: image_data })
    //     })
    //     .catch(error => {console.log('error', error);});

        fetch(`https://imageviewer.loca.lt/bx_block_360_imageviewer/imageviewers`)
        .then(response => response.json())
        .then(result => {
          //console.log(result)
          let image_data = result.data[0].attributes.images.map(function (item: any) {
            return  item.url ;
          });
          this.setState({ imagesData: image_data},()=>{
            console.log(this.state.imagesData)
          })
          //console.log(this.state.imagesData)
        })
        .catch(error => {console.log('error', error);});
  }
  
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    console.log(5)
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        console.log(4)
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
  
        let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (apiRequestCallId && responseJson) {
          if (apiRequestCallId === this.getImagesApiCallId) {
            console.log(2)
            this.imagesResponseHandle(responseJson)
          } 
        }
      }
  

    // Customizable Area Start
    // Customizable Area End
  }





 

 





  // Customizable Area Start
  // Customizable Area End
}
