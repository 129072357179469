import React from "react";

// Customizable Area Start
// Customizable Area End


// Customizable Area Start


import { Grid } from "@material-ui/core";


import {
  Card, CardContent, Container,
  Box,
  Input,
  Typography,
  InputAdornment,
  IconButton,Backdrop,CircularProgress
} from "@material-ui/core"
import Avatar from '@material-ui/core/Avatar';
import { NavLink } from "react-router-dom";
import VisionStatementWeb from "../../../components/src/VisionStatement.web";


// Customizable Area End

import VideosController, { Props, configJSON } from "./VideosController";
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';

export default class Testimonials extends VideosController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
      <Backdrop
          style={{ color: '#fff', zIndex: 1 }}
          open={this.state.isLoading}

        >
          <CircularProgress color="inherit" />
        </Backdrop>

       
        <div style={{ padding: '20px', backgroundColor: '#efefef' }}>
            <Container>

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                <div style={{ color: '#a1a0a4' }}>
                  <a href='/' style={{ textDecoration: 'none', color: '#a1a0a4' }}>Home</a> <ArrowForwardIosRoundedIcon className="arrow-left" /> Testimonials
                </div>
                {!localStorage.getItem("loginSuccessToken") && <div style={{ color: '#a1a0a4' }}>
                  <span>
                    <i>For Better Experience</i>
                    <span onClick={() => {
                      this.props.navigation.navigate('EmailAccountLoginBlock')
                    }} style={{ color: "red", fontWeight: 'bold', cursor: 'pointer' }}> Login/</span><span onClick={() => {
                      this.props.navigation.navigate('signUp')
                    }} style={{ color: "red", fontWeight: 'bold', cursor: 'pointer' }}>Register</span>
                  </span>
                </div>
                }
              </div>
            </Container>
          </div>

      
        <Container style={{marginTop:'30px'}}>
          <Grid container spacing={3}>
            {this.state.testimonialsData.map((item: any) => {
              return <Grid key={item?.id} id='grid' item xs={12} md={4}>
                <Card>
                  <div style={{ display: 'flex',padding:'10px' }}>
                    <Avatar
                      alt="Remy Sharp"
                      src={item?.attributes?.image}
                      style={{ width: 56, height: 56 }}
                    />
                    <div style={{padding:'10px'}}>
                      <div>{item?.attributes?.name}</div>
                      <div><i> {item?.attributes?.designation} </i> </div>
                    </div>

                  </div>
                 


                  <div style={{fontWeight:'bold',padding:'20px'}}>
                    {item?.attributes?.testimonial}

                  </div>
               

                </Card>
              </Grid>
            })}


          </Grid>
          <VisionStatementWeb navigation={undefined} id={""} classes={undefined} openSearch={undefined}/>
        </Container>


      


      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
