// Customizable Area Start
import React from 'react'
import { Card, CardContent,  Container,Grid,Backdrop,CircularProgress,Typography } from "@material-ui/core";
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';



const yellowStar = require("../assets/yellow_star.png");




import FilterInverterController, {
  Props,
  configJSON
} from "./FilterInverterController";
import { love, securedGreenTick } from './assets';
import { heart } from '../../dashboard/src/VehiclesBatteries.web';

export default class FilterInverterShowBatteries extends FilterInverterController {
  render() {
    
    let batteryType = localStorage.getItem('batteryType');
    let InverterCapacity = localStorage.getItem('InverterCapacity');
    let InputVoltage=localStorage.getItem('InputVoltage');
    let BackupTime=localStorage.getItem('BackupTime');
    let batteryCap=localStorage.getItem('batteryCap');
    return (
      <>
       <Backdrop
          style={{ color: '#fff', zIndex: 1 }}
          open={this.state.isLoading}

        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div >
          <div >


          <div style={{ padding: '20px', backgroundColor: '#efefef' }}>
            <Container>

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                <div style={{ color: '#a1a0a4' }}>
                  <a href='/' style={{ textDecoration: 'none', color: '#a1a0a4' }}>Home</a> <ArrowForwardIosRoundedIcon className="arrow-left" /> Inverter Results
                </div>
                {!localStorage.getItem("loginSuccessToken") && <div style={{ color: '#a1a0a4' }}>
                  <span>
                    <i>For Better Experience</i>
                    <span onClick={() => {
                      this.props.navigation.navigate('EmailAccountLoginBlock')
                    }} style={{ color: "red", fontWeight: 'bold', cursor: 'pointer' }}> Login/</span><span onClick={() => {
                      this.props.navigation.navigate('signUp')
                    }} style={{ color: "red", fontWeight: 'bold', cursor: 'pointer' }}>Register</span>
                  </span>
                </div>
                }
              </div>
            </Container>
          </div>


            <div style={{ backgroundColor: "#F4F5F6" }}>
              <Container>
                <Typography style={{ marginTop: "30px", fontSize: "25px", fontWeight: "bold" }}>
                  Showing battery models for
                </Typography>
                <div style={{ color: '#9BA1AA', fontSize: '15px' }}>
                  {batteryType ?? batteryType} <ArrowForwardIosRoundedIcon className="arrow-left" /> {InverterCapacity ?? InverterCapacity}VA <ArrowForwardIosRoundedIcon className="arrow-left" /> {InputVoltage ?? InputVoltage}V <ArrowForwardIosRoundedIcon className="arrow-left" /> {BackupTime ?? BackupTime} hr Backup time <ArrowForwardIosRoundedIcon className="arrow-left" /> {batteryCap ?? batteryCap}
                </div>
                <Grid container spacing={2} style={{ marginTop: '10px', marginBottom: '50px' }}>

                  {this.state.showFilterInverterData?.map((item: any, index: any) => {
                    return (
                      <Grid item xs={12} md={3} key={index} >
                        <Card  >
                          <div style={{ display: "flex", justifyContent: 'space-between', padding: '15px' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>

                              <img src={securedGreenTick} width='25px' height='25px' />
                              <small style={{ textDecoration: 'underline', marginLeft: '10px',color:"#9CA0A9" }}>{item?.attributes?.warranty}</small>
                            </div>

                            <div  onClick={()=>{
                              if (localStorage.getItem('loginSuccessToken')) {

                                if (item?.attributes?.is_wishlisted) {
                                  localStorage.setItem('item_id', item?.id);
                                  this.deleteWishlist()

                                }
                                else {

                                  localStorage.setItem('item_id', item?.id);
                                  this.createWishList()
                                }
                              }
                              else {
                                localStorage.setItem('wishList',JSON.stringify(true))
                                this.props.navigation.navigate('LoginRequired')
                              }

                          }} style={{ display: 'flex', alignItems: 'center',cursor:'pointer' }}>

                          <img src={item?.attributes?.is_wishlisted?heart:love} width='25px' height='20px' />
                        </div>


                          </div>
                          <CardContent>
                            <div
                            onClick={() => {
                              localStorage.setItem("applyShowBatteriesId", item?.id)
                            this.props.navigation.navigate('ProductDescription1',item?.id)
                            }
                            }
                            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center',cursor:'pointer' }}>
                              <div style={{ padding: '15px' }}><img src={item?.attributes?.thumbnail_image} width='100' height='100' /></div>



                              <div style={{ fontWeight: 'bold', marginTop: '15px' }}>{item?.attributes?.model_no}</div>
                              <div style={{ fontWeight: 'bold', marginTop: '15px', marginBottom: '20px' }}><span style={{ color: '#B1B5C3' }}>Capacity</span> {item?.attributes?.capaciy}</div>

                              <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                <span style={{ fontWeight: 'bold', fontSize: '20px' }}>&#x20b9;{this.finalPrice(item)}</span>
                                <s style={{ color: '#9498A9', marginLeft: '10px', fontSize: '20px', fontWeight: 'bold' }}>&#x20b9;{item?.attributes?.amount}</s>
                              </div>
                              <div style={{ display: 'flex', marginTop: '10px' }}>
                                <div style={{ border: '1px solid gray', display: 'flex', marginRight: '10px', borderRadius: '25px', paddingLeft: '5px', paddingRight: '5px' }}>
                                  <span>

                                    <img src={yellowStar} width='15px' height='15px' />
                                  </span>


                                  <span style={{ display: 'flex', alignItems: 'center' }}>{item?.attributes?.range}</span>

                                </div>
                                <span
                                  style={{
                                    borderRadius: '25px', paddingLeft: '10px', color: 'white',
                                    display: 'flex', alignItems: 'center',
                                    paddingRight: '10px', backgroundColor: '#66bc54'
                                  }}> {Math.round(item?.attributes?.applied_discount)}% off</span>
                              </div>
                            </div>
                          </CardContent>


                        </Card>




                      </Grid>


                    )

                  })}




                </Grid>
              </Container>







            </div>-
          </div>



        </div>
      </>
    )
  }
}
// Customizable Area End