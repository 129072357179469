import React from 'react'
// Customizable Area Start

import { Grid, RadioGroup, FormControlLabel, Radio,withStyles } from "@material-ui/core"
import { Input, InputAdornment } from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';




import FilteroptionsController, {
    Props,
} from "./FilteroptionsController";


const CssRadio = withStyles({
    colorSecondary: {
        color: '#757575',
        '&$checked': {
            color: '#43CBA0',
          },
    },
    checked: {}
  })(Radio)
export default class FileterVehicleModel extends FilteroptionsController {

    render() {
        return (
            <div>
                <div style={{ backgroundColor: "#fff" }}>
                    
                   
                    <div style={{ marginRight: "118px", marginLeft: "118px" }}>
                        <div>
                            <Grid container >
                                <Input
                                    id="input-with-icon-adornment"
                                    style={{ width: "675px", height: "41px", border: "1px solid gray", outline: "0px", }}
                                    startAdornment={
                                        <InputAdornment position="start" >
                                            <SearchIcon />
                                        </InputAdornment>
                                    }
                                    onChange={this.handleSearchChange}
                                    value={this.state.searchVehicleModel}
                                    placeholder="search"
                                />

                            </Grid>
                        </div>
                        <Grid item style={{ marginTop: "30px", overflowY: "scroll", maxHeight: "227px" }}>

                            {console.log("maodel........", this.state.getVehicleModelData)
                            }
                            {this.state.getVehicleModelData?.map((item: any, index: any) => {

                                return (
                                    <div key={index}
                                        style={{
                                            padding: "7px",
                                            border: "1px solid lightgrey",
                                            marginBottom: "10px"
                                        }}
                                    >
                                        <Grid container style={{ display: "flex" }}>
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label1"
                                                name="radio-buttons-group1">
                                                <FormControlLabel
                                                    checked={this.state.selectedValue == item?.attributes?.vehicle_model}
                                                    value={item?.attributes?.vehicle_model}
                                                    onChange={(e: any) => {
                                                        this.setState({ selectedValue: e.target.value });
                                                        localStorage.setItem("ModelId", item?.id);
                                                        localStorage.setItem('modelName', item?.attributes?.vehicle_model)

                                                    }
                                                    }
                                                    control={<CssRadio />} label={item?.attributes?.vehicle_model} />

                                            </RadioGroup>
                                            
                                        </Grid>
                                    </div>
                                )
                            })}

                        </Grid>
                    </div>

                   
                </div>
                </div>
        )
    }
}

// Customizable Area End                           





