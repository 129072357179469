// Customizable Area Start
import React, { Component } from 'react'

import {
    Box,
    Container,
    Modal,
    Button,
    ListItem,
    IconButton,
    ListItemIcon,
    ListItemText,
    Divider,
    Collapse,
    List,
    Grid,
    Card,
    CardContent,
    CardActions,
    Typography,
    Switch, Radio, Checkbox, Avatar, Paper

} from "@material-ui/core";
import Rating from '@material-ui/lab/Rating'
import Person from '@material-ui/icons/Person';
import Place from '@material-ui/icons/Place';
import DateRange from '@material-ui/icons/DateRange';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TextField from '@material-ui/core/TextField';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import MerchantDashboard2Controller from './MerchantDashboard2Controller';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';


const ezBattery = require(".././assets/red_battery.png");
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    mt: 5,
    mb: 5,

    boxShadow: 24,
    p: 4,
};

export default class SelectProductToExchange extends MerchantDashboard2Controller {
    arr = ['Product isnt functioning properly', 'Got a faulty product', 'Other Reason'];
    arr1 = [{ label: 'Exchange product with any other model', info: '*Extra charges may apply' }, { label: 'Exchange product with the same model', info: '*No Extra charges' }]

    category = [0, 1, 2, 3, 4, 5]
    manufacturer = [0, 1, 2, 3, 4, 5];
    products = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]
    handleCloseModal = () => {
        this.props.navigation.navigate('Orders')

    }
    steps = ['Return Reason', 'Return Method', 'Confirm Return'];



    render() {
        return (
            <>
                <div style={{ display: 'flex', justifyContent: 'flex-start', padding: '20px', backgroundColor: '#efefef' }}>
                    <div style={{ color: '#a1a0a4', marginLeft: '40px' }}>
                        <a href='/' style={{ textDecoration: 'none', color: '#a1a0a4' }}>Home</a> <ArrowForwardIosRoundedIcon className="arrow-left" /> Orders <ArrowForwardIosRoundedIcon className="arrow-left" /> EZTZ4 <ArrowForwardIosRoundedIcon className="arrow-left" /> Exchange
                    </div>

                </div>

                <Container style={{ marginTop: '20px' }}>
                   
                    <Grid container>
                        <Grid item xs={12} md={3}>

                            <Box sx={{
                                marginRight: '25px',
                                bgcolor: 'background.paper'
                            }}>
                                <nav aria-label="main mailbox folders">
                                    <List>

                                        <ListItem  >
                                            <ListItemText primary={'Pending'} />

                                        </ListItem>
                                        <Divider />
                                        <ListItem  >
                                            <ListItemText primary={'Completed'} />

                                        </ListItem>
                                    </List>
                                </nav>



                            </Box>


                            <Box sx={{
                                bgcolor: 'background.paper',
                                marginRight: '25px',
                                marginTop: '20px'
                            }}>
                                <div style={{ fontWeight: 'bold', padding: '10px' }}>Filter</div>
                                <div style={{ backgroundColor: '#f1f1f1', margin: '10px' }}>
                                    <div style={{ padding: '10px' }}>Battery Type</div>

                                    <div style={{ display: 'flex' }}>
                                        <Radio
                                            color='default'
                                            name="radio-buttons"
                                            inputProps={{ 'aria-label': 'A' }}
                                        />
                                        <span style={{ display: 'flex', alignItems: 'center' }}>From A to Z</span>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <Radio
                                            color='default'
                                            name="radio-buttons"
                                            inputProps={{ 'aria-label': 'A' }}
                                        />
                                        <span style={{ display: 'flex', alignItems: 'center' }}>From A to Z</span>
                                    </div>
                                </div>

                                {/* category */}
                                <div style={{ backgroundColor: '#f1f1f1', margin: '10px' }}>
                                    <div style={{ padding: '10px' }}>Category</div>
                                    {this.category.map(() => {
                                        return <div style={{ display: 'flex' }}>
                                            <Checkbox
                                                color='default'
                                                name="radio-buttons"
                                                inputProps={{ 'aria-label': 'A' }}
                                            />
                                            <span style={{ display: 'flex', alignItems: 'center' }}>2 Wheeler</span>
                                        </div>
                                    })}
                                </div>

                                {/* manufacturer */}
                                <div style={{ backgroundColor: '#f1f1f1', margin: '10px' }}>
                                    <div style={{ padding: '10px' }}>Manufacturer</div>
                                    {this.manufacturer.map(() => {
                                        return <div style={{ display: 'flex' }}>
                                            <Checkbox
                                                color='default'
                                                name="radio-buttons"
                                                inputProps={{ 'aria-label': 'A' }}
                                            />
                                            <span style={{ display: 'flex', alignItems: 'center' }}>Honda</span>
                                        </div>
                                    })}
                                </div>

                                {/* model */}
                                <div style={{ backgroundColor: '#f1f1f1', margin: '10px' }}>
                                    <div style={{ padding: '10px' }}>Model</div>
                                    {this.manufacturer.map(() => {
                                        return <div style={{ display: 'flex' }}>
                                            <Checkbox
                                                color='default'
                                                name="radio-buttons"
                                                inputProps={{ 'aria-label': 'A' }}
                                            />
                                            <span style={{ display: 'flex', alignItems: 'center' }}>CB Twister</span>
                                        </div>
                                    })}
                                </div>

                                {/* Fuel type */}
                                <div style={{ backgroundColor: '#f1f1f1', margin: '10px' }}>
                                    <div style={{ padding: '10px' }}>Fuel Type</div>
                                    {this.manufacturer.map(() => {
                                        return <div style={{ display: 'flex' }}>
                                            <Checkbox
                                                color='default'
                                                name="radio-buttons"
                                                inputProps={{ 'aria-label': 'A' }}
                                            />
                                            <span style={{ display: 'flex', alignItems: 'center' }}>Petrol</span>
                                        </div>
                                    })}
                                </div>

                            </Box>




                        </Grid>

                        <Grid item xs={12} md={8}>
                            <Box sx={{
                                bgcolor: 'background.paper',
                                padding: '30px'
                            }}>

                                <Grid spacing={3} container>
                                    <Grid item xs={12} md={6}>
                                        <div style={{ display: "flex", justifyContent: 'space-between' }}>
                                            <span>Total Pending</span>
                                            <span style={{ fontWeight: 'bold', fontSize: '25px' }}>28</span>
                                        </div>

                                    </Grid>
                                    <Grid item xs={12} md={6}>


                                    </Grid>
                                    {this.arr.map((item) => {
                                        return <Grid item xs={12} md={6}>
                                            <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0px' }}>
                                                <span><DateRange /></span>
                                                <div>10 Apr 22 - 4:30PM</div>
                                            </div>
                                            <Paper elevation={2}>

                                                <Box
                                                    sx={{ bgcolor: 'background.paper', borderRadius: '20px', padding: '5px' }}
                                                >
                                                    <div style={{ display: 'flex', justifyContent: 'center' }}>

                                                        <div style={{ borderRight: '1px solid black', borderBottom: '1px solid black', padding: '5px' }}>
                                                            <div style={{ marginRight: '30px', marginTop: '10px' }}>

                                                                <small>Type of service call</small>
                                                                <div><small style={{ fontWeight: 'bold' }}>Car Battery Problem</small></div>
                                                                <div style={{ border: '1px solid black', borderRadius: '25px', padding: '0px 5px', width: 'max-content', margin: '5px 0px' }}><span style={{ fontSize: 'small' }}>Other Battery</span></div>
                                                            </div>

                                                        </div>

                                                        <div style={{ borderBottom: '1px solid black', padding: '5px' }}>
                                                            <div style={{ marginLeft: '15px', marginTop: '10px' }}>

                                                                <small>Assigned Technician</small>
                                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                    <Avatar style={{ width: '25px', height: '25px' }} alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                                                                    <div style={{ display: 'flex', flexDirection: 'column', padding: '10px' }}>
                                                                        <small>John Doe</small>
                                                                        <small>+91 788394994</small>
                                                                    </div>

                                                                </div>

                                                            </div>


                                                        </div>

                                                    </div>
                                                    <div>
                                                        <div style={{ fontSize: 'small', padding: '5px' }}>Customer Details</div>
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <div style={{ fontSize: 'small' }}><Person /></div>
                                                            <div style={{ fontSize: 'small', paddingLeft: '5px' }}>John Doe, +91788448848</div>

                                                        </div>
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <div><Place fontSize='small' /></div>
                                                            <div style={{ fontSize: 'small', paddingLeft: '5px' }}>Bezel Pharma, Opp Plot 99,Govt Indl Estate,Mumbai,7233</div>

                                                        </div>

                                                    </div>

                                                   

                                                </Box>
                                            </Paper>
                                        </Grid>
                                    })}
                                </Grid>
                            </Box>







                        </Grid>



                    </Grid>

                </Container>

                {/* <div>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={this.state.toggleCancelOrder}
                        onClose={() => this.handleCloseModal()}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={this.state.toggleCancelOrder}>
                            <Box sx={style}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ fontWeight: 'bold', fontSize: '20px' }}>Cancel Order?</div>
                                    <span><CloseIcon /></span>
                                </div>

                                <div style={{ marginTop: '20px', color: '#9da4b3' }}>
                                    Do you wish to cancel your order?Note: Refunds may take 5-7 business days to reflect on your account
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                                    <Button variant='outlined' onClick={() => this.handleCloseModal()} style={{ padding: '15px 45px' }}>No</Button>
                                    <Button variant='contained' style={{ padding: '15px 45px', backgroundColor: '#dd403a', marginLeft: '10px', color: 'white' }} >Yes</Button>
                                </div>








                            </Box>
                        </Fade>
                    </Modal>
                </div> */}

            </>
        )
    }
}
// Customizable Area End