// Customizable Area Start
import React, { Component } from 'react'
import OrdermanagementController from './OrdermanagementController';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import {
    Box,
    Container,
    Modal,
    Button,
    ListItem,
    IconButton,
    ListItemIcon,
    ListItemText,
    Divider,
    Collapse,
    List,
    Grid,
    Card,
    CardContent,
    CardActions,
    Typography,
    Switch, Radio

} from "@material-ui/core";
import Rating from '@material-ui/lab/Rating'
import Info from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import TextField from '@material-ui/core/TextField';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import VisionStatementWeb from '../../../components/src/VisionStatement.web';


const ezBattery = require(".././assets/red_battery.png");
const tick = require('.././assets/tick.png');
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    mt: 5,
    mb: 5,

    boxShadow: 24,
    p: 8,
};

export default class RateProduct extends OrdermanagementController {
    handleCloseModal = () => {
        this.setState({ toggleRating: false })
    }
    render() {
        return (
            <>
                <div style={{ display: 'flex', justifyContent: 'flex-start', padding: '20px', backgroundColor: '#efefef' }}>
                    <Container>

                    <div style={{ color: '#a1a0a4' }}>
                        <a href='/' style={{ textDecoration: 'none', color: '#a1a0a4' }}>Home</a> <ArrowForwardIosRoundedIcon className="arrow-left" /> Orders <ArrowForwardIosRoundedIcon className="arrow-left" /> Rate Product
                    </div>
                    </Container>

                </div>

                <Container style={{ marginTop: '20px' }}>
                    <Grid container>
                        <Grid item xs={12} md={4}>

                            <Box sx={{
                                bgcolor: 'background.paper',
                                marginRight: '25px'
                            }}>
                                <div style={{ display: 'flex', flexDirection: 'column', padding: '20px', alignItems: 'center' }}>

                                    <img src={ezBattery} width='250' height='250' />
                                    <div style={{ padding: '20px', fontWeight: 'bold', fontSize: '25px' }}>EZTZ4</div>
                                    <div style={{ padding: '20px' }}>

                                        <Rating
                                            style={{ fontSize: '2.875rem' }}
                                            name="simple-controlled"
                                            value={this.state.ratingValue}
                                            onChange={(event, newValue) => {
                                                this.setState({ ratingValue: newValue })
                                            }}
                                        />
                                    </div>

                                    <Button disabled style={{ padding: '10px 30px 10px 30px', backgroundColor: '#32cba0' }}>
                                        Upload Review
                                    </Button>
                                </div>

                            </Box>



                        </Grid>

                        <Grid item xs={12} md={8}>
                            <Box sx={{
                                bgcolor: 'background.paper',
                                padding: '30px'
                            }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={8} sm={12}>
                                        <TextField
                                            autoComplete="given-name"
                                            name="firstName"

                                            fullWidth
                                            id="firstName"
                                            label="First Name"
                                            autoFocus
                                            variant='outlined'
                                        />
                                    </Grid>
                                    <Grid item xs={8} sm={6}>
                                        <TextField
                                            autoComplete="given-name"
                                            name="firstName"

                                            fullWidth
                                            id="firstName"
                                            label="First Name"
                                            autoFocus
                                            variant='outlined'
                                        />
                                    </Grid>
                                    <Grid item xs={8} sm={6}>
                                        <TextField

                                            fullWidth
                                            id="lastName"
                                            label="Mobile Number"
                                            name="Mobile Number"
                                            autoComplete="family-name"
                                            variant='outlined'
                                        />
                                    </Grid>
                                    <Grid item xs={8} sm={6}>
                                        <TextField
                                            autoComplete="given-name"
                                            name="firstName"

                                            fullWidth
                                            id="firstName"
                                            label="First Name"
                                            autoFocus
                                            variant='outlined'
                                        />
                                    </Grid>
                                    <Grid item xs={8} sm={6}>
                                        <TextField
                                            autoComplete="given-name"
                                            name="firstName"

                                            fullWidth
                                            id="firstName"
                                            label="First Name"
                                            autoFocus
                                            variant='outlined'
                                        />
                                    </Grid>
                                    <Grid item xs={8} sm={6}>
                                        <TextField
                                            autoComplete="given-name"
                                            name="firstName"

                                            fullWidth
                                            id="firstName"
                                            label="First Name"
                                            autoFocus
                                            variant='outlined'
                                        />
                                    </Grid>
                                    <Grid item xs={8} sm={6}>
                                        <TextField
                                            autoComplete="given-name"
                                            name="firstName"

                                            fullWidth
                                            id="firstName"
                                            label="First Name"
                                            autoFocus
                                            variant='outlined'
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField

                                            fullWidth

                                            id="email"
                                            label="Type your message here"
                                            name="email"
                                            autoComplete="email"
                                            variant='outlined'
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField

                                            fullWidth
                                            multiline
                                            rows={4}

                                            id="email"
                                            label="Type your message here"
                                            name="email"
                                            autoComplete="email"
                                            variant='outlined'
                                        />
                                    </Grid>

                                </Grid>
                                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>

                                    <Button onClick={() => this.setState({ toggleRating: true })} style={{ backgroundColor: '#32cba0', padding: '10px 30px 10px 30px' }}>Upload Review</Button>
                                </div>
                            </Box>



                        </Grid>
                    </Grid>
                            <VisionStatementWeb classes={undefined} id={''} navigation={this.props.navigation} openSearch={undefined}/>
                </Container>

                <div>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={this.state.toggleRating}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={this.state.toggleRating}>
                            <Box sx={style}>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <img src={tick} width={80} />
                                </div>
                                <div style={{ fontWeight: 'bold', display: 'flex', justifyContent: 'center', margin: '30px 0px 20px 0px', fontSize: '25px' }}>
                                    Successfully Rated
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'center',color:'#b5bac6' }}>

                                    Thanks for sharing your experience with the product and helping other customers

                                </div>

                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                                    <div
                                    onClick={()=>this.handleCloseModal()}
                                        style={{
                                            width: '100%', padding: '20px 0px 20px 0px',
                                            border: '2px solid #e3e3e4', display: 'flex',
                                            justifyContent: 'center', marginTop: '15px'
                                        }}>Ok</div>
                                </div>








                            </Box>
                        </Fade>
                    </Modal>
                </div>

            </>
        )
    }
}
// Customizable Area End