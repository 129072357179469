// Customizable Area Start
import React from 'react'



import Modal from '@material-ui/core/Modal';

import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';

import MenuItem from '@material-ui/core/MenuItem';
import Error from "@material-ui/icons/Error";
import { Box, Container, Grid, TextField, Button, Select } from "@material-ui/core"
import Typography from '@material-ui/core/Typography';



const feedbackSuccessTick = require("../assets/Combined Shape.png")



const GenderTypes = [
    {
        value: 'Male',
        label: 'Male',
    },
    {
        value: 'Female',
        label: 'Female',
    },
    {
        value: 'Trans-gender',
        label: 'Trans-gender',
    },

];


import CategoriessubcategoriesController from "./CategoriessubcategoriesController";
import VisionStatement from '../../../components/src/VisionStatement.web';

export default class Feedback extends CategoriessubcategoriesController {
    handleWords=(e:any)=>{
        this.setState({maxWords:200-e.target.value.length})
    
       
      }
      checkValid=()=>{
       
        if(this.state.gender==''){
           
            this.setState({errorMsg:{...this.state.errorMsg,isGenderReq:true}})
            return false
            
        }
       else if(this.state.age==''){
        console.log(this.state.errorMsg);
            this.setState({errorMsg:{...this.state.errorMsg,isAgeReq:true}})
            return false
            
        }
       else if(this.state.cityName==''){
            this.setState({errorMsg:{...this.state.errorMsg,isCityReq:true}})
            return false
            

        }
       else if(this.state.stateName==''){
            this.setState({errorMsg:{...this.state.errorMsg,isStateReq:true}})
            return false
            

        }
       else if(this.state.yourFeedback==''){
            this.setState({errorMsg:{...this.state.errorMsg,isMsg:true}})
            return false
            

        }
       else if(this.state.yourSubject==''){
            this.setState({errorMsg:{...this.state.errorMsg,isSub:true}})
            return false
            

        }
       else return true
       

      }
    render() {
        return (
            <>

                <div>
                    
                <div style={{ padding: '20px', backgroundColor: '#efefef' }}>
            <Container>

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                <div style={{ color: '#a1a0a4' }}>
                  <a href='/' style={{ textDecoration: 'none', color: '#a1a0a4' }}>Home</a> <ArrowForwardIosRoundedIcon className="arrow-left" /> Feedback
                </div>
                {!localStorage.getItem("loginSuccessToken") && <div style={{ color: '#a1a0a4' }}>
                  <span>
                    <i>For Better Experience</i>
                    <span onClick={() => {
                      this.props.navigation.navigate('EmailAccountLoginBlock')
                    }} style={{ color: "red", fontWeight: 'bold', cursor: 'pointer' }}> Login/</span><span onClick={() => {
                      this.props.navigation.navigate('signUp')
                    }} style={{ color: "red", fontWeight: 'bold', cursor: 'pointer' }}>Register</span>
                  </span>
                </div>
                }
              </div>
            </Container>
          </div>

                    
                     <Container style={{ maxWidth: '835px', marginTop: '50px', marginBottom: '25px' }}>

                        <Box sx={{

                            bgcolor: 'white',
                            padding: '30px'
                        }}>
                            <Typography ><b>Give us feedback</b></Typography>
                            <p style={{ color: "#BEC0C7", marginBottom: '30px' }}>Welcome to the world of EZ Battery. We endeavor to make your life easy while you are busy <br />
                                winning the bread for your family or supporting your family members from home. In this direction, <br />
                                we would like to hear from you about how we can make the current website more responsive and <br />
                                easy to use, all in the direction to serve you better. Do not hesitate to share with us our mistakes <br />
                                as there is always a scope for improvements.
                            </p>
                            <form>

                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        variant='outlined'
                                        id="outlined-full-width"
                                        type="text"
                                        disabled
                                        fullWidth
                                        value={this.state.getProfileData?.first_name}
                                        placeholder="Full Name"
                                        
                                        />

                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        variant='outlined'
                                        id="outlined-full-width"
                                        fullWidth
                                        type="text"
                                        disabled
                                        placeholder='Email id'
                                        value={this.state.getProfileData?.email}



                                        onChange={(e: any) => {
                                            this.setState({ email: e.target.value })
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    
                                    <Select 
                                     style={{
                                        border:this.state?.errorMsg?.isGenderReq ? '1px solid red' : '0px solid',
                                        backgroundColor:this.state?.errorMsg?.isGenderReq? "#FDEFEF" : '',
                                      }}
                                    fullWidth  variant='outlined' displayEmpty    value={this.state.gender}
                                        onChange={(e: any) => {
                                            this.setState({ gender: e.target.value,errorMsg:{...this.state.errorMsg,isGenderReq:false} })

                                        }}
                                       >
                                                    <MenuItem value="">
                                                    Gender
                                                     </MenuItem>
                                                    {GenderTypes.map((option: any) => (
                                                <MenuItem key={option.value} value={option.label}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}

                                     </Select>
                                     {this.state?.errorMsg?.isGenderReq&&<div style={{display:'flex',marginTop:'2px'}}>
                                            <div style={{ marginRight: "5px" }}>
                                         <Error color="error" fontSize="small" />
                                            </div>
                                     <small style={{color:'red',display:'flex',alignItems:'center'}}>This is required</small>
                                     </div>}

                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        id="outlined-full-width"
                                        type="text"
                                        placeholder='Age'
                                        value={this.state.age}
                                        variant='outlined'
                                        required
                                        style={{
                                            border:this.state?.errorMsg?.isAgeReq ? '1px solid red' : '0px solid',
                                            backgroundColor:this.state?.errorMsg?.isAgeReq? "#FDEFEF" : '',
                                          }}
                                        onChange={(e: any) => {
                                            this.setState({ age: e.target.value,errorMsg:{...this.state.errorMsg,isAgeReq:false} })
                                        }}
                                        />
                                         {this.state?.errorMsg?.isAgeReq&&<div style={{display:'flex',marginTop:'2px'}}>
                                            <div style={{ marginRight: "5px" }}>
                                         <Error color="error" fontSize="small" />
                                            </div>
                                     <small style={{color:'red',display:'flex',alignItems:'center'}}>This is required</small>
                                     </div>}
                                </Grid>

                                <Grid item xs={12} md={6}>

                                    
                                    <Select fullWidth 
                                     style={{
                                        border:this.state?.errorMsg?.isCityReq ? '1px solid red' : '0px solid',
                                        backgroundColor:this.state?.errorMsg?.isCityReq? "#FDEFEF" : '',
                                      }}
                                    variant='outlined' displayEmpty    value={this.state.cityName}
                                        onChange={(e: any) => {
                                            this.setState({ cityName: e.target.value,errorMsg:{...this.state.errorMsg,isCityReq:false} })
                                        }}
                                       >
                                                    <MenuItem value="">
                                                    City
                                                     </MenuItem>
                                                     {this.state.cityDropdownData?.map((option: any) => (
                                            <MenuItem key={option?.id} value={option?.city}>
                                                {option?.city}
                                            </MenuItem>
                                        ))}

                                     </Select>
                                     {this.state?.errorMsg?.isCityReq&&<div style={{display:'flex',marginTop:'2px'}}>
                                            <div style={{ marginRight: "5px" }}>
                                         <Error color="error" fontSize="small" />
                                            </div>
                                     <small style={{color:'red',display:'flex',alignItems:'center'}}>This is required</small>
                                     </div>}

                                </Grid>



                                <Grid item xs={12} md={6}>
                                    
                                    <Select
                                     style={{
                                        border:this.state?.errorMsg?.isStateReq ? '1px solid red' : '0px solid',
                                        backgroundColor:this.state?.errorMsg?.isStateReq? "#FDEFEF" : '',
                                      }}
                                    fullWidth  variant='outlined' displayEmpty    value={this.state.stateName}
                                         onChange={(e: any) => {
                                            this.setState({ stateName: e.target.value,errorMsg:{...this.state.errorMsg,isStateReq:false} })
                                        }}
                                       >
                                                    <MenuItem value="">
                                                    State
                                                     </MenuItem>
                                                     {this.state.stateDropdownData.map((option: any) => (
                                            <MenuItem key={option?.id} value={option?.state}>
                                                {option?.state}
                                            </MenuItem>
                                        ))}

                                     </Select>
                                     {this.state?.errorMsg?.isStateReq&&<div style={{display:'flex',marginTop:'2px'}}>
                                            <div style={{ marginRight: "5px" }}>
                                         <Error color="error" fontSize="small" />
                                            </div>
                                     <small style={{color:'red',display:'flex',alignItems:'center'}}>This is required</small>
                                     </div>}



                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        variant='outlined'
                                        fullWidth
                                        id="outlined-full-width"
                                        type="text"
                                        disabled
                                        placeholder='Country'
                                        value={this.state.countryName}
                                        
                                        
                                        

                                    />
                                </Grid>

                                <Grid item xs={12} md={12}>
                                    <TextField
                                        variant='outlined'
                                        fullWidth
                                        id="outlined-full-width"
                                        type="text"
                                        value={this.state.yourSubject}
                                        placeholder="Your Subject"
                                        style={{
                                            border:this.state?.errorMsg?.isSub ? '1px solid red' : '0px solid',
                                            backgroundColor:this.state?.errorMsg?.isSub? "#FDEFEF" : '',
                                          }}
                                        onChange={(e: any) => {
                                            this.setState({ yourSubject: e.target.value,errorMsg:{...this.state.errorMsg,isSub:false} })
                                        }}
                                        />
                                    
                                     {this.state?.errorMsg?.isSub&&<div style={{display:'flex',marginTop:'2px'}}>
                                            <div style={{ marginRight: "5px" }}>
                                         <Error color="error" fontSize="small" />
                                            </div>
                                     <small style={{color:'red',display:'flex',alignItems:'center'}}>Please write your subject</small>
                                     </div>}
                                </Grid>

                                <Grid xs={12} md={12}>
                                    <TextField
                                        variant='outlined'
                                        multiline
                                        fullWidth
                                        id="outlined-full-width"
                                        aria-label="minimum height"
                                        minRows={5}
                                        placeholder='Your feedback'
                                        value={this.state.yourFeedback}
                                        style={{
                                            border:this.state?.errorMsg?.isMsg ? '1px solid red' : '0px solid',
                                            backgroundColor:this.state?.errorMsg?.isMsg? "#FDEFEF" : '',
                                          }}
                                        onChange={(e: any) => {
                                            this.setState({ yourFeedback: e.target.value,errorMsg:{...this.state.errorMsg,isMsg:false} })
                                            this.handleWords(e)

                                        }}
                                    />
                                    
                                     {this.state?.errorMsg?.isMsg&&<div style={{display:'flex',marginTop:'2px'}}>
                                            <div style={{ marginRight: "5px" }}>
                                         <Error color="error" fontSize="small" />
                                            </div>
                                     <small style={{color:'red',display:'flex',alignItems:'center'}}>Please give us your feedback</small>
                                     </div>}
                                </Grid>

                            </Grid>
                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                                <i>

                                    {this.state.maxWords} words remaining
                                </i>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '40px 0px 20px 0px' }}>
                                <Button style={{ backgroundColor: '#32cba1', padding: '10px 50px', fontWeight: 'bold' }}
                                   
                                    onClick={
                                        () => {
                                            if(this.checkValid()){

                                                this.createFeedbackCall()
                                                this.setState({ giveSubject: true, giveFeedback: true })
                                            }
                                        }}>Send</Button>
                            </div>

                                        </form>

                        </Box>
                    </Container>

                    <Container>

                          <VisionStatement navigation={this.props.navigation} id={''} classes={undefined} openSearch={undefined}  />
                    </Container>

                </div>




                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.feedbackSuccess}
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}

               
                >
                    <div style={{
                        position: "fixed",
                        top: "35%",
                        right: "40%",
                        left: "40%",
                        backgroundColor: "#fff",
                        zIndex: 2000,
                        padding: "35px",
                        borderRadius: "15px"
                    }}>

                        <img src={feedbackSuccessTick} style={{
                            color: "#29bf04", marginLeft: "40%", fontSize: "50px",
                            width: "40px", height: "40px"
                        }} />

                        <Typography style={{ textAlign: "center" }}><b>Feedback shared</b> <br />
                            Thanks for sharing your feedback, it really <br />
                            means a lot to us and we'll work on it
                        </Typography>

                        <Box style={{
                            marginLeft: "37px", marginTop: "20px", width: "70%", height: "48px", backgroundColor: "#B0B0B0",
                            border: "1px solid black", textAlign: "center", cursor: "pointer"
                        }}
                            onClick={() => this.setState({ feedbackSuccess: false })}
                        >
                            <p style={{ padding: "0px" }}>Ok</p>
                        </Box>


                    </div>
                </Modal>

            </>
        )
    }
}
// Customizable Area End