import React, { Component } from 'react'

// Customizable Area Start

import { Box, Grid, Container, Button, Modal, Backdrop, Fade,CircularProgress } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';




const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

import UserProfileBasicController, {
    Props
} from "./UserProfileBasicController";
import VisionStatement from '../../../components/src/VisionStatement.web';

export default class UserAddressEditDelete extends UserProfileBasicController {
    handleClose = () => {
        this.setState({ openDeleteModal: false })
    }

    adrList = [{
        attributes: {
            name: 'John',
            address: 'Galaxy Building,near Abc market'
        }
    }, {
        attributes: {
            name: 'salman',
            address: 'Galaxy Building,near Abc market'
        }
    }]
    render() {
        if (!localStorage.getItem("loginSuccessToken")) {
            this.props.navigation.navigate('signUp');
            return null

        }
        return (
            <div >
                <Backdrop
          style={{ color: '#fff', zIndex: 1 }}
          open={this.state.isLoading}

        > <CircularProgress color="inherit" /></Backdrop>
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px', backgroundColor: '#efefef' }}>
                    <Container>

                    <div style={{ color: '#a1a0a4' }}>
                        <a href='/' style={{ textDecoration: 'none', color: '#a1a0a4' }}>Home</a> <ArrowForwardIosRoundedIcon className="arrow-left" /> Profile
                    </div>
                    </Container>

                </div>

                <div>

                    <Container style={{ marginTop: '20px' }}>
                        <Grid container spacing={3}>
                            <Grid item md={3}>
                                <div onClick={() => this.props.navigation.navigate('UserProfileBasicBlock')} style={{
                                    height: "55px", border: "1px solid #F4F5F6", borderBottom: "none", borderTopRightRadius: "3px",
                                    borderTopLeftRadius: "3px", backgroundColor: "#fff"
                                }}>
                                    <p style={{ margin: "18px", fontSize: "16px", fontWeight: "bold", cursor: 'pointer' }}>Profile Details</p>
                                </div>

                                <div style={{
                                    height: "55px", border: "1px solid #F4F5F6", borderBottomRightRadius: "3px", cursor: "pointer",
                                    borderBottomLeftRadius: "3px", backgroundColor: "#fff"
                                }}

                                >
                                    <p style={{ margin: "18px", fontSize: "16px", fontWeight: "bold" }}>Saved Addresses</p>
                                </div>

                            </Grid>
                            <Grid item md={8}>
                                <Box sx={{
                                    bgcolor: 'background.paper',
                                    padding: '30px'
                                }}>
                                    {this.state?.addressList?.map((address: any,index:any) => {
                                        return <div style={{ border: '2px solid #EEEEEE', padding: '15px', margin: '15px 0px' }}>
                                            <div style={{fontWeight:'bold',marginBottom:'10px'}}>{address?.attributes?.name}</div>
                                            <div style={{color:'#A8AEBD'}}>{address?.attributes?.address}</div>
                                            <div style={{ display: 'flex', color: '#E5726D', margin: '15px 0px 10px 0px',fontWeight:'bold' }}>
                                                <div onClick={() => {

                                                    localStorage.setItem('singleAddress', JSON.stringify(address))
                                                    this.props.navigation.navigate('UserProfileEditAddress')
                                                }} style={{ marginRight: '10px', cursor: 'pointer' }}>edit</div>
                                               {!address?.attributes?.is_default&& <div onClick={() => { this.setState({ singleAddress: address }); this.setState({ openDeleteModal: true }) }} style={{ cursor: 'pointer' }}>delete</div>}
                                            </div>

                                        </div>
                                    })}


                                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                                        <Button onClick={() => {
                                            this.setState({ countryName: 'testing1' })
                                            this.props.navigation.navigate('UserProfileAddAddress')
                                        }} style={{ backgroundColor: '#32cba1', padding: '15px 50px' }}>Add</Button>
                                    </div>




                                </Box>

                            </Grid>

                        </Grid>
                          <VisionStatement navigation={this.props.navigation} id={''} classes={undefined} openSearch={undefined}  />

                    </Container>

                    {/* <div style={{ marginRight: "30px" }} >
                        <div style={{
                            width: "280px", height: "55px", border: "1px solid #F4F5F6", borderBottom: "none", borderTopRightRadius: "3px",
                            borderTopLeftRadius: "3px", backgroundColor: "#fff"
                        }}>
                            <p style={{ margin: "18px", fontSize: "16px", fontWeight: "bold", cursor: "pointer" }}
                                onClick={() => this.props.navigation.navigate("UserProfileBasicBlock")}
                            >Profile Details</p>
                        </div>
                        <div style={{
                            width: "280px", height: "55px", border: "1px solid #F4F5F6", borderBottomRightRadius: "3px",
                            borderBottomLeftRadius: "3px", backgroundColor: "#fff"
                        }}>
                            <p style={{ margin: "18px", fontSize: "16px", fontWeight: "bold" }}>Saved Addresses</p>
                        </div>

                    </div>
                    <div style={{ width: "2000px", height: "420px", border: "1px solid #F4F5F6", backgroundColor: "#fff" }} >
                        <Grid container style={{ width: "89%", height: "110px", margin: "50px", border: "1px solid black" }} >
                            <div style={{ marginLeft: "10px" }}>
                                <p>{this.state.getProfileData?.first_name}</p>
                                <p></p>
                            </div>
                            <div style={{ display: "flex", marginTop: "54px" }}>
                                <a href='UserProfileEditAddress' style={{ textDecoration: "none" }}>
                                    <p style={{ color: "red", marginRight: "28px" }}>edit</p>
                                </a>
                                <a href='UserProfileDeleteAddress' style={{ textDecoration: "none" }}>
                                    <p style={{ color: "red" }}>delete</p>
                                </a>
                            </div>
                        </Grid>
                        <Grid container style={{ width: "89%", height: "110px", margin: "0px 50px", border: "1px solid black" }} >
                            <div style={{ marginLeft: "10px" }}>
                                <p>{this.state.getProfileData?.first_name}</p>
                                <p></p>
                            </div>
                            <div style={{ display: "flex", marginTop: "54px" }}>
                                <a href='UserProfileEditAddress' style={{ textDecoration: "none" }}>
                                    <p style={{ color: "red", marginRight: "28px" }}>edit</p>
                                </a>
                                <a href='UserProfileDeleteAddress' style={{ textDecoration: "none" }}>
                                    <p style={{ color: "red" }}>delete</p>
                                </a>
                            </div>

                        </Grid>
                        <Grid container style={{ justifyContent: "center", alignItems: "center", marginTop: "30px" }}>
                            <a href='UserProfileAddAddress' style={{ textDecoration: "none" }}>
                                <div style={{
                                    width: "140px", height: "45px", textAlign: "center", justifyContent: "center",
                                    borderRadius: "4px", backgroundColor: "#32cba1"
                                }} >
                                    <p style={{ padding: "13px" }}>Add</p>
                                </div>
                            </a>
                        </Grid>
                    </div> */}
                </div>
                <Modal

                    open={this.state.openDeleteModal}
                    onClose={this.handleClose}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}>
                    <Fade in={this.state.openDeleteModal}>

                        <Box sx={style}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ fontWeight: 'bold', fontSize: '20px' }}>Delete Address?</div>
                                <div onClick={this.handleClose} style={{ cursor: 'pointer' }}><CloseIcon /></div>

                            </div>

                            <div style={{ color: '#B1B6C3', marginTop: '15px' }}>Do you wish to delete this address?</div>

                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '50px' }}>
                                <Button onClick={this.handleClose} style={{ backgroundColor: '#F3F3F3', marginRight: '10px', padding: '15px 50px' }}>No</Button>
                                <Button onClick={() => this.deleteAddress(this.state.singleAddress?.id)} style={{ color: 'white', backgroundColor: '#DD403A', padding: '15px 50px' }}>Yes</Button>

                            </div>
                        </Box>
                    </Fade>
                </Modal>


            </div >
        )
    }
}
