import React from "react";


  // Customizable Area Start
  // Customizable Area End


// Customizable Area Start

import SearchIcon from '@material-ui/icons/Search';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

import { Container,
  Box,
  Input,
  Typography,
  InputAdornment,
  IconButton,Card, CardContent,Avatar,Grid} from "@material-ui/core"

import { NavLink } from "react-router-dom"



const EzBatteryWhite = require("../assets/Artboard 1 copy@4x.png")
const greenTick = require("../assets/greentick.png")
const yellowStar = require("../assets/yellow_star.png")
const trippleLine = require("../assets/tripple_line.png")
const sosImage = require("../assets/sosImage.png")



const Styles = {
  inputSearchBox: {
    width: "25px",
    height: "35px",
    alignItems: "center",
    border: "1px solid #b30000",
    borderRadiusRight: "5px",
    backgroundColor: "#b30000",
    paddingRight: "10px",
    color: "white",
    cursor: "pointer"
  },
  cardhead: {
    font: "10px",
    color: "grayText",
    textDecoration: "underline",
    textDecorationColor: "gray",
    textDecorationThickness: "1px",
  }
}


// Customizable Area End

import VideoManagementController, {
  Props,
} from "./VideoManagementController";

export default class VideoManagement extends VideoManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <div style={{ overflowX: "hidden" }}>
          <div style={{ display: "flex", flexDirection: "column", overflowX: "hidden", borderBottom: "5px solid #32cba1" }}>
            <Grid container style={{ backgroundColor: "red", height: "80px" }}>
              <div style={{ width: "400px", marginRight: "30px" }}>
                <div style={{ display: "flex", marginLeft: "200px" }}>
                  <img src={trippleLine} style={{ width: "20px", height: "20px", marginTop: "30px", marginRight: "20px" }} />
                  <NavLink
                    exact
                    activeClassName="active_class"
                    to="/"
                    style={{ textDecoration: "none" }}
                  >
                    <img src={EzBatteryWhite} style={{ width: "100px", height: "40px", color: "white", marginTop: "20px" }} />
                  </NavLink>
                </div>
              </div>

              <div style={{ marginTop: "20px" }}>
                <Input
                  id="input-with-icon-adornment"
                  value={this.state.searchValue}
                  placeholder='Search Battery type/Category/Model Name/Model Number'
                  style={{
                    width: "470px", border: "1px solid red", borderBlock: "none", backgroundColor: "white",
                    borderRight: "2px solid #b30000",
                    borderRadius: "4px"
                  }}
                  onChange={(e: any) => {
                    this.setState({ searchValue: e.target.value })
                  }}
                  onClick={this.handleSearch}
                  endAdornment={
                    <InputAdornment position="end"
                      style={Styles.inputSearchBox}
                    >
                      <SearchIcon />

                    </InputAdornment>
                  }
                />
                <div style={{ position: "relative", backgroundColor: "white" }}>
                  {this.state.openSearch &&
                    <div>
                      <Typography style={{ padding: "10px", fontSize: "14px", cursor: "pointer" }}
                        onClick={this.recentSearchCall}
                      >
                        Recent searches
                      </Typography>

                      {this.state.recentSearchData?.map((item: any, index: any) => {
                        return (
                          <div key={index} style={{ display: "flex", marginTop: "-25px" }}>
                            <p style={{ marginLeft: "10px" }}>
                              {item?.search_term}
                            </p>
                            {item?.search_term &&
                              <p style={{ marginLeft: "355px", cursor: "pointer" }}
                                onClick={() => {
                                  let x = this.state.recentSearchData
                                  x[index].search_term = !x[index].search_term
                                  this.setState({ recentSearchData: [...x] })
                                  localStorage.setItem("recentSearchId", this.state.recentSearchData?.id);
                                  this.deleteRecentSearchCall(item.id)
                                }}>X</p>
                            }
                          </div>
                        )
                      })}



                      <div style={{ display: "flex" }}>
                      <NavLink
                          exact
                          activeClassName="active_class"
                          to="/AllBatteries"
                          style={{ textDecoration: "none" }}
                        >
                          <div style={{ width: "194px", margin: "10px", padding: "10px", backgroundColor: "#d6f5ed", borderRadius: "5px" }}>
                            <p style={{ textAlign: 'center', fontSize: "12px" }}>
                              <b>All Batteries</b>
                            </p>
                          </div>
                        </NavLink>
                        <NavLink
                          exact
                          activeClassName="active_class"
                          to="/Filteritems"
                          style={{ textDecoration: "none" }}
                        >
                          {/* <a href='Filteritems' style={{textDecoration:"none"}}> */}
                          <div style={{ margin: "10px", width: "195px", padding: "10px", backgroundColor: "#32cba1", borderRadius: "5px" }}
                          // onClick={()=>{this.setState({open:true})}}
                          >
                            <p style={{ textAlign: 'center', fontSize: "12px" }}>
                              <b>Need help with battery selection</b>
                            </p>
                          </div>
                        </NavLink>
                        {/* </a> */}
                      </div>
                      <Avatar
                        style={{
                          width: "25px",
                          height: "25px",
                          fontSize: "20px",
                          margin: "17px 10px",
                          backgroundColor: "red",
                          marginLeft: "222px",
                          cursor: "pointer"
                        }}
                        onClick={() => this.setState({ openSearch: false })}
                      >
                        X
                      </Avatar>
                      {/* <Typography style={{ textAlign: "center" }}
                     >
                      <b>X</b>
                    </Typography> */}
                    </div>
                  }

                </div>
              </div>

              <div>
                <img src={sosImage} style={{ width: "40px", height: "30px", marginTop: "20px", marginLeft: "100px" }} />
              </div>
              <div style={{ display: "flex", color: "white", marginTop: "25px", marginLeft: "40px" }}>
                <div style={{ marginRight: "30px" }}>
                  <NotificationsNoneIcon />
                </div>
                <ShoppingCartIcon onClick={() => this.props.navigation.navigate("AddShoppingCartOrderItem")}
                  style={{ cursor: "pointer" }} />
              </div>
              <div>
                <a
                  href='EmailAccountLoginBlock'
                  style={{ textDecoration: "none" }}
                >
                  <p style={{ color: "white", marginTop: "30px", marginLeft: "200px" }} >Login</p>
                </a>
              </div>

            </Grid>
            <Grid container style={{ backgroundColor: "#b30000", height: "40px" }}>

              <p style={{ marginLeft: "170px", marginRight: "40px", color: "white", fontSize: "13px", cursor: "pointer" }}
                onClick={() => this.props.navigation.navigate("Categoriessubcategories")}>
                2 WHEELER BATTERIES</p>
              <p style={{ marginRight: "40px", color: "white", fontSize: "13px", cursor: "pointer" }}
                onClick={() => this.props.navigation.navigate("ThreeWheeler")}>
                3 WHEELER BATTERIES</p>

              <p style={{ marginRight: "40px", color: "white", fontSize: "13px", cursor: "pointer" }}
                onClick={() => this.props.navigation.navigate("CarSuvMuvBatteries")}
              >CAR/SUV/MUV BATTERIES</p>
              <p style={{ marginRight: "40px", color: "white", fontSize: "13px", cursor: "pointer" }}
                onClick={() => { this.props.navigation.navigate("CommercialVehicle") }}
              >COMMERCIAL VEHICLE BATTERIES</p>
              <p style={{ marginRight: "40px", color: "white", fontSize: "13px", cursor: "pointer" }}
                onClick={() => { this.props.navigation.navigate("TractorDashboard") }}
              >TRACTOR BATTERIES</p>
              <p style={{ marginRight: "40px", color: "white", fontSize: "13px", cursor: "pointer" }}
                onClick={() => { this.props.navigation.navigate("InverterDashboard") }}
              >INVERTER BATTERIES</p>
            </Grid>
            <Box style={{ background: "lightgrey", padding: "10px", height: "24px" }}>
              <Container style={{ display: "flex" }} >
                <Typography style={{ marginLeft: "20px" }}> Home <ArrowForwardIosRoundedIcon className="arrow-left" /> Gallery</Typography>
                <Typography style={{ display: "flex", marginLeft: "795px" }}>For better experience
                  <p style={{ color: "red", marginTop: "0px" }}>Login/</p><p style={{ color: "red", marginTop: "0px" }}>Register</p>
                </Typography>
              </Container>
            </Box>

            {this.state.showSearchData && this.state.openSearch &&
              <div style={{ marginTop: "165px", marginLeft: "155px", marginRight: "155px" }}>
                <Grid container style={{ display: "flex", flexDirection: "row", position: "relative", }} >
                  {/* <Grid item xs={2} /> */}
                  {this.state.serachData?.map((item: any, index: any) => {
                    return (
                      <Grid item xs={3} key={index} >


                        <Box m={1}>
                          <Card style={{ width: "250px", height: "400px" }} >
                            <div style={{ display: "flex", flexDirection: "row" }}>
                              <img style={{ width: "10%", height: "10%", marginLeft: "10px", marginTop: "10px" }} src={greenTick} />
                              <p style={Styles.cardhead}>{item?.attributes?.warranty} warranty</p>

                              <IconButton aria-label="settings" style={{ marginTop: "5px" }}>
                                <FavoriteBorderIcon />
                              </IconButton>

                            </div>

                            <CardContent>
                              <img style={{ display: "block", marginLeft: "60px", marginBottom: "15px", width: "50%", height: "30%" }} src={item?.attributes?.thumbnail_image} />
                              <div style={{ marginLeft: "30px" }}>
                                {/* <div style={{ width: "80%", border: "1px solid white", borderRadius: "10px", backgroundColor: "#ff9999", textAlign: "center" }}>
                                Category:Inverter
                              </div> */}
                              </div>
                              <p style={{ textAlign: "center" }}>{item?.attributes?.model_no}</p>
                              <p style={{ textAlign: "center" }}>{item?.attributes?.capaciy}</p>
                              <p style={{ textAlign: "center" }}>${item?.attributes?.amount}</p>
                              <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                <div style={{ width: "35px", height: "20px", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", border: "1px solid gray", borderRadius: "10px" }}>
                                  <img style={{ width: "10px", height: "10px" }} src={yellowStar} />
                                  <p>{item?.attributes?.range}</p>
                                </div>
                                <div style={{ marginLeft: "10px" }}>
                                  <div style={{ width: "70px", border: "1px solid gray", borderRadius: "10px", backgroundColor: "green" }}>
                                    {item?.attributes?.applied_discount}% off
                                  </div>
                                </div>
                              </div>
                            </CardContent>
                          </Card>
                        </Box>
                      </Grid>


                    )

                  })

                  }
                </Grid>
              </div>
            }

           

          </div>
         
         
        </div>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
